import { useMutation } from 'react-query'

// Services
import { CreateCase } from 'services/api/Cases'

export default () => {
  const {
    mutate: createCase,
    data: createCaseData,
    error: createCaseError,
    isLoading: isCreateCaseLoading,
    isSuccess: isCreateCaseSuccess,
    isError: isCreateCaseError,
  } = useMutation(CreateCase)

  return {
    createCase,
    createCaseData,
    createCaseError,
    isCreateCaseLoading,
    isCreateCaseSuccess,
    isCreateCaseError,
  }
}
