import styled from 'styled-components'

export const BadgeTextWrapper = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;

  margin-left: 1px;
  margin-right: 1px;
  width: fit-content;
  border-radius: 5px;
  font-weight: inherit;
  font-size: inherit;

  // Conditional Properties
  ${({ height }) => height && `height: ${height}px;`}
  ${({ width }) => width && `width: ${width}px;`};
  ${({ color }) => color && `background-color: ${color}33;`};
`

export const BadgeTextValue = styled.div`
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ color }) => color && `color: ${color};`};
`
