/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './ScenariosTableHeader.elements'

function ScenariosTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper>
        <TableRowContainer>
          <TableText>Scenario</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Severity</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Type</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Status</TableText>
        </TableRowContainer>
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
ScenariosTableHeader.defaultProps = {}

// Proptypes Validation
ScenariosTableHeader.propTypes = {}
export default ScenariosTableHeader
