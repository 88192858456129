import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalFooterButton = styled.div`
  & button {
    border: none !important;
    & svg {
      max-width: 20px !important;
      max-height: 20px !important;
    }
  }
  & button:hover {
    & svg path {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }
  }
`

// COMPONENT WRAPPERS
export const ChartWrapper = styled.div`
  padding: 25px 10px 10px 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  margin-bottom: 30px;
  width: 100%;
`

// LAYOUT COMPONENTS
export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
`
export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
`
export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`
export const GridRowContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 1fr 2fr;
  gap: 10px;
  width: 100%;
`

// COMMON COMPONENTS
export const Title = styled.h2`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Text = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 400;
`
export const TinyText = styled.span`
  margin: 0;
  font-size: 8px;
  font-weight: 400;
`
export const SmallText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 400;
`
