// Route Components
import {
  Login,
  LoginTwoFa,
  VaspSignup,
  UserSignup,
  VaspEmailConfirmation,
  VaspUserEmailConfirmation,
  EmailVerification,
  ResetPassword,
  ResetPasswordConfirm,
  UpdatePassword,
  NotFound,
  UserDetails,
  Settings,
  Cases,
  CaseDetails,
  Policies,
  PolicyDetails,
  Accounts,
  AccountDetails,
  Recipients,
  RecipientDetails,
  Vasps,
  VaspDetails,
  TravelRule,
  Address,
  Transactions,
  // Investigation,
  ReportsAddress,
  ReportsTransaction,
  ReportsTravelRule,
  // Admin
  VaspNotification,
  VaspDetailsAdmin,
  RequestedVaspNotification,
} from 'views/pages'

export default [
  // Home
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: TravelRule,
      path: '/travelrule',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Cases,
      path: '/cases',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Policies,
      path: '/cases/policies',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: PolicyDetails,
      path: '/cases/policies/:policyId',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: CaseDetails,
      path: '/cases/details/:caseId',
    },
  },
  // Directory
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Accounts,
      path: '/directory',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Recipients,
      path: '/directory/recipients',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Vasps,
      path: '/directory/vasps',
    },
  },
  // Directory Details
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: AccountDetails,
      path: '/directory/accounts/details/:account_id',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: RecipientDetails,
      path: '/directory/recipients/details/:account_id',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: VaspDetails,
      path: '/directory/vasps/details/:vasp_id',
    },
  },

  // Blockchain Analytics
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Address,
      path: '/blockchain-analytics',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Transactions,
      path: '/blockchain-analytics/transactions',
    },
  },
  // Reports
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: ReportsAddress,
      path: '/reports',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: ReportsTransaction,
      path: '/reports/transaction',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: ReportsTravelRule,
      path: '/reports/travelrule',
    },
  },
  // User Profile
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: UserDetails,
      path: '/user-details/:user_id',
    },
  },
  // Settings
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: false,
    isRoutePublic: false,
    routing: {
      component: Settings,
      path: '/settings',
    },
  },
  // ----- ADMIN ROUTES -----
  // Vasp Notification
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: true,
    isRoutePublic: false,
    routing: {
      component: VaspNotification,
      path: '/admin/vaspnotification',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: true,
    isRoutePublic: false,
    routing: {
      component: RequestedVaspNotification,
      path: '/admin/vaspnotification/requested',
    },
  },
  {
    exact: true,
    isRouteProtected: true,
    isRouteAdmin: true,
    isRoutePublic: false,
    routing: {
      component: VaspDetailsAdmin,
      path: '/admin/details/vaspnotification/:vasp_id',
    },
  },
  // Investigation
  // {
  //   exact: true,
  //   isRouteProtected: true,
  //   isRouteAdmin: false, isRoutePublic: false,
  //   routing: {
  //     component: Investigation,
  //     path: '/investigation/:hash',
  //   },
  // },
  // Vasp Signup
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: VaspSignup, path: '/register/:token' },
  },
  // Vasp Email Confirmation
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: VaspEmailConfirmation, path: '/confirm_email/:hash' },
  },
  // Vasp User Email Confirmation
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: VaspUserEmailConfirmation, path: '/confirm_vasp_user/:hash' },
  },
  // Login
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: Login, path: '/login' },
  },
  // Email Verification
  {
    isRouteAdmin: false,
    isRouteProtected: false,
    isRoutePublic: true,
    exact: true,
    routing: { component: EmailVerification, path: '/email/confirm/:auth_hash' },
  },
  // LoginTwoFa
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: LoginTwoFa, path: '/login/:auth_hash' },
  },
  // User Activation
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: UserSignup, path: '/user/activation/:auth_hash' },
  },
  // Update Password
  {
    isRouteAdmin: false,
    isRoutePublic: false,
    isRouteProtected: true,
    exact: true,
    routing: { component: UpdatePassword, path: '/update_password' },
  },
  // Reset Password
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: ResetPasswordConfirm, path: '/reset_password/:auth_hash' },
  },
  {
    isRouteAdmin: false,
    isRoutePublic: true,
    isRouteProtected: false,
    exact: true,
    routing: { component: ResetPassword, path: '/reset_password' },
  },
  {
    isRouteAdmin: false,
    isRoutePublic: false,
    isRouteProtected: false,
    exact: false,
    routing: { component: NotFound },
  },
]
