import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

// Constants
import { VASP_SORT_DATE_FILTER } from 'common/constants/filters'

// Store
import { actions } from 'core/store'

// Hooks
import { useSearchMidProspect } from 'core/hooks/api'

// Styled Elements
import { TableFilterFormWrapper } from '../Filter.elements'

// Components
import { SimpleSelect, Search } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function RequestedVaspTableFilter(props) {
  // Destructure
  const { filters, actions } = props
  const { vaspDirectoryFilters } = filters
  const { sort } = vaspDirectoryFilters

  // Store Actions
  const { setShowHeaderLoader, setVaspDirectoryFilters } = actions

  // States
  const [searchResults, setSearchResults] = useState([])

  // Hooks
  const { searchMidProspect, midProspectSearchData, isVaspSearchLoading } = useSearchMidProspect()
  const history = useHistory()

  // Functions
  // Filter Functions
  const handleSortSelect = async (value) => {
    if (sort !== value) {
      await setVaspDirectoryFilters({ ...vaspDirectoryFilters, sort: value })
    }
  }
  // Search Functions
  const handleOnAutoCompleteSelect = (value) => {
    history.push(`/directory/vasps/details/${value}`)
  }
  const handleOnAutoCompleteInputChange = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value) return searchMidProspect({ search_term: value })
  }, 500)

  // useEffect
  useEffect(() => {
    if (midProspectSearchData) {
      setSearchResults(() =>
        midProspectSearchData.map(({ name_business, name_legal, id }) => ({
          label: name_business,
          subLabel: name_legal,
          value: id,
        }))
      )
    }
    if (!midProspectSearchData) {
      setSearchResults([])
    }
  }, [midProspectSearchData])

  useEffect(() => {
    if (isVaspSearchLoading) setShowHeaderLoader(true)
    else if (!isVaspSearchLoading) setShowHeaderLoader(false)
  }, [isVaspSearchLoading])

  return (
    <TableFilterFormWrapper>
      <Search
        options={searchResults}
        placeholder="Search ID or Name..."
        onInputChange={handleOnAutoCompleteInputChange}
        onSelect={handleOnAutoCompleteSelect}
        isLoading={isVaspSearchLoading}
        filter={
          <SimpleSelect
            id="VASPSort"
            label="Sort By: "
            options={VASP_SORT_DATE_FILTER}
            onChange={handleSortSelect}
            value={sort}
          />
        }
      />
    </TableFilterFormWrapper>
  )
}

// Default Props
RequestedVaspTableFilter.defaultProps = {
  filters: {},
  actions: {},
}

// Proptypes Validation
RequestedVaspTableFilter.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.shape({
      sort: PropTypes.string,
      vasp_type_id: PropTypes.number,
      vasp_status_id: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setVaspDirectoryFilters: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestedVaspTableFilter)
