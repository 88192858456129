export const queryBuilderConfig = {
	conjunctions: {
			AND: {
					label: 'And',
					mongoConj: '$and',
					reversedConj: 'OR'
			},
			OR: {
					label: 'Or',
					mongoConj: '$or',
					reversedConj: 'AND'
			}
	},
	operators: {
			equal: {
					label: 'Equals To',
					labelForFormat: '==',
					sqlOp: '=',
					reversedOp: 'not_equal',
					jsonLogic: '==',
					elasticSearchQueryType: 'term'
			},
			not_equal: {
					isNotOp: true,
					label: 'Not Equals',
					labelForFormat: '!=',
					sqlOp: '<>',
					reversedOp: 'equal',
					jsonLogic: '!='
			},
			less: {
					label: 'Less Than',
					labelForFormat: '<',
					sqlOp: '<',
					reversedOp: 'greater_or_equal',
					jsonLogic: '<',
					elasticSearchQueryType: 'range'
			},
			less_or_equal: {
					label: 'Less Than or Equal To',
					labelForFormat: '<=',
					sqlOp: '<=',
					reversedOp: 'greater',
					jsonLogic: '<=',
					elasticSearchQueryType: 'range'
			},
			greater: {
					label: 'Greater Than',
					labelForFormat: '>',
					sqlOp: '>',
					reversedOp: 'less_or_equal',
					jsonLogic: '>',
					elasticSearchQueryType: 'range'
			},
			greater_or_equal: {
					label: 'Greater Than or Equal To',
					labelForFormat: '>=',
					sqlOp: '>=',
					reversedOp: 'less',
					jsonLogic: '>=',
					elasticSearchQueryType: 'range'
			},
			like: {
					label: 'Like',
					labelForFormat: 'Like',
					reversedOp: 'not_like',
					sqlOp: 'LIKE',
					jsonLogic: 'in',
					_jsonLogicIsRevArgs: true,
					valueSources: [
							'value'
					],
					elasticSearchQueryType: 'regexp'
			},
			not_like: {
					isNotOp: true,
					label: 'Not like',
					reversedOp: 'like',
					labelForFormat: 'Not Like',
					sqlOp: 'NOT LIKE',
					valueSources: [
							'value'
					]
			},
			starts_with: {
					label: 'Starts with',
					labelForFormat: 'Starts with',
					sqlOp: 'LIKE',
					valueSources: [
							'value'
					]
			},
			ends_with: {
					label: 'Ends with',
					labelForFormat: 'Ends with',
					sqlOp: 'LIKE',
					valueSources: [
							'value'
					]
			},
			between: {
					label: 'Between',
					labelForFormat: 'BETWEEN',
					sqlOp: 'BETWEEN',
					cardinality: 2,
					valueLabels: [
							'Value from',
							'Value to'
					],
					textSeparators: [
							null,
							'and'
					],
					reversedOp: 'not_between',
					jsonLogic: '<='
			},
			not_between: {
					isNotOp: true,
					label: 'Not between',
					labelForFormat: 'NOT BETWEEN',
					sqlOp: 'NOT BETWEEN',
					cardinality: 2,
					valueLabels: [
							'Value from',
							'Value to'
					],
					textSeparators: [
							null,
							'and'
					],
					reversedOp: 'between'
			},
			is_empty: {
					label: 'Is empty',
					labelForFormat: 'IS EMPTY',
					sqlOp: 'IS EMPTY',
					cardinality: 0,
					reversedOp: 'is_not_empty',
					jsonLogic: '!'
			},
			is_not_empty: {
					isNotOp: true,
					label: 'Is not empty',
					labelForFormat: 'IS NOT EMPTY',
					sqlOp: 'IS NOT EMPTY',
					cardinality: 0,
					reversedOp: 'is_empty',
					jsonLogic: '!!',
					elasticSearchQueryType: 'exists'
			},
			select_equals: {
					label: 'Equals To',
					labelForFormat: '==',
					sqlOp: '=',
					reversedOp: 'select_not_equals',
					jsonLogic: '==',
					elasticSearchQueryType: 'term'
			},
			select_not_equals: {
					isNotOp: true,
					label: '!=',
					labelForFormat: '!=',
					sqlOp: '<>',
					reversedOp: 'select_equals',
					jsonLogic: '!='
			},
			select_any_in: {
					label: 'Any in',
					labelForFormat: 'IN',
					sqlOp: 'IN',
					reversedOp: 'select_not_any_in',
					jsonLogic: 'in',
					elasticSearchQueryType: 'term'
			},
			select_not_any_in: {
					isNotOp: true,
					label: 'Not in',
					labelForFormat: 'NOT IN',
					sqlOp: 'NOT IN',
					reversedOp: 'select_any_in'
			},
			multiselect_equals: {
					label: 'Equals To',
					labelForFormat: '==',
					sqlOp: '=',
					reversedOp: 'multiselect_not_equals',
					jsonLogic2: 'all-in',
					elasticSearchQueryType: 'term'
			},
			multiselect_not_equals: {
					isNotOp: true,
					label: 'Not equals',
					labelForFormat: '!=',
					sqlOp: '<>',
					reversedOp: 'multiselect_equals'
			},
			proximity: {
					label: 'Proximity search',
					cardinality: 2,
					valueLabels: [
							{
									label: 'Word 1',
									placeholder: 'Enter first word'
							},
							{
									label: 'Word 2',
									placeholder: 'Enter second word'
							}
					],
					textSeparators: [],
					options: {
							optionLabel: 'Near',
							optionTextBefore: 'Near',
							optionPlaceholder: 'Select words between',
							minProximity: 2,
							maxProximity: 10,
							defaults: {
									proximity: 2
							}
					}
			},
			some: {
					label: 'Some',
					labelForFormat: 'SOME',
					cardinality: 0,
					jsonLogic: 'some'
			},
			all: {
					label: 'All',
					labelForFormat: 'ALL',
					cardinality: 0,
					jsonLogic: 'all'
			},
			none: {
					label: 'None',
					labelForFormat: 'NONE',
					cardinality: 0,
					jsonLogic: 'none'
			}
	},
	types: {
			text: {
					defaultOperator: 'equal',
					mainWidget: 'text',
					widgets: {
							text: {
									operators: [
											'equal',
											'not_equal',
											'is_empty',
											'is_not_empty',
											'like',
											'not_like',
											'starts_with',
											'ends_with',
											'proximity'
									],
									widgetProps: {},
									opProps: {}
							},
							textarea: {
									operators: [
											'equal',
											'not_equal',
											'is_empty',
											'is_not_empty',
											'like',
											'not_like',
											'starts_with',
											'ends_with'
									],
									widgetProps: {},
									opProps: {}
							},
							field: {
									operators: [
											'equal',
											'not_equal',
											'proximity'
									]
							}
					}
			},
			number: {
					defaultOperator: 'equal',
					mainWidget: 'number',
					widgets: {
							number: {
									operators: [
											'equal',
											'less',
											'less_or_equal',
											'greater',
											'greater_or_equal',
									]
							},
							slider: {
									operators: [
										'equal',
										'less',
										'less_or_equal',
										'greater',
										'greater_or_equal',
									]
							},
					}
			},
			date: {
					defaultOperator: 'equal',
					widgets: {
							date: {
									operators: [
											'equal',
											'not_equal',
											'less',
											'less_or_equal',
											'greater',
											'greater_or_equal',
											'between',
											'not_between',
											'is_empty',
											'is_not_empty'
									],
									opProps: {
											between: {
													isSpecialRange: true,
													textSeparators: [
															null,
															null
													]
											},
											not_between: {
													isSpecialRange: true,
													textSeparators: [
															null,
															null
													]
											}
									}
							}
					}
			},
			time: {
					defaultOperator: 'equal',
					widgets: {
							time: {
									operators: [
											'equal',
											'not_equal',
											'less',
											'less_or_equal',
											'greater',
											'greater_or_equal',
											'between',
											'not_between',
											'is_empty',
											'is_not_empty'
									]
							}
					}
			},
			datetime: {
					defaultOperator: 'equal',
					widgets: {
							datetime: {
									operators: [
											'equal',
											'not_equal',
											'less',
											'less_or_equal',
											'greater',
											'greater_or_equal',
											'between',
											'not_between',
											'is_empty',
											'is_not_empty'
									]
							}
					}
			},
			select: {
					mainWidget: 'select',
					defaultOperator: 'select_equals',
					widgets: {
							select: {
									operators: [
											'select_equals',
									],
									widgetProps: {
											customProps: {
													showSearch: true
											}
									}
							},
					}
			},
			multiselect: {
					defaultOperator: 'multiselect_equals',
					widgets: {
							multiselect: {
									operators: [
											'multiselect_equals',
											'select_any_in',
									]
							}
					}
			},
			boolean: {
					defaultOperator: 'equal',
					widgets: {
							boolean: {
									operators: [
											'equal',
											'not_equal'
									],
									widgetProps: {}
							},
							field: {
									operators: [
											'equal',
											'not_equal'
									]
							}
					}
			},
			'!group': {
					defaultOperator: 'some',
					mainWidget: 'number',
					widgets: {
							number: {
									widgetProps: {
											min: 0
									},
									operators: [
											'some',
											'all',
											'none',
											'equal',
											'not_equal',
											'less',
											'less_or_equal',
											'greater',
											'greater_or_equal',
											'between',
											'not_between'
									],
									opProps: {
											equal: {
													label: 'Count =='
											},
											not_equal: {
													label: 'Count !='
											},
											less: {
													label: 'Count <'
											},
											less_or_equal: {
													label: 'Count <='
											},
											greater: {
													label: 'Count >'
											},
											greater_or_equal: {
													label: 'Count >='
											},
											between: {
													label: 'Count between'
											},
											not_between: {
													label: 'Count not between'
											}
									}
							}
					}
			},
			treeselect: {
					mainWidget: 'treeselect',
					defaultOperator: 'select_equals',
					widgets: {
							treeselect: {
									operators: [
											'select_equals',
											'select_not_equals'
									]
							},
							treemultiselect: {
									operators: [
											'select_any_in',
											'select_not_any_in'
									]
							}
					}
			},
			treemultiselect: {
					defaultOperator: 'multiselect_equals',
					widgets: {
							treemultiselect: {
									operators: [
											'multiselect_equals',
											'multiselect_not_equals'
									]
							}
					}
			}
	},
}
