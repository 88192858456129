import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Assets
import { CloseIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Hooks
import { usePostAddressReport } from 'core/hooks/api'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton, RowContainer } from './ReportAddressModal.elements'

// Views
import {
  LoadingTablePlaceholder,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Radio,
  Checkbox,
} from 'views/components'

// constants
import { REPORT_DATA_TYPE_OPTIONS, REPORT_DATE_RANGE_OPTIONS } from 'common/constants/formOptions'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ReportAddressModal(props) {
  // Destructure
  const { ui, address, actions } = props

  // Store Actions
  const { toggleAddressReportModal, setAddressReportDetails } = actions

  // Store State
  const { isAddressReportModalOpen } = ui
  const { addressReportDetails } = address

  // Hooks
  const { postAddressReport, postAddressReportData, isPostAddressReportError, isPostAddressReportLoading } =
    usePostAddressReport()

  // Local State
  const [formValue, setFormValue] = useState({ sections: [], date_range: '' })
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  // Functions
  const toggleModal = () => {
    toggleAddressReportModal()
    setAddressReportDetails({})
  }
  const handleSubmit = () => {
    let start_range = moment().subtract(30, 'days').format(DASHED_DATE_FORMAT)
    const end_range = moment().add(1, 'days').format(DASHED_DATE_FORMAT)
    if (REPORT_DATE_RANGE_OPTIONS[0].value === formValue.date_range) {
      start_range = moment().subtract(30, 'days').format(DASHED_DATE_FORMAT)
    }
    if (REPORT_DATE_RANGE_OPTIONS[1].value === formValue.date_range) {
      start_range = moment().subtract(180, 'days').format(DASHED_DATE_FORMAT)
    }
    if (REPORT_DATE_RANGE_OPTIONS[2].value === formValue.date_range) {
      start_range = moment().subtract(365, 'days').format(DASHED_DATE_FORMAT)
    }
    postAddressReport({
      address_id: addressReportDetails.address_id,
      sections: formValue.sections,
      start_range,
      end_range,
    })
  }

  // UseEffects
  useEffect(() => postAddressReportData && setIsSuccess(true), [postAddressReportData])
  useEffect(() => isPostAddressReportError && setIsError(true), [isPostAddressReportError])
  useEffect(() => {
    if (isAddressReportModalOpen) {
      setFormValue({ sections: [], date_range: '', format: '' })
      setIsError(false)
      setIsSuccess(false)
    }
  }, [isAddressReportModalOpen])
  return (
    <Modal isOpen={isAddressReportModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<CloseIcon onClick={toggleModal} />}>
        REQUEST REPORT
      </ModalHeader>
      <ModalBody>
        {isError && !isPostAddressReportLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            An error occured in generating a report. Please try again later.
          </ModalBodyWrapper>
        )}
        {isSuccess && !isPostAddressReportLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <span>
              Successfully requested for report, generating the report may take a few minutes. Please view the progress
              in the <a href="/reports">reports tab</a>.
            </span>
          </ModalBodyWrapper>
        )}
        {isPostAddressReportLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <LoadingTablePlaceholder style={{ padding: '45px 0' }} />
          </ModalBodyWrapper>
        )}
        {!isPostAddressReportLoading && !isError && !isSuccess && (
          <ModalBodyWrapper>
            <RowContainer style={{ justifyContent: 'flex-start', gap: '50px' }}>
              <Checkbox
                value={formValue.sections}
                onChange={(value) => setFormValue({ ...formValue, sections: value })}
                label="DATA TYPE"
                options={REPORT_DATA_TYPE_OPTIONS}
                name="sections"
              />
              <Radio
                value={formValue.date_range}
                onChange={(value) => setFormValue({ ...formValue, date_range: value })}
                label="DATA RANGE"
                options={REPORT_DATE_RANGE_OPTIONS}
                name="date_range"
              />
            </RowContainer>
          </ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        {!isPostAddressReportLoading && !isError && !isSuccess && (
          <ModalFooterWrapper>
            <ModalFooterButton onClick={toggleModal}>
              <Button variant="outlined">Cancel</Button>
            </ModalFooterButton>
            <ModalFooterButton>
              <Button
                onClick={handleSubmit}
                variant="outlined"
                disabled={formValue.sections.length < 1 || !formValue.date_range}
              >
                Request
              </Button>
            </ModalFooterButton>
          </ModalFooterWrapper>
        )}
      </ModalFooter>
    </Modal>
  )
}

// Default Props
ReportAddressModal.defaultProps = {
  ui: {},
  address: {},
  actions: {},
}

// Proptypes Validation
ReportAddressModal.propTypes = {
  ui: PropTypes.shape({ isAddressReportModalOpen: PropTypes.bool }),
  address: PropTypes.shape({
    addressReportDetails: PropTypes.shape({
      address_id: PropTypes.string,
      address: PropTypes.string,
      chain: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    toggleAddressReportModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportAddressModal)
