/* eslint-disable max-len */
import React from 'react'

export default function ScanIcon() {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.07524 1.48828H3.74191C3.15254 1.48828 2.58731 1.73247 2.17056 2.16712C1.75381 2.60178 1.51968 3.1913 1.51968 3.80599V7.28255M21.5197 7.28255V3.80599C21.5197 3.1913 21.2856 2.60178 20.8688 2.16712C20.4521 1.73247 19.8868 1.48828 19.2975 1.48828H15.9641M15.9641 22.3477H19.2975C19.8868 22.3477 20.4521 22.1035 20.8688 21.6688C21.2856 21.2342 21.5197 20.6446 21.5197 20.0299V16.5534M1.51968 16.5534V20.0299C1.51968 20.6446 1.75381 21.2342 2.17056 21.6688C2.58731 22.1035 3.15254 22.3477 3.74191 22.3477H7.07524"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
