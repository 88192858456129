import { useMutation } from 'react-query'

// Services
import { GetReportsTransaction } from 'services/api/Reports'

export default () => {
  const {
    mutate: getReportsTransaction,
    data: getReportsTransactionData,
    error: getReportsTransactionError,
    isLoading: isGetReportsTransactionLoading,
    isSuccess: isGetReportsTransactionSuccess,
    isError: isGetReportsTransactionError,
  } = useMutation(GetReportsTransaction)

  return {
    getReportsTransaction,
    getReportsTransactionData,
    getReportsTransactionError,
    isGetReportsTransactionLoading,
    isGetReportsTransactionSuccess,
    isGetReportsTransactionError,
  }
}
