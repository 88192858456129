import { useState } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetDashboardCaseAddresses } from 'services/api/Cases'

export default () => {
  const [dashboardCaseAddressesData, setDashboardCaseAddressesData] = useState({
    title: 'Address Whitelisting Activity',
    figureTotalValue: 0,
    figureTitle: 'All-Time',
    figureSubtitle: 'Activity',
    breakdownData: [
      {
        name: 'BTC',
        data: [0],
      },
      {
        name: 'ETH',
        data: [0],
      },
      {
        name: 'ERC-20',
        data: [0],
      },
      {
        name: 'LTC',
        data: [0],
      },
      {
        name: 'BCH',
        data: [0],
      },
    ],
  })

  const {
    mutate: getDashboardCaseAddresses,
    error: dashboardCaseAddressesError,
    isLoading: isDashboardCaseAddressesLoading,
    isSuccess: isDashboardCaseAddressesSuccess,
    isError: isDashboardCaseAddressesError,
  } = useMutation(GetDashboardCaseAddresses, {
    onSuccess: (data) => {
      setDashboardCaseAddressesData((prevState) => ({
        ...prevState,
        figureTotalValue: data.TotalAddress,
        breakdownData: [
          {
            name: 'BTC',
            data: [data.BTC],
          },
          {
            name: 'ETH',
            data: [data.ETH],
          },
          {
            name: 'ERC-20',
            data: [data['ERC-20']],
          },
          {
            name: 'LTC',
            data: [data.LTC],
          },
          {
            name: 'BCH',
            data: [data.BCH],
          },
        ],
      }))
    },
  })

  return {
    getDashboardCaseAddresses,
    dashboardCaseAddressesData,
    dashboardCaseAddressesError,
    isDashboardCaseAddressesLoading,
    isDashboardCaseAddressesSuccess,
    isDashboardCaseAddressesError,
  }
}
