import { useState } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetWhitelistingRiskBucket } from 'services/api/Whitelisting'

export default () => {
  const [whitelistingRiskBucketData, setWhitelistingRiskBucketData] = useState({
    results: {
      highResult: 0,
      mediumResult: 0,
      lowResult: 0,
    },
    chart: {
      labels: ['Low', 'Medium', 'High'],
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: ['#f5a4a3', '#ffda81', '#9bdbc1'],
          borderWidth: 2,
        },
      ],
    },
    totalValue: 0,
  })

  const {
    mutate: getWhitelistingRiskBucket,
    error: whitelistingRiskBucketError,
    isLoading: isWhitelistingRiskBucketLoading,
    isSuccess: isWhitelistingRiskBucketSuccess,
    isError: isWhitelistingRiskBucketError,
  } = useMutation(GetWhitelistingRiskBucket, {
    onSuccess: (data) => {
      setWhitelistingRiskBucketData({
        results: {
          lowResult: data[0].Total,
          mediumResult: data[1].Total,
          highResult: data[2].Total,
        },
        chart: {
          labels: ['Low', 'Medium', 'High'],
          datasets: [
            {
              data: [data[0].Total, data[1].Total, data[2].Total],
              backgroundColor: ['#f5a4a3', '#ffda81', '#9bdbc1'],
              borderWidth: 2,
            },
          ],
        },
        totalValue: data.reduce((accumulator, { Total }) => accumulator + Total, 0),
      })
    },
  })

  return {
    getWhitelistingRiskBucket,
    whitelistingRiskBucketData,
    whitelistingRiskBucketError,
    isWhitelistingRiskBucketLoading,
    isWhitelistingRiskBucketSuccess,
    isWhitelistingRiskBucketError,
  }
}
