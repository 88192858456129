import styled from 'styled-components'

// Library Components
import { Snackbar } from '@material-ui/core'

export const AlertContent = styled(Snackbar)`
  & .MuiPaper-root {
    border-radius: 8px;
    border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};

    // Variants
    ${({ theme }) => `color: ${theme.colors.pureWhite};`}
    ${({ type, theme }) => type === 'success' && `background-color: ${theme.colors.success} !important;`}
    ${({ type, theme }) => type === 'warning' && `background-color: ${theme.colors.warning} !important;`}
    ${({ type, theme }) => type === 'error' && `background-color: ${theme.colors.red} !important;`}
    ${({ type, theme }) => type === 'info' && `background-color: ${theme.colors.blueGray} !important;`}
  }
  & button {
    padding: 6px !important;
  }
`
