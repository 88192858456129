import styled from 'styled-components'

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
`
