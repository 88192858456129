/* eslint-disable max-len */
import React from 'react'

export default function PolygonMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M326.562 198.469C321.094 195.344 314.062 195.344 307.812 198.469L264.062 224.25L234.375 240.656L191.406 266.438C185.937 269.563 178.906 269.563 172.656 266.438L139.062 246.125C133.594 243 129.687 236.75 129.687 229.719V190.656C129.687 184.406 132.812 178.156 139.062 174.25L172.656 154.719C178.125 151.594 185.156 151.594 191.406 154.719L225 175.031C230.469 178.156 234.375 184.406 234.375 191.438V217.219L264.062 200.031V173.469C264.062 167.219 260.937 160.969 254.687 157.062L192.187 120.344C186.719 117.219 179.687 117.219 173.437 120.344L109.375 157.844C103.125 160.969 100 167.219 100 173.469V246.906C100 253.156 103.125 259.406 109.375 263.313L172.656 300.031C178.125 303.156 185.156 303.156 191.406 300.031L234.375 275.031L264.062 257.844L307.031 232.844C312.5 229.719 319.531 229.719 325.781 232.844L359.375 252.375C364.844 255.5 368.75 261.75 368.75 268.781V307.844C368.75 314.094 365.625 320.344 359.375 324.25L326.562 343.781C321.094 346.906 314.062 346.906 307.812 343.781L274.219 324.25C268.75 321.125 264.844 314.875 264.844 307.844V282.844L235.156 300.031V325.813C235.156 332.063 238.281 338.313 244.531 342.219L307.812 378.937C313.281 382.062 320.312 382.062 326.562 378.937L389.844 342.219C395.312 339.094 399.219 332.844 399.219 325.813V251.594C399.219 245.344 396.094 239.094 389.844 235.188L326.562 198.469Z"
        fill="#00CACD"
      />
    </svg>
  )
}
