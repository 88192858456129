import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

const initialState = {
  accountDirectoryFilters: {
    sort: '-created',
    page: 1,
    size: 10,
    account_type_id: undefined,
    status_id: undefined,
  },
  recipientDirectoryFilters: {
    sort: '-created',
    page: 1,
    size: 10,
    account_type_id: undefined,
  },
  vaspDirectoryFilters: {
    sort: '-created',
    page: 1,
    size: 10,
  },
  travelRuleFilters: {
    sort: '-last_modified',
    page: 1,
    size: 10,
    transfer_status_id: undefined,
    asset_code: undefined,
    direction: undefined,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  },
  addressFilters: {
    sort: '-last_modified',
    page: 1,
    size: 10,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  },
  transactionFilters: {
    sort: '-last_modified',
    page: 1,
    size: 10,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  },
  transactionEventsFilters: {
    page: 1,
    size: 10,
  },
  addressDetailsFilters: {
    sort: '-last_modified',
    page: 1,
    size: 10,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  },
  addressHistoryFilters: {
    address: '',
    address_id: '',
    sort: '-last_modified',
    page: 1,
    size: 10,
    start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  },
  reportsAddressFilters: {
    sort: '-created',
    page: 1,
    size: 10,
    start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  },
  reportsTransactionFilters: {
    sort: '-created',
    page: 1,
    size: 10,
    start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  },
  reportsTravelRuleFilters: {
    page: 1,
    size: 10,
  },
  whitelistingFilters: {
    start: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    sortBy: '',
    sortDirection: 'DESC',
    pageIndex: 0,
    pageSize: 10,
  },
  monitoringFilters: {
    start: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    sortBy: '',
    sortDirection: 'DESC',
    pageIndex: 0,
    pageSize: 10,
  },
  caseManagementFilters: {
    sortBy: 'Case_Since',
    sortDirection: 'DESC',
    page: 0,
    size: 10,
    caseType: undefined,
    caseStatus: undefined,
  },
}

const store = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setAccountDirectoryFilters: (state, { payload }) => {
      if (payload) {
        state.accountDirectoryFilters = {
          ...state.accountDirectoryFilters,
          ...payload,
        }
      } else {
        state.accountDirectoryFilters = initialState.accountDirectoryFilters
      }
    },
    setRecipientDirectoryFilters: (state, { payload }) => {
      if (payload) {
        state.recipientDirectoryFilters = {
          ...state.recipientDirectoryFilters,
          ...payload,
        }
      } else {
        state.recipientDirectoryFilters = initialState.recipientDirectoryFilters
      }
    },
    setVaspDirectoryFilters: (state, { payload }) => {
      if (payload) {
        state.vaspDirectoryFilters = {
          ...state.vaspDirectoryFilters,
          ...payload,
        }
      } else {
        state.vaspDirectoryFilters = initialState.vaspDirectoryFilters
      }
    },
    setTravelRuleFilters: (state, { payload }) => {
      if (payload) {
        state.travelRuleFilters = {
          ...state.travelRuleFilters,
          ...payload,
        }
      } else {
        state.travelRuleFilters = initialState.travelRuleFilters
      }
    },
    setAddressFilters: (state, { payload }) => {
      if (payload) {
        state.addressFilters = {
          ...state.addressFilters,
          ...payload,
        }
      } else {
        state.addressFilters = initialState.addressFilters
      }
    },
    setTransactionFilters: (state, { payload }) => {
      if (payload) {
        state.transactionFilters = {
          ...state.transactionFilters,
          ...payload,
        }
      } else {
        state.transactionFilters = initialState.transactionFilters
      }
    },
    setTransactionEventsFilters: (state, { payload }) => {
      if (payload) {
        state.transactionEventsFilters = {
          ...state.transactionEventsFilters,
          ...payload,
        }
      } else {
        state.transactionEventsFilters = initialState.transactionEventsFilters
      }
    },
    setAddressDetailsFilters: (state, { payload }) => {
      if (payload) {
        state.addressDetailsFilters = {
          ...state.addressDetailsFilters,
          ...payload,
        }
      } else {
        state.addressDetailsFilters = initialState.addressDetailsFilters
      }
    },
    setAddressHistoryFilters: (state, { payload }) => {
      if (payload) {
        state.addressHistoryFilters = {
          ...state.addressHistoryFilters,
          ...payload,
        }
      } else {
        state.addressHistoryFilters = initialState.addressHistoryFilters
      }
    },
    setReportsAddressFilters: (state, { payload }) => {
      if (payload) {
        state.reportsAddressFilters = {
          ...state.reportsAddressFilters,
          ...payload,
        }
      } else {
        state.reportsAddressFilters = initialState.reportsAddressFilters
      }
    },
    setReportsTransactionFilters: (state, { payload }) => {
      if (payload) {
        state.reportsTransactionFilters = {
          ...state.reportsTransactionFilters,
          ...payload,
        }
      } else {
        state.reportsTransactionFilters = initialState.reportsTransactionFilters
      }
    },
    setReportsTravelRuleFilters: (state, { payload }) => {
      if (payload) {
        state.reportsTravelRuleFilters = {
          ...state.reportsTravelRuleFilters,
          ...payload,
        }
      } else {
        state.reportsTravelRuleFilters = initialState.reportsTravelRuleFilters
      }
    },
    setWhitelistingFilters: (state, { payload }) => {
      if (payload) {
        state.whitelistingFilters = {
          ...state.whitelistingFilters,
          ...payload,
        }
      } else {
        state.whitelistingFilters = initialState.whitelistingFilters
      }
    },
    setMonitoringFilters: (state, { payload }) => {
      if (payload) {
        state.monitoringFilters = {
          ...state.monitoringFilters,
          ...payload,
        }
      } else {
        state.monitoringFilters = initialState.monitoringFilters
      }
    },
    setCaseManagementFilters: (state, { payload }) => {
      if (payload) {
        state.caseManagementFilters = {
          ...state.caseManagementFilters,
          ...payload,
        }
      } else {
        state.caseManagementFilters = initialState.caseManagementFilters
      }
    },
  },
})

export default store
