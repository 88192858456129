import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './UpdateTransferModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UpdateTransferModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isUpdateTransferModalOpen, updateTransferModalData } = ui

  // Store Actions
  const { toggleUpdateTransferModal, setUpdateTransferModalStatus, setUpdateTransferModalData } = actions

  // Functions
  const cancel = () => {
    toggleUpdateTransferModal()
    setUpdateTransferModalStatus()
    setUpdateTransferModalData()
  }
  const handleConfirm = () => {
    setUpdateTransferModalStatus({ confirmed: true })
    toggleUpdateTransferModal()
  }

  // UseEffect
  useEffect(() => {}, [])
  return (
    <Modal isOpen={isUpdateTransferModalOpen} toggle={cancel}>
      <ModalHeader toggle={cancel} close={<Image width={20} height={20} src={CloseIcon} onClick={cancel} />}>
        {updateTransferModalData?.title}
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>{updateTransferModalData?.content}</ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={cancel}>
            <Button color="default">Cancel</Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={handleConfirm}>
            <Button color="secondary">Confirm</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
UpdateTransferModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
UpdateTransferModal.propTypes = {
  ui: PropTypes.shape({
    isUpdateTransferModalOpen: PropTypes.bool,
    updateTransferModalStatus: PropTypes.shape({ confirmed: PropTypes.bool }),
    updateTransferModalData: PropTypes.shape({ title: PropTypes.string, content: PropTypes.string }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({
    addressRemove: PropTypes.shape({ address_id: PropTypes.string }),
  }),
  actions: PropTypes.shape({
    toggleUpdateTransferModal: PropTypes.func,
    setUpdateTransferModalStatus: PropTypes.func,
    setUpdateTransferModalData: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTransferModal)
