import { useMutation } from 'react-query'

// Services
import { CreateVasp } from 'services/api/Vasps'

export default () => {
  const {
    mutate: createVasp,
    data: vaspCreateData,
    error: vaspCreateError,
    isLoading: isVaspCreateLoading,
    isSuccess: isVaspCreateSuccess,
    isError: isVaspCreateError,
  } = useMutation(CreateVasp)

  return {
    createVasp,
    vaspCreateData,
    vaspCreateError,
    isVaspCreateLoading,
    isVaspCreateSuccess,
    isVaspCreateError,
  }
}
