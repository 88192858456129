import styled from 'styled-components'

export const AccordionTableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`

export const AccordionDetailsColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const AccordionDetailsRowWrapper = styled.div`
  display: flex;
`

export const AccordionDetailsWrapper = styled.div`
  flex: 1.8;
`

export const AccountDetailsWrapper = styled.div`
  flex: 1;

  padding: 0px 0px 0px 20px;
`

export const AccordionTableDetailsItem = styled.div`
`

export const AccordionTableDetailsTitle = styled.div`
  padding: 6px 0px;
  
  font-size: 16px;
  font-weight: 500;
  
  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
