import styled from 'styled-components'

export const EmptyTablePlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
`

export const EmptyTablePlaceholderImage = styled.div`
  display: flex;
  justify-content: center;
  width: 200px;
`

export const EmptyTablePlaceholderTextWrapper = styled.div`
  width: 100%;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const EmptyTablePlaceholderText = styled.div`
  font-size: 20px;
  font-weight: 600;

  text-align: center;
`

export const EmptyTablePlaceholderSubText = styled.div`
  font-size: 16px;
  font-weight: 400;

  text-align: center;
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`
