import styled from 'styled-components'

export const RiskScoreChartWrapper = styled.div`
  width: 100%;
`

export const RiskScoreChartContent = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('padding', ['0px', '0px', '15px', '15px', '15px', '15px', '15px', '15px'])}
`

export const DoughnutChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('width', ['140px', '150px', '180px', '200px', '200px', '160px', '160px', '180px'])}
  ${({ theme }) => theme.functions.mediaQuery('flex', ['.5', '.5', '.5', '.5', '1.3', 'none', 'none', 'none'])}
`

export const RiskScoreChartBreakdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('margin', [
      '0px 0px 0px 10px',
      '0px 10px',
      '0px 0px 0px 20px',
      '0px 0px 0px 40px',
      '0px 30px',
      '0px 0px 0px 20px',
      '0px 0px 0px 20px',
      '0px 0px 0px 20px',
    ])}
`

export const ChartTotal = styled.div`
  font-family: Roboto;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transition: 2s ease-in;
  transform: translate(-50%, -50%);
`

export const ChartTotalValue = styled.p`
  padding: 0;
  margin: 0 0 5px 0;
  line-height: 25px;
  font-size: 25px;

  color:  ${({ theme }) => theme.colors.darkGray};
`

export const ChartTotalText = styled.p`
  line-height: 11.2px;
  padding: 0px;
  margin: 0px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.gray};
`

export const RiskScoreChartBreakdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const RiskScoreChartBreakdownItem = styled.div`
  width: fit-content;
  font-weight: 400;
  font-size: 13px;
  width: fit-content;
  padding: 2px 0px;
  color: ${({ theme }) => theme.colors.gray};

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('font-size', ['10px', '11px', '13px', '13px', '13px', '13px', '13px', '13px'])}
`
