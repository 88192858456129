import React from 'react'
import PropTypes from 'prop-types'

// Assets
import MenuIcon from 'assets/images/menu-icon.svg'

// Styled Elements
import {
  BreachReportTableWrapper,
  Title,
  TableWrapper,
  TableHeaders,
  TableHeaderTitle,
  TableItem,
  TableItemTitle,
  TableItemLink,
  IconWrapper
} from './BreachReportTable.elements'

// Views
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Image,
} from 'views/components'

function BreachReportTable(props) {
  // Destructure
  const { data } = props

  // Variables
  const tableHeaders = ['Record Type', 'Rule ID', 'Description', 'Record ID']

  return (
    <BreachReportTableWrapper>
      <Title>Breach Reports</Title>
      <TableHeaders>
        {tableHeaders.map((name) => (
          <TableHeaderTitle key={name}>{name}</TableHeaderTitle>
        ))}
      </TableHeaders>
      <TableWrapper>
        {data.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableItem key={i}>
            <TableItemTitle>{item.dataset_type}</TableItemTitle>
            <TableItemTitle>{item.rule_id}</TableItemTitle>
            <TableItemTitle>{item.rule_description}</TableItemTitle>
            <TableItemTitle>{item.record_id}</TableItemTitle>
            <IconWrapper>
              <UncontrolledDropdown direction="left">
                <DropdownToggle>
                  <Image src={MenuIcon} height="15" width="15" />
                </DropdownToggle>
                <DropdownMenu left>
                  <DropdownItem header>
                    <TableItemLink to="#">
                      View
                    </TableItemLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </IconWrapper>
          </TableItem>
        ))}
      </TableWrapper>
    </BreachReportTableWrapper>
  )
}

// Default Props
BreachReportTable.defaultProps = {
  data: {}
}

// Proptypes Validation
BreachReportTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    dataset_type: PropTypes.string,
    rule_id: PropTypes.string,
    rule_description: PropTypes.string,
    record_id: PropTypes.string,
  }))
}

export default BreachReportTable
