const riskOptions = [
	{ label: 'High', value: 'high' },
	{ label: 'Medium', value: 'medium' },
	{ label: 'Low', value: 'low' },
]

const typeOptions = [
	{ label: 'Profile', value: 'profile' },
	{ label: 'Behavior', value: 'behavior' },
	{ label: 'Address', value: 'address' },
	{ label: 'Transaction', value: 'transaction' },
]

const statusOptions = [
	{ label: 'Active', value: 'active' },
	{ label: 'Inactive', value: 'inactive' },
]

export { riskOptions, typeOptions, statusOptions }
