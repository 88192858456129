import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Utils
import { parseJwt } from 'common/utils/jwt'

// Store
import { actions } from 'core/store'

// Hooks
import { useLogin, useGetUser } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import { LoginFormWrapper, FormInputGroupWrapper, FormInputGroupItem, SuccessMsg, FormLink } from './LoginForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function LoginForm(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { loginUser, loginUserData, loginUserError, isLoginUserLoading } = useLogin()
  const { getUser, getUserData, isGetUserLoading } = useGetUser()

  const [isLoggedIn, setIsloggedIn] = useLocalStorage('isLoggedIn', false)
  const [userCredentials, setUserCredentials] = useLocalStorage('userCredentials', {})
  const [, setTheme] = useLocalStorage('theme', 'light')
  const [TwoFactorAuthMsg, setTwoFactorAuthMsg] = useState()

  const [userData, setUserData] = useState()

  // Variables
  const formRef = useRef()
  const initialFormValues = {
    username: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().email('Invalid Email Format').required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  // Functions
  const handleOnSubmit = (values) => {
    loginUser(values)
  }
  const handleLoginSuccess = (data) => {
    if (data?.has_2fa && !data?.access_token) {
      setTwoFactorAuthMsg('The sign in link was sent to your email.')
      return
    }
    setUserData(data)
    localStorage.setItem('userCredentials', JSON.stringify(data))
    const { uid } = parseJwt(data.access_token)
    if (uid) {
      getUser({ user_id: uid })
    }
  }
  const login = async () => {
    // setting the data in order to make user logged in
    if (loginUserData && !loginUserError) {
      await setTheme('dark')
      await setUserCredentials({ ...userData, ...getUserData })
      await setIsloggedIn(true)
    }
  }
  const checkIfSessionExpired = async () => {
    // Checking if user session is expired
    if (isLoggedIn && !userCredentials?.access_token) {
      await localStorage.setItem('isLoggedIn', 'false')
      await localStorage.setItem('userCredentials', '{}')
      await localStorage.setItem('reportStrings', '[]')
      await localStorage.setItem('reportTitles', '[]')
      showAlert({ type: 'error', message: 'Session Expired' })
    }
  }
  // useEffect
  useEffect(() => loginUserData && handleLoginSuccess(loginUserData), [loginUserData])
  useEffect(() => getUserData && login(), [getUserData])
  useEffect(() => isLoggedIn && window.location.replace(window.location.origin), [isLoggedIn])
  useEffect(() => checkIfSessionExpired(), [])
  useEffect(() => {
    if (!loginUserError) return
    if (loginUserError?.response?.status === 400) {
      showAlert({ type: 'error', message: 'Username and password did not match' })
    } else showAlert({ type: 'error', message: 'An error occured in logging in' })
  }, [loginUserError])

  return (
    <LoginFormWrapper>
      {TwoFactorAuthMsg && (
        <>
          <SuccessMsg>{TwoFactorAuthMsg}</SuccessMsg>
          <Button
            fullWidth
            onClick={() => {
              if (formRef.current) {
                formRef.current.resetForm()
              }
              setTwoFactorAuthMsg()
            }}
          >
            Sign in with a different account
          </Button>
        </>
      )}
      {!TwoFactorAuthMsg && (
        <Form
          formRef={formRef}
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormInputGroupWrapper>
            <FormInputGroupItem>
              <FormTextField label="Email Address" type="email" name="username" />
            </FormInputGroupItem>
            <FormLink href="/reset_password">Forgot Password?</FormLink>
            <FormInputGroupItem>
              <FormTextField label="Password" type="password" name="password" />
            </FormInputGroupItem>
          </FormInputGroupWrapper>

          <Button fullWidth type="submit" disabled={isLoginUserLoading || isGetUserLoading}>
            {isLoginUserLoading || isGetUserLoading ? 'Logging in...' : 'Sign in'}
          </Button>
        </Form>
      )}
    </LoginFormWrapper>
  )
}

// Default Props
LoginForm.defaultProps = {
  actions: {},
}

// Proptypes Validation
LoginForm.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
