import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Styled Elements
import { DrawerWrapper, DrawerCloseIcon } from '../DirectoryDrawers.elements'

// Views
import { Drawer } from 'views/components'

// Layouts
import { EntityAccountForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function EntityAccountDrawer(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isEntityAccountDrawerOpen } = ui

  // Store Actions
  const { setIsEntityAccountDrawerOpen } = actions

  const handleCloseDrawer = () => {
    setIsEntityAccountDrawerOpen(false)
  }

  return (
    <Drawer open={isEntityAccountDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <DrawerWrapper>
        <DrawerCloseIcon onClick={handleCloseDrawer}>
          <CloseIcon />
        </DrawerCloseIcon>
        <EntityAccountForm />
      </DrawerWrapper>
    </Drawer>
  )
}

// Default Props
EntityAccountDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
EntityAccountDrawer.propTypes = {
  ui: PropTypes.shape({ isEntityAccountDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ setIsEntityAccountDrawerOpen: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityAccountDrawer)
