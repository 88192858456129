import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { DropdownWrapper } from './Dropdown.elements'

function Dropdown(props) {
  // Destructure
  const { children, direction, isOpen, toggle, ...rest } = props

  return (
    <DropdownWrapper isOpen={isOpen} toggle={toggle} direction={direction} {...rest}>
      {children}
    </DropdownWrapper>
  )
}

// Default Props
Dropdown.defaultProps = {
  children: '',
  direction: '',
  isOpen: false,
  toggle: () => {},
}

// Proptypes Validation
Dropdown.propTypes = {
  direction: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Dropdown
