/* eslint-disable max-len */
import React from 'react'

export default function BitcoinCashMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M332.838 167.716C320.274 139.226 291.404 133.135 256.061 139.036L244.703 95L217.926 101.916L229.094 145.825C222.051 147.602 214.817 149.124 207.647 151.155L196.48 107.5L169.703 114.416L181.061 158.452C175.287 160.102 127 172.475 127 172.475L134.36 201.155C134.36 201.155 154.03 195.635 153.84 196.079C164.754 193.223 169.893 198.68 172.305 203.82L203.523 324.505C203.904 327.995 203.269 333.959 195.782 335.99C196.226 336.244 176.302 341.003 176.302 341.003L179.221 374.442C179.221 374.442 227.063 362.195 233.345 360.609L244.83 405.152L271.607 398.236L260.122 353.376C267.482 351.662 274.652 349.886 281.632 348.046L293.053 392.652L319.83 385.736L308.345 341.256C349.589 331.231 378.713 305.216 372.749 265.431C368.942 241.447 342.736 221.777 320.972 219.556C334.36 207.69 341.15 190.368 332.838 167.716ZM319.957 272.728C325.287 312.132 270.528 316.954 252.444 321.713L236.708 262.766C254.855 258.008 310.947 238.02 319.957 272.728ZM286.962 192.462C292.609 227.487 245.782 231.548 230.68 235.419L216.34 181.929C231.505 178.185 275.477 160.038 286.962 192.462Z"
        fill="#00CACD"
      />
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
    </svg>
  )
}
