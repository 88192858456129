import { useMutation } from 'react-query'

// Services
import { CreateTransfer } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: createTransfer,
    data: transferCreateData,
    error: transferCreateError,
    isLoading: isTransferCreateLoading,
    isSuccess: isTransferCreateSuccess,
    isError: isTransferCreateError,
  } = useMutation(CreateTransfer)

  return {
    createTransfer,
    transferCreateData,
    transferCreateError,
    isTransferCreateLoading,
    isTransferCreateSuccess,
    isTransferCreateError,
  }
}
