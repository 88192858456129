import styled from 'styled-components'

export const CaseDetailsKYCFormWrapper = styled.div``

export const FormHeader = styled.p`
  margin-bottom: 40px;
  width: 100%;

  font-weight: 700;
  font-size: 17px;
  color: ${({ theme }) => (theme.colors.darkGray)};
`

export const FormInputGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  padding: 0px 10px 0px 0px;
  margin: 0;
  width: 100%;
  gap: 20px;

  // TODO: Use theme function
  &:last-child {
    padding: 0;
  }

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('padding', ['0px', '0px', '0px', '0px', '0px 10px 0px 0px'])}
`

export const PolicyListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  max-height: 500px;
  overflow-y: auto;
`

export const PolicyItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme }) => theme.colors.white};

  /* FOR ADDING POLICIES */
  & > div {
    width: 100%;
  }
  form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  form > div {
    width: 100%;
  }
  form > div:last-child {
    width: auto;
  }
  textarea {
    height: auto;
  }
`

export const ItemDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &:last-child {
    align-items: flex-end;
    gap: 10px;
  }
`

export const ItemTitle = styled.div`
  font-weight: bold;
  text-transform: capitalize;

  color: ${(props) => (props.color ? props.color : '#000')};
`

export const ItemData = styled.div`
  font-weight: 100;
`
