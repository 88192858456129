import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useUpdateUser, useCreateUserActivationLink } from 'core/hooks/api'

// Constants
import { ROLE_OPTIONS } from 'common/constants/formOptions'

// Styled Elements
import { TableItemWrapper, TableRowWrapper, TableRowContainer } from './UsersTableItem.elements'

// Views
import { TextField, Select, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

function UsersTableItem(props) {
  // Destructure
  const { data, setIsUpdated, actions } = props
  const { showAlert } = actions

  // States
  const [isEditing, setIsEditing] = useState(false)
  const [email, setEmail] = useState()
  const [role_id, setRoleId] = useState()

  // Hooks
  const { updateUser, updateUserData, updateUserError, isUpdateUserLoading } = useUpdateUser()
  const {
    createUserActivationLink,
    createUserActivationLinkData,
    createUserActivationLinkError,
    isCreateUserActivationLinkLoading,
  } = useCreateUserActivationLink()

  // Functions
  const handleSave = () => {
    updateUser({ user_id: data?.id, email, role_id })
  }
  const handleCancel = () => {
    setIsEditing(false)
  }
  const handleSuccess = () => {
    setIsEditing(false)
    setIsUpdated(true)
    showAlert({ type: 'success', message: 'Successfully updated user' })
  }
  const handleErr = () => {
    setIsEditing(false)
    setEmail(data?.email)
    setRoleId(data?.role_id)
    showAlert({ type: 'error', message: 'Error: Could not update user' })
  }
  const sendActivation = () => {
    createUserActivationLink({ user_id: data?.id })
  }

  // UseEffect
  useEffect(() => {
    if (data) {
      setEmail(data?.email)
      setRoleId(data?.role_id)
    }
  }, [data])
  useEffect(() => {
    if (isUpdateUserLoading) return
    if (updateUserError) {
      handleErr()
      return
    }
    if (updateUserData) {
      handleSuccess()
    }
  }, [updateUserData, updateUserError])
  useEffect(() => {
    if (isCreateUserActivationLinkLoading) return
    if (createUserActivationLinkError) {
      showAlert({ type: 'error', message: 'Error: Could not send activation link.' })
      return
    }
    if (createUserActivationLinkData) {
      showAlert({ type: 'success', message: "Successfully sent activation link to user's email" })
    }
  }, [createUserActivationLinkData, createUserActivationLinkError])

  return (
    <TableItemWrapper>
      <TableRowWrapper>
        <TableRowContainer>
          <TextField value={email} disabled={!isEditing} />
        </TableRowContainer>
        <TableRowContainer>
          <TextField value={`${data?.name_first} ${data?.name_last}`} disabled />
        </TableRowContainer>
        <TableRowContainer>
          <Select
            placeholder={data?.role_desc}
            value={role_id}
            onChange={(v) => setRoleId(v)}
            options={ROLE_OPTIONS}
            disabled={!isEditing}
          />
        </TableRowContainer>
        <TableRowContainer>
          <TextField value={data?.is_active ? 'Active' : 'Inactive'} disabled />
        </TableRowContainer>
        {/* For activating user */}
        {!data?.is_active && (
          <TableRowContainer style={{ justifyContent: 'flex-end' }}>
            {!isCreateUserActivationLinkLoading && <Button onClick={sendActivation}>Send Activation</Button>}
            {isCreateUserActivationLinkLoading && <Button disabled>Loading...</Button>}
          </TableRowContainer>
        )}
        {/* For editing user */}
        {data?.is_active && (
          <TableRowContainer style={{ justifyContent: 'flex-end' }}>
            {!isEditing && !isUpdateUserLoading && (
              <Button variant="outlined" onClick={() => setIsEditing(true)}>
                Edit User
              </Button>
            )}
            {isEditing && !isUpdateUserLoading && (
              <>
                <Button variant="outlined" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button onClick={handleSave}>Save changes</Button>
              </>
            )}
            {isUpdateUserLoading && (
              <Button variant="outlined" disabled>
                Loading...
              </Button>
            )}
          </TableRowContainer>
        )}
      </TableRowWrapper>
    </TableItemWrapper>
  )
}

// Default Props
UsersTableItem.defaultProps = {
  address: {},
  actions: {},
  data: {
    email: '',
    name: '',
    permissions: [],
  },
}

// Proptypes Validation
UsersTableItem.propTypes = {
  address: PropTypes.shape({
    addressLinkDetails: PropTypes.shape({
      address_id: PropTypes.string,
    }),
    addressLinkData: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    name_first: PropTypes.string,
    name_last: PropTypes.string,
    role_desc: PropTypes.string,
    role_id: PropTypes.number,
    status_name: PropTypes.string,
    is_active: PropTypes.bool,
  }),
  setIsUpdated: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersTableItem)
