import { useMutation } from 'react-query'

// Services
import { GetAutoCompleteCustomer } from 'services/api/Customer'

export default () => {
  const {
    mutate: getAutoCompleteCustomer,
    data: autoCompleteCustomerData,
    error: autoCompleteCustomerError,
    isLoading: isAutoCompleteCustomerLoading,
    isSuccess: isAutoCompleteCustomerSuccess,
    isError: isAutoCompleteCustomerError,
  } = useMutation(GetAutoCompleteCustomer)

  return {
    getAutoCompleteCustomer,
    autoCompleteCustomerData,
    autoCompleteCustomerError,
    isAutoCompleteCustomerLoading,
    isAutoCompleteCustomerSuccess,
    isAutoCompleteCustomerError,
  }
}
