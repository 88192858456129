import { useMutation } from 'react-query'

// Services
import { AssignWhitelistingAddress } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: assignWhitelistingAddress,
    data: assignWhitelistingAddressData,
    error: assignWhitelistingAddressError,
    isLoading: isAssignWhitelistingAddressLoading,
    isSuccess: isAssignWhitelistingAddressSuccess,
    isError: isAssignWhitelistingAddressError,
  } = useMutation(AssignWhitelistingAddress)

  return {
    assignWhitelistingAddress,
    assignWhitelistingAddressData,
    assignWhitelistingAddressError,
    isAssignWhitelistingAddressLoading,
    isAssignWhitelistingAddressSuccess,
    isAssignWhitelistingAddressError,
  }
}
