import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './AccountDetailsSubHeader.elements'

function AccountDetailsSubHeader(props) {
  // Hooks
  const { page, setPage, pages, isButton } = props

  return (
    <SubHeaderWrapper>
      <SubHeaderNavList isButton={isButton}>
        {pages &&
          pages.map((item) => (
            <SubHeaderNavListItem>
              <SubHeaderNavListLink
                isButton={isButton}
                className={page === item.value && 'active'}
                onClick={() => {
                  setPage(item.value)
                }}
              >
                {item.label}
              </SubHeaderNavListLink>
            </SubHeaderNavListItem>
          ))}
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

// Default Props
AccountDetailsSubHeader.defaultProps = {
  isButton: false,
  page: 0,
  setPage: () => {},
  pages: [],
}

// Proptypes Validation
AccountDetailsSubHeader.propTypes = {
  isButton: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func,
  pages: PropTypes.arrayOf({}),
}
export default AccountDetailsSubHeader
