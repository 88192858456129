/* eslint-disable max-len */
import React from 'react'

export default function LinkIcon() {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33337 8.85527C8.66898 9.25462 9.09714 9.58508 9.58882 9.82418C10.0805 10.0633 10.6242 10.2055 11.1831 10.2411C11.7419 10.2767 12.3028 10.205 12.8278 10.0307C13.3527 9.85636 13.8294 9.58359 14.2255 9.23088L16.5699 7.14412C17.2816 6.48818 17.6755 5.60962 17.6666 4.69772C17.6577 3.78581 17.2467 2.91351 16.5223 2.26866C15.7978 1.62383 14.8179 1.25805 13.7934 1.25013C12.7689 1.2422 11.7819 1.59276 11.045 2.22629L9.70092 3.41576"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 7.14473C10.3311 6.74537 9.90295 6.41494 9.41127 6.17581C8.91959 5.9367 8.37587 5.7945 7.81704 5.75888C7.25818 5.72327 6.69725 5.79502 6.17232 5.96933C5.64737 6.14363 5.17067 6.4164 4.77456 6.76911L2.43019 8.85587C1.71845 9.51182 1.32463 10.3904 1.33352 11.3023C1.34243 12.2142 1.75336 13.0865 2.47779 13.7313C3.20224 14.3762 4.18222 14.7419 5.20669 14.7499C6.23117 14.7578 7.21814 14.4072 7.95508 13.7737L9.29136 12.5842"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
