import styled from 'styled-components'

export const ContentWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px 0 0 0;

  display: flex;
  justify-content: center;
`
