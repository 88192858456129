import styled from 'styled-components'

export const TableButtonWrapper = styled.div`
  position: relative;
  padding: 20px 41px 20px 41px;
  border-radius: 15px;
  display: grid;
  gap: 50px;
  align-items: center;

  color: ${({ theme }) => theme.colors.darkGray};
`

export const TableRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const TableRowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  gap: 20px;
`
export const TableColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TableText = styled.div`
  font-size: 15px;
  font-weight: 400;

  &:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
export const TableTextTiny = styled.span`
  font-size: 8px;
  font-weight: 400;
`
export const TableSubText = styled.div`
  font-size: 10px;
  font-weight: 400;
`

export const TablePanelWrapper = styled.div``
