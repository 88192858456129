import { useMutation } from 'react-query'

// Services
import { LinkTransaction } from 'services/api/Transactions'

export default () => {
  const {
    mutate: linkTransaction,
    data: linkTransactionData,
    error: linkTransactionError,
    isLoading: isLinkTransactionLoading,
    isSuccess: isLinkTransactionSuccess,
    isError: isLinkTransactionError,
  } = useMutation(LinkTransaction)

  return {
    linkTransaction,
    linkTransactionData,
    linkTransactionError,
    isLinkTransactionLoading,
    isLinkTransactionSuccess,
    isLinkTransactionError,
  }
}
