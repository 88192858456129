import styled from 'styled-components'

export const SubHeaderWrapper = styled.div``

export const SubHeaderNavList = styled.ul`
  display: flex;
  gap: 20px;
  padding: 10px 40px 0 40px;
  margin: 0;
  list-style: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
`

export const SubHeaderNavListItem = styled.li`
  padding: 0px;
`

export const SubHeaderNavListLink = styled.div`
  position: relative;
  display: block;
  padding: 12px 0;
  margin: 0 0 -2px 0;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  // TODO: Use theme mixins
  &.active {
    font-weight: 700;
  }

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.blue};

    border-bottom: 3px solid ${({ theme }) => theme.colors.blue};
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`
