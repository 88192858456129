import styled from 'styled-components'

export const FormWrapper = styled.div`
  padding-bottom: 40px;
`

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div > div {
    margin-bottom: 0 !important;
  }
`
export const FormGroupWrapper = styled.div`
  margin: 0 0 40px 0;
`
export const FormTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const ErrMsg = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 10px 10px;
  color: ${({ theme }) => theme.colors.red};
`
