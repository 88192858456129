import styled from 'styled-components'

export const ActivityChartWrapper = styled.div`
  width: 100%;

  margin: auto;
`

export const BarChartWrapper = styled.div`
  width: 100%;
  margin: auto;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('height', ['unset', 'unset', 'unset', 'unset', 'unset', '230px'])}
`
