import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Utils
import { parseJwt } from 'common/utils/jwt'

// Store
import { actions } from 'core/store'

// Hooks
import { useResetPasswordConfirm, useGetUserAuth } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  LoginFormWrapper,
  FormInputGroupWrapper,
  FormInputGroupItem,
  SuccessMsg,
  ErrorMsg,
  FormLink,
} from './ResetPasswordConfirmForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ResetPasswordConfirmForm(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions
  const { auth_hash } = useParams()

  // Hooks
  const { resetPasswordConfirm, resetPasswordConfirmData, resetPasswordConfirmError, isResetPasswordConfirmLoading } =
    useResetPasswordConfirm()
  const { getUserAuth, getUserAuthData, getUserAuthError, isGetUserAuthLoading } = useGetUserAuth()

  // const [userCredentials, setUserCredentials] = useLocalStorage('userCredentials', {})
  // const [, setTheme] = useLocalStorage('theme', 'light')
  // const [TwoFactorAuthMsg, setTwoFactorAuthMsg] = useState()

  const [userData, setUserData] = useState()

  // Variables
  const formRef = useRef()
  const initialFormValues = {
    password: '',
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
  })

  // Functions
  const handleOnSubmit = (values) => {
    resetPasswordConfirm({ ...values, token: auth_hash })
  }
  const fetchUserAuth = () => {
    getUserAuth({ hash: auth_hash })
  }
  useEffect(() => {
    if (resetPasswordConfirmError) {
      showAlert({ type: 'error', message: 'An error occured in resetting password' })
    }
  }, [resetPasswordConfirmData])

  useEffect(() => {
    fetchUserAuth()
  }, [])

  return (
    <LoginFormWrapper>
      <Form
        formRef={formRef}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormInputGroupWrapper>
          {getUserAuthError && <ErrorMsg>Link has expired.</ErrorMsg>}
          {resetPasswordConfirmData && <SuccessMsg>Successfully reset password, try logging in.</SuccessMsg>}
          {!resetPasswordConfirmData && !isGetUserAuthLoading && !getUserAuthError && (
            <FormInputGroupItem>
              <FormTextField
                label="New Password"
                type="password"
                name="password"
                disabled={isResetPasswordConfirmLoading}
              />
            </FormInputGroupItem>
          )}
        </FormInputGroupWrapper>
        {!resetPasswordConfirmData && !getUserAuthError && !isGetUserAuthLoading && (
          <Button fullWidth type="submit" disabled={isResetPasswordConfirmLoading}>
            {isResetPasswordConfirmLoading ? 'Saving password...' : 'Save Password'}
          </Button>
        )}
        {resetPasswordConfirmData && !resetPasswordConfirmError && (
          <FormLink href="/login">
            <Button fullWidth type="button">
              Go to Login
            </Button>
          </FormLink>
        )}
      </Form>
    </LoginFormWrapper>
  )
}

// Default Props
ResetPasswordConfirmForm.defaultProps = {
  actions: {},
}

// Proptypes Validation
ResetPasswordConfirmForm.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConfirmForm)
