import styled from 'styled-components'

export const PoliciesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 40px 0 0 0;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 60px 0 0 0;
`

export const PolicyItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;

  padding: 20px;
  gap: 20px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background-color: ${({ theme }) => theme.colors.white};

  /* FOR ADDING POLICIES */
  & > div {
    width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;

    gap: 20px;
  }
  form > div {
    width: 100%;
  }
  form > div:last-child {
    width: auto;
  }
  textarea {
    height: auto;
    min-height: 150px;
    width: 100%;
  }
`

export const ItemDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  &:last-child {
    align-items: flex-end;
    gap: 10px;
  }
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;

  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
`
