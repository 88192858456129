/* eslint-disable max-len */
import React from 'react'

export default function EmptyIcon() {
  return (
    <svg width="71" height="78" viewBox="0 0 71 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.667 48.668H48.667L42 58.668H28.667L22 48.668H2"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 25.7L2 48.667V68.667C2 70.435 2.702 72.13 3.953 73.381C5.203 74.631 6.89899 75.333 8.66699 75.333H62C63.768 75.333 65.464 74.631 66.714 73.381C67.964 72.13 68.667 70.435 68.667 68.667V48.667L57.167 25.7C56.615 24.589 55.764 23.655 54.71 23.001C53.656 22.347 52.44 22.001 51.2 22H19.467C18.226 22.001 17.011 22.347 15.957 23.001C14.903 23.655 14.052 24.589 13.5 25.7Z"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M34.75 2V10" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M49.2319 4.73602L45.2319 11.664"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2681 11.659L21.2681 4.72998"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
