import { useMutation } from 'react-query'

// Services
import { GetAssetConversion } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: getAssetConversion,
    data: assetConversionData,
    error: assetConversionError,
    isLoading: isAssetConversionLoading,
    isSuccess: isAssetConversionSuccess,
    isError: isAssetConversionError,
  } = useMutation(GetAssetConversion)

  return {
    getAssetConversion,
    assetConversionData,
    assetConversionError,
    isAssetConversionLoading,
    isAssetConversionSuccess,
    isAssetConversionError,
  }
}
