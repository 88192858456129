import { useMutation } from 'react-query'

// Services
import { GetAllMidProspects } from 'services/api/Vasps'

export default () => {
  const {
    mutate: getAllMidProspects,
    data: allMidProspectsData,
    error: allMidProspectsError,
    isLoading: isAllMidProspectsLoading,
    isSuccess: isAllMidProspectsSuccess,
    isError: isAllMidProspectsError,
  } = useMutation(GetAllMidProspects)

  return {
    getAllMidProspects,
    allMidProspectsData,
    allMidProspectsError,
    isAllMidProspectsLoading,
    isAllMidProspectsSuccess,
    isAllMidProspectsError,
  }
}
