import React from 'react'

export default function PlusWhiteIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6.4209H12" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M6.5 11.4863L6.5 1.35475" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}
