import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalBodyWrapper = styled.div`
  font-size: 14.5px;
  font-weight: 400;
  padding: 0px 0px 20px 0px;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
`

export const UploadFileWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed ${({ theme }) => (theme.colors.ash)};
  border-radius: 2px;
  background-color: ${({ theme }) => (theme.colors.darkishWhite)};
  color: ${({ theme }) => (theme.colors.darkGray)};
  width: 100%;
  min-height: 219px;
  outline: none;
  margin: 0;
`

export const UploadFileText = styled.p`
  font-weight: 500;
  font-size: 20px;
  margin: 0px;

  color: ${({ theme }) => (theme.colors.darkGray)};
`

export const UploadFileSubtext = styled.p`
  font-weight: 300;
  font-size: 14px;
  margin: 0px 0px 0px 10px;

  color: ${({ theme }) => (theme.colors.darkGray)};
`

export const UploadFileInfoWrapper = styled.div``

export const UploadFileInfoItem = styled.div``

export const UploadFileInfoItemHeader = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
`
export const UploadFileInfoItemErrorText = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.red};
`

export const UploadFileInfoItemText = styled.p`
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 300;
`

export const UploadFileInfoDownload = styled.span`
  font-weight: 400;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.blue};
`

export const UploadFileInfoLink = styled.a`
  font-weight: 400;
  cursor: pointer;

  // TODO: Use themed functions
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  color: ${({ theme }) => theme.colors.blue};
`
export const LoaderWrapper = styled.div`
  padding: 20px 0 40px 0;
`
