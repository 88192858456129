import mediaQuery from 'common/config/theme/app/functions/mediaQuery'
import * as mixins from 'common/config/theme/app/functions/mixins'

export const colors = {
  blue: '#26A3F5',
  lightBlue: '#e6fafa',
  darkBlue: '#405264',
  brightBlue: '#2e4ad4',
  blueGray: '#727F97',
  navyBlue: '#0b037a',
  royalBlue: '#4436eb',
  aliceBlue: '#f3f5f7',
  paleNavy: '#eef9fe',
  warning: '#FFB347',
  success: '#3cb371',
  info: '#0275d8',

  lightYellow: '#f8ffdd',

  mediumPurple: '#7068df',
  lightPurple: '#b7b3ee',

  red: '#F45C65',
  brightRed: '#fd4a71',

  yellowGreen: '#CAFF0A',
  green: '#89CF82',

  orange: '#ffa800',

  lightGray: '#F3F4F7',
  darkGray: '#727F97',
  sandGray: '#525252',
  gray: '#d2d2d2',
  ash: '#f7f7f7',

  black: '#000000',
  lightBlack: '#282828',
  darkGrayishNavy: '#31353e',

  darkishWhite: '#F9F9FB',
  white: '#ffffff',
  pureWhite: '#ffffff',
}

export const fonts = {
  code: "'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default",
  text: "'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default",
}

export const functions = {
  mediaQuery,
  mixins,
}

export const layout = {
  sidebar: false,
}
