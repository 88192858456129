import { useMutation } from 'react-query'

// Services
import { UpdateProfile } from 'services/api/Profile'

export default () => {
  const {
    mutate: updateProfile,
    data: updateProfileData,
    error: updateProfileError,
    isLoading: isUpdateProfileLoading,
    isSuccess: isUpdateProfileSuccess,
    isError: isUpdateProfileError,
  } = useMutation(UpdateProfile)

  return {
    updateProfile,
    updateProfileData,
    updateProfileError,
    isUpdateProfileLoading,
    isUpdateProfileSuccess,
    isUpdateProfileError,
  }
}
