/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  DataBreakdownTableWrapper,
  DataBreakdownTableHeader,
  DataBreakdownTableHeaderRow,
  DataBreakdownTableHeaderRowMultiple,
  DataBreakdownTableHeaderItem,
  DataBreakdownTableBody,
  DataBreakdownTableRow,
  DataBreakdownTableRowMultiple,
  DataBreakdownTableRowData,
} from './DataBreakdownTable.elements'

function DataBreakdownTable(props) {
  // Destructure
  const { headers, data } = props

  // Variable
  const withHeader = headers.length
  const withData = data.length

  return (
    <DataBreakdownTableWrapper>
      <DataBreakdownTableHeader withHeader={withHeader}>
        {headers?.length > 2 ?
          <DataBreakdownTableHeaderRowMultiple>
            {headers &&
              headers.map((name, i) => <DataBreakdownTableHeaderItem key={i}>{name}</DataBreakdownTableHeaderItem>)}
          </DataBreakdownTableHeaderRowMultiple>
        :
          <DataBreakdownTableHeaderRow>
            {headers &&
              headers.map((name, i) => <DataBreakdownTableHeaderItem key={i}>{name}</DataBreakdownTableHeaderItem>)}
          </DataBreakdownTableHeaderRow>
        }
      </DataBreakdownTableHeader>
      <DataBreakdownTableBody withData={withData}>
        {data &&
          headers?.length > 2 ?
            data.map(({ name, data }, i) => (
              <DataBreakdownTableRowMultiple key={i}>
                <DataBreakdownTableRowData>{name}</DataBreakdownTableRowData>
                {data.map((value, i) => (
                  <DataBreakdownTableRowData key={i}>{value}</DataBreakdownTableRowData>
                ))}
              </DataBreakdownTableRowMultiple>
            ))
          :
            data.map(({ name, data }, i) => (
              <DataBreakdownTableRow key={i}>
                <DataBreakdownTableRowData>{name}</DataBreakdownTableRowData>
                {data.map((value, i) => (
                  <DataBreakdownTableRowData key={i}>{value}</DataBreakdownTableRowData>
                ))}
              </DataBreakdownTableRow>
            ))
        }
      </DataBreakdownTableBody>
    </DataBreakdownTableWrapper>
  )
}

// Default Props
DataBreakdownTable.defaultProps = {
  headers: [],
  data: [],
}

// Proptypes Validation
DataBreakdownTable.propTypes = {
  headers: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
}

export default DataBreakdownTable
