import React from 'react'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './DirectorySubHeader.elements'

function DirectorySubHeader() {
  return (
    <SubHeaderWrapper>
      <SubHeaderNavList>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/directory">
            Accounts
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/directory/recipients">
            Recipients
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/directory/vasps">
            VASPs
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

export default DirectorySubHeader
