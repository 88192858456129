import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { CloseIcon } from 'assets/images'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'
// import { useGetAddressHistory } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ColWrapper } from './TransactionDetailsModal.elements'

// Views
import { Modal, ModalHeader, ModalBody } from 'views/components'
import { TransactionRisk, TransactionEvents } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TransactionDetailsModal(props) {
  // Destructure
  const { transaction, actions, ui } = props

  // Store State
  const { transactionModalDetails } = transaction
  const { isTransactionDetailsModalOpen } = ui

  // Store Actions
  const { toggleTransactionDetailsModalOpen, setTransactionModalDetails } = actions

  // Constants
  const defaultEvents = {
    inputs: {
      events: [],
      page: 1,
    },
    outputs: {
      events: [],
      page: 1,
    },
  }

  // Local State
  const [riskData, setRiskData] = useState()
  const [eventDetails, setEventDetails] = useState(defaultEvents)

  // Hooks
  // const { getAddressHistory, getAddressHistoryData, isGetAddressHistoryLoading } = useGetAddressHistory()

  // Functions
  const toggleModal = () => {
    toggleTransactionDetailsModalOpen()
    setTransactionModalDetails()
    setRiskData(null)
    setEventDetails(defaultEvents)
  }
  // UseEffects
  useEffect(() => {
    if (isTransactionDetailsModalOpen) {
      setRiskData(transactionModalDetails?.riskData)
    }
  }, [isTransactionDetailsModalOpen])
  // useEffect(() => {
  //   if (isTransactionDetailsModalOpen) handleToggleInfo('TEST', 'TEST')
  // }, [isTransactionDetailsModalOpen])

  return (
    <Modal isOpen={isTransactionDetailsModalOpen} toggle={toggleModal} style={{ maxWidth: '1440px' }}>
      <ModalHeader toggle={toggleModal} close={<CloseIcon />} style={{ border: 'none' }} />

      <ModalBody style={{ maxHeight: '80vh' }}>
        <ColWrapper>
          {riskData && (
            <TransactionRisk riskData={riskData} transactionData={{ ...transactionModalDetails, riskData: null }} />
          )}
          {riskData && (
            <TransactionEvents
              transactionData={{ ...transactionModalDetails, riskData: null }}
              currentIpUsed={riskData?.risk_integration_id}
              isExpanded={isTransactionDetailsModalOpen}
              eventDetails={eventDetails}
              setEventDetails={setEventDetails}
            />
          )}
          {/* {isGetAddressHistoryLoading && <LoadingTablePlaceholder />}
          {!isGetAddressHistoryLoading && <EmptyTablePlaceholder />} */}
        </ColWrapper>
      </ModalBody>
    </Modal>
  )
}

// Default Props
TransactionDetailsModal.defaultProps = {
  ui: {},
  filters: {},
  transaction: {},
  actions: {},
}

// Proptypes Validation
TransactionDetailsModal.propTypes = {
  ui: PropTypes.shape({ isTransactionDetailsModalOpen: PropTypes.bool }),
  filters: PropTypes.shape({}),
  transaction: PropTypes.shape({
    transactionModalDetails: PropTypes.shape({
      riskData: PropTypes.shape({}),
      financial_data: PropTypes.shape({
        events: PropTypes.arrayOf(),
      }),
      risk_events: PropTypes.arrayOf(),
    }),
  }),
  actions: PropTypes.shape({
    toggleTransactionDetailsModalOpen: PropTypes.func,
    setTransactionModalDetails: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    toggleInfoModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetailsModal)
