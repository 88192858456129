/* eslint-disable max-len */
import styled from 'styled-components'

export const PageWrapper = styled.div`
  width: 100%;
`

export const ScenarioBuilderWrapper = styled.div`
  width: 100%;
  height: fit-content;

  /* CONTAINERS */
  & * {
    color: ${({ theme }) => theme.colors.darkGray};
  }
  & > div {
    padding: 0;
  }
  & .group {
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 10px;
    padding: 15px 20px;
  }
  & .rule {
    background-color: ${({ theme }) => theme.colors.white};
  }
  & .query-builder {
    margin: 0 !important;
  }

  /* FIELDS */
  // first select element of the line
  & .rule--field > select {
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.darkGray};

    padding: 15px 20px;
    font-size: 14px;
  } // disabled props
  & .rule--field > select:disabled {
    padding: 15px 20px;
    cursor: not-allowed;

    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.white};
  }
  // rules or middle element of the line
  & .rule--operator > select {
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.lightGray} !important;
    color: ${({ theme }) => theme.colors.darkGray} !important;

    padding: 15px 20px;
    font-size: 14px;
  } // disabled props
  & .rule--operator > select:disabled {
    padding: 15px 20px;
    cursor: not-allowed;

    border: 2px solid ${({ theme }) => theme.colors.lightGray} !important;
    background-color: ${({ theme }) => theme.colors.white} !important;
  }
  // last select element of the line
  & .ant-select-selector {
    border-radius: 10px !important;
    border: 2px solid ${({ theme }) => theme.colors.lightGray} !important;
    background-color: ${({ theme }) => theme.colors.lightGray} !important;
    color: ${({ theme }) => theme.colors.darkGray} !important;

    width: auto !important;
    height: auto !important;

    padding: 15px 20px !important;
    font-size: 14px !important;
  }
  & .ant-select-selection-search-input {
    height: auto !important;

    padding: 15px 20px !important;
  } // disabled props
  & .ant-input-number-disabled {
    opacity: 0.7 !important;
    color: ${({ theme }) => theme.colors.black} !important;

    border: 2px solid ${({ theme }) => theme.colors.lightGray} !important;
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  // last number input of the line
  & .ant-input-number-sm {
    border-radius: 10px !important;
    border: 2px solid ${({ theme }) => theme.colors.lightGray} !important;
    background-color: ${({ theme }) => theme.colors.lightGray} !important;

    padding: 15px 20px !important;
  } // disabled props
  & .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    opacity: 0.7 !important;
    color: ${({ theme }) => theme.colors.black} !important;

    border: 2px solid ${({ theme }) => theme.colors.lightGray} !important;
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  /* BUTTONS */
  // button container
  .ant-btn-group {
    display: flex;
    gap: 10px;
  }
  .qb-lite .group--drag-handler,
  .qb-lite .group--actions {
    opacity: 1 !important;
  }
  // or button
  .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border: 2px solid ${({ theme }) => theme.colors.lightGray} !important;
  }
  // all buttons
  .ant-btn-group .ant-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    border-radius: 10px !important;
    padding: 7px 20px !important;
    height: auto !important;
    width: auto !important;

    opacity: 1 !important;
    border: 2px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.3s;
  }

  .ant-btn-group .ant-btn:hover {
    border: 2px solid ${({ theme }) => theme.colors.darkGray};
  }
  .ant-btn.ant-btn-primary {
    background-color: ${({ theme }) => theme.colors.blue};
    border: 2px solid ${({ theme }) => theme.colors.blue};
  }
`
