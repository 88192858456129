import { useMutation } from 'react-query'

// Services
import { DownloadReportsTravelRule } from 'services/api/Reports'

export default () => {
  const {
    mutate: downloadReportsTravelRule,
    data: downloadReportsTravelRuleData,
    error: downloadReportsTravelRuleError,
    isLoading: isDownloadReportsTravelRuleLoading,
    isSuccess: isDownloadReportsTravelRuleSuccess,
    isError: isDownloadReportsTravelRuleError,
  } = useMutation(DownloadReportsTravelRule)

  return {
    downloadReportsTravelRule,
    downloadReportsTravelRuleData,
    downloadReportsTravelRuleError,
    isDownloadReportsTravelRuleLoading,
    isDownloadReportsTravelRuleSuccess,
    isDownloadReportsTravelRuleError,
  }
}
