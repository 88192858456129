import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  alertList: {
    open: false,
    message: 'None',
    type: 'error',
    duration: 3000,
  },
}

const store = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, { payload }) => {
      const { message, type } = payload

      const payloadToAppend = {
        open: true,
        message,
        type,
        duration: 3000,
      }

      state.alertList = payloadToAppend
    },
    closeAlert: (state) => {
      state.alertList = {}
    },
  },
})

export default store
