import styled from 'styled-components'

export const FormWrapper = styled.div``

export const FormInputGroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  & > div > div {
    margin-bottom: 0 !important;
  }

  & > div {
    width: 100%;
  }
`
export const FormGroupWrapper = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 0 60px;
`
export const FormTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const FormButtonWrapper = styled.span`
  margin-top: 22px;
  width: fit-content;

  & button {
    padding: 14px 25px;
    width: max-content;
  }
`
