import { useState } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetAlertsByStatus } from 'services/api/Alerts'

export default () => {
  // States
  const [alertsByStatusData, setAlertStatusData] = useState([])
  const {
    mutate: getAlertsByStatus,
    error: alertsByStatusError,
    isLoading: isAlertsByStatusLoading,
    isSuccess: isAlertsByStatusSuccess,
    isError: isAlertsByStatusError,
  } = useMutation(GetAlertsByStatus, {
    onSuccess: (data) => {
      const finalData = formatData(data)
      setAlertStatusData(finalData)
    }
  })
// Functions
const formatData = (data) => {
  const formattedData = [
    {
      alert_count: 0,
      alert_status: 'Open',
      percent: 0
    },
    {
      alert_count: 0,
      alert_status: 'Pending',
      percent: 0
    },
    {
      alert_count: 0,
      alert_status: 'Closed',
      percent: 0
    }
  ]
  let totalCount = 0

  data.forEach((item) => {
    if (item) totalCount += item.alert_count
  })

  data.forEach((item) => {
    formattedData.forEach((alert, i) => {
      if (item.alert_status === alert.alert_status) {
        const currentItem = {
          ...item,
          percent: Math.round((item.alert_count / totalCount) * 100)
        }
        formattedData[i] = currentItem
      }
    })
  })
  return formattedData
}

  return {
    getAlertsByStatus,
    alertsByStatusData,
    alertsByStatusError,
    isAlertsByStatusLoading,
    isAlertsByStatusSuccess,
    isAlertsByStatusError,
  }
}
