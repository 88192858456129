import { useMutation } from 'react-query'

// Services
import { ActivateUser } from 'services/api/Profile'

export default () => {
  const {
    mutate: activateUser,
    data: activateUserData,
    error: activateUserError,
    isLoading: isActivateUserLoading,
    isSuccess: isActivateUserSuccess,
    isError: isActivateUserError,
  } = useMutation(ActivateUser)

  return {
    activateUser,
    activateUserData,
    activateUserError,
    isActivateUserLoading,
    isActivateUserSuccess,
    isActivateUserError,
  }
}
