import styled from 'styled-components'

export const ImageWrapper = styled.img`
  width: 100%;
  height: 100%;

  font-weight: inherit;
  font-size: inherit;

  // Conditional Props
  ${({ width }) => width && `width:${width}px;`}
  ${({ height }) => height && `height:${height}px;`}
`
