import { useMutation } from 'react-query'

// Services
import { GetReports } from 'services/api/Reports'

export default () => {
  const {
    mutate: getReports,
    data: getReportsData,
    error: getReportsError,
    isLoading: isGetReportsLoading,
    isSuccess: isGetReportsSuccess,
    isError: isGetReportsError,
  } = useMutation(GetReports)

  return {
    getReports,
    getReportsData,
    getReportsError,
    isGetReportsLoading,
    isGetReportsSuccess,
    isGetReportsError,
  }
}
