import { useMutation } from 'react-query'

// Services
import { GetWhitelistingAddressCount } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: getWhitelistingAddressCount,
    data: whitelistingAddressCountData,
    error: whitelistingAddressCountError,
    isLoading: isWhitelistingAddressCountLoading,
    isSuccess: isWhitelistingAddressCountSuccess,
    isError: isWhitelistingAddressCountError,
  } = useMutation(GetWhitelistingAddressCount)

  return {
    getWhitelistingAddressCount,
    whitelistingAddressCountData,
    whitelistingAddressCountError,
    isWhitelistingAddressCountLoading,
    isWhitelistingAddressCountSuccess,
    isWhitelistingAddressCountError,
  }
}
