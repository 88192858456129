import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Assets
import DownloadIcon from 'assets/images/download'
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchVasp, useUpdateVasp } from 'core/hooks/api'

// Styled Elements
import { Wrapper, Container, Title, EditBtnWrapper, FooterBtnWrapper } from './VaspDetails.elements'

// Views
import { Button } from 'views/components'

import { VaspDetailsForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function VaspDetails(props) {
  // Destructure
  const { actions } = props
  const { vasp_id } = useParams()

  // store actions
  const { setShowHeaderLoader, showAlert } = actions

  // refs
  const formRef = useRef()

  // hooks
  const { getVasp, vaspData, isVaspLoading } = useFetchVasp()
  const { updateVasp, isVaspUpdateLoading, isVaspUpdateSuccess } = useUpdateVasp()

  // state
  const [vaspDataState, setVaspDataState] = useState()
  const [viewMode, setViewMode] = useState(true)

  // functions
  const fetchData = () => {
    getVasp({ vasp_id })
  }
  const handleSave = () => {
    setViewMode(true)
    const newPayload = formRef.current.values
    updateVasp({ vasp_id, values: newPayload })
  }
  const handleCancel = () => {
    setViewMode(true)
    setVaspDataState(vaspData)
  }
  const handleLoadingUpdateChange = () => {
    if (isVaspUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isVaspUpdateLoading) {
      setShowHeaderLoader(false)
      if (vaspDataState) {
        if (isVaspUpdateSuccess) {
          showAlert({ type: 'success', message: 'Successfully updated record' })
        } else if (!isVaspUpdateSuccess) {
          showAlert({ type: 'error', message: 'An error occured in updating record' })
        }
      }
    }
  }
  const handleLoadingVasp = () => {
    if (isVaspLoading) setShowHeaderLoader(true)
    if (!isVaspLoading) setShowHeaderLoader(false)
  }

  // useEffect
  useEffect(() => fetchData(), [])
  useEffect(() => {
    if (vaspData) setVaspDataState(vaspData)
  }, [vaspData])
  useEffect(() => handleLoadingVasp(), [isVaspLoading])
  useEffect(() => handleLoadingUpdateChange(), [isVaspUpdateLoading])

  return (
    <Wrapper>
      {!isVaspLoading && vaspDataState && (
        <>
          <Title>{vaspDataState.name_business}</Title>
          <Container>
            {/* {viewMode === true && (
              <EditBtnWrapper>
                <Button color="primary" onClick={() => setViewMode(false)} disabled>
                  Edit
                </Button>
              </EditBtnWrapper>
            )} */}
            <VaspDetailsForm values={vaspDataState} viewMode={viewMode} formRef={formRef} />
            {viewMode === false && (
              <FooterBtnWrapper>
                <Button type="button" onClick={handleCancel} variant="outlined" startIcon={<CloseIcon />}>
                  Cancel
                </Button>
                <Button type="button" onClick={handleSave} startIcon={<DownloadIcon />}>
                  Save Changes
                </Button>
              </FooterBtnWrapper>
            )}
          </Container>
        </>
      )}
    </Wrapper>
  )
}

// Default Props
VaspDetails.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
VaspDetails.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setIsIndividualVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspDetails)
