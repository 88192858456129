/* eslint-disable max-len */
import React from 'react'

export default function NeoMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path d="M115 160.484V345.488L235.084 388.569V202.063L365 154.18L247.092 112L115 160.484Z" fill="#00CACD" />
      <path d="M244.914 207.392V308.562L364.998 351.643V162.96L244.914 207.392Z" fill="#00CACD" />
    </svg>
  )
}
