import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { COUNTRIES_LIST } from 'common/constants/countries'
import { RISK_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useCreateVasp, useUpdateVasp } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  FormContainer,
  FormInputGroupItem,
  FormHeader,
  FormTitle,
  FormFooter,
} from '../DirectoryForms.elements'

// Views
import { Form, FormTextField, FormSearchSelect, Button, FormSelect } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function VaspForm(props) {
  // Destructure
  const { form, actions } = props

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const { createVasp, vaspCreateData, isVaspCreateLoading, isVaspCreateSuccess } = useCreateVasp()
  const { updateVasp, vaspUpdateData, isVaspUpdateLoading, isVaspUpdateSuccess } = useUpdateVasp()

  // Store State
  const { toBeUpdatedVasp } = form

  // Store Actions
  const {
    setIsVaspDrawerOpen,
    setToBeUpdatedVasp,
    showAlert,
    setShowHeaderLoader,
    setCreatedVasp,
    setIsPageTableUpdated,
    setIsRecordUpdated,
  } = actions

  // Internal State
  const [isCreateVasp, setIsCreateVasp] = useState(true)
  const [initialData, setInitialData] = useState()

  // Variables
  const formRef = useRef()
  const initialValues = {
    vasp_id: '',
    name_business: '',
    name_legal: '',
    email: '',
    website: '',
    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
    summary: '',
    vasp_risk_value: 0,
  }

  const validationSchema = Yup.object().shape({
    vasp_id: Yup.string().required('VASP ID is required'),
    name_business: Yup.string().required('Business Name is required'),
    name_legal: Yup.string().required('Legal Name is required'),
    email: Yup.string().email().required('Email is required'),
    website: Yup.string().required('Website is required'),
    address_street: Yup.string().required('Address Street is required'),
    address_city: Yup.string().required('Address City is required'),
    address_region: Yup.string().required('Address Region is required'),
    address_postcode: Yup.string().required('Address Postcode is required'),
    address_country_code: Yup.string().required('Address Country is required'),
    summary: Yup.string(),
    vasp_risk_value: Yup.number(),
  })

  // Functions
  const triggerSubmit = () => {
    formRef.current.submitForm()
  }
  const handleOnSubmit = (values) => {
    const payload = {
      biz_id: userCredentials.Business_ID,
      ...values,
    }
    // removing empty fields on object
    Object.keys(payload).forEach((k) => payload[k] === '' && delete payload[k])
    if (isCreateVasp) {
      createVasp(payload)
    } else if (!isCreateVasp) {
      updateVasp({ vasp_id: toBeUpdatedVasp.vasp_id, values: payload })
    }
  }

  const handleLoadingChange = () => {
    if (isVaspCreateLoading || isVaspUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isVaspCreateLoading && !isVaspUpdateLoading) {
      setShowHeaderLoader(false)
      if (initialData) {
        if (isCreateVasp) {
          if (isVaspCreateSuccess) {
            setCreatedVasp(vaspCreateData)
            setIsVaspDrawerOpen(false)
            setIsPageTableUpdated(true)
            showAlert({ type: 'success', message: 'Successfully created record' })
          } else if (!isVaspCreateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in creating record' })
          }
        } else if (!isCreateVasp) {
          if (isVaspUpdateSuccess) {
            setCreatedVasp(vaspUpdateData)
            setToBeUpdatedVasp(null)
            setIsVaspDrawerOpen(false)
            setIsRecordUpdated(true)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isVaspUpdateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        }
      }
    }
  }
  // UseEffects
  useEffect(() => {
    if (!toBeUpdatedVasp) {
      setIsCreateVasp(true)
      setInitialData(initialValues)
    } else {
      setIsCreateVasp(false)
      setInitialData(toBeUpdatedVasp)
    }
  }, [])
  useEffect(() => handleLoadingChange(), [isVaspCreateLoading, isVaspUpdateLoading])
  return (
    <FormWrapper>
      {initialData && (
        <Form
          formRef={formRef}
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormHeader>
            <FormTitle>{isCreateVasp ? 'Create VASP' : 'Edit VASP'}</FormTitle>
          </FormHeader>

          <FormContainer>
            <FormInputGroupItem>
              <FormTextField label="VASP ID e.g. ID0001" name="vasp_id" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Legal Name" name="name_legal" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Business Name" name="name_business" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Website" name="website" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Street" name="address_street" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="City" name="address_city" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Region" name="address_region" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Zipcode" name="address_postcode" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect label="Country" name="address_country_code" options={COUNTRIES_LIST} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Summary" name="summary" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect type="number" label="VASP Risk" name="vasp_risk_value" options={RISK_OPTIONS} />
            </FormInputGroupItem>
          </FormContainer>

          <FormFooter>
            <Button type="button" onClick={triggerSubmit}>
              {isCreateVasp ? 'Create VASP' : 'Save VASP'}
            </Button>
          </FormFooter>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
VaspForm.defaultProps = {
  formRef: {},
  form: {
    toBeUpdatedVasp: null,
  },
  actions: {},
}

// Proptypes Validation
VaspForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    countryListOptions: PropTypes.instanceOf(Array),
    toBeUpdatedVasp: PropTypes.shape({
      vasp_id: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setCreatedVasp: PropTypes.func,
    showAlert: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setIsRecordUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspForm)
