import { useMutation } from 'react-query'

// Services
import { GetTransfer } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: getTransfer,
    data: transferData,
    error: transferError,
    isLoading: isTransferLoading,
    isSuccess: isTransferSuccess,
    isError: isTransferError,
  } = useMutation(GetTransfer)

  return {
    getTransfer,
    transferData,
    transferError,
    isTransferLoading,
    isTransferSuccess,
    isTransferError,
  }
}
