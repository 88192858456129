import { useMutation } from 'react-query'

// Services
import { GetCaseTransactions } from 'services/api/Cases'

export default () => {
  const {
    mutate: getCaseTransactions,
    data: caseTransactionsData,
    error: caseTransactionsError,
    isLoading: isCaseTransactionsLoading,
    isSuccess: isCaseTransactionsSuccess,
    isError: isCaseTransactionsError,
  } = useMutation(GetCaseTransactions)

  return {
    getCaseTransactions,
    caseTransactionsData,
    caseTransactionsError,
    isCaseTransactionsLoading,
    isCaseTransactionsSuccess,
    isCaseTransactionsError,
  }
}
