export const SAMPLE_TOKEN_DATA = [
  {
		Asset: 'BTC',
		Chain: 'Bitcoin',
		Balance_USD: 23,
		Balance: 141125151,
		Contract: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa'
  },
  {
		Asset: 'BTC',
		Chain: 'Bitcoin',
		Balance_USD: 23,
		Balance: 141125151,
		Contract: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa'
  },
  {
		Asset: 'BTC',
		Chain: 'Bitcoin',
		Balance_USD: 23,
		Balance: 141125151,
		Contract: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa'
  },
  {
		Asset: 'BTC',
		Chain: 'Bitcoin',
		Balance_USD: 23,
		Balance: 141125151,
		Contract: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa'
  },
  {
		Asset: 'BTC',
		Chain: 'Bitcoin',
		Balance_USD: 23,
		Balance: 141125151,
		Contract: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa'
  },
  {
		Asset: 'BTC',
		Chain: 'Bitcoin',
		Balance_USD: 23,
		Balance: 141125151,
		Contract: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa'
  },
  {
		Asset: 'BTC',
		Chain: 'Bitcoin',
		Balance_USD: 23,
		Balance: 141125151,
		Contract: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa'
  },
]

export const TOKEN_HEADERS = [
	'Token Name',
	'USD Balance',
	'Balance',
	'Contract'
]
