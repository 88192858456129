import { useMutation } from 'react-query'

// Services
import { GetAllRecipients } from 'services/api/Recipients'

export default () => {
  const {
    mutate: getAllRecipients,
    data: allRecipientsData,
    error: allRecipientsError,
    isLoading: isAllRecipientsLoading,
    isSuccess: isAllRecipientsSuccess,
    isError: isAllRecipientsError,
  } = useMutation(GetAllRecipients)

  return {
    getAllRecipients,
    allRecipientsData,
    allRecipientsError,
    isAllRecipientsLoading,
    isAllRecipientsSuccess,
    isAllRecipientsError,
  }
}
