import { useMutation } from 'react-query'

// Services
import { GetWhitelistingSearchAddress } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: getWhitelistingSearchAddress,
    data: whitelistingSearchAddressData,
    error: whitelistingSearchAddressError,
    isLoading: isWhitelistingSearchAddressLoading,
    isSuccess: isWhitelistingSearchAddressSuccess,
    isError: isWhitelistingSearchAddressError,
  } = useMutation(GetWhitelistingSearchAddress)

  return {
    getWhitelistingSearchAddress,
    whitelistingSearchAddressData,
    whitelistingSearchAddressError,
    isWhitelistingSearchAddressLoading,
    isWhitelistingSearchAddressSuccess,
    isWhitelistingSearchAddressError,
  }
}
