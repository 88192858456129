import { useMutation } from 'react-query'

// Services
import { GetActivationLink } from 'services/api/Profile'

export default () => {
  const {
    mutate: getActivationLink,
    data: getActivationLinkData,
    error: getActivationLinkError,
    isLoading: isGetActivationLinkLoading,
    isSuccess: isGetActivationLinkSuccess,
    isError: isGetActivationLinkError,
  } = useMutation(GetActivationLink)

  return {
    getActivationLink,
    getActivationLinkData,
    getActivationLinkError,
    isGetActivationLinkLoading,
    isGetActivationLinkSuccess,
    isGetActivationLinkError,
  }
}
