import React, { createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Constants
import {
  CASE_RISK_OPTIONS,
  ADVERSE_MEDIA_OPTIONS,
  POLITICALLY_EXPOSED_PERSON_OPTIONS,
} from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useUpdateCase } from 'core/hooks/api'

// Styled Elements
import {
  CaseDetailsKYCFormWrapper,
  FormHeader,
  FormInputGroupWrapper,
  FormInputGroupItem,
} from './CaseDetailsKYCForm.elements'

// Views
import { Form, FormSelect, FormTextArea } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function CaseDetailsKYCForm(props) {
  // Destructure
  const { setIsCaseUpdated, formRef, customerData, actions } = props
  const { setShowHeaderLoader, showAlert } = actions
  const {
    Adverse_Media_ID,
    Business_ID,
    Business_Name,
    Case_ID,
    Case_Since,
    Case_Status,
    Case_Type,
    Case_Type_ID,
    Comments,
    Company_Name,
    Country_Address,
    Customer_ID,
    Date_Birth,
    Date_Incorporation,
    Country_Incorporation,
    First_Name,
    Last_Modified,
    Last_Name,
    Middle_Name,
    Nationality,
    Nationality_Description,
    PEP_ID,
    Risk_ID,
    Status_ID,
  } = customerData

  // Hooks
  const { updateCase, isUpdateCaseLoading, isUpdateCaseSuccess, isUpdateCaseError } = useUpdateCase()

  // Variables
  const initialValues = {
    caseRisk: Risk_ID,
    adverseMedia: Adverse_Media_ID,
    politicallyExposedPerson: PEP_ID,
    comments: Comments,
  }

  const validationSchema = Yup.object().shape({})

  // Functions
  const handleOnSubmit = (values) => {
    const { caseRisk, adverseMedia, politicallyExposedPerson, comments } = values

    const payload = {
      // Default
      customer_id: Customer_ID,
      case_type: Case_Type,
      case_type_id: Case_Type_ID,
      case_since: Case_Since,
      last_modified: Last_Modified,
      business_id: Business_ID,
      business_name: Business_Name,
      case_id: Case_ID,
      statusText: Case_Status,
      status: Status_ID,
      country_address: Country_Address,

      // Default (Natural Person)
      date_birth: Date_Birth,
      first_name: First_Name,
      last_name: Last_Name,
      middle_name: Middle_Name,
      nationality: Nationality,
      nationalityText: Nationality_Description,

      // Default (Legal Person)
      company_name: Company_Name,
      date_incorporation: Date_Incorporation,
      country_incorporation: Country_Incorporation,

      // Editable
      adverseMediaText: ADVERSE_MEDIA_OPTIONS.filter(({ value }) => value === +adverseMedia)[0]?.label || '',
      adverse_media: adverseMedia,
      caseRiskText: CASE_RISK_OPTIONS.filter(({ value }) => value === +caseRisk)[0]?.label || '',
      case_risk: caseRisk,
      comments,
      pep: politicallyExposedPerson,
      pepText: POLITICALLY_EXPOSED_PERSON_OPTIONS.filter(({ value }) => value === +politicallyExposedPerson)[0]?.label || '',
    }

    updateCase(payload)
  }

  useEffect(() => {
    if (isUpdateCaseLoading) { return setShowHeaderLoader(true) }
    if (!isUpdateCaseLoading && isUpdateCaseSuccess) {
      setIsCaseUpdated(true)
      setShowHeaderLoader(false)
    }
  }, [isUpdateCaseLoading])

  useEffect(() => {
    if (isUpdateCaseError) showAlert({ type: 'error', message: 'An error occured in saving the case, please try again.' })
  }, [isUpdateCaseError])

  return (
    <CaseDetailsKYCFormWrapper>
      <Form
        enableReinitialize
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormHeader>Know Your Customer (KYC) Overview</FormHeader>
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect label="Case Risk" name="caseRisk" options={CASE_RISK_OPTIONS} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect label="Adverse Media" name="adverseMedia" options={ADVERSE_MEDIA_OPTIONS} />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSelect
                label="Politically Exposed Person"
                name="politicallyExposedPerson"
                options={POLITICALLY_EXPOSED_PERSON_OPTIONS}
              />
            </FormInputGroupItem>
            <FormInputGroupItem />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextArea label="Observations" name="comments" resize rows={9} />
          </FormInputGroupItem>
        </FormInputGroupWrapper>
      </Form>
    </CaseDetailsKYCFormWrapper>
  )
}

// Default Props
CaseDetailsKYCForm.defaultProps = {
  formRef: createRef(),
  setIsCaseUpdated: () => {},
  actions: {},
  customerData: {},
}

// Proptypes Validation
CaseDetailsKYCForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  setIsCaseUpdated: PropTypes.func,
  customerData: PropTypes.shape({
    Customer_ID: PropTypes.string,
    AddressCount: PropTypes.number,
    Adverse_Media: PropTypes.string,
    Adverse_Media_ID: PropTypes.number,
    Business_ID: PropTypes.string,
    Business_Name: PropTypes.string,
    Case_ID: PropTypes.string,
    Case_Risk: PropTypes.string,
    Case_Since: PropTypes.string,
    Case_Status: PropTypes.string,
    Case_Type: PropTypes.string,
    Case_Type_ID: PropTypes.number,
    Comments: PropTypes.string,
    Company_Name: PropTypes.string,
    Country_Address: PropTypes.string,
    Country_Address_Description: PropTypes.string,
    Country_Incorporation: PropTypes.string,
    Country_Incorporation_Description: PropTypes.string,
    Date_Birth: PropTypes.string,
    Date_Incorporation: PropTypes.string,
    First_Name: PropTypes.string,
    Last_Modified: PropTypes.string,
    Last_Name: PropTypes.string,
    Middle_Name: PropTypes.string,
    Nationality: PropTypes.string,
    Nationality_Description: PropTypes.string,
    PEP: PropTypes.string,
    PEP_ID: PropTypes.number,
    Risk_ID: PropTypes.number,
    Status_ID: PropTypes.number,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetailsKYCForm)
