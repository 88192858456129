import styled from 'styled-components'

import DatePicker from 'react-bootstrap-daterangepicker'

// Assets
import CalendarIcon from 'assets/images/icon-calendar.svg'

export const DatePickerWrapper = styled.div`
  font-size: 18px;

  width: 100%;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`
export const DatePickerInput = styled(DatePicker)``

export const DateHolder = styled.div`
  position: relative;

  display: flex;
  padding: 15px 20px;
  width: 100%;

  border-radius: 10px;

  cursor: pointer;

  // TODO: Use themed functions
  &:after {
    content: '';

    position: absolute;
    top: 50%;
    right: 15px;

    transform: translateY(-50%);

    width: 15px;
    height: 15px;

    background-image: url(${CalendarIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  // Themed Properties
  border: 1px solid ${({ theme }) => theme.colors.lightGray};

  background-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.colors.lightGray)};
`

export const DateTextWrapper = styled.p`
  font-size: 16px;
  margin: 0;
`

export const DateText = styled.span``
