import { useMutation } from 'react-query'

// Services
import { SearchTransfer } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: searchTransfer,
    data: transferSearchData,
    error: transferSearchError,
    isLoading: isTransferSearchLoading,
    isSuccess: isTransferSearchSuccess,
    isError: isTransferSearchError,
  } = useMutation(SearchTransfer)

  return {
    searchTransfer,
    transferSearchData,
    transferSearchError,
    isTransferSearchLoading,
    isTransferSearchSuccess,
    isTransferSearchError,
  }
}
