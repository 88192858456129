import { useMutation } from 'react-query'

// Services
import { GetAllCaseCountries } from 'services/api/Cases'

export default () => {
  const {
    mutate: getAllCaseCountries,
    data: allCaseCountriesData,
    error: allCaseCountriesError,
    isLoading: isAllCaseCountriesLoading,
    isSuccess: isAllCaseCountriesSuccess,
    isError: isAllCaseCountriesError,
  } = useMutation(GetAllCaseCountries)

  return {
    getAllCaseCountries,
    allCaseCountriesData,
    allCaseCountriesError,
    isAllCaseCountriesLoading,
    isAllCaseCountriesSuccess,
    isAllCaseCountriesError,
  }
}
