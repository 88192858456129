import { useMutation } from 'react-query'

// Services
import { DeleteAddress } from 'services/api/Addresses'

export default () => {
  const {
    mutate: deleteAddress,
    data: deleteAddressData,
    error: deleteAddressError,
    isLoading: isDeleteAddressLoading,
    isSuccess: isDeleteAddressSuccess,
    isError: isDeleteAddressError,
  } = useMutation(DeleteAddress)

  return {
    deleteAddress,
    deleteAddressData,
    deleteAddressError,
    isDeleteAddressLoading,
    isDeleteAddressSuccess,
    isDeleteAddressError,
  }
}
