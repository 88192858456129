import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Styled Elements
import { DrawerWrapper, DrawerCloseIcon } from '../DirectoryDrawers.elements'

// Views
import { Drawer } from 'views/components'

import { VaspForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function VaspDrawer(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isVaspDrawerOpen } = ui

  // Store Actions
  const { setIsVaspDrawerOpen } = actions

  const handleCloseDrawer = () => {
    setIsVaspDrawerOpen(false)
  }

  return (
    <Drawer open={isVaspDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <DrawerWrapper>
        <DrawerCloseIcon onClick={handleCloseDrawer}>
          <CloseIcon />
        </DrawerCloseIcon>
        <VaspForm />
      </DrawerWrapper>
    </Drawer>
  )
}

// Default Props
VaspDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
VaspDrawer.propTypes = {
  ui: PropTypes.shape({ isVaspDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ setIsVaspDrawerOpen: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspDrawer)
