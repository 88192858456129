/* eslint-disable max-len */
import React from 'react'

export default function RippleMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M356.055 126H399.414L309.18 215.344C276.502 247.682 223.521 247.682 190.82 215.344L100.551 126H143.945L212.5 193.869C222.488 203.714 235.949 209.232 249.974 209.232C263.998 209.232 277.459 203.714 287.447 193.869L356.055 126Z"
        fill="#00CACD"
      />
      <path
        d="M143.389 374.438H100L190.82 284.543C223.498 252.205 276.479 252.205 309.18 284.543L400 374.438H356.641L287.5 306.018C277.512 296.173 264.051 290.655 250.026 290.655C236.002 290.655 222.541 296.173 212.553 306.018L143.389 374.438Z"
        fill="#00CACD"
      />
    </svg>
  )
}
