import { useMutation } from 'react-query'

// Services
import { SearchRecipient } from 'services/api/Recipients'

export default () => {
  const {
    mutate: searchRecipient,
    data: recipientSearchData,
    error: recipientSearchError,
    isLoading: isRecipientSearchLoading,
    isSuccess: isRecipientSearchSuccess,
    isError: isRecipientSearchError,
  } = useMutation(SearchRecipient)

  return {
    searchRecipient,
    recipientSearchData,
    recipientSearchError,
    isRecipientSearchLoading,
    isRecipientSearchSuccess,
    isRecipientSearchError,
  }
}
