// * Accounts
export const ACCOUNT_STATUS_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Inactive', value: 0 },
  { label: 'Active', value: 1 },
  { label: 'On Hold', value: 2 },
  { label: 'Dormant', value: 3 },
]
export const ACCOUNT_TYPE_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Individual', value: 1 },
  { label: 'Entity', value: 2 },
]
export const ACCOUNT_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-created' },
  { label: 'Oldest', value: 'created' },
]
// * Recipients
export const RECIPIENT_TYPE_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Individual', value: 1 },
  { label: 'Entity', value: 2 },
]
export const RECIPIENT_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-created' },
  { label: 'Oldest', value: 'created' },
]
// * VASPS
export const VASP_SORT_DATE_FILTER = [
  { label: 'Oldest', value: 'created' },
  { label: 'Newest', value: '-created' },
  { label: 'Name (A-Z)', value: 'name_business' },
  { label: 'Name (Z-A)', value: '-name_business' },
]

// * Travel Rule
export const TRAVELRULE_SORT = [
  { label: 'Amount (Highest)', value: '-amount' },
  { label: 'Amount (Lowest)', value: 'amount' },
  { label: 'Date (Newest)', value: '-last_modified' },
  { label: 'Date (Oldest)', value: 'last_modified' },
]
export const TRAVELRULE_STATUS_FILTER = [
  { label: 'All', value: undefined },
  { label: 'Rejected', value: -3 },
  { label: 'Cancelled', value: -2 },
  { label: 'Confirmed', value: -1 },
  { label: 'Initiated', value: 0 },
  { label: 'Pending', value: 2 },
]
export const TRAVELRULE_ASSET_FILTER = [
  { label: 'All', value: undefined },
  { value: 'BTC', label: 'Bitcoin' },
  { value: 'ETH', label: 'Ethereum' },
  { value: 'BCH', label: 'Bitcoin Cash' },
  { value: 'LTC', label: 'Litecoin' },
  { value: 'BSV', label: 'Bitcoin SV' },
  { value: 'DOGE', label: 'Doge Coin' },
  { value: 'ZEC', label: 'Zcash' },
  { value: 'XRP', label: 'Ripple' },
  { value: 'XLM', label: 'Stellar' },
  { value: 'XDC', label: 'XDC' },
  { value: 'SOL', label: 'Solana' },
  { value: 'ALGO', label: 'Algorand' },
  { value: 'MATIC', label: 'Polygon' },
  { value: 'DOT', label: 'Polkadot' },
  { value: 'XTZ', label: 'Tezos' },
  { value: 'NEAR', label: 'Near' },
]
export const TRAVELRULE_DIRECTION_FILTER = [
  { label: 'In', value: 1 },
  { label: 'Out', value: 2 },
]

//  * Address
export const ADDRESS_SORT_DATE_FILTER = [
  { label: 'Date (Newest)', value: '-last_modified' },
  { label: 'Date (Oldest)', value: 'last_modified' },
  { label: 'Risk Score (Highest)', value: '-risk_ratio' },
  { label: 'Risk Score (Lowest)', value: 'risk_ratio' },
]
export const TRANSACTION_SORT_DATE_FILTER = [
  { label: 'Date (Newest)', value: '-last_modified' },
  { label: 'Date (Oldest)', value: 'last_modified' },
  { label: 'Risk Score (Highest)', value: '-risk_ratio' },
  { label: 'Risk Score (Lowest)', value: 'risk_ratio' },
  { label: 'Amount (Highest)', value: '-in_usd' },
  { label: 'Amount (Lowest)', value: 'in_usd' },
]
export const REPORT_ADDRESS_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-created' },
  { label: 'Oldest', value: 'created' },
]
export const REPORT_TRANSACTION_SORT_DATE_FILTER = [
  { label: 'Newest', value: '-created' },
  { label: 'Oldest', value: 'created' },
]
