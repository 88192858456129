import styled from 'styled-components'

export const ModalHeaderWrapper = styled.div`
  position: relative;
  border: none;
  padding: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`

export const ModalHeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & * {
    text-align: center;
  }
`

export const ModalHeaderCloseIcon = styled.div`
  transition: 0.4s;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 25px;

  & svg {
    fill: ${({ theme }) => theme.colors.gray};
  }
  & svg * path {
    fill: ${({ theme }) => theme.colors.gray};
  }
  &:hover {
    transform: scale(1.2);
  }
`
