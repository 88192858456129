import { useMutation } from 'react-query'

// Services
import { SearchMidProspect } from 'services/api/Vasps'

export default () => {
  const {
    mutate: searchMidProspect,
    data: midProspectSearchData,
    error: midProspectSearchError,
    isLoading: isMidProspectSearchLoading,
    isSuccess: isMidProspectSearchSuccess,
    isError: isMidProspectSearchError,
  } = useMutation(SearchMidProspect)

  return {
    searchMidProspect,
    midProspectSearchData,
    midProspectSearchError,
    isMidProspectSearchLoading,
    isMidProspectSearchSuccess,
    isMidProspectSearchError,
  }
}
