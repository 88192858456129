/* eslint-disable max-len */
import React from 'react'

export default function AlgorandMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M161.832 385.956L201.018 318.097L240.204 250.478L279.15 182.619L285.602 171.867L288.469 182.619L300.416 227.301L287.035 250.478L247.85 318.097L208.903 385.956H255.735L294.92 318.097L315.23 282.973L324.788 318.097L342.947 385.956H385L366.841 318.097L348.681 250.478L343.903 233.035L373.053 182.619H330.522L329.088 177.602L314.274 122.168L312.363 115H271.504L270.549 116.434L232.319 182.619L193.133 250.478L154.186 318.097L115 385.956H161.832Z"
        fill="#00CACD"
      />
    </svg>
  )
}
