import { useMutation } from 'react-query'

// Services
import { CreateRecipientEntity } from 'services/api/Recipients'

export default () => {
  const {
    mutate: createRecipientEntity,
    data: recipientEntityCreateData,
    error: recipientEntityCreateError,
    isLoading: isRecipientEntityCreateLoading,
    isSuccess: isRecipientEntityCreateSuccess,
    isError: isRecipientEntityCreateError,
  } = useMutation(CreateRecipientEntity)

  return {
    createRecipientEntity,
    recipientEntityCreateData,
    recipientEntityCreateError,
    isRecipientEntityCreateLoading,
    isRecipientEntityCreateSuccess,
    isRecipientEntityCreateError,
  }
}
