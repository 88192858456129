import styled from 'styled-components'

export const ProfileAccountInformationFormWrapper = styled.div``

export const FormLabel = styled.label`
  display: block;
`

export const FormInputGroupWrapper = styled.div``

export const FormInputGroupItem = styled.div`
  margin: 0px 0px 20px 0px;
`
