import { useMutation } from 'react-query'

// Services
import { GetUserProfile } from 'services/api/Profile'

export default () => {
  const {
    mutate: getUserProfile,
    data: userProfileData,
    error: userProfileError,
    isLoading: isUserProfileLoading,
    isSuccess: isUserProfileSuccess,
    isError: isUserProfileError,
  } = useMutation(GetUserProfile)

  return {
    getUserProfile,
    userProfileData,
    userProfileError,
    isUserProfileLoading,
    isUserProfileSuccess,
    isUserProfileError,
  }
}
