/* eslint-disable max-len */
import React from 'react'

export default function TravelRuleIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 18.25C17.0188 18.25 18.25 17.0188 18.25 15.5C18.25 13.9812 17.0188 12.75 15.5 12.75C13.9812 12.75 12.75 13.9812 12.75 15.5C12.75 17.0188 13.9812 18.25 15.5 18.25Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 7.25C6.01878 7.25 7.25 6.01878 7.25 4.5C7.25 2.98122 6.01878 1.75 4.5 1.75C2.98122 1.75 1.75 2.98122 1.75 4.5C1.75 6.01878 2.98122 7.25 4.5 7.25Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.916 4.5H13.666C14.1522 4.5 14.6186 4.69315 14.9624 5.03697C15.3062 5.38079 15.4993 5.8471 15.4993 6.33333V12.75"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.5 7.25V18.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
