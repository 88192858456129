/* eslint-disable max-len */
import React from 'react'

export default function CardanoMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M148.74 306.866C150.551 305.056 153.04 304 155.68 304H395.082C399.457 304 401.644 309.28 398.552 312.372L351.26 359.664C349.449 361.475 346.96 362.531 344.32 362.531H104.918C100.543 362.531 98.3558 357.251 101.448 354.158L148.74 306.866Z"
        fill="#00CACD"
      />
      <path
        d="M148.74 140.866C150.626 139.056 153.115 138 155.68 138H395.082C399.457 138 401.644 143.28 398.552 146.372L351.26 193.664C349.449 195.475 346.96 196.531 344.32 196.531H104.918C100.543 196.531 98.3558 191.251 101.448 188.158L148.74 140.866Z"
        fill="#00CACD"
      />
      <path
        d="M351.26 223.866C349.449 222.056 346.96 221 344.32 221H104.918C100.543 221 98.3558 226.28 101.448 229.372L148.74 276.664C150.551 278.475 153.04 279.531 155.68 279.531H395.082C399.457 279.531 401.644 274.251 398.552 271.158L351.26 223.866Z"
        fill="#00CACD"
      />
    </svg>
  )
}
