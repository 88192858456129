import { useMutation } from 'react-query'

// Services
import { PostTransactionReport } from 'services/api/Transactions'

export default () => {
  const {
    mutate: postTransactionReport,
    data: postTransactionReportData,
    error: postTransactionReportError,
    isLoading: isPostTransactionReportLoading,
    isSuccess: isPostTransactionReportSuccess,
    isError: isPostTransactionReportError,
  } = useMutation(PostTransactionReport)

  return {
    postTransactionReport,
    postTransactionReportData,
    postTransactionReportError,
    isPostTransactionReportLoading,
    isPostTransactionReportSuccess,
    isPostTransactionReportError,
  }
}
