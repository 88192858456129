/* eslint-disable max-len */
import React from 'react'

export default function StellarMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M357.658 156.217L321.535 174.621L147.102 263.468C146.512 258.97 146.215 254.437 146.213 249.9C146.237 231.797 150.99 214.014 160.002 198.312C169.013 182.611 181.97 169.536 197.589 160.383C213.208 151.23 230.948 146.317 249.05 146.129C267.152 145.941 284.99 150.486 300.795 159.314L321.471 148.779L324.556 147.205C305.615 133.458 283.242 125.215 259.91 123.388C236.578 121.56 213.194 126.22 192.344 136.851C171.495 147.482 153.99 163.671 141.766 183.628C129.541 203.586 123.073 226.534 123.075 249.938C123.075 253.171 123.198 256.39 123.443 259.597C123.787 264.139 122.78 268.68 120.548 272.651C118.317 276.621 114.961 279.843 110.903 281.911L100 287.47V313.401L132.099 297.04L142.494 291.735L152.737 286.518L336.626 192.822L357.29 182.3L400 160.532V134.614L357.658 156.217Z"
        fill="#00CACD"
      />
      <path
        d="M400 186.476L163.183 307.054L142.52 317.602L100 339.268V365.173L142.228 343.66L178.351 325.255L352.962 236.281C353.552 240.809 353.849 245.371 353.85 249.938C353.839 268.062 349.087 285.869 340.067 301.589C331.047 317.31 318.072 330.397 302.43 339.553C286.789 348.709 269.024 353.615 250.901 353.783C232.777 353.951 214.925 349.376 199.116 340.512L197.846 341.185L175.432 352.608C194.368 366.356 216.736 374.601 240.065 376.433C263.393 378.264 286.775 373.611 307.624 362.987C328.474 352.363 345.981 336.182 358.211 316.232C370.441 296.282 376.917 273.339 376.925 249.938C376.925 246.676 376.798 243.414 376.557 240.19C376.214 235.65 377.22 231.11 379.449 227.14C381.678 223.17 385.03 219.948 389.084 217.877L400 212.318V186.476Z"
        fill="#00CACD"
      />
    </svg>
  )
}
