import styled from 'styled-components'

export const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  padding: 50px 0 0 100px;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.lightGray};
`
