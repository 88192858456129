import styled from 'styled-components'

export const AddPolicyFormWrapper = styled.div`
  height: ${({ isCreatePolicyActive }) => (isCreatePolicyActive ? 'fit-content' : 0)};
  padding: ${({ isCreatePolicyActive }) => (isCreatePolicyActive ? '25px 30px' : '0px 30px')};
  margin: ${({ isCreatePolicyActive }) => (isCreatePolicyActive ? '20px 0' : '0')};
  overflow: hidden;
  transition: 0.3s;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};

  /* FOR ADDING POLICIES */
  & > div {
    width: 100%;
  }
  form {
    display: grid;
    align-items: center;
    grid-template-columns: 200px 1fr 180px;
    gap: 25px;
    margin-top: -20px;
  }
  form > div {
    width: 100%;
  }
  form > div:last-child {
    width: auto;
  }
  textarea {
    height: auto;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
`
