import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 600px;

  font-size: 18px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  border-radius: 15px;

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
`

export const HeaderFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`

export const HeaderOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  height: 0px;
  width: 100%;
  padding: 0px 20px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-top: none;
  border-radius: 0 0 15px 15px;
  transform: translateY(-22px);

  transition: all 0.3s;

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['none', 'none', 'none', 'none', 'none', 'flex'])}

  // Conditional Properties
  ${({ collapseFilter, withFilter }) => (!collapseFilter && withFilter ? 'height: auto;' : 'height: 0;')}
  ${({ collapseFilter, withFilter }) => !collapseFilter && withFilter && 'padding: 20px;'}
  ${({ collapseFilter, withFilter }) => (!collapseFilter && withFilter ? 'overflow: visible;' : 'overflow: hidden;')}
  ${({ theme, collapseFilter, withFilter }) =>
    !collapseFilter && withFilter && `border: 1px solid ${theme.colors.lightGray}; border-top: none;`}
`

export const TableBodyOverflowWrapper = styled.div`
  overflow-x: visible;
`

export const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
`

export const TableBodyHeaderWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '10px')};

  padding: ${(props) => (props.headerPadding ? `${props.headerPadding}` : '20px')};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background: ${({ theme }) => theme.colors.darkishWhite};
  border-radius: 15px;

  // Conditional Properties
  ${({ showHeader }) => showHeader && 'display: flex;'}
`

export const TableBodyHeaderItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  text-align: left;
  font-weight: 400;
  font-size: 15px;
  font-family: Roboto;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};

  // Conditional Properties
  ${({ sortable }) => sortable && 'cursor: pointer;'}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ width }) => width && 'flex: none;'}
`

export const TableBodyHeaderText = styled.div``

export const TableBodySubHeaderText = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: 300;
`

export const TableBodyHeaderIcon = styled.span`
  display: none;

  margin: 0px 0px 0px 6px;
  transition: all 0.1s ease-in;

  // Conditional Properties
  ${({ active }) => active && 'display: flex;'}
  ${({ sortDirection }) => sortDirection === 'DESC' && 'transform: rotate(180deg);'}
`

export const TableBodyChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
