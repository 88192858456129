import styled from 'styled-components'

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: right;
`
export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > div:last-child {
    border: none;
    padding-bottom: 0;
  }
`
export const RowWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const RowContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
`
export const TextRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`
export const BlockContainer = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};

  /* background-color: ${({ theme }) => `${theme.colors.gray}10`}; */
  & > div:last-child {
    border: none;
  }
`
export const InfoTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};

  font-size: 14px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
`
