import React from 'react'
import { LoaderCircle } from '../../../components'

import { LoadingPageWrapper, LoadingPageTitle } from './LoadingPage.elements'

function LoadingPage() {
	return (
  <LoadingPageWrapper>
    <LoadingPageTitle>
      <LoaderCircle />
    </LoadingPageTitle>
  </LoadingPageWrapper>
	)
}

export default LoadingPage
