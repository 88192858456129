import { useMutation } from 'react-query'

// Services
import { UpdateVasp } from 'services/api/Vasps'

export default () => {
  const {
    mutate: updateVasp,
    data: vaspUpdateData,
    error: vaspUpdateError,
    isLoading: isVaspUpdateLoading,
    isSuccess: isVaspUpdateSuccess,
    isError: isVaspUpdateError,
  } = useMutation(UpdateVasp)

  return {
    updateVasp,
    vaspUpdateData,
    vaspUpdateError,
    isVaspUpdateLoading,
    isVaspUpdateSuccess,
    isVaspUpdateError,
  }
}
