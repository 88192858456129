import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import {
  ACCOUNT_TYPE_OPTIONS,
  ACCOUNT_STATUS_OPTIONS,
  NATIONAL_ID_TYPE_OPTIONS,
  // ACTIVITY_INDIVIDUAL_OPTIONS
} from 'common/constants/formOptions'
import { COUNTRIES_LIST } from 'common/constants/countries'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  FormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormTitle,
  TableButtonWrapper,
  TableRowWrapper,
  TablePanelWrapper,
} from './RecipientIndividualDetailsForm.elements'

// Views
import {
  Form,
  FormTextField,
  FormSelect,
  FormSearchSelect,
  FormDatePicker,
  FormTextArea,
  AccordionTableItem,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function RecipientIndividualDetailsForm(props) {
  // Destructure
  const { values, viewMode, formRef, onSubmit } = props

  // Internal State
  const [initialData, setInitialData] = useState()
  // Forms
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(true)
  const [isAddressExpanded, setIsAddressExpanded] = useState(false)
  const [isFreeFormAddressExpanded, setIsFreeFormAddressExpanded] = useState(false)
  const [isNationalIDExpanded, setIsNationalIDExpanded] = useState(false)

  // Variables
  const validationSchema = Yup.object().shape({
    reference_id: Yup.string().max(50).required('Recipient Id is required'),
    email: Yup.string().email().nullable(),
    primary_activity_code: Yup.number().nullable(),
    nationality_country_code: Yup.string().max(2).nullable(),
    name_first: Yup.string().max(50).nullable(),
    name_middle: Yup.string().max(50).nullable(),
    name_last: Yup.string().max(50).required('Last Name is required'),
    date_of_birth: Yup.date()
      .max(new Date(Date.now() - 567648000000), 'Must be atleast 18 years')
      .nullable()
      .test('date_of_birth', 'Place of Birth required when birthdate is specified', (value, details) => {
        if (!details?.parent?.place_of_birth && value) return false
        return true
      }),
    place_of_birth: Yup.string()
      .max(50)
      .nullable()
      .test('place_of_birth', 'Birthdate required when Place of Birth is specified', (value, details) => {
        if (!details?.parent?.date_of_birth && value) return false
        return true
      }),
    account_type_id: Yup.number().required(),
    status_id: Yup.number().nullable(),

    created: Yup.date().nullable(),
    last_modified: Yup.date().nullable(),

    address_line: Yup.string()
      .max(256)
      .nullable()
      .test(
        'address_line',
        "Required if street number, street name, and building name aren't specified",
        (value, details) => {
          const empty_address_street_no = !details?.parent?.address_street_no
          const empty_address_building_name = !details?.parent?.address_building_name
          const empty_address_street_name = !details?.parent?.address_street_name
          if (!value && empty_address_street_no && empty_address_building_name && empty_address_street_name) {
            return false
          }
          return true
        }
      ),
    address_street_no: Yup.string()
      .max(10)
      .nullable()
      .test('address_street_no', 'Required with street name if address line is not specified', (value, details) =>
        validateForm(details)
      ),
    address_street_name: Yup.string()
      .max(100)
      .nullable()
      .test('address_street_name', 'Required with building name if address line is not specified', (value, details) =>
        validateForm(details)
      ),
    address_building_name: Yup.string()
      .max(20)
      .nullable()
      .test('address_building_name', 'Required with street name if address line is not specified', (value, details) =>
        validateForm(details)
      ),
    address_floor: Yup.string().max(20).nullable(),
    address_room: Yup.string().max(20).nullable(),
    address_department: Yup.string().max(20).nullable(),
    address_postbox: Yup.string().max(20).nullable(),
    address_city_location: Yup.string().max(50).nullable(),
    address_city: Yup.string().max(50).nullable(),
    address_region: Yup.string().max(50).nullable(),
    address_district: Yup.string().max(50).nullable(),
    address_postcode: Yup.string().max(25).nullable(),
    address_country_code: Yup.string().max(2).nullable(),
    national_id_type_code: Yup.string().max(10).nullable(),
    national_id: Yup.string().max(35).nullable(),
    national_id_issue_country: Yup.string().max(2).nullable(),
    national_id_registration_authority: Yup.string().max(10).nullable(),
  })

  // Functions
  function validateForm(details) {
    const empty_line = !details?.parent?.address_line
    const empty_address_street_no = !details?.parent?.address_street_no
    const empty_address_building_name = !details?.parent?.address_building_name
    const empty_address_street_name = !details?.parent?.address_street_name
    if (!empty_line) return true
    if (empty_line && empty_address_street_no && empty_address_building_name && empty_address_street_name) {
      return false
    }
    if (empty_address_street_name) return false
    if (empty_address_street_no && empty_address_building_name) return false
    return true
  }

  // UseEffects
  useEffect(() => {
    setInitialData(values)
  }, [values])
  return (
    <FormWrapper>
      {initialData && (
        <Form formRef={formRef} initialValues={initialData} validationSchema={validationSchema} onSubmit={onSubmit}>
          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsDetailsExpanded(val)
            }}
            expandPanel={isDetailsExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isDetailsExpanded}>Personal information</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Recipient ID" name="reference_id" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSelect disabled label="Recipient Type" name="account_type_id" options={ACCOUNT_TYPE_OPTIONS} />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSelect disabled={viewMode} label="Status" name="status_id" options={ACCOUNT_STATUS_OPTIONS} />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Email" type="email" name="email" />
                  </FormInputGroupItem>
                  {/* <FormInputGroupItem>
                    <FormSelect
                      disabled={viewMode}
                      label="Primary Activity / Profession"
                      name="primary_activity_code"
                      options={ACTIVITY_INDIVIDUAL_OPTIONS}
                    />
                  </FormInputGroupItem> */}
                  <FormInputGroupItem>
                    <FormSearchSelect
                      disabled={viewMode}
                      label="Nationality"
                      name="nationality_country_code"
                      options={COUNTRIES_LIST}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="First Name" name="name_first" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Middle Name" name="name_middle" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Last Name" name="name_last" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormDatePicker disabled={viewMode} label="Date of Birth" name="date_of_birth" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Place of Birth" name="place_of_birth" />
                  </FormInputGroupItem>
                  {/* <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="National ID" name="national_id" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSearchSelect
                      disabled={viewMode}
                      label="National ID Country Issuer"
                      name="national_id_issue_country"
                      options={COUNTRIES_LIST}
                    />
                  </FormInputGroupItem> */}

                  <FormInputGroupItem>
                    <FormDatePicker disabled label="Created" name="created" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormDatePicker disabled label="Last Modified" name="last_modified" />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />
          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsAddressExpanded(val)
            }}
            expandPanel={isAddressExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isAddressExpanded}>Structured address</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Street Number" name="address_street_no" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Street Name" name="address_street_name" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Building Name" name="address_building_name" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Floor" name="address_floor" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Room" name="address_room" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Department" name="address_department" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Postbox" name="address_postbox" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="City Location" name="address_city_location" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="City" name="address_city" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Region" name="address_region" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="District" name="address_district" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="Zipcode" name="address_postcode" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSearchSelect
                      disabled={viewMode}
                      label="Country"
                      name="address_country_code"
                      options={COUNTRIES_LIST}
                    />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />
          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsFreeFormAddressExpanded(val)
            }}
            expandPanel={isFreeFormAddressExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isFreeFormAddressExpanded}>Free Form address</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormTextArea disabled={viewMode} label="Address Line" name="address_line" />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />

          <AccordionTableItem
            style={{ marginTop: '20px' }}
            setIsExpanded={(val) => {
              setIsNationalIDExpanded(val)
            }}
            expandPanel={isNationalIDExpanded}
            button={
              <TableButtonWrapper>
                <TableRowWrapper isExpanded={isNationalIDExpanded}>National ID</TableRowWrapper>
              </TableButtonWrapper>
            }
            panel={
              <TablePanelWrapper>
                <FormGroupWrapper>
                  <FormInputGroupItem>
                    <FormSelect
                      disabled={viewMode}
                      label="National ID Type"
                      name="national_id_type_code"
                      options={NATIONAL_ID_TYPE_OPTIONS}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField disabled={viewMode} label="National ID" name="national_id" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSelect
                      disabled={viewMode}
                      label="National ID Country Issuer"
                      name="national_id_issue_country"
                      options={COUNTRIES_LIST}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField
                      disabled={viewMode}
                      label="National ID Registration Authority"
                      name="national_id_registration_authority"
                    />
                  </FormInputGroupItem>
                </FormGroupWrapper>
              </TablePanelWrapper>
            }
          />
          <FormTitle />
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
RecipientIndividualDetailsForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    reference_id: '',
    account_type_id: 0,
    status_id: 0,
    email: '',
    primary_activity_code: 0,
    nationality_country_code: '',
    name_first: '',
    name_middle: '',
    name_last: '',
    date_of_birth: '',

    created: '',
    last_modified: '',

    address_street_name: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  },
  viewMode: true,
}

// Proptypes Validation
RecipientIndividualDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedRecipient: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    reference_id: PropTypes.string,
    account_type_id: PropTypes.number,
    status_id: PropTypes.number,
    email: PropTypes.string,
    primary_activity_code: PropTypes.number,
    nationality_country_code: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    date_of_birth: PropTypes.string,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street_name: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
  }),
  viewMode: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientIndividualDetailsForm)
