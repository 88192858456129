/* eslint-disable max-len */
import React from 'react'

export default function LitecoinMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 12.5001C24.5 19.127 19.1275 24.5 12.5001 24.5C5.8729 24.5 0.5 19.127 0.5 12.5001C0.5 5.8726 5.87289 0.5 12.5001 0.5C19.1276 0.5 24.5 5.87249 24.5 12.5001Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
      />
      <path
        d="M11.7697 15.9533L12.5816 12.8961L14.5038 12.1938L14.982 10.3971L14.9656 10.3525L13.0735 11.0438L14.4368 5.9104H10.5704L8.78753 12.6096L7.29895 13.1534L6.80708 15.0057L8.29453 14.4623L7.24375 18.4105H17.5337L18.1934 15.9533H11.7697Z"
        fill="#00CACD"
      />
    </svg>
  )
}
