import { useMutation } from 'react-query'

// Services
import { GetCaseInvestigation } from 'services/api/Cases'

export default () => {
  const {
    mutate: getCaseInvestigation,
    data: caseInvestigationData,
    error: caseInvestigationError,
    isLoading: isCaseInvestigationLoading,
    isSuccess: isCaseInvestigationSuccess,
    isError: isCaseInvestigationError,
  } = useMutation(GetCaseInvestigation, { retry: 2 })

  return {
    getCaseInvestigation,
    caseInvestigationData,
    caseInvestigationError,
    isCaseInvestigationLoading,
    isCaseInvestigationSuccess,
    isCaseInvestigationError,
  }
}
