import { useMutation } from 'react-query'

// Services
import { CreateAccountEntity } from 'services/api/Accounts'

export default () => {
  const {
    mutate: createAccountEntity,
    data: accountEntityCreateData,
    error: accountEntityCreateError,
    isLoading: isAccountEntityCreateLoading,
    isSuccess: isAccountEntityCreateSuccess,
    isError: isAccountEntityCreateError,
  } = useMutation(CreateAccountEntity)

  return {
    createAccountEntity,
    accountEntityCreateData,
    accountEntityCreateError,
    isAccountEntityCreateLoading,
    isAccountEntityCreateSuccess,
    isAccountEntityCreateError,
  }
}
