/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-family: 'OpenSans', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    
    &:hover, &:focus{
      text-decoration: none;
      color: inherit;
    }
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  button {
    &:focus {
      outline: none;
    }
  }

  button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    box-shadow: none;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  label {
    margin-bottom: 5px;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-button {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #787878;
    border: 0px none #ffffff;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #787878;
  }
  ::-webkit-scrollbar-track {
    background: #e3e3e3;
    border: 0px none #ffffff;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #e3e3e3;
  }
  ::-webkit-scrollbar-track:active {
    background: #e3e3e3;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

`

export default GlobalStyle
