import { useMutation } from 'react-query'

// Services
import { UpdateAccountEntity } from 'services/api/Accounts'

export default () => {
  const {
    mutate: updateAccountEntity,
    data: accountEntityUpdateData,
    error: accountEntityUpdateError,
    isLoading: isAccountEntityUpdateLoading,
    isSuccess: isAccountEntityUpdateSuccess,
    isError: isAccountEntityUpdateError,
  } = useMutation(UpdateAccountEntity)

  return {
    updateAccountEntity,
    accountEntityUpdateData,
    accountEntityUpdateError,
    isAccountEntityUpdateLoading,
    isAccountEntityUpdateSuccess,
    isAccountEntityUpdateError,
  }
}
