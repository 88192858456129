import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'

// Views
import { Image } from 'views/components'

// Styled Elements
import {
  SelectWrapper,
  Label,
  FormGroupWrapper,
  SelectInput,
  SelectInputLabel,
  SelectInputOptionsContainer,
  SelectInputOptions,
  FormErrorMessage,
  Abbreviation,
} from './FormSearchSelect.elements'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function FormSearchSelect(props) {
  // Destructure
  const { label, placeholder, options, disabled, type, ...rest } = props

  const [fields] = useField(props)

  // Variables
  const wrapperRef = useRef()
  const inputRef = useRef(null)
  const { name, value, onChange } = fields

  // Hooks
  const [formValue, setValue] = useState('')
  const [formValueChanged, setFormValueChanged] = useState(false)
  const [valueLabel, setValueLabel] = useState(placeholder || '')
  const [showOptions, setShowOptions] = useState(false)
  const [optionState, setOptionState] = useState(options)

  // Functions
  const handleOnChange = (inputValue, inputLabel) => {
    setValue(inputValue)
    if (inputValue === undefined) {
      setValueLabel(placeholder || '')
      setFormValueChanged(true)
      return setShowOptions(false)
    }
    setValueLabel(inputLabel)
    setFormValueChanged(true)
    setShowOptions(false)
  }
  const handleOnSearch = (inputValue) => {
    if (!showOptions) setShowOptions(true)
    setValueLabel(inputValue)
    if (!inputValue) {
      setOptionState(options)
      setValue('')
      return setFormValueChanged(true)
    }
    const newOptions = []
    options.forEach((option) => {
      if (option?.label.toLowerCase().startsWith(inputValue.toLowerCase())) {
        newOptions.push(option)
      }
    })
    if (newOptions.length < 1) {
      return setOptionState([])
    }
    setOptionState(newOptions)
  }

  // UseEffects
  useEffect(() => {
    // setting the value and label on initial load
    if (value !== undefined && value !== null) {
      setValue(value)
      const currentLabel = options.find((data) => {
        if (typeof data.value === 'number') {
          return data.value === Number(value)
        }
        return data.value === value
      })
      if (currentLabel?.label) {
        setValueLabel(currentLabel.label)
      } else if (!currentLabel?.label) {
        setValueLabel(placeholder || '')
      }
    }
  }, [value])
  // manually calling onchange event of formik input through onClick
  useEffect(() => {
    if (formValueChanged) {
      inputRef.current.click()
      setFormValueChanged(false)
    }
  }, [formValueChanged])
  useEffect(() => setOptionState(options), [options])
  // Closing options on click away
  useOutsideAlerter(wrapperRef, setShowOptions)

  return (
    <SelectWrapper label={label}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <FormGroupWrapper ref={wrapperRef}>
        <SelectInput
          autoComplete="form-search-select"
          ref={inputRef}
          type="search"
          onClick={(e) => {
            onChange(e)
          }}
          name={name}
          onChange={() => {}}
          value={formValue}
          {...rest}
        />
        <SelectInputLabel
          autoComplete="form-search-select"
          disabled={disabled}
          value={valueLabel !== undefined ? valueLabel : placeholder}
          type="search"
          onChange={(e) => handleOnSearch(e.target.value)}
          onClick={() => {
            if (!disabled) setShowOptions(true)
          }}
        />
        {showOptions && (
          <SelectInputOptionsContainer>
            {optionState.length > 0 &&
              optionState.map(({ value, label, abrv, icon }) => (
                <div key={label}>
                  {label !== valueLabel && (
                    <SelectInputOptions
                      onClick={() => {
                        handleOnChange(value, label)
                      }}
                      value={value}
                    >
                      {icon && <Image src={icon} width={30} height={30} />}
                      {label}
                      {abrv && <Abbreviation> {abrv} </Abbreviation>}
                    </SelectInputOptions>
                  )}
                </div>
              ))}
            {optionState.length < 1 && <div> No Options Found</div>}
          </SelectInputOptionsContainer>
        )}
        <FormErrorMessage>
          <ErrorMessage {...fields} />
        </FormErrorMessage>
      </FormGroupWrapper>
    </SelectWrapper>
  )
}

// Default Props
FormSearchSelect.defaultProps = {
  label: '',
  placeholder: '',
  type: '',
  options: [],
}

// Proptypes Validation
FormSearchSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
}

export default FormSearchSelect
