import { useMutation } from 'react-query'

// Services
import { GetTransactions } from 'services/api/Transactions'

export default () => {
  const {
    mutate: getTransactions,
    data: getTransactionsData,
    error: getTransactionsError,
    isLoading: isGetTransactionsLoading,
    isSuccess: isGetTransactionsSuccess,
    isError: isGetTransactionsError,
  } = useMutation(GetTransactions)

  return {
    getTransactions,
    getTransactionsData,
    getTransactionsError,
    isGetTransactionsLoading,
    isGetTransactionsSuccess,
    isGetTransactionsError,
  }
}
