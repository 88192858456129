import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Components
import { Wrapper } from './AccountDetails.elements'

// Views
import { Container } from 'views/components'
import { App, Header, Sidebar, Content, PageWrapper, AccountDetails as AccountDetailsWrapper } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AccountDetails() {
  return (
    <App>
      <Sidebar />
      <Header link="/directory" pageTitle="Accounts" />
      <Content>
        <PageWrapper>
          <Container>
            <Wrapper>
              <AccountDetailsWrapper />
            </Wrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
AccountDetails.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
AccountDetails.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
