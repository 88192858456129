import { useState } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetDashboardTotalCases } from 'services/api/Cases'

export default () => {
  const [dashboardTotalCasesData, setDashboardTotalCasesData] = useState({
    title: 'Total Cases',
    figureTotalValue: 0,
    figureTitle: 'All-Time',
    figureSubtitle: 'Activity',
    breakdownData: [
      {
        name: 'Legal Person (Entity)',
        data: [0],
      },
      {
        name: 'Natural Person (Individual)',
        data: [0],
      },
    ],
  })

  const {
    mutate: getDashboardTotalCases,
    error: dashboardTotalCasesError,
    isLoading: isDashboardTotalCasesLoading,
    isSuccess: isDashboardTotalCasesSuccess,
    isError: isDashboardTotalCasesError,
  } = useMutation(GetDashboardTotalCases, {
    onSuccess: (data) => {
      setDashboardTotalCasesData((prevState) => ({
        ...prevState,
        figureTotalValue: data.TotalCases,
        breakdownData: [
          {
            name: 'Legal Person (Entity)',
            data: [data.Entities],
          },
          {
            name: 'Natural Person (Individual)',
            data: [data.Individuals],
          },
        ],
      }))
    },
  })

  return {
    getDashboardTotalCases,
    dashboardTotalCasesData,
    dashboardTotalCasesError,
    isDashboardTotalCasesLoading,
    isDashboardTotalCasesSuccess,
    isDashboardTotalCasesError,
  }
}
