import styled from 'styled-components'

export const SubHeaderWrapper = styled.div`
  padding: 5px 0 15px 15px;
`

export const SubHeaderNavList = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  gap: 30px;
  list-style: none;
`

export const SubHeaderNavListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  margin: 0;

  &:first-child {
    justify-content: flex-end;
  }
`

export const SubHeaderNavListLink = styled.div`
  position: relative;
  display: block;
  padding: 0 0 8px 0;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  // TODO: Use theme mixins
  &.active {
    font-weight: 700;
  }

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.blue};

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`
