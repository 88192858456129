import { useMutation } from 'react-query'

// Services
import { GetWhitelistingPagedTable } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: getWhitelistingPagedTable,
    data: whitelistingPagedTableData,
    error: whitelistingPagedTableError,
    isLoading: isWhitelistingPagedTableLoading,
    isSuccess: isWhitelistingPagedTableSuccess,
    isError: isWhitelistingPagedTableError,
  } = useMutation(GetWhitelistingPagedTable)

  return {
    getWhitelistingPagedTable,
    whitelistingPagedTableData,
    whitelistingPagedTableError,
    isWhitelistingPagedTableLoading,
    isWhitelistingPagedTableSuccess,
    isWhitelistingPagedTableError,
  }
}
