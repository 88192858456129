/* eslint-disable max-len */
// Utils
import riskScoreColor from 'common/utils/riskScoreColor'
import { numberWithCommas, abbreviateNumber } from 'common/utils/valueFormat'
import getObjectPropertyByString from 'common/utils/getObjectPropertyByString'
import { v4 as uuid } from 'uuid'

// Services
import { GetCaseInvestigation } from 'services/api/Cases'

// Constants
import { CHART_OPTIONS } from 'common/constants/chartOptions'

//* Parent Data Getter
const getParentData = (chartData, hash) => {
	let parentData = null

	const searchParentDataRecursively = (chartData, hash) =>
		chartData.map((item) => {
			if (item.value.hash === hash) {
				parentData = item
			}
			searchParentDataRecursively(item.children, hash)
		})

	searchParentDataRecursively(chartData, hash)

	return parentData
}

//* Tooltip formatter
const formatNodeTooltipWindow = ({
	value: { type, asset, direction, value, token, valueUsd, hash, color, riskScore, flag, timeStamp },
}) => {
	const extractToken = (token) => {
		const tokenListData = []
		const tokenStringItems = token ? token.split(';') : []

		tokenStringItems.map((item) => {
			const [name, value, contract] = item.split(',')
			tokenListData.push({ name, value, contract })
		})

		return tokenListData
	}

	const extractedTokenData = extractToken(token)

	const renderedTokenList = extractedTokenData.map(
		({ name, value, contract }) =>
			`<span style="cursor: pointer; color: #412ED4;" 
				data-element-type="node-token"
				data-contract=${contract} 
				data-direction=${direction} 
				data-hash=${hash} 
				>
					${name} 
					<span style="font-size: 10px; font-weight: 400; color: darkgrey;">(${abbreviateNumber(value)})</span>
			</span>`
	)

	return `
		<div style="background: #fff; border: 0; 
			padding: 15px; margin: 0px; border-radius: 5px; 
			border: 1px solid lightgrey; width: 550px;">
			<p style="padding: 0px 0px 10px 5px; margin: 0px; 
			color: grey; font-size: 14px; font-weight: 700; color: black;">${hash}</p>
			<ul style="padding: 0px; margin: 0px; list-style: none; color: black;">
				<li id="this-tooltip" style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
				border-left: 5px solid lightgrey; white-space: normal;">
					Type: ${type}
				</li>
				<li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
				border-left: 5px solid lightgrey; white-space: normal;">
					Asset: ${asset}
				</li>
				<li style=" margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
				border-left: 5px solid lightgrey; white-space: normal;">
					${type === 'transaction' ? `Amount ${asset}` : `Balance ${asset}`}: ${numberWithCommas(value)}
				</li>
				<li style=" margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
				border-left: 5px solid lightgrey; white-space: normal;">
					${type === 'transaction' ? 'Amount USD' : 'Balance USD'}: 
					${type === 'transaction' ? '-' : numberWithCommas(valueUsd)}
				</li>
				<li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
				border-left: 5px solid ${riskScoreColor(riskScore)}; white-space: normal;">
					Token: ${token === 'NA' ? '-' : renderedTokenList}
				</li>
				<li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
				border-left: 5px solid ${riskScoreColor(riskScore)}; white-space: normal;">
					Risk Score: ${riskScore}
				</li>
				<li style="margin: -1px; padding: 5px; border-bottom: 1px solid lightgrey; 
				border-left: 5px solid ${riskScoreColor(riskScore)}; white-space: normal;">
					Flags: ${flag}
				</li>
				<li style="margin: -1px; padding: 5px; border-left: 5px solid lightgrey; white-space: normal;">
				${type === 'transaction' ? 'Timestamp' : 'Last Activity'}: ${timeStamp}
				</li>
			</ul>
		</div>
	`
}

//* Chart Data Formatter
const formatChartData = (rawChildrenData) =>
	rawChildrenData.map(({ asset, chain, children, direction, entity, color, flag, hash, riskScore, token, type, value, valueUsd, timeStamp }) => ({
			name: hash,
			value: { type, asset, chain, amount: `${value} ${asset}`, value, valueUsd, token, entity, hash, riskScore, flag, color, direction, timeStamp, children, uniqueID: uuid() },
			symbol: type === 'transaction' ? 'square' : 'circle',
			symbolSize: 0,
			itemStyle: {
				borderWidth: 1,
				borderColor: '#777',
				color: riskScoreColor(riskScore),
			},
			tooltip: {
				trigger: 'item',
				triggerOn: 'mousemove',
				borderColor: 'none',
				padding: 0,
				formatter: formatNodeTooltipWindow,
			},
			label: {
				position: direction === 'outgoing' ? 'left' : 'right',
				verticalAlign: direction === 'middle',
				align: direction === 'outgoing' ? 'right' : 'left',
				formatter: ({ value: { hash, amount } }) => {
					const shortenedHash = `${hash.slice(0, 5)}[...]${hash.slice(hash.length - 5)}`

					return `{amount|${numberWithCommas(amount)}}\n{hash|${shortenedHash}}`
				},
				distance: 0,
				rich: {
					amount: {
						padding: 0,
						color: '#777',
						fontSize: 10,
						fontWeight: 600,
					},
					hash: {
						padding: 0,
						color: '#777',
						fontSize: 10,
						fontWeight: 600,
					},
				},
			},
			children: [],
		})
	)

const unifyDataFormat = (data) =>
	data.map(({ asset, chain, color, entity, flag, riskScore, token, type, ...rest }) => ({
		hash: type === 'transaction' ? rest.transaction : rest.address,
		value: type === 'transaction' ? rest.amount : rest.balance,
		valueUsd: type === 'transaction' ? rest.amountUSD : rest.balanceUSD,
		timeStamp: type === 'transaction' ? rest.timestamp : rest.lastActivity,
		asset,
		chain,
		color,
		entity,
		flag,
		riskScore,
		token,
		type,
	}))

//* Function for Appending Children to Node
const appendChildrenData = (parent, chartData, children) => {
	const childrenData = children.map((data) => ({
		...data,
		value: {
			...data.value,
			uniqueID: uuid(),
			direction: parent.value.direction,
			color: riskScoreColor(parent.value.riskScore),
			isToken: parent.value.isToken || false,
			children: [],
		},
	}))
	const updateRawChartDataRecursively = (parent, chartData) =>
		chartData.map((item) => {
			if (item.value.uniqueID === parent.value.uniqueID && !item.children.length) {
				return { ...item, children: childrenData }
			}
			if (item.value.uniqueID === parent.value.uniqueID && item.children.length) {
				return { ...item, children: [...parent.children, ...childrenData] }
			}
			if (item.value.uniqueID !== parent.value.uniqueID && item.children.length) {
				return { ...item, children: updateRawChartDataRecursively(parent, item.children) }
			}
			return item
		})

	const updatedRawChartData = updateRawChartDataRecursively(parent, chartData)

	return updatedRawChartData
}

//* Node Style formatter
const updateNodeStyles = (chartData) => {
	const getLargestNodeValue = (array, innerArrayField, field) => {
		let largestValue = 0
		const getLargestValueRecursively = (array, innerArrayField, field) => {
			if (array && array.length) {
				array.map((item) => {
					const currentValue = getObjectPropertyByString(field, item)
					if (currentValue) {
						if (currentValue > largestValue) largestValue = currentValue
					}
					getLargestValueRecursively(item[innerArrayField], innerArrayField, field)
				})
			}
		}
		getLargestValueRecursively(array, innerArrayField, field)
		return largestValue
	}

	const largestChartValue = getLargestNodeValue(chartData, 'children', 'value.value')

	const updateChartSymbolSizeRecursively = (chartData) =>
		chartData.map((item) => {
			const safeValue = item.value.value / largestChartValue || 0

			const cappedAmountForGraph = safeValue * 30 + 5

			return {
				...item,
				symbolSize: cappedAmountForGraph,
				label: {
					...item.label,
					distance: -(cappedAmountForGraph / 2),

					position: item.value.direction === 'outgoing' ? 'left' : 'right',
					verticalAlign: item.value.direction === 'middle',
					align: item.value.direction === 'outgoing' ? 'right' : 'left',

					rich: {
						...item.label.rich,
						amount: {
							...item.label.rich.amount,
							padding:
								item.value.direction === 'outgoing'
									? [0, cappedAmountForGraph / 2 + 5, -5, 0]
									: [0, 0, -5, cappedAmountForGraph / 2 + 5],
						},
						hash: {
							...item.label.rich.amount,
							padding:
								item.value.direction === 'outgoing'
									? [0, cappedAmountForGraph / 2 + 5, 0, 0]
									: [0, 0, 0, cappedAmountForGraph / 2 + 5],
						},
					},
				},
				children: item.children.length ? updateChartSymbolSizeRecursively(item.children) : [],
			}
		})

	const data = updateChartSymbolSizeRecursively(chartData)

	return data
}

//* Getting New Chart Options on Event
const handleUpdateEvent = ({ direction, hash, riskScore, children, type, chain, uniqueID }, echart, showAlert) => {
	GetCaseInvestigation({ cipher: hash, type, chain, direction, limit: 2, })
		.then((response) => {
			// Get current chart data to be updated
			const currentSeriesData = echart.getOption().series.filter(({ name }) => name === direction)[0].data
			const formattedChildrenData = formatChartData(unifyDataFormat(response))
			const chartDataWithAppendedChildren = appendChildrenData(
				{ value: { hash, direction, riskScore, uniqueID }, children },
				currentSeriesData,
				formattedChildrenData
			)
			const updatedChartData = updateNodeStyles(chartDataWithAppendedChildren)
			const updatedSeriesData = echart.getOption().series.map((seriesItem) => {
				if (seriesItem.name === direction) {
					return { ...seriesItem, data: updatedChartData }
				}
				return seriesItem
			})
			echart.setOption({ ...echart.getOption(), series: updatedSeriesData })
			echart.hideLoading()
		})
		.catch((error) => {
			const responseStatus = error.response.status

			if (responseStatus === 500) {
				showAlert({
					type: 'error',
					message: 'An error occured while fetching. Please try again.',
				})
				echart.hideLoading()
			} else if (responseStatus === 400) {
				showAlert({
					type: 'info',
					message: 'No further children data',
				})
				echart.hideLoading()
			}
		})
}

const handleOnInitialLoad = async ({ initialNodeData, setChartOptions, setIsChartLoaded }, showAlert) => {
	setIsChartLoaded(false)
	Promise.all([
		GetCaseInvestigation({
			cipher: initialNodeData.hash,
			type: initialNodeData.type,
			chain: initialNodeData.chain,
			direction: 'incoming',
			limit: 2,
		}),
		GetCaseInvestigation({
			cipher: initialNodeData.hash,
			type: initialNodeData.type,
			chain: initialNodeData.chain,
			direction: 'outgoing',
			limit: 2,
		})
	])
	.then(async (response) => {
		const [caseInvestigationData, caseInvestigationDataOutgoing] = response

		// Chart Data
		const incoming = updateNodeStyles(formatChartData([initialNodeData]))
		const outgoing = updateNodeStyles(formatChartData([{ ...initialNodeData, direction: 'outgoing' }]))

		// Chart Options
		const updatedChartOptions = CHART_OPTIONS;
		updatedChartOptions.series[0].data = incoming
		updatedChartOptions.series[1].data = outgoing

		const formattedChildrenData = formatChartData(unifyDataFormat(caseInvestigationData))
		const formattedChildrenDataOutgoing = formatChartData(unifyDataFormat(caseInvestigationDataOutgoing))

		const chartDataWithAppendedChildren = appendChildrenData(
			incoming[0],
			incoming,
			formattedChildrenData
		)
		const chartDataWithAppendedChildrenOutgoing = appendChildrenData(
			outgoing[0],
			outgoing,
			formattedChildrenDataOutgoing
		)

		const updatedChartData = updateNodeStyles(chartDataWithAppendedChildren)
		updatedChartOptions.series[0].data = updatedChartData

		const updatedChartDataOutgoing = updateNodeStyles(chartDataWithAppendedChildrenOutgoing)
		updatedChartOptions.series[1].data = updatedChartDataOutgoing

		await setChartOptions(updatedChartOptions)
		await setIsChartLoaded(true)
	})
	.catch(() => {
		showAlert({
			type: 'error',
			message: 'An error occured while fetching. Please try again.',
		})
	})
}

export { formatChartData, unifyDataFormat, appendChildrenData, updateNodeStyles, getParentData, handleUpdateEvent, handleOnInitialLoad }
