/* eslint-disable max-len */
import React from 'react'

export default function ReceiverIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 12.5001C24.5 19.127 19.1275 24.5 12.5001 24.5C5.8729 24.5 0.5 19.127 0.5 12.5001C0.5 5.8726 5.87289 0.5 12.5001 0.5C19.1276 0.5 24.5 5.87249 24.5 12.5001Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
      />
      <path d="M4 13H13.125" stroke="#00CACD" strokeWidth="2" />
      <path d="M9.47461 16.5L13.1246 13L9.47461 9.5" stroke="#00CACD" strokeWidth="2" />
      <circle cx="18" cy="13" r="2" fill="#00CACD" />
    </svg>
  )
}
