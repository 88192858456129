import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ContainerFluidWrapper } from './ContainerFluid.elements'

function ContainerFluid(props) {
  // Destructure
  const { children } = props

  return <ContainerFluidWrapper>{children}</ContainerFluidWrapper>
}

// Default Props
ContainerFluid.defaultProps = {
  children: '',
}

// Proptypes Validation
ContainerFluid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default ContainerFluid
