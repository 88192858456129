import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import LightTheme from 'common/config/theme/app/light'
import DarkTheme from 'common/config/theme/app/dark'

import { useLocalStorage } from 'core/hooks/storage'

function AppThemeProvider(props) {
  // Destructure
  const { children } = props

  const [theme] = useLocalStorage('theme', 'dark')

  return <ThemeProvider theme={theme === 'light' ? LightTheme : DarkTheme}>{children}</ThemeProvider>
}

export default AppThemeProvider

// Proptypes Validation
AppThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
}
