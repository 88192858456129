import React from 'react'
import PropTypes from 'prop-types'

// Library Components
import { IconButton } from '@material-ui/core'

// Assets
import { CloseIcon } from 'assets/images'

// Styled Elements
import { AlertContent } from './Alert.elements'

function Alert(props) {
  // Destructure
  const { open, message, onClose, anchorOrigin, color, autoHideDuration, ...rest } = props

  // Functions
  const handleOnClose = (e) => {
    onClose(e)
  }

  return (
    <AlertContent
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={handleOnClose}
      autoHideDuration={autoHideDuration}
      message={message}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleOnClose}>
          <CloseIcon onClick={handleOnClose} />
        </IconButton>
      }
      {...rest}
    />
  )
}

// Default Props
Alert.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  open: false,
  autoHideDuration: 3000,
  onClose: () => {},
  color: '',
  message: '',
}

// Proptypes Validation
Alert.propTypes = {
  anchorOrigin: PropTypes.shape({ vertical: PropTypes.string, horizontal: PropTypes.string }),
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func,
  color: PropTypes.string,
  message: PropTypes.string,
}

export default Alert
