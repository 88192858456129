import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

export const LineWrapper = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  max-width: 325px;
  top: 18px;
  left: 18px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
`
export const CompletedLineWrapper = styled.div`
  position: absolute;
  z-index: 1;
  max-width: 325px;
  top: 18px;
  left: 18px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
  width: ${({ width }) => width && `${width}%;`};
`
export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(52px, 1fr));
  gap: 10px;

  /* cursor: pointer; */
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const NumberContainer = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.gray};

  font-size: 14px;
  font-weight: 500;

  ${({ theme, active }) => active && `background-color: ${theme.colors.blue} !important;`};
  ${({ theme, active }) => active && `color: ${theme.colors.white} !important;`};
  ${({ theme, active }) => active && `border: 2px solid ${theme.colors.blue} !important;`};

  ${({ theme, completed }) => completed && `color: ${theme.colors.blue} !important;`};
  ${({ theme, completed }) => completed && `border: 2px solid ${theme.colors.blue} !important;`};
`

export const LabelContainer = styled.div`
  font-size: 12px;
  font-weight: 400;

  color: ${({ theme }) => theme.colors.gray};

  ${({ theme, active }) => active && `color: ${theme.colors.blue} !important;`};
  ${({ theme, completed }) => completed && `color: ${theme.colors.blue} !important;`};
`
