import styled from 'styled-components'

export const CaseStatusSummaryWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  padding: 20px;
  border-radius: 5px;

  // Themed Properties
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background-color: ${({ theme }) => theme.colors.white};
`

export const CaseStatusSummaryTextWrapper = styled.div`
  padding: 0px 50px 0px 20px;

  // Themed Properties
  border-right: 1px solid ${({ theme }) => theme.colors.lightGray};

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('border-right', [
      'none',
      'none',
      'none',
      'none',
      'none',
      `1px solid ${theme.colors.lightGray} `,
      `1px solid ${theme.colors.lightGray}`,
    ])}

  ${({ theme }) =>
    theme.functions.mediaQuery('padding', [
      '0px 20px 0px 0px',
      '0px 20px 0px 0px',
      '0px 20px 0px 0px',
      '0px 20px 0px 0px',
      '0px 20px 0px 0px',
      '0px 50px 0px 20px',
      '0px 50px 0px 20px',
    ])}

  // TODO: Use theme functions
  &:last-child {
    border-right: none;
  }

  &:first-child {
    /* padding: 0px 50px 0px 0px; */

    // Responsive Properties
    ${({ theme }) =>
      theme.functions.mediaQuery('padding', [
        '0px 20px 0px 0px',
        '0px 20px 0px 0px',
        '0px 20px 0px 0px',
        '0px 20px 0px 0px',
        '0px 20px 0px 0px',
        '0px 50px 0px 0px',
        '0px 50px 0px 0px',
      ])}
  }
`

export const CaseStatusSummaryText = styled.div`
  font-size: 14px;

  // Themed Properties
  color: ${({ theme }) => theme.colors.gray};
`
