/* eslint-disable no-confusing-arrow */
import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

export const SidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  width: 100%;
  max-width: 100px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
`
export const SidebarMenuNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  height: 100%;
  width: 100%;
`

export const SidebarMenuNavList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  height: 100%;
  width: 100%;
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    padding: 0 0 20px 0;
  }
`

export const SidebarBrand = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  padding: 15px 0 60px 0;
  text-align: left;
`

export const SidebarBrandLogo = styled.div`
  padding: 6px 0 0 0;
  width: 70px;
  & svg > path {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
`

export const SidebarMenuNavItem = styled.div`
  padding: 0px;
  align-items: center;
  width: 100%;
  transition: 0.1s;
`

export const SidebarMenuNavLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;

  text-align: center;
  line-height: 1.2;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-decoration: none;
  white-space: pre-wrap;

  padding: 10px 35px !important;
  transition: 0.3s;
  max-width: 100px;
  min-height: 80px;
  max-height: 80px;

  & > img {
    width: 25px;
    height: 25px;
  }
  & > svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }
  }

  // Media Query Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('padding', [
      '25px 125px',
      '25px 125px',
      '25px 125px',
      '25px 125px',
      '25px 125px',
      '25px 32px',
    ])}

  &:hover {
    background-color: ${({ theme }) => theme.colors.ash};
    color: ${({ theme }) => theme.colors.darkGray};
  }

  &.active {
    font-weight: 700;
    border-left: 6px solid ${({ theme }) => `${theme.colors.blue}`};
    background-color: ${({ theme }) => theme.colors.ash};
  }
`

export const SidebarMenuNavText = styled.div``

export const SidebarHelpWrapper = styled.div`
  padding: 0px;
  align-items: center;
  width: 100%;
  transition: 0.1s;
  cursor: pointer;
  position: relative;
`
export const SidebarHelpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;

  text-align: center;
  line-height: 1.2;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-decoration: none;
  white-space: pre-wrap;
  background-color: ${({ theme }) => `${theme.colors.blue}50`};

  max-width: 100px;
  min-height: 80px;
  max-height: 80px;
  transition: 0.3s;

  & > svg {
    width: 20px;
    height: 20px;
    & path {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.ash};
    color: ${({ theme }) => theme.colors.darkGray};
  }

  ${({ theme, active }) =>
    active
      ? `font-weight: 700; border-left: 6px solid ${theme.colors.blue}};`
      : `border-left: 0px solid ${theme.colors.blue}}`}
`
export const HelpMenuWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: 15px;
  overflow: hidden;
  ${({ active }) =>
    active ? 'width: fit-content; z-index: 99; padding: 15px;' : 'width: 0; z-index: 0; padding: 15px 0;'};
  transition: 0.3s;
`
export const HelpMenuContainer = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  overflow: hidden;
  width: max-content;
  transition: 0.3s;
`
export const Divider = styled.div`
  width: 100%;
  height: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`
