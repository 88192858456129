import { useMutation } from 'react-query'

// Services
import { CreateRecipientIndividual } from 'services/api/Recipients'

export default () => {
  const {
    mutate: createRecipientIndividual,
    data: recipientIndividualCreateData,
    error: recipientIndividualCreateError,
    isLoading: isRecipientIndividualCreateLoading,
    isSuccess: isRecipientIndividualCreateSuccess,
    isError: isRecipientIndividualCreateError,
  } = useMutation(CreateRecipientIndividual)

  return {
    createRecipientIndividual,
    recipientIndividualCreateData,
    recipientIndividualCreateError,
    isRecipientIndividualCreateLoading,
    isRecipientIndividualCreateSuccess,
    isRecipientIndividualCreateError,
  }
}
