import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  TableItemWrapper,
  TableItemInnerWrapper,
  TableItemInner,
  TableItemStartIcon,
  TableItemEndIcon,
} from './TableItem.elements'

function TableItem(props) {
  // Destructure
  const { children, startIcon, endIcon } = props

  return (
    <TableItemWrapper>
      <TableItemInnerWrapper>
        {startIcon && <TableItemStartIcon>{startIcon}</TableItemStartIcon>}
        {children && <TableItemInner>{children}</TableItemInner>}
        {endIcon && <TableItemEndIcon>{endIcon}</TableItemEndIcon>}
      </TableItemInnerWrapper>
    </TableItemWrapper>
  )
}

// Default Props
TableItem.defaultProps = {
  children: '',
  endIcon: '',
  startIcon: '',
}

// Proptypes Validation
TableItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element, PropTypes.node]),
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  startIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default TableItem
