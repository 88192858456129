import { useMutation } from 'react-query'

// Services
import { ResetPassword } from 'services/api/Auth'

export default () => {
  const {
    mutate: resetPassword,
    data: resetPasswordData,
    error: resetPasswordError,
    isLoading: isResetPasswordLoading,
    isSuccess: isResetPasswordSuccess,
    isError: isResetPasswordError,
  } = useMutation(ResetPassword)

  return {
    resetPassword,
    resetPasswordData,
    resetPasswordError,
    isResetPasswordLoading,
    isResetPasswordSuccess,
    isResetPasswordError,
  }
}
