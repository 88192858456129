import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  transactionHistoryDetails: {
    transaction_id: '',
    transaction: '',
    chain: '',
  },
  transactionReportDetails: {
    transaction_id: '',
    transaction: '',
    chain: '',
  },
  transactionLinkDetails: {
    link_id: '',
    link_type: '',
  },
  transactionLinkData: {
    account_id: '',
  },
  transactionRemove: {
    transaction_id: '',
  },
  transactionDetails: {
    transaction_id: '',
    transaction: '',
    chain: '',
    integration_id: '',
  },
  transactionModalDetails: {},
  scannedTransaction: {
    // id: '',
    // business_id: '',
    // block_id: '',
    // hash: '',
    // chain: '',
    // symbol: '',
    // financial_data: {
    //   hash: '',
    //   block_id: '',
    //   created: '',
    //   input_count: 0,
    //   input_native: 0,
    //   input_usd: 0,
    //   output_count: 0,
    //   output_native: 0,
    //   output_usd: 0,
    //   events: [],
    // },
    // risk_data: {
    //   risk_descriptors: '',
    //   risk: '',
    //   max_score: 0,
    //   risk_score: 0,
    //   risk_ratio: 0,
    //   sender_risk_score: 0,
    //   recipient_risk_score: 0,
    //   sender_risk_ratio: 0,
    //   recipient_risk_ratio: 0,
    //   risk_integration_id: '',
    //   risk_partner_desc: '',
    //   risk_score_desc: '',
    //   risk_partner: '',
    // },
    // removed: false,
    // created_by: '',
    // modified_by: '',
    // created: '',
    // last_modified: '',
    // request_tz: '',
    // observations: '',
    // sender_link_id: '',
    // recipient_link_id: '',
    // internal_tr_id: '',
    // fin_integration_id: '',
  },
  scannedTransactionDetails: {},
  transactionChains: {
    // RISK: {
    //   RDBIG: {
    //     BTC: {},
    //     ETH: {},
    //   },
    //   RDCFI: {
    //     BTC: {},
    //     ETH: {},
    //   },
    // },
    // FINANCIAL: {
    //   FDBLM: {
    //     BTC: {},
    //     ETH: {},
    //   },
    // },
  },
  defaultTransactionProvider: {
    // RISK: {
    //   RDCFI: {
    //     BTC: {},
    //     ETH: {},
    //   },
    // },
    // FINANCIAL: {
    //   FDBLM: {
    //     BTC: {},
    //     ETH: {},
    //   },
    // },
  },
}

const store = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransactionHistoryDetails: (state, { payload }) => {
      const { transaction_id, transaction, chain } = payload
      const payloadToAppend = { transaction_id, transaction, chain }
      state.transactionHistoryDetails = payloadToAppend
    },
    setTransactionReportDetails: (state, { payload }) => {
      const { transaction_id, transaction, chain } = payload
      const payloadToAppend = { transaction_id, transaction, chain }
      state.transactionReportDetails = payloadToAppend
    },
    setTransactionLinkDetails: (state, { payload }) => {
      // const { transaction_id, transaction, chain } = payload
      // const payloadToAppend = { transaction_id, transaction, chain }
      state.transactionLinkDetails = payload
    },
    setTransactionLinkData: (state, { payload }) => {
      // const { transaction_id, transaction, chain } = payload
      // const payloadToAppend = { transaction_id, transaction, chain }
      state.transactionLinkData = payload
    },
    setTransactionRemove: (state, { payload }) => {
      const { transaction_id } = payload
      const payloadToAppend = { transaction_id }
      state.transactionRemove = payloadToAppend
    },
    setTransactionDetails: (state, { payload }) => {
      const { transaction_id, transaction, chain } = payload
      const payloadToAppend = { transaction_id, transaction, chain }
      state.transactionDetails = payloadToAppend
    },
    setTransactionModalDetails: (state, { payload }) => {
      if (payload) state.transactionModalDetails = payload
      else state.transactionModalDetails = null
    },
    setScannedTransaction: (state, { payload }) => {
      if (payload) {
        state.scannedTransaction = payload
      } else {
        state.scannedTransaction = null
      }
    },
    setScannedTransactionDetails: (state, { payload }) => {
      if (payload) {
        state.scannedTransactionDetails = payload
      } else {
        state.scannedTransactionDetails = null
      }
    },
    setTransactionChains: (state, { payload }) => {
      if (payload) {
        state.transactionChains = payload
      } else {
        state.transactionChains = null
      }
    },
    setDefaultTransactionProvider: (state, { payload }) => {
      if (payload) {
        state.defaultTransactionProvider = payload
      } else {
        state.defaultTransactionProvider = null
      }
    },
  },
})

export default store
