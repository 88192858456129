import styled from 'styled-components'

export const FormWrapper = styled.div`
  padding-top: 40px;
`

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div > div {
    margin-bottom: 0 !important;
  }
`
export const FormGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 10px 60px;
`
export const FormTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TableButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 25px;
  gap: 50px;
  align-items: center;

  padding: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const TableRowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: 20px;
`

export const TablePanelWrapper = styled.div``
