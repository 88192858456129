import { useMutation } from 'react-query'

// Services
import { GetUser } from 'services/api/Auth'

export default () => {
  const {
    mutate: getUser,
    data: getUserData,
    error: getUserError,
    isLoading: isGetUserLoading,
    isSuccess: isGetUserSuccess,
    isError: isGetUserError,
  } = useMutation(GetUser)

  return { getUser, getUserData, getUserError, isGetUserLoading, isGetUserSuccess, isGetUserError }
}
