import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ContainerWrapper } from './Container.elements'

function Container(props) {
  // Destructure
  const { children } = props

  return <ContainerWrapper>{children}</ContainerWrapper>
}

// Default Props
Container.defaultProps = {
  children: '',
}

// Proptypes Validation
Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Container
