import { useMutation } from 'react-query'

// Services
import { GetAllCases } from 'services/api/Cases'

export default () => {
  const {
    mutate: getAllCases,
    data: allCasesData,
    error: allCasesError,
    isLoading: isAllCasesLoading,
    isSuccess: isAllCasesSuccess,
    isError: isAllCasesError,
  } = useMutation(GetAllCases)

  return {
    getAllCases,
    allCasesData,
    allCasesError,
    isAllCasesLoading,
    isAllCasesSuccess,
    isAllCasesError,
  }
}
