/* eslint-disable max-len */
import React from 'react'

export default function OspreeLogoIcon() {
  return (
    <svg width="70" height="18" viewBox="0 0 70 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.41901C0 2.8721 2.96803 0 6.62969 0C10.289 0 13.2617 2.8721 13.2617 6.41901C13.2617 9.9648 10.2936 12.838 6.62969 12.838C2.96803 12.838 0 9.9648 0 6.41901ZM3.28143 7.77085C3.41508 8.07758 3.59022 8.362 3.80222 8.61742C4.46588 9.41827 5.48441 9.93134 6.62969 9.93134C8.6368 9.93134 10.2591 8.35531 10.2591 6.41901C10.2591 4.48271 8.6368 2.90668 6.62969 2.90668C4.63179 2.90668 3.0026 4.47713 3.0026 6.41901C3.0026 6.89751 3.10169 7.3537 3.28143 7.77085Z"
        fill="white"
      />
      <path
        d="M18.859 4.87644C19.5411 5.18763 20.1195 5.49659 20.5919 5.80221C21.0389 6.0922 21.3915 6.37774 21.645 6.65882C21.9353 6.98897 22.1497 7.37489 22.281 7.80989C22.387 8.15566 22.44 8.53266 22.44 8.93754C22.44 10.0797 22.0091 11.0278 21.1611 11.7472C20.3177 12.461 19.2185 12.8269 17.905 12.8269C17.1422 12.8269 16.4048 12.6841 15.7043 12.3919C15.0084 12.1008 14.377 11.7182 13.8216 11.136L15.3909 8.69215L15.5453 8.81708C15.9878 9.17735 16.3933 9.43388 16.7643 9.5878C17.1307 9.74284 17.5271 9.83988 17.9442 9.86777C18.352 9.88003 18.67 9.8053 18.889 9.64581C19.0134 9.55434 19.101 9.44727 19.1517 9.32123C19.1862 9.23423 19.2024 9.13942 19.2024 9.03458C19.2024 8.78919 19.0848 8.56612 18.8475 8.34304C18.594 8.10881 18.0916 7.82327 17.3542 7.49201C16.9625 7.32693 16.6007 7.15516 16.2735 6.97893C15.8679 6.76032 15.5131 6.5339 15.2112 6.30078C15.1144 6.22605 15.0222 6.15132 14.937 6.07547C14.8517 6.00074 14.7733 5.9249 14.6996 5.84794C14.3263 5.46425 14.0705 5.02479 13.9369 4.53737C13.8608 4.26187 13.8216 3.97075 13.8216 3.66625C13.8216 3.29595 13.87 2.94795 13.9645 2.62337C14.1489 1.99207 14.513 1.44999 15.0545 1.00496C15.3149 0.79192 15.6006 0.612344 15.9117 0.466229C16.2735 0.295576 16.6698 0.172884 17.0962 0.0959227C17.4464 0.032346 17.8174 0 18.2115 0C19.207 0 20.3038 0.27996 21.4676 0.839881L21.6081 0.907919L20.1863 3.66068C19.7278 3.37514 19.3383 3.16991 19.0249 3.04387C18.723 2.92341 18.4396 2.86095 18.1815 2.86095C17.8497 2.86095 17.5847 2.9301 17.3957 3.0606C17.2206 3.18106 17.1376 3.34614 17.1376 3.56922C17.1376 3.72871 17.2552 3.90606 17.4902 4.10014C17.7621 4.31764 18.2161 4.57975 18.859 4.87644Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9384 0C28.5858 0 27.3299 0.393729 26.2791 1.06742V0.405998H23.2834V18H26.2791V11.7695C27.3299 12.4432 28.5858 12.838 29.9384 12.838C33.6001 12.838 36.5681 9.9648 36.5681 6.41901C36.5681 2.8721 33.6001 0 29.9384 0ZM29.9384 9.93134C27.9313 9.93134 26.309 8.35531 26.309 6.41901C26.309 6.24167 26.3229 6.06767 26.3482 5.89813C26.3759 5.71632 26.4197 5.54009 26.475 5.36944C26.6547 4.81063 26.975 4.31206 27.3967 3.91275C27.6272 3.69414 27.8875 3.50564 28.1687 3.35283C28.4475 3.20114 28.7517 3.08403 29.0697 3.00818C29.3485 2.94126 29.6389 2.90668 29.9384 2.90668C31.9432 2.90668 33.5655 4.48271 33.5655 6.41901C33.5655 6.89416 33.4664 7.34812 33.2913 7.76193C33.2452 7.869 33.1945 7.97385 33.1369 8.07535C33.054 8.22258 32.9618 8.36423 32.8604 8.49808C32.2013 9.36696 31.139 9.93134 29.9384 9.93134Z"
        fill="white"
      />
      <path
        d="M37.4069 6.40786C37.4069 2.86095 40.375 0 44.0366 0C44.4214 0 44.7994 0.0345768 45.1588 0.0914612L44.38 2.91783C44.2624 2.90668 44.1495 2.9011 44.032 2.9011C42.0249 2.9011 40.4026 4.46598 40.4026 6.40786V12.838H37.4069V6.40786Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5721 9.02342C52.9292 9.5878 52.0789 9.93134 51.141 9.93134C49.6731 9.93134 48.4057 9.08588 47.8388 7.869H56.9088C57.5886 10.7132 60.2225 12.838 63.368 12.838C65.0802 12.838 66.6379 12.2101 67.8108 11.1817L65.7991 9.02342C65.1562 9.5878 64.3059 9.93134 63.368 9.93134C62.7297 9.93134 62.1283 9.77073 61.6052 9.48965C60.9277 9.12492 60.3861 8.55608 60.0658 7.869H69.8295C69.9401 7.40166 70 6.91536 70 6.41901C70 2.8721 67.032 0 63.368 0C60.6189 0 58.2592 1.61953 57.2545 3.92837C56.2498 1.61953 53.8924 0 51.141 0C47.4793 0 44.5113 2.8721 44.5113 6.41901C44.5113 9.95923 47.4793 12.838 51.141 12.838C52.8531 12.838 54.4109 12.2101 55.5838 11.1817L53.5721 9.02342ZM51.141 2.9011C52.6112 2.9011 53.8786 3.74656 54.4455 4.96233H47.8319C48.4103 3.74656 49.6731 2.9011 51.141 2.9011ZM66.6725 4.96233C66.1056 3.74656 64.8382 2.9011 63.368 2.9011C61.9001 2.9011 60.6373 3.74656 60.0589 4.96233H66.6725Z"
        fill="white"
      />
    </svg>
  )
}
