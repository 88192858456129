import { useMutation } from 'react-query'

// Services
import { BatchUploadCaseAddresses } from 'services/api/Upload'

export default () => {
  const {
    mutate: batchUploadAddresses,
    data: batchUploadAddressesData,
    error: batchUploadAddressesError,
    isLoading: isBatchUploadAddressesLoading,
    isSuccess: isBatchUploadAddressesSuccess,
    isError: isBatchUploadAddressesError,
  } = useMutation(BatchUploadCaseAddresses)

  return {
    batchUploadAddresses,
    batchUploadAddressesData,
    batchUploadAddressesError,
    isBatchUploadAddressesLoading,
    isBatchUploadAddressesSuccess,
    isBatchUploadAddressesError,
  }
}
