import styled from 'styled-components'

export const LegendTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: inherit;
  font-size: inherit;
`

export const LegendColor = styled.span`
  margin-right: 15px;
  width: 8px;
  height: 8px;

  // Conditional Properties
  ${({ color }) => color && `background-color: ${color};`}
`
