import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { AppWrapper } from './App.elements'

function App(props) {
  // Destructure
  const { children } = props

  return <AppWrapper>{children}</AppWrapper>
}

// Default Props
App.defaultProps = {
  children: '',
}

// Proptypes Validation
App.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default App
