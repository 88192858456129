/* eslint-disable max-len */
import React from 'react'

export default function USDCMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M318.758 286.665C318.758 250.208 296.883 237.708 253.133 232.5C221.883 228.333 215.633 220 215.633 205.415C215.633 190.83 226.051 181.458 246.883 181.458C265.633 181.458 276.051 187.708 281.258 203.333C282.301 206.458 285.426 208.54 288.551 208.54H305.216C309.383 208.54 312.508 205.415 312.508 201.25V200.208C308.341 177.29 289.591 159.583 265.633 157.5V132.5C265.633 128.333 262.508 125.208 257.301 124.165H241.676C237.508 124.165 234.383 127.29 233.341 132.5V156.458C202.091 160.625 182.301 181.458 182.301 207.5C182.301 241.875 203.133 255.415 246.883 260.625C276.051 265.833 285.426 272.083 285.426 288.75C285.426 305.418 270.841 316.875 251.051 316.875C223.966 316.875 214.591 305.415 211.466 289.79C210.426 285.625 207.301 283.54 204.176 283.54H186.466C182.301 283.54 179.176 286.665 179.176 290.833V291.875C183.341 317.915 200.008 336.665 234.383 341.875V366.875C234.383 371.04 237.508 374.165 242.716 375.208H258.341C262.508 375.208 265.633 372.083 266.676 366.875V341.875C297.926 336.665 318.758 314.79 318.758 286.665Z"
        fill="#00CACD"
      />
      <path
        d="M196.884 396.04C115.634 366.875 73.9661 276.25 104.176 196.04C119.801 152.29 154.176 118.957 196.884 103.332C201.051 101.25 203.134 98.125 203.134 92.915V78.3325C203.134 74.165 201.051 71.04 196.884 70C195.841 70 193.759 70 192.716 71.04C93.7586 102.29 39.5911 207.5 70.8411 306.457C89.5911 364.79 134.384 409.582 192.716 428.332C196.884 430.415 201.051 428.332 202.091 424.165C203.134 423.125 203.134 422.082 203.134 420V405.415C203.134 402.29 200.009 398.125 196.884 396.04ZM307.301 71.04C303.134 68.9575 298.966 71.04 297.926 75.2075C296.884 76.25 296.884 77.29 296.884 79.375V93.9575C296.884 98.125 300.009 102.29 303.134 104.375C384.384 133.54 426.051 224.165 395.841 304.375C380.216 348.125 345.841 381.457 303.134 397.082C298.966 399.165 296.884 402.29 296.884 407.5V422.082C296.884 426.25 298.966 429.375 303.134 430.415C304.176 430.415 306.259 430.415 307.301 429.375C406.259 398.125 460.426 292.915 429.176 193.957C410.426 134.582 364.591 89.79 307.301 71.04Z"
        fill="#00CACD"
      />
    </svg>
  )
}
