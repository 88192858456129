import React from 'react'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Styled Elements
import {
  UserSignupWrapper,
  UserSignupInnerWrapper,
  UserSignupLogoLink,
  UserSignupTitle,
  UserSignupFormWrapper,
  UserSignupFormInnerWrapper,
  UserSignupFormContent,
} from './UserSignup.elements'

// Components
import { UserSignupForm } from 'views/layouts'

function UserSignup() {
  return (
    <UserSignupWrapper>
      <UserSignupInnerWrapper>
        <UserSignupFormWrapper>
          <UserSignupFormInnerWrapper>
            <UserSignupFormContent>
              <UserSignupLogoLink>
                <OspreeLogoIcon />
              </UserSignupLogoLink>
              <UserSignupTitle>Activate Account</UserSignupTitle>
              <UserSignupForm />
            </UserSignupFormContent>
          </UserSignupFormInnerWrapper>
        </UserSignupFormWrapper>
      </UserSignupInnerWrapper>
    </UserSignupWrapper>
  )
}

// Default Props
UserSignup.defaultProps = {}

// Proptypes Validation
UserSignup.propTypes = {}

export default UserSignup
