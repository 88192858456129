import { useMutation } from 'react-query'

// Services
import { VerifyEmail } from 'services/api/Profile'

export default () => {
  const {
    mutate: verifyEmail,
    data: verifyEmailData,
    error: verifyEmailError,
    isLoading: isVerifyEmailLoading,
    isSuccess: isVerifyEmailSuccess,
    isError: isVerifyEmailError,
  } = useMutation(VerifyEmail)

  return {
    verifyEmail,
    verifyEmailData,
    verifyEmailError,
    isVerifyEmailLoading,
    isVerifyEmailSuccess,
    isVerifyEmailError,
  }
}
