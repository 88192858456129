import styled from 'styled-components'

export const LoaderLineWrapper = styled.div`
  transition: all 0.5s ease-in-out;
`

export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 6px;
  background: ${({ theme }) => theme.colors.darkGray};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background: ${({ theme }) => theme.colors.blue};
    animation: box-1 1500ms cubic-bezier(0.65, 0.81, 0.73, 0.4) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    background: ${({ theme }) => theme.colors.blue};
    animation: box-2 1500ms cubic-bezier(0.16, 0.84, 0.44, 1) infinite;
    animation-delay: 9000ms;
  }

  @keyframes box-1 {
    0% {
      left: -35%;
      right: 100%;
    }
    60%,
    100% {
      left: 100%;
      right: -90%;
    }
  }
  @keyframes box-2 {
    0% {
      left: -200%;
      right: 100%;
    }
    60%,
    100% {
      left: 107%;
      right: -8%;
    }
  }
`
