/* eslint-disable max-len */
import React from 'react'

export default function CalendarIcon() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0143 2.5H3.26434C2.57399 2.5 2.01434 3.05964 2.01434 3.75V12.5C2.01434 13.1904 2.57399 13.75 3.26434 13.75H12.0143C12.7047 13.75 13.2643 13.1904 13.2643 12.5V3.75C13.2643 3.05964 12.7047 2.5 12.0143 2.5Z"
        stroke="#606167"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.1393 1.25V3.75" stroke="#606167" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.13934 1.25V3.75" stroke="#606167" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.01434 6.25H13.2643" stroke="#606167" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
