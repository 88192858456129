import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
import { CASES_DUMMY_DATA } from 'common/constants/dummyData'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllAlerts } from 'core/hooks/api'

// Views
import { Container } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  CasesTable,
  CasesSubHeader,
  LoadingPage,
  NoAccess,
  PageWrapper,
} from 'views/layouts'

// Styled Components
import { CasesBreakdownTableWrapper } from './Cases.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Cases(props) {
  // Destructure
  const { actions, filters, auth } = props

  // Store State
  const { caseManagementFilters } = filters
  const { caseType, caseStatus } = caseManagementFilters

  // Store Actions
  const { setActiveModule, showAlert, updateAccessToFeatures } = actions

  // States
  const [allCasesDataState, setAllCasesDataState] = useState()

  // Hooks
  // const [userCredentials] = useLocalStorage('userCredentials')
  const [casesData, setCasesData] = useLocalStorage('casesData')
  const {
    // getAllAlerts,
    allAlertsData,
    isAllAlertsLoading,
    isAllAlertsError,
  } = useFetchAllAlerts()

  // Variables
  const isLoading = isAllAlertsLoading
  const rececivedData = !!allAlertsData
  const isError = isAllAlertsError

  // Functions
  // const fetchData = () => {
  //   getAllAlerts({ business_id: userCredentials.Business_ID })
  // }

  // useEffect
  useEffect(() => {
    updateAccessToFeatures(process.env.HAS_ACCESS_TO_FEATURE === 'true' || false)
    // Fetch alerts Data on Render
    // fetchData()
    if (!casesData) {
      setCasesData(CASES_DUMMY_DATA)
      setAllCasesDataState(CASES_DUMMY_DATA)
    }
    if (casesData) setAllCasesDataState(casesData)
    setActiveModule('alerts')
  }, [caseType, caseStatus])

  useEffect(() => {
    if (rececivedData) {
      setAllCasesDataState(allAlertsData)
    }
  }, [allAlertsData])

  useEffect(() => {
    if (isError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isError])

  return (
    <App>
      <Sidebar />
      <NoAccess />
    </App>
  )
}

// <Header subHeader={<CasesSubHeader />} pageTitle="Case Management" />
// <Content>
//  <PageWrapper>
//    {isLoading ? (
//      <LoadingPage />
//    ) : (
//      <>
//        {/* {!auth.hasAccessToFeatures && <NoAccess />} */}
//        {!!auth.hasAccessToFeatures && (
//          <Container>
//            <CasesBreakdownTableWrapper>
//              <CasesTable data={allCasesDataState} isLoading={isAllAlertsLoading} />
//            </CasesBreakdownTableWrapper>
//          </Container>
//        )}
//      </>
//    )}
//  </PageWrapper>
// </Content>

// Default Props
Cases.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
Cases.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    showAlert: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
    updateAccessToFeatures: PropTypes.func,
  }),
  auth: PropTypes.shape({
    hasAccessToFeatures: PropTypes.bool,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Cases)
