/*
 * Copyright 2002-2015 the original author or authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {SpelNode} from './SpelNode';

/**
 * Represent a map in an expression, e.g. '{name:'foo',age:12}'
 *
 * @author Andy Clement
 * @author Ben March
 * @since 0.2.0
 */

function createNode(position, elements) {
    var node = SpelNode.create('map', position),
        mapPieces = [].concat(elements || []);

    node.getValue = function (state) {
        var key = true,
            keyValue = null,
            map = {};

        mapPieces.forEach(function (piece) {
            if (key) {
                //unquoted property names come as type "property" but should be treated as strings
                if (piece.getType() === 'property') {
                    keyValue = piece.getName();
                } else {
                    keyValue = piece.getValue(state);
                }
            } else {
                map[keyValue] = piece.getValue(state);
            }
            key = !key;
        });

        return map;
    };

    return node;
}

export var InlineMap =  {
    create: createNode
};
