import { useMutation } from 'react-query'

// Services
import { CreateUser } from 'services/api/ManagerProfile'

export default () => {
  const {
    mutate: createUser,
    data: createUserData,
    error: createUserError,
    isLoading: isCreateUserLoading,
    isSuccess: isCreateUserSuccess,
    isError: isCreateUserError,
  } = useMutation(CreateUser)

  return {
    createUser,
    createUserData,
    createUserError,
    isCreateUserLoading,
    isCreateUserSuccess,
    isCreateUserError,
  }
}
