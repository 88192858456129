import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Styled Elements
import { CasesTableWrapper } from './CasesTable.elements'

// Views
import { Table, EmptyTablePlaceholder, LoadingTablePlaceholder, CustomDateRangePicker } from 'views/components'
import { CasesTableItem, CasesTableHeader } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function CasesTable(props) {
  // Destructure
  const { data, isLoading, filters, actions } = props

  // Store State
  const { setCaseManagementFilters } = actions
  const { caseManagementFilters } = filters

  // States
  const [alertsData, setAlertsData] = useState([])

  useEffect(() => {
    if (data) {
      setAlertsData(data)
    }
  }, [data])

  return (
    <CasesTableWrapper>
      <Table
        gap={25}
        minWidth={900}
        filterComponents={<CustomDateRangePicker />}
        tableFilters={caseManagementFilters}
        setTableFilters={setCaseManagementFilters}
      >
        <CasesTableHeader />
        {alertsData.length > 0 && !isLoading ? (
          alertsData.map(({ case_id, severity, case_status, rule_description, timestamp }) => (
            <CasesTableItem
              key={case_id}
              case_id={case_id}
              severity={severity}
              case_status={case_status}
              rule_description={rule_description}
              timestamp={moment(timestamp).format(DASHED_DATE_FORMAT)}
            />
          ))
        ) : (
          <div>
            {isLoading ? <LoadingTablePlaceholder /> : <div>{alertsData.length < 1 && <EmptyTablePlaceholder />}</div>}
          </div>
        )}
      </Table>
    </CasesTableWrapper>
  )
}

// Default Props
CasesTable.defaultProps = {
  isLoading: false,
  data: [],
  filters: {},
  actions: {},
}

// Proptypes Validation
CasesTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      case_id: PropTypes.string,
      breach_count: PropTypes.number,
      primary_dataset: PropTypes.number,
      severity: PropTypes.string,
      case_status: PropTypes.string,
      timestamp: PropTypes.string,
      rule_description: PropTypes.string,
      rule_code: PropTypes.string,
    })
  ),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.shape({
      size: PropTypes.number,
      page: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    showAlert: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
    updateAccessToFeatures: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CasesTable)
