import styled from 'styled-components'

export const CaseManagementTableChartWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  align-items: center;
  width: 100%;
  max-width: 150px;

  font-size: 15px;
  font-weight: 100;
  color: ${({ theme }) => theme.colors.darkGray};
`
