import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding-top: 81px;
`

export const TableOptionWrapper = styled.div`
  display: flex;
`

export const DropdownItemInnerText = styled.div`
  margin: 0px 0px 0px 16px;

  font-weight: inherit;
  font-size: inherit;
`
