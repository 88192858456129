import { useMutation } from 'react-query'

// Services
import { GetUsers } from 'services/api/ManagerProfile'

export default () => {
  const {
    mutate: getUsers,
    data: getUsersData,
    error: getUsersError,
    isLoading: isGetUsersLoading,
    isSuccess: isGetUsersSuccess,
    isError: isGetUsersError,
  } = useMutation(GetUsers)

  return {
    getUsers,
    getUsersData,
    getUsersError,
    isGetUsersLoading,
    isGetUsersSuccess,
    isGetUsersError,
  }
}
