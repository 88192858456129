import styled from 'styled-components'

export const NumberedChartWrapper = styled.div`
  display: flex;
  gap: 5px;
`
export const NumberBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.darkGray};
  border-radius: 5px;
  font-size: 15px;

  ${({ theme, isActive }) => isActive && `color: ${theme.colors.yellowGreen};`}
  ${({ theme, isActive }) => isActive && `border: 1px solid ${theme.colors.yellowGreen};`}
  ${({ isActive }) => isActive && 'font-weight: 700;'}
`
