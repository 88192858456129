import { useMutation } from 'react-query'

// Services
import { GetAddressChainRecommendation } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddressChainRecommendation,
    data: getAddressChainRecommendationData,
    error: getAddressChainRecommendationError,
    isLoading: isGetAddressChainRecommendationLoading,
    isSuccess: isGetAddressChainRecommendationSuccess,
    isError: isGetAddressChainRecommendationError,
  } = useMutation(GetAddressChainRecommendation)

  return {
    getAddressChainRecommendation,
    getAddressChainRecommendationData,
    getAddressChainRecommendationError,
    isGetAddressChainRecommendationLoading,
    isGetAddressChainRecommendationSuccess,
    isGetAddressChainRecommendationError,
  }
}
