import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './NotifyVaspModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Hooks
import { useNotifyVasp } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function NotifyVaspModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isNotifyVaspModalOpen, notifyVaspDetails } = ui

  // Store Actions
  const { toggleNotifyVaspModal, showAlert } = actions

  // Hooks
  const { notifyVasp, notifyVaspData, isNotifyVaspLoading, isNotifyVaspError } = useNotifyVasp()

  // Functions
  const toggleModal = () => {
    toggleNotifyVaspModal()
  }
  const handleNotify = () => {
    notifyVasp({ vasp_id: notifyVaspDetails.vasp_id })
  }

  // UseEffect
  useEffect(() => {
    if (notifyVaspData && !isNotifyVaspError) {
      showAlert({ type: 'success', message: 'Successfully notified VASP' })
      toggleModal()
    }
  }, [notifyVaspData])
  useEffect(() => {
    if (isNotifyVaspError) {
      showAlert({ type: 'error', message: 'An error occured in notifying VASP' })
      toggleModal()
    }
  }, [isNotifyVaspError])
  return (
    <Modal isOpen={isNotifyVaspModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Notify Vasp
      </ModalHeader>
      <ModalBody>
        {isNotifyVaspLoading && <ModalBodyWrapper>Notifying...</ModalBodyWrapper>}
        {notifyVaspDetails?.vasp_id && !isNotifyVaspLoading && !isNotifyVaspError && (
          <ModalBodyWrapper>Are you sure you want to notify this vasp: {notifyVaspDetails.vasp_name}?</ModalBodyWrapper>
        )}
        {!isNotifyVaspLoading && isNotifyVaspError && (
          <ModalBodyWrapper>An error occured in notifying vasp, please try again later.</ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={toggleModal}>
            <Button color="secondary" disabled={isNotifyVaspLoading}>
              Cancel
            </Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={handleNotify}>
            <Button color="default" disabled={isNotifyVaspLoading}>
              Notify
            </Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
NotifyVaspModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
NotifyVaspModal.propTypes = {
  ui: PropTypes.shape({
    isNotifyVaspModalOpen: PropTypes.bool,
    notifyVaspDetails: PropTypes.shape({
      vasp_name: PropTypes.string,
      vasp_id: PropTypes.string,
    }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({
    addressRemove: PropTypes.shape({ address_id: PropTypes.string }),
  }),
  actions: PropTypes.shape({
    toggleNotifyVaspModal: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(NotifyVaspModal)
