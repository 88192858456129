import React from 'react'

// Constants
// import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Styled Elements
import {
  ResetPasswordWrapper,
  ResetPasswordInnerWrapper,
  ResetPasswordLogoLink,
  ResetPasswordTitle,
  ResetPasswordFormWrapper,
  ResetPasswordFormInnerWrapper,
  ResetPasswordFormContent,
  ResetPasswordFormFooterText,
  ResetPasswordFormFooterLink,
  FormText,
} from './ResetPassword.elements'

// Components
import { ResetPasswordForm } from 'views/layouts'

function ResetPassword() {
  return (
    <ResetPasswordWrapper>
      <ResetPasswordInnerWrapper>
        <ResetPasswordFormWrapper>
          <ResetPasswordFormInnerWrapper>
            <ResetPasswordFormContent>
              <ResetPasswordLogoLink>
                <OspreeLogoIcon />
              </ResetPasswordLogoLink>
              <ResetPasswordTitle>Reset Password</ResetPasswordTitle>
              <ResetPasswordForm />
            </ResetPasswordFormContent>
          </ResetPasswordFormInnerWrapper>
        </ResetPasswordFormWrapper>
      </ResetPasswordInnerWrapper>
    </ResetPasswordWrapper>
  )
}

// Default Props
ResetPassword.defaultProps = {}

// Proptypes Validation
ResetPassword.propTypes = {}

export default ResetPassword
