import styled from 'styled-components'

export const TableItemWrapper = styled.div`
  padding: 15px 20px;

  // TODO: Use theme functions
  &:focus {
    outline: none;
  }

  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => `${theme.colors.lightGray}`};

  width: 100%;
  display: grid;
  gap: 50px;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TableRowWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 300px 250px 180px 180px 1fr;
  width: 100%;

  gap: 20px;
`
export const TableRowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  & > div > div {
    margin: 0 !important;
  }
`
export const TableColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  & > div > div {
    margin: 0 !important;
  }
`

export const TableText = styled.div`
  font-size: 15px;
  font-weight: 400;
  max-width: 250px;

  &:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
export const TableTextTiny = styled.span`
  font-size: 8px;
  font-weight: 400;
`
export const TableSubText = styled.div`
  font-size: 10px;
  font-weight: 400;
`

export const TablePanelWrapper = styled.div``

export const StatusText = styled.div`
  padding: 5px 30px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  ${({ theme, status }) =>
    status === 'Completed' && `color: ${theme.colors.blue}; border: 1px solid ${theme.colors.blue};`}
  ${({ theme, status }) =>
    status === 'Pending' && `color: ${theme.colors.yellowGreen}; border: 1px solid ${theme.colors.yellowGreen};`}
`
