import React from 'react'

export default function ArrowDownIcon() {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L6.85858 6.85858C6.93668 6.93668 7.06332 6.93668 7.14142 6.85858L13 1"
        stroke="#727F97"
        strokeLinecap="round"
      />
    </svg>
  )
}
