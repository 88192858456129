/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import { LinkIcon, ExternalLinkIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Title,
  RowWrapper,
  RowContainer,
  TextRowContainer,
  BlockContainer,
  FlowWrapper,
  ExternalLink,
} from '../../AddressesTableItemDetails.elements'

// Components
import { Button, LoadingTablePlaceholder } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function LinkedAccount(props) {
  // Destructure
  const { address_data, actions, accountDataProp, isLoading, isFetchError } = props

  // Store
  const { setAddressLinkDetails, setIsLinkAddressDrawerOpen, showAlert } = actions

  // Functions
  const handleLink = () => {
    if (address_data.id) {
      setAddressLinkDetails({
        address: address_data.address,
        address_id: address_data.id,
        chain: address_data.chain,
      })
      setIsLinkAddressDrawerOpen(true)
    }
  }

  // UseEffects
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (isFetchError) showAlert({ type: 'error', message: 'An error occured in fetching linkage' })
  }, [isFetchError])
  return (
    <FlowWrapper>
      <RowWrapper>
        {isLoading && (
          <Title style={{ fontWeight: '600', fontSize: '15px', padding: '10px 0 5px 20px' }}>Loading...</Title>
        )}
        {!isLoading && accountDataProp && (
          <Title style={{ fontWeight: '600', fontSize: '15px', padding: '10px 0 5px 20px' }}>
            Linked Account Information:
          </Title>
        )}
        {!isLoading && !accountDataProp && (
          <Title style={{ fontWeight: '600', fontSize: '15px', padding: '10px 0 5px 20px' }}>
            Address is not linked with any account
          </Title>
        )}
      </RowWrapper>
      {!isLoading && accountDataProp && address_data.id && (
        <RowWrapper>
          <BlockContainer>
            <TextRowContainer>
              <Text>Account ID: </Text>
              <Text>
                <ExternalLink
                  exact
                  target="_blank"
                  to={
                    accountDataProp?.is_recipient
                      ? `/directory/recipients/details/${accountDataProp.id}`
                      : `/directory/accounts/details/${accountDataProp.id}`
                  }
                >
                  {accountDataProp.reference_id}
                  <ExternalLinkIcon />
                </ExternalLink>
              </Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>Account Type: </Text>
              <Text>{accountDataProp.account_type_id === 1 ? 'Individual' : 'Entity'}</Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>Status: </Text>
              <Text>{accountDataProp.status_id === 1 ? 'Active' : 'Inactive'}</Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>Email: </Text>
              <Text>{accountDataProp.email}</Text>
            </TextRowContainer>
          </BlockContainer>

          <BlockContainer>
            <TextRowContainer>
              <Text>{accountDataProp.account_type_id === 1 ? 'First Name' : 'Business Name'}: </Text>
              <Text>
                {accountDataProp.account_type_id === 1 ? accountDataProp.name_first : accountDataProp.name_business}
              </Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>{accountDataProp.account_type_id === 1 ? 'Last Name' : 'Legal Name'}: </Text>
              <Text>
                {accountDataProp.account_type_id === 1 ? accountDataProp.name_last : accountDataProp.name_legal}
              </Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>{accountDataProp.account_type_id === 1 ? 'Nationality' : 'Country of Incorporation'}: </Text>
              <Text>{accountDataProp?.display_country && accountDataProp.display_country}</Text>
            </TextRowContainer>
            {accountDataProp.account_type_id === 1 && (
              <TextRowContainer>
                <Text>Date of Birth: </Text>
                <Text>{accountDataProp?.date_of_birth && accountDataProp.date_of_birth}</Text>
              </TextRowContainer>
            )}
          </BlockContainer>

          <BlockContainer>
            <TextRowContainer>
              <Text>Street: </Text>
              <Text>{accountDataProp.address_street_name}</Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>City: </Text>
              <Text>{accountDataProp.address_city}</Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>Region: </Text>
              <Text>{accountDataProp.address_region}</Text>
            </TextRowContainer>
            <TextRowContainer>
              <Text>Postcode: </Text>
              <Text>{accountDataProp.address_postcode}</Text>
            </TextRowContainer>
          </BlockContainer>
        </RowWrapper>
      )}
      {isLoading && <LoadingTablePlaceholder />}

      <RowWrapper>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" size="small" endIcon={<LinkIcon />} onClick={handleLink}>
            Link
          </Button>
        </RowContainer>
      </RowWrapper>
    </FlowWrapper>
  )
}

// Default Props
LinkedAccount.defaultProps = {
  actions: {},
  address_data: {},
}

// Proptypes Validation
LinkedAccount.propTypes = {
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    toggleAddressReportModal: PropTypes.func,
    setAddressReportDetails: PropTypes.func,
    setAddressHistoryDetails: PropTypes.func,
    toggleDeleteAddressModal: PropTypes.func,
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  accountDataProp: PropTypes.shape({
    id: PropTypes.string,
    reference_id: PropTypes.string,
    business_id: PropTypes.string,
    account_type_id: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    date_of_birth: PropTypes.string,
    nationality_code: PropTypes.string,
    display_country: PropTypes.string,
    name_legal: PropTypes.string,
    name_business: PropTypes.string,
    inc_country_code: PropTypes.string,
    inc_date: PropTypes.string,
    email: PropTypes.string,
    address_street_name: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
    primary_activity_code: PropTypes.number,
    account_risk_value: PropTypes.number,
    sanction_id: PropTypes.number,
    pep_id: PropTypes.number,
    adverse_media_id: PropTypes.number,
    comments: PropTypes.string,
    status_id: PropTypes.number,
    created: PropTypes.string,
    last_modified: PropTypes.string,
    is_recipient: PropTypes.bool,
  }),
  address_data: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    balance_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    link_type: PropTypes.number,
    link_id: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isFetchError: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedAccount)
