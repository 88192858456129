import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Views
import { Container, Button } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  CasesSubHeader,
  LoadingPage,
  NoAccess,
  PageWrapper,
  PoliciesTable,
  AddPolicyForm,
} from 'views/layouts'

// Styled Components
import { PoliciesWrapper, AddPolicyButtonWrapper } from './Policies.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Policies(props) {
  // Props
  const { auth, actions } = props
  // Store Actions
  const { updateAccessToFeatures } = actions

  // Hooks
  const [policiesList] = useLocalStorage('policies', [])

  // States
  const [isCreatePolicyActive, setIsCreatePolicyActive] = useState(false)
  const [policyListState, setPolicyListState] = useState(formatPolicyList(policiesList))
  const [isLoading] = useState(false)

  // Functions
  function formatPolicyList(values) {
    const formattedPolicyList = []
    values.map(({ policy_info: { policyTitle, policyDescription, status }, policy_id }) =>
      formattedPolicyList.push({
        policy_id,
        policyTitle,
        status,
        policyDescription,
      })
    )

    return formattedPolicyList
  }
  const onSubmit = ({ values }) => {
    setPolicyListState(formatPolicyList(values))
  }

  // UseEffect
  useEffect(() => {
    updateAccessToFeatures(process.env.HAS_ACCESS_TO_FEATURE === 'true' || false)
  }, [])
  return (
    <App>
      <Sidebar />
      <Header subHeader={<CasesSubHeader />} pageTitle="Case Management" />
      <Content>
        <PageWrapper>
          {isLoading ? (
            <LoadingPage />
          ) : (
            <Container>
              {!!auth.hasAccessToFeatures && (
                <PoliciesWrapper>
                  <AddPolicyButtonWrapper isCreatePolicyActive={isCreatePolicyActive}>
                    <Button onClick={() => setIsCreatePolicyActive(true)}>Add Policy</Button>
                  </AddPolicyButtonWrapper>
                  <AddPolicyForm
                    setIsCreatePolicyActive={setIsCreatePolicyActive}
                    isCreatePolicyActive={isCreatePolicyActive}
                    onSubmit={onSubmit}
                  />
                  <PoliciesTable data={policyListState} isLoading={isLoading} />
                </PoliciesWrapper>
              )}
              {!auth.hasAccessToFeatures && <NoAccess />}
            </Container>
          )}
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Policies.defaultProps = {}

// Proptypes Validation
Policies.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    updateAccessToFeatures: PropTypes.func,
  }),
  auth: PropTypes.shape({
    hasAccessToFeatures: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Policies)
