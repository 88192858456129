import React from 'react'
import { NavLink } from 'react-router-dom'

// Assets
import Logo from 'assets/images/ospreeBlackLarge.png'

// Styled Components
import { NotFoundWrapper, NotFoundLink, NotFoundText, NotFoundBox } from './NotFound.elements'

// Components
import { Image } from 'views/components'

function NotFound() {
  return (
    <NotFoundWrapper>
      <NotFoundBox>
        <NavLink to="/">
          <NotFoundLink>
            <Image src={Logo} width={100} />
          </NotFoundLink>
        </NavLink>
        <NotFoundText>Error 404: The destination you are trying to access does not exist.</NotFoundText>
      </NotFoundBox>
    </NotFoundWrapper>
  )
}

// Default Props
NotFound.defaultProps = {}

// Proptypes Validation
NotFound.propTypes = {}

export default NotFound
