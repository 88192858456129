/*
 * Copyright 2002-2015 the original author or authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @author Ben March
 * @since 0.2.0
 */

export function Stack(startingElements) {
    this.elements = startingElements || [];
}

Stack.prototype.push = function (el) {
    this.elements.push(el);
    return el;
};

Stack.prototype.pop = function () {
    return this.elements.pop();
};

Stack.prototype.peek = function () {
    return this.elements[this.elements.length - 1];
};

Stack.prototype.empty = function () {
    return this.elements.length > 0;
};

Stack.prototype.search = function (el) {
    return this.elements.length - this.elements.indexOf(el);
};
