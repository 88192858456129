export const numberWithCommas = (value) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : value)

export const abbreviateNumber = (value, decimalPlace = 0) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' k' },
    { value: 1e6, symbol: ' M' },
    { value: 1e9, symbol: ' B' },
    { value: 1e12, symbol: ' T' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (Number.isNaN(value) ? 0 : value >= si[i].value) {
      break
    }
  }

  const safeValue = value / si[i].value || 0

  return numberWithCommas(safeValue.toFixed(decimalPlace).replace(rx, '$1')) + si[i].symbol
}

export const formatChain = (value) => value.replace('-', ' ')

export const compactNumberFormatter = Intl.NumberFormat('en', { notation: 'compact' })

export const basicNumFormatter = Intl.NumberFormat('en')
