import { useMutation } from 'react-query'

// Services
import { RequestEmailVerification } from 'services/api/Profile'

export default () => {
  const {
    mutate: requestEmailVerification,
    data: requestEmailVerificationData,
    error: requestEmailVerificationError,
    isLoading: isRequestEmailVerificationLoading,
    isSuccess: isRequestEmailVerificationSuccess,
    isError: isRequestEmailVerificationError,
  } = useMutation(RequestEmailVerification)

  return {
    requestEmailVerification,
    requestEmailVerificationData,
    requestEmailVerificationError,
    isRequestEmailVerificationLoading,
    isRequestEmailVerificationSuccess,
    isRequestEmailVerificationError,
  }
}
