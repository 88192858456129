import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.blue};
  background: ${({ theme }) => `linear-gradient(25deg, ${theme.colors.lightGray} 65%, ${theme.colors.blue} 130%)`};
`

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

export const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const FormInnerWrapper = styled.div`
  max-width: 500px;
  width: 100%;
`

export const FormContent = styled.div`
  padding: 40px;
  width: 100%;

  border-radius: 10px;
`

export const LogoLink = styled.a`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  & svg {
    width: 150px;
    height: 50px;
    & path {
      fill: ${({ theme }) => theme.colors.darkGray};
    }
  }
`
export const Title = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 0 0 30px 0;
`
export const Error = styled.h1`
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.red};
  margin: 0 0 30px 0;
`

export const BrandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`

export const BottomWrapper = styled.div`
  align-self: center;

  //TODO: Style Variable
  color: ${({ theme }) => theme.colors.white};
`

export const PrivacyPolicyLink = styled.a`
  display: block;
  margin: 20px 0px 0px 0px;
  width: 100%;

  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  text-align: center;

  color: ${({ theme }) => theme.colors.darkGray};
`

export const FormFooterText = styled.p`
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 0 0;

  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: right;
`

export const FormFooterLink = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
`
