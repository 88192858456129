import { useMutation } from 'react-query'

// Services
import { GetMonitoringSearchTransaction } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: getMonitoringSearchTransaction,
    data: monitoringSearchTransactionData,
    error: monitoringSearchTransactionError,
    isLoading: isMonitoringSearchTransactionLoading,
    isSuccess: isMonitoringSearchTransactionSuccess,
    isError: isMonitoringSearchTransactionError,
  } = useMutation(GetMonitoringSearchTransaction)

  return {
    getMonitoringSearchTransaction,
    monitoringSearchTransactionData,
    monitoringSearchTransactionError,
    isMonitoringSearchTransactionLoading,
    isMonitoringSearchTransactionSuccess,
    isMonitoringSearchTransactionError,
  }
}
