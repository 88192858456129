import styled from 'styled-components'

// Library Components
import { Drawer } from '@material-ui/core'

export const DrawerWrapper = styled(Drawer)`
  // Overrides
  & .MuiPaper-root {
    // Conditional Properties
    ${({ theme, maxwidth }) => {
      if (typeof maxwidth === 'string') return `width ${maxwidth}`
      if (typeof maxwidth === 'object') return theme.functions.mediaQuery('width', maxwidth)
    }}
  }
`
