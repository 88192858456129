/* eslint-disable max-len */
import React from 'react'

export default function ProfileIcon() {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.9836 15.5C28.9836 23.2389 22.7574 29.5 15.0924 29.5C7.42732 29.5 1.20117 23.2389 1.20117 15.5C1.20117 7.76114 7.42732 1.5 15.0924 1.5C22.7574 1.5 28.9836 7.76114 28.9836 15.5Z"
        stroke="#727F97"
        strokeWidth="2"
      />
      <path
        d="M15.0921 16.5C17.2852 16.5 19.0631 14.7091 19.0631 12.5C19.0631 10.2909 17.2852 8.5 15.0921 8.5C12.899 8.5 11.1211 10.2909 11.1211 12.5C11.1211 14.7091 12.899 16.5 15.0921 16.5Z"
        stroke="#727F97"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0343 25.5V23.5C23.0343 22.4391 22.616 21.4217 21.8713 20.6716C21.1266 19.9214 20.1165 19.5 19.0633 19.5H11.1214C10.0682 19.5 9.05817 19.9214 8.31347 20.6716C7.56876 21.4217 7.15039 22.4391 7.15039 23.5V25.5"
        stroke="#727F97"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
