import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

// elements
import { Wrapper, ChildrenWrapper, TextWrapper, TextContainer, Text } from './CircularProgressBar.elements'
import { useEffect } from 'react'

function CircularProgressBar(props) {
  const { size: sizeProp, progress: progressProp, id, children, ...rest } = props

  // Usestates
  const [size, setSize] = useState(sizeProp)
  const [progress, setProgress] = useState(progressProp)

  // Advanced
  const strokeWidth = useMemo(() => size * 0.12, [size])
  const center = useMemo(() => size / 2, [size])
  const radius = useMemo(() => size / 2 - strokeWidth / 2, [strokeWidth])
  const circumference = useMemo(() => 2 * Math.PI * radius, [radius])
  const maskValue = useMemo(() => (100 - progress) * 0.7, [progress])

  // UseEffects
  useEffect(() => {
    setSize(sizeProp)
  }, [sizeProp])
  useEffect(() => {
    setProgress(progressProp)
  }, [progressProp])
  return (
    <Wrapper {...rest}>
      {strokeWidth && center && radius && circumference && (
        <svg height={size} width={size} fill="none" {...props}>
          <g transform={`rotate(216 ${center} ${center})`} origin={center}>
            <circle
              id="background"
              cx={center}
              cy={center}
              r={radius}
              strokeWidth={strokeWidth}
              stroke={`url(#gradient${id})`}
              strokeDasharray={circumference}
              strokeDashoffset={circumference - (circumference * 70) / 100}
              transform={`translate(${2 * center},0) scale(-1, 1) `}
              mask={`url(#circleMask${id})`}
            />
            <circle
              id="foreground"
              transform={`translate(${2 * center},0) scale(-1, 1) `}
              cx={center}
              cy={center}
              r={radius}
              strokeWidth={strokeWidth + 1}
              strokeDasharray={circumference}
              strokeDashoffset={circumference - (circumference * maskValue) / 100}
            />
          </g>
          <defs>
            <mask id={`#circleMask${id}`}>
              <rect x="0" y="0" width="100%" height="100%" fill="white" />
              <circle
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth + 20}
                stroke="black"
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={circumference - (circumference * maskValue) / 100}
              />
            </mask>
            <linearGradient id={`gradient${id}`} x1="0%" y1="0%" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
              <stop stopColor="#CAFF0A" />
              <stop offset="30%" stopColor="#CAFF0A" />
              <stop offset="50%" stopColor="#EC8800" />
              <stop offset="100%" stopColor="#CB5300" />
            </linearGradient>
          </defs>
        </svg>
      )}
      <ChildrenWrapper>
        {children}
        <TextWrapper>
          <TextContainer size={size}>
            <Text>Low</Text>
            <Text>High</Text>
          </TextContainer>
        </TextWrapper>
      </ChildrenWrapper>
    </Wrapper>
  )
}

// Default Props
CircularProgressBar.defaultProps = {
  size: 100,
  progress: 0,
  children: '',
  id: '',
}

// Proptypes Validation
CircularProgressBar.propTypes = {
  size: PropTypes.number,
  id: PropTypes.string,
  progress: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}
export default CircularProgressBar
