import { useMutation } from 'react-query'

// Services
import { GetAddressesLinked } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddressesLinked,
    data: getAddressesLinkedData,
    error: getAddressesLinkedError,
    isLoading: isGetAddressesLinkedLoading,
    isSuccess: isGetAddressesLinkedSuccess,
    isError: isGetAddressesLinkedError,
  } = useMutation(GetAddressesLinked)

  return {
    getAddressesLinked,
    getAddressesLinkedData,
    getAddressesLinkedError,
    isGetAddressesLinkedLoading,
    isGetAddressesLinkedSuccess,
    isGetAddressesLinkedError,
  }
}
