import { useMutation } from 'react-query'

// Services
import { CreateUserActivationLink } from 'services/api/ManagerProfile'

export default () => {
  const {
    mutate: createUserActivationLink,
    data: createUserActivationLinkData,
    error: createUserActivationLinkError,
    isLoading: isCreateUserActivationLinkLoading,
    isSuccess: isCreateUserActivationLinkSuccess,
    isError: isCreateUserActivationLinkError,
  } = useMutation(CreateUserActivationLink)

  return {
    createUserActivationLink,
    createUserActivationLinkData,
    createUserActivationLinkError,
    isCreateUserActivationLinkLoading,
    isCreateUserActivationLinkSuccess,
    isCreateUserActivationLinkError,
  }
}
