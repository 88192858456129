import styled from 'styled-components'

import { DropdownToggle } from 'reactstrap'

export const DropdownToggleWrapper = styled(DropdownToggle)`
  position: relative;
  padding: 0 5px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;

  // Themed Properties
  ${({ theme }) =>
    theme.functions.mixins.onEvent(`
    box-shadow: none;
    outline: 0;
  `)}
`
