import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

// Styled Elements
import {
  FormInputGroupWrapper,
  FormInputGroupItem,
  ProfileOrganizationDetailsFormWrapper,
} from './ProfileOrganizationDetailsForm.elements'

// Views
import { Form, FormTextField } from 'views/components'

function ProfileOrganizationDetailsForm(props) {
  // Destructure
  const { userProfileData } = props
  const {
    Business_Activity,
    Business_Address_1,
    Business_Address_2,
    Business_Country,
    Business_Name,
    Plan,
  } = userProfileData

  // Variables
  const initialValues = {
    businessName: Business_Name,
    businessActivity: Business_Activity,
    businessPlan: Plan,
    addressLine1: Business_Address_1,
    addressLine2: Business_Address_2,
    country: Business_Country,
  }

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required('Business Name is required'),
    businessActivity: Yup.string().required('Business Activity is required'),
    businessPlan: Yup.string().required('Business Plan is required'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    addressLine2: Yup.string().required('Address Line 2 is required'),
    country: Yup.string().required('Country is required'),
  })

  // Functions
  const handleOnSubmit = (values) => {}

  return (
    <ProfileOrganizationDetailsFormWrapper>
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormTextField label="Business Name" type="text" name="businessName" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Business Activity" type="text" name="businessActivity" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Business Plan" type="text" name="businessPlan" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Address Line 1" type="text" name="addressLine1" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Address Line 2" type="text" name="addressLine2" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Country" type="text" name="country" disabled />
          </FormInputGroupItem>
        </FormInputGroupWrapper>
      </Form>
    </ProfileOrganizationDetailsFormWrapper>
  )
}

// Default Props
ProfileOrganizationDetailsForm.defaultProps = {
  userProfileData: {},
}

// Proptypes Validation
ProfileOrganizationDetailsForm.propTypes = {
  userProfileData: PropTypes.shape({
    Business_Activity: PropTypes.string,
    Business_Address_1: PropTypes.string,
    Business_Address_2: PropTypes.string,
    Business_Country: PropTypes.string,
    Business_Name: PropTypes.string,
    Plan: PropTypes.string,
  }),
}

export default ProfileOrganizationDetailsForm
