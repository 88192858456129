/* eslint-disable max-len */
import React from 'react'

export default function CaseManagementIcon() {
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4344 20C18.7171 20 22.9996 15.7467 22.9996 10.5C22.9996 5.25329 18.7171 1 13.4344 1C8.15163 1 3.86914 5.25329 3.86914 10.5C3.86914 15.7467 8.15163 20 13.4344 20Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.78261 20C8.42397 20 10.5652 17.8734 10.5652 15.25C10.5652 12.6266 8.42397 10.5 5.78261 10.5C3.14125 10.5 1 12.6266 1 15.25C1 17.8734 3.14125 20 5.78261 20Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
