import { useMutation } from 'react-query'

// Services
import { SearchVasp } from 'services/api/Vasps'

export default () => {
  const {
    mutate: searchVasp,
    data: vaspSearchData,
    error: vaspSearchError,
    isLoading: isVaspSearchLoading,
    isSuccess: isVaspSearchSuccess,
    isError: isVaspSearchError,
  } = useMutation(SearchVasp)

  return {
    searchVasp,
    vaspSearchData,
    vaspSearchError,
    isVaspSearchLoading,
    isVaspSearchSuccess,
    isVaspSearchError,
  }
}
