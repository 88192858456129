/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import {
  TableButtonWrapper,
  TableRowWrapper,
  TableRowContainer,
  TableText,
} from './ReportsTravelRuleTableHeader.elements'

function ReportsTravelRuleTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper style={{ gridTemplateColumns: '1fr 0.7fr 2fr' }}>
        <TableRowContainer>
          <TableText>Requested At</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Name</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Date Range</TableText>
        </TableRowContainer>
      </TableRowWrapper>

      <TableRowWrapper style={{ gridTemplateColumns: '150px 1fr' }}>
        <TableRowContainer style={{ justifyContent: 'center' }}>
          <TableText>Status</TableText>
        </TableRowContainer>
        <TableRowContainer />
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
ReportsTravelRuleTableHeader.defaultProps = {}

// Proptypes Validation
ReportsTravelRuleTableHeader.propTypes = {}
export default ReportsTravelRuleTableHeader
