import styled from 'styled-components'

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`
export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  & > div:last-child {
    border: none;
    padding-bottom: 0;
  }
`
export const RowWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`
export const GridRowContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
`
export const TextRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 5px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`
export const InfoTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};

  font-size: 14px;
  font-weight: 400;
  padding-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`
export const RiskContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  & #rsSubTitle {
    color: ${({ theme }) => theme.colors.gray};
  }
`
