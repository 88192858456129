import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core'
import { StylesProvider } from '@material-ui/core/styles'

import theme from 'common/config/theme/external/material-ui'

function AppMaterialUIThemeProvider(props) {
  // Destructure
  const { children } = props

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>{children}</StylesProvider>
    </ThemeProvider>
  )
}

export default AppMaterialUIThemeProvider

// Proptypes Validation
AppMaterialUIThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
