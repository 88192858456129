import { authAxios } from 'common/utils/axios'
import { REPORT_ENDPOINTS } from '../../common/constants/apiUrls'

import {
  // LOCAL_URL,
  BASE_URL,
  TRANSACTION_SERVICE_URL,
} from 'common/constants/apiUrls'
import axios from 'axios'
import { param } from 'jquery'

const { GET_REPORTS, GET_REPORT, GET_REPORTS_TRANSACTION, GET_REPORTS_TRAVELRULE } = REPORT_ENDPOINTS

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const newAuthAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})
export const newTxAuthAxios = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const GetReports = async ({ start_range, end_range, sort, page, size }) => {
  const response = await authAxios.get(GET_REPORTS, {
    params: {
      start_range,
      end_range,
      sort,
      page,
      size,
    },
  })

  const { data } = response.data

  return data
}
export const GetReport = async ({ format, report_id }) => {
  const response = await authAxios.get(`${GET_REPORT}/${report_id}`, {
    params: { format },
  })

  return response.data
}

export const GetReportsTransaction = async ({ start_range, end_range, sort, page, size }) => {
  const response = await authAxios.get(GET_REPORTS_TRANSACTION, {
    params: {
      start_range,
      end_range,
      sort,
      page,
      size,
    },
  })

  const { data } = response.data

  return data
}

export const GetReportsTravelRule = async ({ page, size }) => {
  const response = await authAxios.get(GET_REPORTS_TRAVELRULE, {
    params: {
      page,
      size,
    },
  })

  const { data } = response.data

  return data
}

export const DownloadReportsTravelRule = async ({ id, format }) => {
  const response = await authAxios.get(`${GET_REPORTS_TRAVELRULE}/${id}`, { params: { format } })

  const { data } = response

  return data
}
