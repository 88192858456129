import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper } from './LoaderWrapper.elements'

function LoaderWrapper(props) {
  const { children } = props
  return <Wrapper> {children}</Wrapper>
}

// Default Props
LoaderWrapper.defaultProps = {
  children: '',
}

// Proptypes Validation
LoaderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default LoaderWrapper
