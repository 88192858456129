import styled from 'styled-components'

// Library Components
import { ModalFooter } from 'reactstrap'

export const ModalFooterWrapper = styled(ModalFooter)`
  padding: 25px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};

  display: flex;
  flex-direction: column;
  align-items: center;

  & * {
    text-align: center;
  }
`
