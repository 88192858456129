import { useMutation } from 'react-query'

// Services
import { GetRecipient } from 'services/api/Recipients'

export default () => {
  const {
    mutate: getRecipient,
    data: recipientData,
    error: recipientError,
    isLoading: isRecipientLoading,
    isSuccess: isRecipientSuccess,
    isError: isRecipientError,
  } = useMutation(GetRecipient)

  return {
    getRecipient,
    recipientData,
    recipientError,
    isRecipientLoading,
    isRecipientSuccess,
    isRecipientError,
  }
}
