import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Styled Elements
import { DrawerWrapper, DrawerCloseIcon } from '../DirectoryDrawers.elements'

// Views
import { Drawer } from 'views/components'

// Layouts
import { IndividualAccountForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function IndividualAccountDrawer(props) {
  // Destructure
  const { ui, actions } = props

  //  Store State
  const { isIndividualAccountDrawerOpen } = ui

  // Store Actions
  const { setIsIndividualAccountDrawerOpen } = actions

  const handleCloseDrawer = () => {
    setIsIndividualAccountDrawerOpen()
  }

  return (
    <Drawer open={isIndividualAccountDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <DrawerWrapper>
        <DrawerCloseIcon onClick={handleCloseDrawer}>
          <CloseIcon />
        </DrawerCloseIcon>
        <IndividualAccountForm />
      </DrawerWrapper>
    </Drawer>
  )
}

// Default Props
IndividualAccountDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
IndividualAccountDrawer.propTypes = {
  ui: PropTypes.shape({ isIndividualAccountDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ setIsIndividualAccountDrawerOpen: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualAccountDrawer)
