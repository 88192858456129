import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  AutoCompleteWrapper,
  Label,
  AutoCompleteInput,
  SelectInputOptionsContainer,
  SelectInputOptions,
  SelectInputText,
  SelectInputSubText,
} from './AutoComplete.elements'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function AutoComplete(props) {
  // Destructure
  const { label, options, placeholder, onInputChange, disabled, onSelect, isLoading, value, ...rest } = props

  // Variables
  const withOptions = options && options?.length > 0
  const wrapperRef = useRef()

  // States
  const [inputValue, setInputValue] = useState(value || '')
  const [showOptions, setShowOptions] = useState(false)

  // Functions
  const handleOnSelect = ({ value, label }) => {
    setInputValue(label || value)
    onSelect(value)
    setShowOptions(false)
  }
  const handleOnChange = (e) => {
    setInputValue(e.target.value)
    onInputChange(e.target.value)
  }

  // Closing options on click away
  useOutsideAlerter(wrapperRef, setShowOptions)

  return (
    <AutoCompleteWrapper ref={wrapperRef} label={label}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <AutoCompleteInput
        value={inputValue}
        onChange={handleOnChange}
        placeholder={placeholder}
        onFocus={() => {
          setShowOptions(true)
        }}
        type="search"
        autocomplete="off"
        disabled={disabled}
        {...rest}
      />
      {showOptions && (
        <SelectInputOptionsContainer>
          {withOptions ? (
            options.map(({ value, label, subLabel }) => (
              <SelectInputOptions
                onClick={() => {
                  handleOnSelect({ value, label })
                }}
                key={label}
                value={value}
              >
                <SelectInputText>{label}</SelectInputText>
                {subLabel && <SelectInputSubText>{subLabel}</SelectInputSubText>}
              </SelectInputOptions>
            ))
          ) : (
            <div>{isLoading ? 'loading...' : 'No results found'}</div>
          )}
        </SelectInputOptionsContainer>
      )}
    </AutoCompleteWrapper>
  )
}

// Default Props
AutoComplete.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  onSelect: () => {},
  onInputChange: () => {},
  isLoading: false,
  disabled: false,
  value: '',
}

// Proptypes Validation
AutoComplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
}

export default AutoComplete
