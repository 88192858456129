import styled from 'styled-components'

export const DateRangePickerWrapper = styled.div`
  font-family: Roboto;
  font-size: 18px;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const DateRangeHolder = styled.div`
  position: relative;

  display: flex;
  padding: 8px 15px 7px 15px;
  width: fit-content;
  height: 50px;

  border-radius: 5px;

  cursor: pointer;

  // Themed Properties
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
`

export const DateRangeTextWrapper = styled.p`
  font-size: 14px;
  margin: 7px 0px 0px 0px;

  // TODO: Use themed functions
  &:first-child {
    margin-right: 10px;
  }
  & span {
    &:nth-child(2) {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  & svg > * {
    stroke: ${({ theme }) => theme.colors.blue};
  }
`

export const DateRangeText = styled.span`
  // TODO: Use themed functions
  &:first-child {
    font-weight: 500;
    margin: 0px 5px 0px 0px;
  }
`
