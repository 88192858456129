import { useMutation } from 'react-query'

// Services
import { UpdateAccountIndividual } from 'services/api/Accounts'

export default () => {
  const {
    mutate: updateAccountIndividual,
    data: accountIndividualUpdateData,
    error: accountIndividualUpdateError,
    isLoading: isAccountIndividualUpdateLoading,
    isSuccess: isAccountIndividualUpdateSuccess,
    isError: isAccountIndividualUpdateError,
  } = useMutation(UpdateAccountIndividual)

  return {
    updateAccountIndividual,
    accountIndividualUpdateData,
    accountIndividualUpdateError,
    isAccountIndividualUpdateLoading,
    isAccountIndividualUpdateSuccess,
    isAccountIndividualUpdateError,
  }
}
