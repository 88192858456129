import styled from 'styled-components'

export const TransferFormWrapper = styled.div``

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const FormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormLabel = styled.label`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 30px 0 0 0;
`
export const FormTitle = styled.h3`
  text-align: start;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 30px 0 0 0;
`
export const OptionLabel = styled.label`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  margin: 0 0 10px 0;
`

export const FormInputGroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  & button {
    background: ${({ theme }) => theme.colors.white};
  }
`
