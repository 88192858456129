import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Components
import { TableWrapper } from './Recipients.elements'

// Views
import { Container } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  RecipientsTable,
  PageWrapper,
  DirectorySubHeader,
  // NoAccess,
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Recipients() {
  return (
    <App>
      <Sidebar />
      <Header pageTitle="Directory" subHeader={<DirectorySubHeader />} />
      {/* <NoAccess /> */}
      <Content>
        <PageWrapper>
          <Container>
            <TableWrapper>
              <RecipientsTable />
            </TableWrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Recipients.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
Recipients.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Recipients)
