import React from 'react'
import PropTypes from 'prop-types'

// Assets
import { ExternalLinkIcon } from 'assets/images'

// Styled Elements
import { TableDataWrapper, TableDataItem, IconContainer } from './PoliciesTableItem.elements'

// Views
import { TableItem } from 'views/components'

import { NavLink } from 'react-router-dom'

function PoliciesTableItem(props) {
  // Destructure
  const { policy_id, policyTitle, status, policyDescription } = props

  return (
    <TableItem
      key={policy_id}
      endIcon={
        <NavLink to={`/cases/policies/${policy_id}`}>
          <IconContainer>
            <ExternalLinkIcon />
          </IconContainer>
        </NavLink>
      }
    >
      <TableDataWrapper>
        <TableDataItem width={200}>{policyTitle}</TableDataItem>
        <TableDataItem width={120} style={{ textTransform: 'capitalize' }} status={status}>
          {status}
        </TableDataItem>
        <TableDataItem>{policyDescription}</TableDataItem>
      </TableDataWrapper>
    </TableItem>
  )
}

// Default Props
PoliciesTableItem.defaultProps = {
  policy_id: '',
  policyTitle: '',
  status: '',
  policyDescription: '',
}

// Proptypes Validation
PoliciesTableItem.propTypes = {
  policy_id: PropTypes.string,
  policyTitle: PropTypes.string,
  status: PropTypes.string,
  policyDescription: PropTypes.string,
}

export default PoliciesTableItem
