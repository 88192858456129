/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

// Views
import { AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { ScenariosTableItem, ScenariosTableHeader } from 'views/layouts'

// Styled Elements
import { TransactionsTableWrapper } from './ScenariosTable.elements'

function ScenariosTable(props) {
  // Destructure
  const { isLoading, currentPolicy, setCurrentPolicy, scenarios, setScenarios } = props

  return (
    <TransactionsTableWrapper>
      <AccordionTable title="Available Scenarios" minWidth={700}>
        <ScenariosTableHeader />
        {scenarios?.length > 0 && !isLoading ? (
          scenarios.map(({ scenario_id, scenario_info: { scenarioName, severity, type, status }, queryData }) => (
            <ScenariosTableItem
              key={scenario_id}
              scenario_id={scenario_id}
              scenarioName={scenarioName}
              severity={severity}
              type={type}
              status={status}
              queryData={queryData}
              currentPolicy={currentPolicy}
              setCurrentPolicy={setCurrentPolicy}
              scenarios={scenarios}
              setScenarios={setScenarios}
            />
          ))
        ) : (
          <>
            {isLoading ? <LoadingTablePlaceholder /> : <>{scenarios?.length < 1 ? <EmptyTablePlaceholder /> : <></>}</>}
          </>
        )}
      </AccordionTable>
    </TransactionsTableWrapper>
  )
}

// Default Props
ScenariosTable.defaultProps = {
  isLoading: false,
  currentPolicy: {},
  setCurrentPolicy: () => {},
  scenarios: [],
  setScenarios: () => {},
}

// Proptypes Validation
ScenariosTable.propTypes = {
  isLoading: PropTypes.bool,
  currentPolicy: PropTypes.object,
  setCurrentPolicy: PropTypes.func,
  scenarios: PropTypes.array,
  setScenarios: PropTypes.func,
}

export default ScenariosTable
