import { useMutation } from 'react-query'

// Services
import { GetCaseActivities } from 'services/api/Cases'

export default () => {
  const {
    mutate: getCaseActivities,
    data: caseActivitiesData,
    error: caseActivitiesError,
    isLoading: isCaseActivitiesLoading,
    isSuccess: isCaseActivitiesSuccess,
    isError: isCaseActivitiesError,
  } = useMutation(GetCaseActivities)

  return {
    getCaseActivities,
    caseActivitiesData,
    caseActivitiesError,
    isCaseActivitiesLoading,
    isCaseActivitiesSuccess,
    isCaseActivitiesError,
  }
}
