/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './PoliciesTableHeader.elements'

function CasesTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper>
        <TableRowContainer>
          <TableText>Title</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Status</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Description</TableText>
        </TableRowContainer>
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
CasesTableHeader.defaultProps = {}

// Proptypes Validation
CasesTableHeader.propTypes = {}
export default CasesTableHeader
