import styled from 'styled-components'

export const SubHeaderWrapper = styled.div``

export const SubHeaderNavList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  gap: ${({ isButton }) => (isButton ? '10px' : '20px')};
  list-style: none;
  border-bottom: ${({ theme, isButton }) => !isButton && ` 2px solid ${theme.colors.white}`};
`

export const SubHeaderNavListItem = styled.li`
  padding: 0px;
  margin: 0;
`

export const SubHeaderNavListLink = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${({ isButton }) => (isButton ? '5px 15px' : '0 0 8px 0')};
  border: ${({ theme, isButton }) => isButton && ` 2px solid ${theme.colors.white}`};
  border-radius: 8px;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.07);

  &:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  }

  // TODO: Use theme mixins
  &.active {
    font-weight: 700;
    border: ${({ isButton }) => isButton && 'none'};
  }

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme, isButton }) => isButton && theme.colors.white};

    &:before {
      ${({ isButton, theme }) =>
        !isButton &&
        `content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${theme.colors.blue}
      `};
    }
  }

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`
