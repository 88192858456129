import { useMutation } from 'react-query'

// Services
import { CreateAccountIndividual } from 'services/api/Accounts'

export default () => {
  const {
    mutate: createAccountIndividual,
    data: accountIndividualCreateData,
    error: accountIndividualCreateError,
    isLoading: isAccountIndividualCreateLoading,
    isSuccess: isAccountIndividualCreateSuccess,
    isError: isAccountIndividualCreateError,
  } = useMutation(CreateAccountIndividual)

  return {
    createAccountIndividual,
    accountIndividualCreateData,
    accountIndividualCreateError,
    isAccountIndividualCreateLoading,
    isAccountIndividualCreateSuccess,
    isAccountIndividualCreateError,
  }
}
