import { useMutation } from 'react-query'

// Services
import { GetVaspInfo } from 'services/api/Auth'

export default () => {
  const {
    mutate: getVaspInfo,
    data: getVaspInfoData,
    error: getVaspInfoError,
    isLoading: isGetVaspInfoLoading,
    isSuccess: isGetVaspInfoSuccess,
    isError: isGetVaspInfoError,
  } = useMutation(GetVaspInfo)

  return {
    getVaspInfo,
    getVaspInfoData,
    getVaspInfoError,
    isGetVaspInfoLoading,
    isGetVaspInfoSuccess,
    isGetVaspInfoError,
  }
}
