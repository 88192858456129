import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import { CasesTableWrapper } from './PoliciesTable.elements'

// Views
import { Table, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'
import { PoliciesTableItem, PoliciesTableHeader } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function PoliciesTable(props) {
  // Destructure
  const { data, isLoading } = props

  // States
  const [alertsData, setAlertsData] = useState([])

  useEffect(() => {
    if (data) {
      setAlertsData(data)
    }
  }, [data])

  return (
    <CasesTableWrapper>
      <Table gap={25} minWidth={900} tableFilters={{ page: 0, size: 0 }} setTableFilters={() => {}}>
        <PoliciesTableHeader />
        {alertsData.length > 0 && !isLoading ? (
          alertsData.map(({ policy_id, policyTitle, status, policyDescription }) => (
            <PoliciesTableItem
              key={policy_id}
              policy_id={policy_id}
              policyTitle={policyTitle}
              status={status}
              policyDescription={policyDescription}
            />
          ))
        ) : (
          <div>
            {isLoading ? <LoadingTablePlaceholder /> : <div>{alertsData.length < 1 && <EmptyTablePlaceholder />}</div>}
          </div>
        )}
      </Table>
    </CasesTableWrapper>
  )
}

// Default Props
PoliciesTable.defaultProps = {
  isLoading: false,
  data: [],
  filters: {},
  actions: {},
}

// Proptypes Validation
PoliciesTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      policy_id: PropTypes.string,
      breach_count: PropTypes.number,
      primary_dataset: PropTypes.number,
      policyTitle: PropTypes.string,
      status: PropTypes.string,
      timestamp: PropTypes.string,
      policyDescription: PropTypes.string,
      rule_code: PropTypes.string,
    })
  ),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.shape({
      size: PropTypes.number,
      page: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    showAlert: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
    updateAccessToFeatures: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(PoliciesTable)
