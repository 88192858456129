import styled from 'styled-components'

export const ButtonFilterWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  margin-top: ${({ label }) => (label ? '-5px;' : '20px;')};
  /* background: ${({ theme }) => theme.colors.white};

  border-radius: ${({ open }) => (open ? '10px 10px 0 0' : '10px')};
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07); */
`
export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-height: 35.5px;

  opacity: 1;
  transition: 0.3s;

  ${({ collapseFilter }) => collapseFilter && 'opacity: 0; min-width: 0px; overflow: hidden; width: 0;'};
  & div {
    max-width: 160px;
  }
`
