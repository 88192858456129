import { useState } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetDashboardCaseTransactions } from 'services/api/Cases'

export default () => {
  const [dashboardCaseTransactionsData, setDashboardCaseTransactionsData] = useState({
    title: 'Transaction Monitoring Activity',
    figureTotalValue: 0,
    figureTitle: 'All-Time',
    figureSubtitle: 'Activity',
    breakdownData: [
      {
        name: 'BTC',
        data: [0],
      },
      {
        name: 'ETH',
        data: [0],
      },
      {
        name: 'ERC-20',
        data: [0],
      },
      {
        name: 'LTC',
        data: [0],
      },
      {
        name: 'BCH',
        data: [0],
      },
    ],
  })

  const {
    mutate: getDashboardCaseTransactions,
    error: dashboardCaseTransactionsError,
    isLoading: isDashboardCaseTransactionsLoading,
    isSuccess: isDashboardCaseTransactionsSuccess,
    isError: isDashboardCaseTransactionsError,
  } = useMutation(GetDashboardCaseTransactions, {
    onSuccess: (data) => {
      setDashboardCaseTransactionsData((prevState) => ({
        ...prevState,
        figureTotalValue: data.TotalTransactions,
        breakdownData: [
          {
            name: 'BTC',
            data: [data.BTC],
          },
          {
            name: 'ETH',
            data: [data.ETH],
          },
          {
            name: 'ERC-20',
            data: [data['ERC-20']],
          },
          {
            name: 'LTC',
            data: [data.LTC],
          },
          {
            name: 'BCH',
            data: [data.BCH],
          },
        ],
      }))
    },
  })

  return {
    getDashboardCaseTransactions,
    dashboardCaseTransactionsData,
    dashboardCaseTransactionsError,
    isDashboardCaseTransactionsLoading,
    isDashboardCaseTransactionsSuccess,
    isDashboardCaseTransactionsError,
  }
}
