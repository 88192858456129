/* eslint-disable max-len */
import React from 'react'

export default function BitcoinSvMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M343.773 191.36C340.552 157.88 311.648 146.66 275.122 143.46V97H246.857V142.22C239.436 142.22 231.835 142.36 224.294 142.52V97H196.029L196.009 143.42C189.888 143.54 183.867 143.66 178.006 143.66V143.52L139.02 143.5V173.7C139.02 173.7 159.903 173.3 159.543 173.68C171.005 173.68 174.726 180.32 175.806 186.06V238.98C176.606 238.98 177.626 239.02 178.786 239.18H175.806L175.786 313.32C175.286 316.92 173.165 322.66 165.164 322.68C165.524 323 144.621 322.68 144.621 322.68L139 356.44H175.806C182.647 356.44 189.388 356.56 195.989 356.6L196.009 403.58H224.254V357.1C231.995 357.26 239.496 357.32 246.837 357.32L246.817 403.58H275.082V356.7C322.609 353.98 355.915 342 360.035 297.36C363.376 261.42 346.473 245.36 319.489 238.88C335.911 230.56 346.173 215.86 343.773 191.36ZM304.206 291.8C304.206 326.9 244.097 322.92 224.914 322.92V260.66C244.097 260.7 304.206 255.2 304.206 291.8ZM291.044 203.98C291.044 235.92 240.876 232.18 224.914 232.2V175.76C240.896 175.76 291.064 170.68 291.044 203.98Z"
        fill="#00CACD"
      />
      <path d="M175.848 237.74H180.048V240.84H175.848V237.74Z" fill="#00CACD" />
    </svg>
  )
}
