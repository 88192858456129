import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px 35px 30px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
`
export const DetailsContainer = styled.div`
  width: 100%;
`
export const FooterBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
`

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 80px;
  padding: 0 40px 0 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};

  & button {
    border-radius: 5px;
  }
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  min-width: 200px;

  // overwriting select component
  & div > div {
    margin: 0 !important;
  }
  & button {
    padding: 12px;
    border-radius: 10px;
  }
`
export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Title = styled.h2`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Text = styled.p`
  margin: 0;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const ButtonWrapper = styled.a`
  width: 100%;
`
