/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Views
import { AccordionTableItem } from 'views/components'
import { ScenariosTableItemDetails } from 'views/layouts'

// Styled Elements
import {
  AccordionTableButtonWrapper,
  AccordionTableButtonItem,
  AccordionTablePanelWrapper,
} from './ScenariosTableItem.elements'

function ScenariosTableItem(props) {
  // Destructure
  const {
    scenario_id,
    scenarioName,
    severity,
    type,
    status,
    queryData,
    currentPolicy,
    setCurrentPolicy,
    scenarios,
    setScenarios,
  } = props

  // States
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <AccordionTableItem
      setIsExpanded={setIsExpanded}
      expandPanel={isExpanded}
      button={
        <AccordionTableButtonWrapper>
          <AccordionTableButtonItem>{scenarioName}</AccordionTableButtonItem>
          <AccordionTableButtonItem>{severity}</AccordionTableButtonItem>
          <AccordionTableButtonItem>{type}</AccordionTableButtonItem>
          <AccordionTableButtonItem>{status}</AccordionTableButtonItem>
        </AccordionTableButtonWrapper>
      }
      panel={
        <AccordionTablePanelWrapper>
          <ScenariosTableItemDetails
            key={scenario_id}
            queryData={queryData}
            scenario_id={scenario_id}
            scenarioName={scenarioName}
            severity={severity}
            type={type}
            status={status}
            isExpanded={isExpanded}
            currentPolicy={currentPolicy}
            setCurrentPolicy={setCurrentPolicy}
            scenarios={scenarios}
            setScenarios={setScenarios}
          />
        </AccordionTablePanelWrapper>
      }
    />
  )
}

// Default Props
ScenariosTableItem.defaultProps = {
  scenario_id: '',
  scenarioName: '',
  severity: '',
  type: '',
  status: '',
  queryData: {},
  currentPolicy: {},
  setCurrentPolicy: () => {},
  scenarios: [],
  setScenarios: () => {},
}

// Proptypes Validation
ScenariosTableItem.propTypes = {
  scenario_id: PropTypes.string,
  scenarioName: PropTypes.string,
  severity: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  queryData: PropTypes.object,
  currentPolicy: PropTypes.object,
  setCurrentPolicy: PropTypes.func,
  scenarios: PropTypes.array,
  setScenarios: PropTypes.func,
}

export default ScenariosTableItem
