/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './UsersTableHeader.elements'

function UsersTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper>
        <TableRowContainer>
          <TableText>Email</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Name</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Role Type</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Status</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText />
        </TableRowContainer>
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
UsersTableHeader.defaultProps = {}

// Proptypes Validation
UsersTableHeader.propTypes = {}
export default UsersTableHeader
