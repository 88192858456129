export const CHART_OPTIONS = {
  tooltip: {
		trigger: 'item',
		triggerOn: 'mousemove',
		enterable: true,
	},
	legend: {
		top: '110px',
		left: '20px',
		orient: 'vertical',
		itemGap: 5,
		itemWidth: 0,
		padding: 0,
		data: [
			{
				name: 'incoming',
				icon: 'none',
				textStyle: {
					color: '#555',
					fontWeight: 800,
					fontSize: 16,
					margin: 0,
					padding: 0,
				},
			},
			{
				name: 'outgoing',
				icon: 'none',
				textStyle: {
					color: '#555',
					fontWeight: 800,
					fontSize: 16,
					margin: 0,
					padding: 0,
				},
			},
		],
		formatter(name) {
			return name.charAt(0).toUpperCase() + name.slice(1)
		},
		selected: {
			incoming: true,
			outgoing: false,
		},
	},
	series: [
		{
			type: 'tree',
			name: 'incoming',
			roam: true,
			initialTreeDepth: -1,
			orient: 'RL',
			data: {},
			zoom: 0.9,
			zoomToNodeRatio: 0.32 * 0.32,
			leaves: {
				label: {
					position: 'right',
					verticalAlign: 'middle',
					align: 'left',
				},
			},
			right: '50%',
			expandAndCollapse: true,
			animationDuration: 550,
			animationDurationUpdate: 750,
			selectedMode: true,
		},
		{
			type: 'tree',
			name: 'outgoing',
			roam: true,
			initialTreeDepth: -1,
			orient: 'LR',
			data: {},
			zoom: 0.9,
			zoomToNodeRatio: 0.32 * 0.32,
			leaves: {
				label: {
					position: 'left',
					verticalAlign: 'middle',
					align: 'right',
				},
			},
			left: '50%',
			expandAndCollapse: true,
			animationDuration: 550,
			animationDurationUpdate: 750,
		},
	],
}
