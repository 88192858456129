import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  AlertStatusWrapper,
  AlertStatusContainer,
  AlertStatusTitle,
  AlertStatusNumber,
  AlertStatusSpan,
} from './AlertBreakDownBoxes.elements'

function AlertBreakDownBoxes(props) {
  // Destructure
  const { data } = props

  return (
    <AlertStatusWrapper>
      {data?.length > 0 && data.map((item) => (
        <AlertStatusContainer key={item.alert_status}>
          <AlertStatusTitle>{item.alert_status} Alerts</AlertStatusTitle>
          <AlertStatusNumber>{item.alert_count}</AlertStatusNumber>
          <AlertStatusSpan>{item.percent}%</AlertStatusSpan>
        </AlertStatusContainer>
      ))}
    </AlertStatusWrapper>
  )
}

// Default Props
AlertBreakDownBoxes.defaultProps = {
  data: []
}

// Proptypes Validation
AlertBreakDownBoxes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    alert_status: PropTypes.string,
    alert_count: PropTypes.number,
    percent: PropTypes.number,
  }))
}

export default AlertBreakDownBoxes
