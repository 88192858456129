import { useMutation } from 'react-query'

// Services
import { DeleteTransaction } from 'services/api/Transactions'

export default () => {
  const {
    mutate: deleteTransaction,
    data: deleteTransactionData,
    error: deleteTransactionError,
    isLoading: isDeleteTransactionLoading,
    isSuccess: isDeleteTransactionSuccess,
    isError: isDeleteTransactionError,
  } = useMutation(DeleteTransaction)

  return {
    deleteTransaction,
    deleteTransactionData,
    deleteTransactionError,
    isDeleteTransactionLoading,
    isDeleteTransactionSuccess,
    isDeleteTransactionError,
  }
}
