import { useMutation } from 'react-query'

// Services
import { GetMonitoringTransactionCount } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: getMonitoringTransactionCount,
    data: monitoringTransactionCountData,
    error: monitoringTransactionCountError,
    isLoading: isMonitoringTransactionCountLoading,
    isSuccess: isMonitoringTransactionCountSuccess,
    isError: isMonitoringTransactionCountError,
  } = useMutation(GetMonitoringTransactionCount)

  return {
    getMonitoringTransactionCount,
    monitoringTransactionCountData,
    monitoringTransactionCountError,
    isMonitoringTransactionCountLoading,
    isMonitoringTransactionCountSuccess,
    isMonitoringTransactionCountError,
  }
}
