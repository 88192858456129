import styled from 'styled-components'

export const SearchBarWrapper = styled.div`
  position: relative;
  padding: 47px 0 37px 0;

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.ash};
  background-color: ${({ theme }) => theme.colors.white};
`

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1080px;
  display: flex;
  align-items: center;
`
