/* eslint-disable max-len */
import React from 'react'

export default function EyeIcon() {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33337 6.99999C1.33337 6.99999 4.30308 1.28571 9.50004 1.28571C14.697 1.28571 17.6667 6.99999 17.6667 6.99999C17.6667 6.99999 14.697 12.7143 9.50004 12.7143C4.30308 12.7143 1.33337 6.99999 1.33337 6.99999Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.50004 8.14283C10.1444 8.14283 10.6667 7.63115 10.6667 6.99997C10.6667 6.3688 10.1444 5.85712 9.50004 5.85712C8.85571 5.85712 8.33337 6.3688 8.33337 6.99997C8.33337 7.63115 8.85571 8.14283 9.50004 8.14283Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
