import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

// Store
import { actions } from 'core/store'

// Contants
import { LINK_TYPE_OPTIONS } from 'common/constants/formOptions'

// Styled Elements
import {
  TransferFormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  FormWrapper,
  FormLabel,
  OptionLabel,
} from './LinkAddressForm.elements'

// Hooks
import { useSearchAccount, useSearchRecipient, useFetchAccount, useFetchRecipient } from 'core/hooks/api'

// Views
import { TextField, Button, Search, Select } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function LinkAddressForm(props) {
  // Destructure
  const { formData, setFormData, actions, ui } = props

  // Store
  const { isLinkAddressDrawerOpen } = ui
  const {
    setIsIndividualAccountDrawerOpen,
    setIsEntityAccountDrawerOpen,
    setIsIndividualRecipientDrawerOpen,
    setIsEntityRecipientDrawerOpen,
    setShowHeaderLoader,
    setToBeUpdatedRecipient,
    setToBeUpdatedAccount,
  } = actions

  // hooks
  const { searchAccount, accountSearchData, isAccountSearchLoading } = useSearchAccount()
  const { searchRecipient, recipientSearchData, isRecipientSearchLoading } = useSearchRecipient()
  const { getAccount, accountData, isAccountLoading } = useFetchAccount()
  const { getRecipient, recipientData, isRecipientLoading } = useFetchRecipient()

  // Internal State
  const [searchResults, setSearchResults] = useState([])
  const [isLinkRecipient, setIsLinkRecipient] = useState(false)

  // functions
  const handleSearch = debounce((value) => {
    if (!value) return setSearchResults([])
    if (value && !isLinkRecipient) return searchAccount({ search_term: value })
    if (value && isLinkRecipient) return searchRecipient({ search_term: value })
  }, 500)
  const handleOnAutoCompleteSelect = (value) => {
    if (!isLinkRecipient) getAccount({ account_id: value })
    if (isLinkRecipient) getRecipient({ account_id: value })
  }

  // useEffects
  useEffect(() => {
    setSearchResults([])
    setFormData({ ...formData, account: null })
  }, [isLinkRecipient])
  useEffect(() => {
    if (accountData) {
      setFormData({ ...formData, account: { ...formData.account, ...accountData } })
    }
    if (recipientData) {
      setFormData({ ...formData, account: { ...formData.account, ...recipientData } })
    }
  }, [accountData, recipientData])
  useEffect(() => {
    if (!isLinkRecipient) {
      if (accountSearchData) {
        setSearchResults(() =>
          accountSearchData.map(({ reference_id, display_name, id }) => ({
            label: reference_id,
            subLabel: display_name,
            value: id,
          }))
        )
      }
      if (!accountSearchData) setSearchResults([])
    }
    if (isLinkRecipient) {
      if (recipientSearchData) {
        setSearchResults(() =>
          recipientSearchData.map(({ reference_id, display_name, id }) => ({
            label: reference_id,
            subLabel: display_name,
            value: id,
          }))
        )
      }
      if (!recipientSearchData) setSearchResults([])
    }
  }, [accountSearchData, recipientSearchData])
  useEffect(() => {
    if (!isLinkRecipient) {
      if (isAccountLoading) {
        setShowHeaderLoader(true)
        setFormData({ ...formData, account: null })
      }
      if (!isAccountLoading) setShowHeaderLoader(false)
    }
    if (isLinkRecipient) {
      if (isRecipientLoading) {
        setShowHeaderLoader(true)
        setFormData({ ...formData, account: null })
      }
      if (!isRecipientLoading) setShowHeaderLoader(false)
    }
  }, [isAccountLoading, isRecipientLoading])
  useEffect(() => !isLinkAddressDrawerOpen && setSearchResults([]), [isLinkAddressDrawerOpen])
  return (
    <TransferFormWrapper>
      <FormWrapper>
        <FormGroupWrapper>
          <FormInputGroupItem>
            <Select
              key={formData.account}
              longValue={false}
              placeholder="Account"
              label="Select Link Type"
              onChange={setIsLinkRecipient}
              value={isLinkRecipient}
              options={LINK_TYPE_OPTIONS}
            />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <Search
              placeholder={isLinkRecipient ? 'Search for recipient' : 'Search for account'}
              onInputChange={handleSearch}
              options={searchResults}
              setOptions={setSearchResults}
              onSelect={handleOnAutoCompleteSelect}
              isLoading={isAccountSearchLoading || isRecipientSearchLoading}
              bottomOptions={
                <FormGroupWrapper>
                  <OptionLabel>Create New {isLinkRecipient ? 'Recipient' : 'Account'}</OptionLabel>
                  <FormInputGroupItem>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (isLinkRecipient) {
                          setToBeUpdatedRecipient(null)
                          setIsIndividualRecipientDrawerOpen(true)
                        }
                        if (!isLinkRecipient) {
                          setToBeUpdatedAccount(null)
                          setIsIndividualAccountDrawerOpen(true)
                        }
                      }}
                    >
                      Individual
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (isLinkRecipient) {
                          setToBeUpdatedRecipient(null)
                          setIsEntityRecipientDrawerOpen(true)
                        }
                        if (!isLinkRecipient) {
                          setToBeUpdatedAccount(null)
                          setIsEntityAccountDrawerOpen(true)
                        }
                      }}
                    >
                      Business
                    </Button>
                  </FormInputGroupItem>
                </FormGroupWrapper>
              }
            />
          </FormInputGroupItem>

          {formData.account && !isAccountLoading && (
            <>
              <FormLabel>Selected {isLinkRecipient ? 'Recipient' : 'Account'}</FormLabel>
              <FormInputGroupItem>
                <TextField
                  label={isLinkRecipient ? 'Recipient ID' : 'Account ID'}
                  disabled
                  value={formData.account.reference_id}
                />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField
                  label={isLinkRecipient ? 'Recipient Type' : 'Account Type'}
                  disabled
                  value={formData.account.account_type_desc}
                />
              </FormInputGroupItem>
              <FormInputGroupItem>
                <TextField label="Name" disabled value={formData.account.display_name} />
              </FormInputGroupItem>
            </>
          )}
          {isAccountLoading && <FormLabel>Loading...</FormLabel>}
        </FormGroupWrapper>
      </FormWrapper>
    </TransferFormWrapper>
  )
}

// Default Props
LinkAddressForm.defaultProps = {
  ui: {},
  formData: {},
  setFormData: () => {},
  actions: {},
  form: {},
}

// Proptypes Validation
LinkAddressForm.propTypes = {
  ui: PropTypes.shape({ isLinkAddressDrawerOpen: PropTypes.bool }),
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      in_hash_address: PropTypes.string,
    }),
    account: PropTypes.shape({
      reference_id: PropTypes.string,
      account_type_desc: PropTypes.string,
      display_name: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdAccount: PropTypes.shape({}),
  }),
  setFormData: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkAddressForm)
