import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Services
import { GetTransactionEvents } from 'services/api/Transactions'

// Assets
import { LinkedIcon, ExternalLinkIcon } from 'assets/images'

// Constants
// import { IP_RISK_PARTNER_OPTIONS_MAPPING } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Components
import { LoadingTablePlaceholder, RiskBar, EmptyTablePlaceholder } from 'views/components'

// Styled Elements
import {
  Text,
  ColContainer,
  RowWrapper,
  RowContainer,
  BlockContainer,
  InfoTitle,
  Title,
  IconContainer,
  ExternalLink,
} from './TransactionEvents.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TransactionEvents(props) {
  const { transactionData, currentIpUsed, isExpanded, eventDetails, setEventDetails } = props

  // Input Refs
  const inputContainerRef = useRef(null)
  const outputContainerRef = useRef(null)

  // UseStates
  const [isInputLoading, setIsInputLoading] = useState(false)
  const [isOutputLoading, setIsOutputLoading] = useState(false)
  const [isLinked, setIsLinked] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isResetLoading, setIsResetLoading] = useState(false)
  const [isInputBottom, setIsInputBottom] = useState(false)
  const [isOutputBottom, setIsOutputBottom] = useState(false)

  // Functions
  async function fetchInputEvents(page) {
    if (isInputBottom) {
      setIsInputLoading(false)
      return
    }
    if (isInputLoading) return
    const data = await GetTransactionEvents({
      transaction_id: transactionData.id,
      risk_integration_id: currentIpUsed && currentIpUsed,
      page: page || eventDetails.inputs.page + 1,
      type: 'in',
    })

    if (data?.items && data?.items?.length > -1) {
      setEventDetails((prevValue) => ({
        ...prevValue,
        inputs: {
          page: page || eventDetails.inputs.page + 1,
          events: [...prevValue.inputs.events, ...data.items],
        },
      }))
    }
    if (data?.items?.length < 1) {
      setIsInputBottom(true)
    }
    setIsInputLoading(false)
  }
  async function fetchOutputEvents(page) {
    if (isOutputBottom) {
      setIsOutputLoading(false)
      return
    }
    if (isOutputLoading) return
    const data = await GetTransactionEvents({
      transaction_id: transactionData.id,
      risk_integration_id: currentIpUsed && currentIpUsed,
      page: page || eventDetails.outputs.page + 1,
      type: 'out',
    })
    if (data?.items && data?.items?.length > -1) {
      setEventDetails((prevValue) => ({
        ...prevValue,
        outputs: {
          page: page || eventDetails.outputs.page + 1,
          events: [...prevValue.outputs.events, ...data.items],
        },
      }))
    }
    if (data?.items?.length < 1) {
      setIsOutputBottom(true)
    }
    setIsOutputLoading(false)
  }
  async function resetEvents(linked) {
    const data_in = await GetTransactionEvents({
      transaction_id: transactionData.id,
      risk_integration_id: currentIpUsed && currentIpUsed,
      page: 1,
      type: 'in',
      linked: linked && 'in',
    })
    const data_out = await GetTransactionEvents({
      transaction_id: transactionData.id,
      risk_integration_id: currentIpUsed && currentIpUsed,
      page: 1,
      type: 'out',
      linked: linked && 'out',
    })

    if (data_in?.items?.length >= 0 && data_out?.items?.length >= 0) {
      setEventDetails(() => ({
        outputs: {
          page: 1,
          events: [...data_out.items],
        },
        inputs: {
          page: 1,
          events: [...data_in.items],
        },
      }))
    }
    setIsResetLoading(false)
    setIsInputBottom(false)
    setIsOutputBottom(false)
  }
  function formatValue(number) {
    if (number < 0.01) return number
    return numberWithCommas(Math.round(number * 100) / 100)
  }
  function convertAmount(amount, decimals) {
    decimals = Math.max(0, Math.floor(decimals))
    const multiplier = 10 ** decimals
    const convertedAmount = amount / multiplier
    return convertedAmount
  }
  function formatEvents(rawEvents) {
    const inputs = []
    const outputs = []
    if (rawEvents?.length > 0) {
      rawEvents.forEach((item) => {
        if (item?.source) {
          inputs.push(item)
        }
        if (item?.destination) {
          outputs.push(item)
        }
      })
    }
    setEventDetails({
      inputs: {
        events: inputs,
        page: 1,
      },
      outputs: {
        events: outputs,
        page: 1,
      },
    })
  }

  // UseEffects
  useEffect(() => {
    if (transactionData?.financial_data?.events) {
      formatEvents(transactionData?.financial_data?.events)
      return
    }
    if (!isLoaded && (eventDetails?.inputs?.events?.length > 0 || eventDetails?.outputs?.events?.length > 0)) {
      setIsLoaded(true)
      return
    }
    if (isExpanded && !isLoaded && transactionData.id) {
      setIsInputLoading(true)
      setIsOutputLoading(true)
      fetchInputEvents(1)
      fetchOutputEvents(1)
      setIsLoaded(true)
    }
  }, [isExpanded, transactionData])
  useEffect(() => {
    if (!isExpanded) return
    if (!isLoaded) return
    setIsResetLoading(true)
    resetEvents(isLinked)
  }, [isLinked])

  useEffect(() => {
    const handleScroll = () => {
      const container = inputContainerRef.current
      const { clientHeight, scrollHeight, scrollTop } = container
      if (clientHeight + scrollTop >= scrollHeight && transactionData.id) {
        setIsInputLoading(true)
        fetchInputEvents()
      }
    }
    const container = inputContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isInputBottom, isInputLoading, isResetLoading])
  useEffect(() => {
    const handleScroll = () => {
      const container = outputContainerRef.current
      const { clientHeight, scrollHeight, scrollTop } = container
      if (clientHeight + scrollTop >= scrollHeight && transactionData.id) {
        setIsOutputLoading(true)
        fetchOutputEvents()
      }
    }
    const container = outputContainerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isOutputBottom, isOutputLoading, isResetLoading])
  return (
    <BlockContainer
      style={{ marginTop: '20px', padding: '20px 0', borderWidth: '0', borderTopWidth: '1px', borderRadius: 0 }}
    >
      <RowWrapper style={{ padding: 0 }}>
        <RowContainer style={{ gap: '15px' }}>
          <ColContainer style={{ height: '100%' }}>
            <RowContainer style={{ paddingLeft: '20px', flex: 0 }}>
              <Title>Input Events</Title>
            </RowContainer>
            {!isResetLoading && (
              <ColContainer
                ref={inputContainerRef}
                style={{ height: '100%', width: '100%', maxHeight: '330px', overflowY: 'auto' }}
              >
                {eventDetails?.inputs?.events &&
                  eventDetails?.inputs?.events?.length > 0 &&
                  eventDetails?.inputs?.events.map((item) => {
                    if (item?.type !== 'fee') {
                      return (
                        <BlockContainer style={{ height: 'fit-content' }} key={item?.transaction_event_id}>
                          <ColContainer>
                            <RowContainer>
                              <InfoTitle>Address: </InfoTitle>
                              <Text>{item?.source}</Text>
                            </RowContainer>
                            <RowContainer>
                              <ColContainer>
                                <RowContainer>
                                  <InfoTitle>Amount Native: </InfoTitle>
                                  <Text>
                                    {item?.denomination && `${item?.denomination} `}
                                    {item?.amount &&
                                      item?.decimals &&
                                      `${formatValue(convertAmount(item.amount, item.decimals))}`}
                                  </Text>
                                </RowContainer>
                                <RowContainer>
                                  <InfoTitle>Amount USD: </InfoTitle>
                                  <Text>{item?.amount_usd && `$ ${formatValue(item.amount_usd)}`}</Text>
                                </RowContainer>
                              </ColContainer>
                              <ColContainer
                                style={{ borderLeftWidth: '1px', paddingLeft: '10px', marginLeft: '-10px' }}
                              >
                                <RowContainer>
                                  <InfoTitle>Linked to: </InfoTitle>
                                  <ExternalLink
                                    exact
                                    target="_blank"
                                    to={`/directory/${
                                      item?.in_account_is_recipient ? 'recipients' : 'accounts'
                                    }/details/${item.in_account_id}`}
                                  >
                                    {item?.in_account_reference_id ? (
                                      <>
                                        {item.in_account_reference_id} <ExternalLinkIcon />{' '}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </ExternalLink>
                                </RowContainer>
                                <RowContainer>
                                  <InfoTitle>Type: </InfoTitle>
                                  <Text>Sender</Text>
                                </RowContainer>
                              </ColContainer>
                            </RowContainer>
                            {item?.in_risk_ratio && (
                              <RowContainer>
                                <InfoTitle>Risk Score: </InfoTitle>
                                <Text>{item?.in_risk_ratio && `${item.in_risk_ratio}%`}</Text>
                                <RiskBar
                                  height={6}
                                  width={item?.in_risk_ratio !== null || undefined ? `${item.in_risk_ratio}%` : '0%'}
                                />
                              </RowContainer>
                            )}
                          </ColContainer>
                        </BlockContainer>
                      )
                    }
                  })}
                {eventDetails?.inputs?.events && eventDetails?.inputs?.events?.length < 1 && !isInputLoading && (
                  <EmptyTablePlaceholder
                    title="No linked Addresses found"
                    content="Addresses that were linked to an account were not found"
                    setFilter={() => setIsLinked(false)}
                  />
                )}
                {isInputLoading && (
                  <BlockContainer style={{ padding: '10px 0' }}>
                    <LoadingTablePlaceholder style={{ padding: 0 }} />
                  </BlockContainer>
                )}
              </ColContainer>
            )}
            {isResetLoading && (
              <BlockContainer style={{ padding: '10px 0' }}>
                <LoadingTablePlaceholder style={{ padding: 0 }} />
              </BlockContainer>
            )}
          </ColContainer>

          <ColContainer style={{ height: '100%' }}>
            <RowContainer style={{ padding: '0 20px 0 10px', flex: 0, justifyContent: 'space-between' }}>
              <Title>Output Events</Title>
              <IconContainer active={isLinked} onClick={() => setIsLinked((v) => !v)}>
                <LinkedIcon />
              </IconContainer>
            </RowContainer>
            {!isResetLoading && (
              <ColContainer
                ref={outputContainerRef}
                style={{ height: '100%', width: '100%', maxHeight: '330px', overflowY: 'auto' }}
              >
                {eventDetails?.outputs?.events &&
                  eventDetails?.outputs?.events?.length > 0 &&
                  eventDetails?.outputs?.events.map((item) => {
                    if (item?.type !== 'fee') {
                      return (
                        <BlockContainer style={{ height: 'fit-content' }} key={item?.transaction_event_id}>
                          <ColContainer>
                            <RowContainer>
                              <InfoTitle>Address: </InfoTitle>
                              <Text>{item?.destination}</Text>
                            </RowContainer>
                            <RowContainer>
                              <ColContainer>
                                <RowContainer>
                                  <InfoTitle>Amount Native: </InfoTitle>
                                  <Text>
                                    {item?.denomination && `${item?.denomination} `}
                                    {item?.amount &&
                                      item?.decimals &&
                                      `${formatValue(convertAmount(item.amount, item.decimals))}`}
                                  </Text>
                                </RowContainer>
                                <RowContainer>
                                  <InfoTitle>Amount USD: </InfoTitle>
                                  <Text>{item?.amount_usd && `$ ${formatValue(item.amount_usd)}`}</Text>
                                </RowContainer>
                              </ColContainer>
                              <ColContainer
                                style={{ borderLeftWidth: '1px', paddingLeft: '10px', marginLeft: '-10px' }}
                              >
                                <RowContainer>
                                  <InfoTitle>Linked to: </InfoTitle>
                                  <ExternalLink
                                    exact
                                    target="_blank"
                                    to={`/directory/${
                                      item?.out_account_is_recipient ? 'recipients' : 'accounts'
                                    }/details/${item.out_account_id}`}
                                  >
                                    {item?.out_account_reference_id ? (
                                      <>
                                        {item.out_account_reference_id} <ExternalLinkIcon />{' '}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </ExternalLink>
                                </RowContainer>
                                <RowContainer>
                                  <InfoTitle>Type: </InfoTitle>
                                  <Text>Receiver</Text>
                                </RowContainer>
                              </ColContainer>
                            </RowContainer>
                            {item?.out_risk_ratio && (
                              <RowContainer>
                                <InfoTitle>Risk Score: </InfoTitle>
                                <Text>{item?.out_risk_ratio && `${item.out_risk_ratio}%`}</Text>
                                <RiskBar
                                  height={6}
                                  width={item?.out_risk_ratio !== null || undefined ? `${item.out_risk_ratio}%` : '0%'}
                                />
                              </RowContainer>
                            )}
                          </ColContainer>
                        </BlockContainer>
                      )
                    }
                  })}

                {eventDetails?.outputs?.events && eventDetails?.outputs?.events?.length < 1 && !isInputLoading && (
                  <EmptyTablePlaceholder
                    title="No linked Addresses found"
                    content="Addresses that were linked to an account were not found"
                    setFilter={() => setIsLinked(false)}
                  />
                )}
                {isOutputLoading && (
                  <BlockContainer style={{ padding: '10px 0' }}>
                    <LoadingTablePlaceholder style={{ padding: 0 }} />
                  </BlockContainer>
                )}
              </ColContainer>
            )}
            {isResetLoading && (
              <BlockContainer style={{ padding: '10px 0' }}>
                <LoadingTablePlaceholder style={{ padding: 0 }} />
              </BlockContainer>
            )}
          </ColContainer>
        </RowContainer>
      </RowWrapper>
    </BlockContainer>
  )
}

// Default Props
TransactionEvents.defaultProps = {
  actions: {},
  transactionData: {},
}

// Proptypes Validation
TransactionEvents.propTypes = {
  currentIpUsed: PropTypes.string,
  actions: PropTypes.shape({
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
  transactionData: PropTypes.shape({
    id: PropTypes.string,
    hash: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    in_usd: PropTypes.number,
    out_usd: PropTypes.number,
    fee_amount_usd: PropTypes.number,
    balance: PropTypes.number,
    last_modified: PropTypes.string,
    created: PropTypes.string,
    in_count: PropTypes.number,
    out_count: PropTypes.number,
    asset_count: PropTypes.number,
    financial_data: PropTypes.shape({
      events: PropTypes.arrayOf({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
    risk_events: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  isExpanded: PropTypes.bool,
  eventDetails: PropTypes.shape({
    inputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
    outputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
  }),
  setEventDetails: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionEvents)
