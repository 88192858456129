import styled from 'styled-components'

export const IconButtonWrapper = styled.span`
  display: flex;
  cursor: pointer;
  padding: 6px;

  // TODO: Use theme function
  &:hover {
    background-color: #e9e9e9;
    border-radius: 50%;
  }
`
