import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { COUNTRIES_LIST } from 'common/constants/countries'
import { NATIONAL_ID_TYPE_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useCreateRecipientEntity, useUpdateRecipientEntity } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  FormContainer,
  FormInputGroupItem,
  FormHeader,
  FormTitle,
  FormFooter,
  TableButtonWrapper,
  TableRowWrapper,
  TablePanelWrapper,
} from '../DirectoryForms.elements'

// Views
import {
  Form,
  FormSelect,
  FormTextField,
  FormTextArea,
  FormSearchSelect,
  Button,
  AccordionTableItem,
} from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function EntityRecipientForm(props) {
  // Destructure
  const { form, actions } = props

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials')
  const {
    createRecipientEntity,
    recipientEntityCreateData,
    isRecipientEntityCreateLoading,
    isRecipientEntityCreateSuccess,
  } = useCreateRecipientEntity()
  const {
    updateRecipientEntity,
    recipientEntityUpdateData,
    isRecipientEntityUpdateLoading,
    isRecipientEntityUpdateSuccess,
  } = useUpdateRecipientEntity()

  // Store State
  const { toBeUpdatedRecipient } = form

  // Store Actions
  const {
    setIsEntityRecipientDrawerOpen,
    setToBeUpdatedRecipient,
    showAlert,
    setShowHeaderLoader,
    setCreatedRecipient,
    setIsPageTableUpdated,
    setIsRecordUpdated,
  } = actions

  // Internal State
  const [isCreateRecipient, setIsCreateRecipient] = useState(true)
  const [initialData, setInitialData] = useState()
  const [isFreeFormExpanded, setIsFreeFormExpanded] = useState(true)
  const [isStructuredExpanded, setIsStructuredExpanded] = useState(false)
  const [isNationalIDExpanded, setIsNationalIDExpanded] = useState(false)

  // Variables
  const formRef = useRef()
  const initialValues = {
    reference_id: '',
    email: '',
    name_legal: '',
    name_business: '',
    registration_country_code: 0,
    // inc_date: '',
    address_line: '',
    address_street_no: '',
    address_street_name: '',
    address_building_name: '',
    address_floor: '',
    address_room: '',
    address_department: '',
    address_postbox: '',
    address_city_location: '',
    address_city: '',
    address_region: '',
    address_district: '',
    address_postcode: '',
    address_country_code: '',
  }

  const validationSchema = Yup.object().shape({
    reference_id: Yup.string().max(50).required('Customer Id is required'),
    email: Yup.string().email().nullable(),
    name_legal: Yup.string().max(100).required('Legal Name is required'),
    name_business: Yup.string().max(100).nullable(),
    registration_country_code: Yup.string().max(2).nullable(),
    // inc_date: Yup.date().required('Date of Incorporation is required'),
    address_line: Yup.string()
      .max(256)
      .nullable()
      .test(
        'address_line',
        "Required if street number, street name, and building name aren't specified",
        (value, details) => {
          const empty_address_street_no = !details?.parent?.address_street_no
          const empty_address_building_name = !details?.parent?.address_building_name
          const empty_address_street_name = !details?.parent?.address_street_name
          if (!value && empty_address_street_no && empty_address_building_name && empty_address_street_name) {
            return false
          }
          return true
        }
      ),
    address_street_no: Yup.string()
      .max(10)
      .nullable()
      .test('address_street_no', 'Required with street name if address line is not specified', (value, details) =>
        validateForm(details)
      ),
    address_street_name: Yup.string()
      .max(100)
      .nullable()
      .test('address_street_name', 'Required with building name if address line is not specified', (value, details) =>
        validateForm(details)
      ),
    address_building_name: Yup.string()
      .max(20)
      .nullable()
      .test('address_building_name', 'Required with street name if address line is not specified', (value, details) =>
        validateForm(details)
      ),
    address_floor: Yup.string().max(20).nullable(),
    address_room: Yup.string().max(20).nullable(),
    address_department: Yup.string().max(20).nullable(),
    address_postbox: Yup.string().max(20).nullable(),
    address_city_location: Yup.string().max(50).nullable(),
    address_city: Yup.string().max(50).nullable(),
    address_region: Yup.string().max(50).nullable(),
    address_district: Yup.string().max(50).nullable(),
    address_postcode: Yup.string().max(25).nullable(),
    address_country_code: Yup.string().max(2).nullable(),
    national_id_type_code: Yup.string().max(10).nullable(),
    national_id: Yup.string().max(35).nullable(),
    national_id_issue_country: Yup.string().max(2).nullable(),
    national_id_registration_authority: Yup.string().max(10).nullable(),
  })

  // Functions
  const triggerSubmit = () => {
    formRef.current.submitForm()
  }
  const handleOnSubmit = (values) => {
    const payload = {
      biz_id: userCredentials.Business_ID,
      ...values,
    }
    // removing empty fields on object
    Object.keys(payload).forEach((k) => payload[k] === '' && delete payload[k])
    if (isCreateRecipient) {
      createRecipientEntity(payload)
    } else if (!isCreateRecipient) {
      updateRecipientEntity({ account_id: toBeUpdatedRecipient.id, values: payload })
    }
  }

  const handleLoadingChange = () => {
    if (isRecipientEntityCreateLoading || isRecipientEntityUpdateLoading) {
      setShowHeaderLoader(true)
    }
    if (!isRecipientEntityCreateLoading && !isRecipientEntityUpdateLoading) {
      setShowHeaderLoader(false)
      if (initialData) {
        if (isCreateRecipient) {
          if (isRecipientEntityCreateSuccess) {
            setCreatedRecipient(recipientEntityCreateData)
            setIsEntityRecipientDrawerOpen(false)
            setIsPageTableUpdated(true)
            showAlert({ type: 'success', message: 'Successfully created record' })
          } else if (!isRecipientEntityCreateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in creating record' })
          }
        } else if (!isCreateRecipient) {
          if (isRecipientEntityUpdateSuccess) {
            setCreatedRecipient(recipientEntityUpdateData)
            setToBeUpdatedRecipient(null)
            setIsEntityRecipientDrawerOpen(false)
            setIsRecordUpdated(true)
            showAlert({ type: 'success', message: 'Successfully updated record' })
          } else if (!isRecipientEntityUpdateSuccess) {
            showAlert({ type: 'error', message: 'An error occured in updating record' })
          }
        }
      }
    }
  }

  function validateForm(details) {
    const empty_line = !details?.parent?.address_line
    const empty_address_street_no = !details?.parent?.address_street_no
    const empty_address_building_name = !details?.parent?.address_building_name
    const empty_address_street_name = !details?.parent?.address_street_name
    if (!empty_line) return true
    if (empty_line && empty_address_street_no && empty_address_building_name && empty_address_street_name) {
      return false
    }
    if (empty_address_street_name) return false
    if (empty_address_street_no && empty_address_building_name) return false
    return true
  }
  // UseEffects
  useEffect(() => {
    if (!toBeUpdatedRecipient) {
      setIsCreateRecipient(true)
      setInitialData(initialValues)
    } else {
      setIsCreateRecipient(false)
      setInitialData(toBeUpdatedRecipient)
    }
  }, [])
  useEffect(() => handleLoadingChange(), [isRecipientEntityCreateLoading, isRecipientEntityUpdateLoading])
  return (
    <FormWrapper>
      {initialData && (
        <Form
          formRef={formRef}
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormHeader>
            <FormTitle>{isCreateRecipient ? 'Create Entity Recipient' : 'Edit Entity Recipient'}</FormTitle>
          </FormHeader>

          <FormContainer>
            <FormInputGroupItem>
              <FormTextField label="Customer ID e.g. ID0001" name="reference_id" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Legal Name" name="name_legal" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Business Name" name="name_business" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Legal Entity Identifier" name="national_id" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormSearchSelect
                label="Registration Country"
                name="registration_country_code"
                options={COUNTRIES_LIST}
              />
            </FormInputGroupItem>
            {/* <FormInputGroupItem>
              <FormDatePicker label="Date of Incorporation" name="inc_date" />
            </FormInputGroupItem> */}

            <AccordionTableItem
              style={{ marginTop: '20px' }}
              setIsExpanded={setIsStructuredExpanded}
              expandPanel={isStructuredExpanded}
              button={
                <TableButtonWrapper>
                  <TableRowWrapper isExpanded={isStructuredExpanded}>Structured Address (preferred)</TableRowWrapper>
                </TableButtonWrapper>
              }
              panel={
                <TablePanelWrapper>
                  <FormInputGroupItem>
                    <FormTextField label="Street Number" name="address_street_no" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Street Name" name="address_street_name" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Building Name" name="address_building_name" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Floor" name="address_floor" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Room" name="address_room" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Department" name="address_department" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Postbox" name="address_postbox" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="City Location" name="address_city_location" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="City" name="address_city" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Region" name="address_region" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="District" name="address_district" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Zipcode" name="address_postcode" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSearchSelect label="Country" name="address_country_code" options={COUNTRIES_LIST} />
                  </FormInputGroupItem>
                </TablePanelWrapper>
              }
            />
            <AccordionTableItem
              style={{ marginTop: '20px' }}
              setIsExpanded={(val) => {
                setIsFreeFormExpanded(val)
              }}
              expandPanel={isFreeFormExpanded}
              button={
                <TableButtonWrapper>
                  <TableRowWrapper isExpanded={isFreeFormExpanded}>Free Form Address</TableRowWrapper>
                </TableButtonWrapper>
              }
              panel={
                <TablePanelWrapper>
                  <FormInputGroupItem>
                    <FormTextArea label="Address Line" name="address_line" />
                  </FormInputGroupItem>
                </TablePanelWrapper>
              }
            />
            <AccordionTableItem
              style={{ marginTop: '20px' }}
              setIsExpanded={(val) => {
                setIsNationalIDExpanded(val)
              }}
              expandPanel={isNationalIDExpanded}
              button={
                <TableButtonWrapper>
                  <TableRowWrapper isExpanded={isNationalIDExpanded}>National ID</TableRowWrapper>
                </TableButtonWrapper>
              }
              panel={
                <TablePanelWrapper>
                  <FormInputGroupItem>
                    <FormSelect
                      label="National ID Type"
                      name="national_id_type_code"
                      options={NATIONAL_ID_TYPE_OPTIONS}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Legal Entity Identifier" name="national_id" />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSelect
                      label="National ID Country Issuer"
                      name="national_id_issue_country"
                      options={COUNTRIES_LIST}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField
                      label="National ID Registration Authority"
                      name="national_id_registration_authority"
                    />
                  </FormInputGroupItem>
                </TablePanelWrapper>
              }
            />
          </FormContainer>

          <FormFooter>
            <Button type="button" onClick={triggerSubmit}>
              {isCreateRecipient ? 'Create Entity Recipient' : 'Save Entity Recipient'}
            </Button>
          </FormFooter>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
EntityRecipientForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
EntityRecipientForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedRecipient: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsRecordUpdated: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setCreatedRecipient: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityRecipientForm)
