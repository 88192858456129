/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// Common
import { useOutsideAlerter } from 'common/utils/outsideAlerter'

// Assets
import ArrowDownIcon from 'assets/images/arrowDown'

// Components
import { Button } from 'views/components'

// Styled Elements
import { ButtonWrapper, ButtonContainer, ButtonColContainer, ButtonSeperator } from './ButtonSplitSelect.elements'
import { useState } from 'react'

function ButtonSplitSelect(props) {
  // Destructure
  const { type, value, onClick, variant, fullWidth, disabled, options, children } = props

  // Hooks
  const [isExpanded, setIsExpanded] = useState(false)

  // Refs
  const btnRef = useRef()

  // utils
  useOutsideAlerter(btnRef, setIsExpanded)

  return (
    <ButtonWrapper ref={btnRef}>
      <ButtonContainer>
        <Button
          type={type}
          size="small"
          onClick={onClick}
          variant={variant}
          fullWidth={fullWidth}
          disabled={disabled}
          value={value}
        >
          {children && children}
        </Button>
        <Button
          type="button"
          size="small"
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          <ArrowDownIcon />
        </Button>
      </ButtonContainer>
      {isExpanded && options && (
        <ButtonColContainer>
          {options.map((item, i) => (
            <>
              <ButtonSeperator />
              <Button
                color="tertiary"
                size="small"
                key={i}
                onClick={() => {
                  setIsExpanded(!isExpanded)
                  item.onClick()
                }}
                value={item.value}
                endIcon={item.endIcon}
              />
            </>
          ))}
        </ButtonColContainer>
      )}
    </ButtonWrapper>
  )
}

// Default Props
ButtonSplitSelect.defaultProps = {
  onClick: () => {},
  type: 'primary',
  value: '',
  variant: 'contained',
  fullWidth: false,
  disabled: false,
  options: [],
}

// Proptypes Validation
ButtonSplitSelect.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  options: PropTypes.arrayOf({
    value: PropTypes.string,
    onClick: PropTypes.func,
    endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  }),
}

export default ButtonSplitSelect
