import { useState } from 'react'
import { useMutation } from 'react-query'

// Services
import { GetAlertsBySeverity } from 'services/api/Alerts'

export default () => {
  // States
  const [alertsBySeverityData, setAlertsBySeverityData] = useState({ })
  const {
    mutate: getAlertsBySeverity,
    error: alertsBySeverityError,
    isLoading: isAlertsBySeverityLoading,
    isSuccess: isAlertsBySeveritySuccess,
    isError: isAlertsBySeverityError,
  } = useMutation(GetAlertsBySeverity, {
    onSuccess: (data) => {
      formatData(data)
    },
  })

  // Functions
  const formatData = (data) => {
    let finalData = {
      uiData: [],
      chartData: {
        labels: ['High', 'Medium', 'Low'],
        datasets: [
          {
            backgroundColor: ['#F45C65cc', '#FFB502cc', '#89CF82cc'],
            data: [0, 0, 0],
          },
        ]
      }
    }
    const formattedData = [
      {
        alert_count: 0,
        severity: 'High',
        percent: 0
      },
      {
        alert_count: 0,
        severity: 'Medium',
        percent: 0
      },
      {
        alert_count: 0,
        severity: 'Low',
        percent: 0
      }
    ]
    let totalCount = 0

    data.forEach((item) => {
      if (item) totalCount += item.alert_count
    })

    data.forEach((item) => {
      formattedData.forEach((alert, i) => {
        if (item.severity === alert.severity) {
          const currentItem = {
            ...item,
            percent: Math.round((item.alert_count / totalCount) * 100)
          }
          formattedData[i] = currentItem
          finalData.chartData.datasets[0].data[i] = item.alert_count
        }
      })
    })
    finalData = { ...finalData, uiData: formattedData }

    setAlertsBySeverityData(finalData)
  }
  return {
    getAlertsBySeverity,
    alertsBySeverityData,
    alertsBySeverityError,
    isAlertsBySeverityLoading,
    isAlertsBySeveritySuccess,
    isAlertsBySeverityError,
  }
}
