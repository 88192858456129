import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAllTransfers, useGetAddressChains } from 'core/hooks/api'

// Styled Elements
import { TravelRuleTableWrapper } from './TravelRuleTable.elements'

// Views
import { Button, AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

// Layouts
import { TravelRuleTableItem, TravelRuleTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function TravelRuleTable(props) {
  // Destructure
  const { actions, filters, ui, address } = props
  const { addressChains } = address
  const { travelRuleFilters } = filters
  const { isPageTableUpdated } = ui
  const {
    setAddressChains,
    setIsTransferDrawerOpen,
    setTravelRuleFilters,
    setIsPageTableUpdated,
    toggleTransferReportModal,
  } = actions

  // Hooks
  const [transfersData, setTransfersData] = useState({ items: [], total: 0 })
  const { getAllTransfers, allTransferData, isAllTransferLoading } = useFetchAllTransfers()
  const { getAddressChains, getAddressChainsData } = useGetAddressChains()

  // State
  const [isTransferLoading, setIsTransferLoading] = useState(false)

  // Functions
  const fetchData = async () => {
    getAllTransfers(travelRuleFilters)
  }
  const handleOnTransferSearch = (data) => {
    if (!data) {
      return getAllTransfers(travelRuleFilters)
    }
    setTransfersData({ items: data, total: 0 })
  }
  function handleAddressChains() {
    if (!getAddressChainsData) return
    // !Idk why but this is causing some bugs but we need this to optimize the process
    // if (addressChains && defaultAddressProvider) return
    const newAddressChains = {}
    const newDefaultAddressProvider = {}
    getAddressChainsData.forEach((item) => {
      // Destructure with default values
      const {
        data_type = '',
        provider_code = '',
        symbol = '',
        is_user_default = false,
        integration_id = '',
      } = item || {}

      // Initialize the data_type and provider_code if they don't exist
      if (!newAddressChains[data_type]) newAddressChains[data_type] = {}
      if (!newAddressChains[data_type][provider_code]) newAddressChains[data_type][provider_code] = {}
      // Insert the item directly
      newAddressChains[data_type][provider_code][symbol] = item
      // Handle the default address provider
      if (is_user_default && !newDefaultAddressProvider[data_type]) {
        newDefaultAddressProvider[data_type] = { provider_code, integration_id, data_type }
      }
    })

    // Sorting by symbol using .sort()
    Object.keys(newAddressChains).forEach((data_type) => {
      Object.keys(newAddressChains[data_type]).forEach((provider_code) => {
        // Get the symbols and sort them
        const symbols = Object.keys(newAddressChains[data_type][provider_code])
        const sortedSymbols = symbols.sort() // Sort the symbols
        // Create a new object to store sorted entries
        const sortedPartner = {}
        sortedSymbols.forEach((symbol) => {
          sortedPartner[symbol] = newAddressChains[data_type][provider_code][symbol]
        })
        // Replace the unsorted partner object with the sorted one
        newAddressChains[data_type][provider_code] = sortedPartner
      })
    })
    setAddressChains(newAddressChains)
  }

  // UseEffects
  useEffect(() => {
    if (!addressChains) getAddressChains()
  }, [])
  useEffect(() => handleAddressChains(), [getAddressChainsData])
  useEffect(() => fetchData(), [travelRuleFilters])
  useEffect(() => allTransferData && setTransfersData(allTransferData), [allTransferData])
  useEffect(() => {
    if (isPageTableUpdated) {
      fetchData()
      setIsPageTableUpdated(false)
    }
  }, [isPageTableUpdated])

  return (
    <TravelRuleTableWrapper>
      <AccordionTable
        minWidth={700}
        totalItems={transfersData.total}
        tableFilters={travelRuleFilters}
        setTableFilters={setTravelRuleFilters}
        filterComponents={
          <TravelRuleTableFilter
            setIsTransferLoading={setIsTransferLoading}
            onTransferSearch={handleOnTransferSearch}
          />
        }
        headerOptions={
          <>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                toggleTransferReportModal()
              }}
            >
              Report
            </Button>
            <Button
              size="small"
              variant="outlined"
              startIcon={<PlusWhiteIcon />}
              onClick={() => {
                setIsTransferDrawerOpen(true)
              }}
            >
              Transfer
            </Button>
          </>
        }
      >
        {transfersData.items.length > 0 &&
          !isAllTransferLoading &&
          !isTransferLoading &&
          transfersData.items.map((singleData) => (
            <TravelRuleTableItem key={singleData.transfer_id} data={singleData} />
          ))}
        {(isAllTransferLoading || isTransferLoading) && <LoadingTablePlaceholder />}
        {transfersData?.items?.length < 1 && !isAllTransferLoading && !isTransferLoading && (
          <EmptyTablePlaceholder setFilter={setTravelRuleFilters} />
        )}
      </AccordionTable>
    </TravelRuleTableWrapper>
  )
}

// Default Props
TravelRuleTable.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
TravelRuleTable.propTypes = {
  actions: PropTypes.shape({
    setAddressChains: PropTypes.func,
    setTravelRuleFilters: PropTypes.func,
    setIsTransferDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    toggleTransferReportModal: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    travelRuleFilters: PropTypes.instanceOf(Object),
  }),
  address: PropTypes.shape({
    addressChains: PropTypes.shape({}),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelRuleTable)
