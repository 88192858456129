import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'

// Styled Elements
import { BarChartWrapper } from './BarChart.elements'

function BarChart(props) {
  // Destructure
  const { data, width, height, options, ...rest } = props

  return (
    <BarChartWrapper>
      <Bar data={data} width={width} height={height} options={options} {...rest} />
    </BarChartWrapper>
  )
}

// Default Props
BarChart.defaultProps = {
  data: {},
  width: 200,
  height: 90,
  options: {},
}

// Proptypes Validation
BarChart.propTypes = {
  data: PropTypes.shape({ root: PropTypes.string }),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.shape({ root: PropTypes.string }),
}

export default BarChart
