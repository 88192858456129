import styled from 'styled-components'

export const NumericTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: inherit;
  font-size: inherit;
`

export const NumericCurrencyText = styled.span`
  margin-right: 5px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 10px;
  opacity: 0.9;

  // Conditional Properties
  ${({ withCurrency }) => !withCurrency && 'display: none;'}
`
