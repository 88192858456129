import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`
export const BarBackdrop = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 10px;
  width: 100%;
  border-radius: 15px;
`
export const InnerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.white}`};
  height: 85px;
  width: 85px;
  border-radius: 100%;
  border: 5px solid ${({ theme }) => theme.colors.white};
`
export const Backdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.darkGray}20`};
  color: ${({ theme }) => theme.colors.darkGray};
  height: 100%;
  width: 100%;
  border-radius: 100%;
`
export const BarLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 110px;
  border-radius: 100% !important;
  background: ${({ theme, deg }) =>
    `conic-gradient(${theme.colors.yellowGreen} ${deg}deg, ${theme.colors.darkGray}20 0deg)`};
  border-radius: 15px;
`
