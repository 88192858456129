import styled from 'styled-components'

export const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 8px 20px;

  font-weight: 300;
  font-size: 16px;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.blue : theme.colors.darkGray)};

  transition: 0.3s;
  cursor: pointer;

  & svg {
    max-width: 20px;
  }
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  & svg > ellipse {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
  & svg > circle {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  & svg > rect {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
  }
  &:first-child {
    border-radius: 7px 7px 0 0;
  }
  &:last-child {
    border-radius: 0 0 7px 7px;
  }
  &:only-child {
    border-radius: 7px;
  }

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.lightGray};
  }

  &:focus {
    background-color: unset;
    color: inherit;
  }

  cursor: ${({ disabled }) => disabled && 'not-allowed'};

  & p {
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.darkGray)};
  }
  & a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;

    padding: 5px 0px;
    font-size: 15px;
    font-weight: 400;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.darkGray)};

    & > svg {
      height: 12px;
      width: 12px;
    }
  }
`
