import styled from 'styled-components'

export const FormWrapper = styled.div`
  & button {
    min-height: 56px;
    margin: 20px 0px 0px 0px;
  }
`

export const FormInputGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & input {
    background-color: ${({ theme }) => theme.colors.white};
  }
  & label {
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 3px 10px;
  }
`
export const FormInputTimezoneGroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div > div > div {
    background-color: ${({ theme }) => theme.colors.white};
    min-height: 58px;
  }
  & label {
    background-color: ${({ theme }) => theme.colors.lightGray};
    padding: 3px 10px;
  }

  & > div > div :nth-child(1) {
    max-width: none;
  }
`
// export const FormInputGroupItem = styled.div`
//   margin: 0px 0px 20px 0px;
// `
export const FormGroupWrapper = styled.div`
  display: grid;
  gap: 15px;
  margin-bottom: 40px;
`
export const FormTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGray};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 15px 20px;
  border-radius: 7px;
`
export const ErrorTitle = styled.h4`
  font-size: 15px;
  font-weight: 600;
  margin: 20px 0 0 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 15px 20px;
  border-radius: 7px;
`
export const ButtonWrapper = styled.a``
