import { useMutation } from 'react-query'

// Services
import { GetAllAlerts } from 'services/api/Alerts'

export default () => {
  const {
    mutate: getAllAlerts,
    data: allAlertsData,
    error: allAlertsError,
    isLoading: isAllAlertsLoading,
    isSuccess: isAllAlertsSuccess,
    isError: isAllAlertsError,
  } = useMutation(GetAllAlerts)

  return {
    getAllAlerts,
    allAlertsData,
    allAlertsError,
    isAllAlertsLoading,
    isAllAlertsSuccess,
    isAllAlertsError,
  }
}
