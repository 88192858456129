import { useMutation } from 'react-query'

// Services
import { GetCaseAddresses } from 'services/api/Cases'

export default () => {
  const {
    mutate: getCaseAddresses,
    data: caseAddressesData,
    error: caseAddressesError,
    isLoading: isCaseAddressesLoading,
    isSuccess: isCaseAddressesSuccess,
    isError: isCaseAddressesError,
  } = useMutation(GetCaseAddresses)

  return {
    getCaseAddresses,
    caseAddressesData,
    caseAddressesError,
    isCaseAddressesLoading,
    isCaseAddressesSuccess,
    isCaseAddressesError,
  }
}
