import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { CardWrapper, CardHeader, CardHeaderIcon, CardHeaderTitle, CardBody, CardFooter } from './Card.elements'

function Card(props) {
  // Destructure
  const { children, titleIcon, title, footer } = props

  return (
    <CardWrapper>
      <CardHeader>
        <CardHeaderIcon>{titleIcon}</CardHeaderIcon>
        <CardHeaderTitle>{title}</CardHeaderTitle>
      </CardHeader>
      <CardBody>{children}</CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </CardWrapper>
  )
}

// Default Props
Card.defaultProps = {
  title: '',
  titleIcon: '',
  footer: '',
  children: '',
}

// Proptypes Validation
Card.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Card
