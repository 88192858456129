import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useUpdateProfile } from 'core/hooks/api'
import { useLocalStorage } from 'core/hooks/storage'

// Styled Elements
import {
  FormInputGroupWrapper,
  FormInputGroupItem,
  ProfilePersonalInformationFormWrapper,
} from './ProfilePersonalInformationForm.elements'

// Views
import { Form, FormTextField } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ProfilePersonalInformationForm(props) {
  // Destructure
  const { formRef, userProfileData, actions } = props
  const { FullName, User_Role, User_TimeZone, User_ID, User_Name, Business_Name } = userProfileData

  // Store Actions
  const { setShowHeaderLoader, setIsProfileUpdated } = actions

  // Hooks
  const { updateProfile, updateProfileData, isUpdateProfileLoading } = useUpdateProfile()
  const [userCredentials, setUserCredentials] = useLocalStorage('userCredentials', {})
  const [savedFullname, setSavedFullname] = useState('')

  // Variables
  const initialValues = {
    fullName: FullName,
    role: User_Role,
    timezone: User_TimeZone,
  }

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
  })

  // Functions
  const handleOnSubmit = (values) => {
    const { fullName, role, timezone } = values

    const payload = {
      business_name: Business_Name,
      fullname: fullName,
      user_email: User_Name,
      user_id: User_ID,
      user_role: role,
      user_timezone: timezone,
    }
    setSavedFullname(fullName)
    updateProfile(payload)
  }

  // useEffects
  useEffect(() => {
    if (updateProfileData) {
      setUserCredentials({
        ...userCredentials,
        FullName: savedFullname,
      })
      setIsProfileUpdated(true)
    }
  }, [updateProfileData])

  useEffect(() => (isUpdateProfileLoading ? setShowHeaderLoader(true) : setShowHeaderLoader(false))
  , [isUpdateProfileLoading])
  return (
    <ProfilePersonalInformationFormWrapper>
      <Form
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormTextField label="Full Name" type="text" name="fullName" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Role" type="text" name="role" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Timezone" type="text" name="timezone" disabled />
          </FormInputGroupItem>
        </FormInputGroupWrapper>
      </Form>
    </ProfilePersonalInformationFormWrapper>
  )
}

// Default Props
ProfilePersonalInformationForm.defaultProps = {
  formRef: createRef(),
  userProfileData: {},
  actions: {},
}

// Proptypes Validation
ProfilePersonalInformationForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  userProfileData: PropTypes.shape({
    FullName: PropTypes.string,
    User_Role: PropTypes.string,
    User_TimeZone: PropTypes.string,
    User_ID: PropTypes.string,
    User_Name: PropTypes.string,
    Business_Name: PropTypes.string,
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsProfileUpdated: PropTypes.func
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePersonalInformationForm)
