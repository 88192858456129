/* eslint-disable max-len */
import React from 'react'

export default function DogecoinMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.051 172.385V234.77H137.026H120L120.276 250.491L120.551 266.212L137.301 266.486L154.051 266.759V328.621V390.483L201.801 390.733C272.669 391.103 276.491 390.85 297.505 384.38C341.393 370.868 369.101 337.801 378.209 288.07C381.348 270.932 381.368 234.642 378.247 217.624C372.973 188.864 362.876 167.441 346.31 149.864C325.494 127.777 297.367 115.155 259.84 111.06C254.358 110.461 229.212 110 202.09 110H154.051V172.385ZM266.551 164.845C277.163 167.717 291.145 174.593 298.527 180.57C310.986 190.659 320.575 206.365 325.222 224.289C327.315 232.364 327.544 235.396 327.486 254.234C327.429 272.426 327.139 276.211 325.29 282.88C317.629 310.508 300.874 327.776 274.051 335.688C267.231 337.701 263.854 337.94 236.801 338.328L207.051 338.757V302.734V266.711H236.051H265.051V250.741V234.77H236.051H207.051V198.228V161.687L232.801 162.184C255.079 162.614 259.63 162.972 266.551 164.845Z"
        fill="#00CACD"
      />
    </svg>
  )
}
