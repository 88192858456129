import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: right;
`
export const Title = styled.h2`
  margin: 0;
  padding: 10px 0 0 0;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`
export const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  gap: 5px;
  border: 0px solid ${({ theme }) => theme.colors.lightGray};
`
export const RowWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 10px;
  width: 100%;
`
export const BlockContainer = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  & > div:last-child {
    border: none;
  }
`
export const InfoTitle = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.darkGray};

  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
`
export const IconContainer = styled.div`
  cursor: pointer;
  & > svg > path:first-child {
    fill: ${({ theme, active }) => (active ? theme.colors.blue : theme.colors.darkGray)};
    fill-opacity: ${({ active }) => (active ? 1 : 0.3)};
  }
`

export const ExternalLink = styled(NavLink)`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: right;
  display: flex;
  align-items: center;

  & svg {
    height: 100%;
    max-height: 15px;
  }
`
