export default (args) => {
  if ((args >= 0 && args < 6) || args === 'Low') {
    return '#89CF82'
  }
  if ((args > 5 && args < 8) || args === 'Medium') {
    return '#FFB502'
  }
  if ((args > 7 && args < 11) || args === 'High') {
    return '#F45C65'
  }
}
