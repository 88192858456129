import { useMutation } from 'react-query'

// Services
import { GetAddressVerification } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddressVerification,
    data: getAddressVerificationData,
    error: getAddressVerificationError,
    isLoading: isGetAddressVerificationLoading,
    isSuccess: isGetAddressVerificationSuccess,
    isError: isGetAddressVerificationError,
  } = useMutation(GetAddressVerification)

  return {
    getAddressVerification,
    getAddressVerificationData,
    getAddressVerificationError,
    isGetAddressVerificationLoading,
    isGetAddressVerificationSuccess,
    isGetAddressVerificationError,
  }
}
