import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  initialNodeData: {
    type: '',
    hash: '',
    chain: '',
    asset: '',
    value: '',
    valueUsd: '',
    riskScore: '',
    color: '',
    flag: '',
    lastActivity: '',
    children: [],
  },
}

const store = createSlice({
  name: 'investigation',
  initialState,
  reducers: {
    setInitialNodeData: (state, { payload }) => {
      state.initialNodeData = payload
    },
  },
})

export default store
