import styled from 'styled-components'

export const FormInputWrapper = styled.div`
  position: relative;
  margin-top: ${({ label }) => label && '-5px;'};
`

export const FormLabel = styled.label`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  padding: 0 8px;
  transform: translate(15px, 15px);

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`

export const FormTextAreaInput = styled.textarea`
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  font-weight: 400;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGray};

  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  background-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.colors.lightGray)};

  ${({ resize }) => !resize && 'resize: none;'}

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`

export const FormErrorMessage = styled.div`
  position: absolute;
  bottom: -20px;
  left: 5px;
  color: ${({ theme }) => theme.colors.brightRed};
  font-size: 14px;
`
