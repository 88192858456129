import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalBodyWrapper = styled.div`
  font-size: 14.5px;
  font-weight: 400;
  padding: 0 20px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
`
export const Title = styled.div`
  font-size: 24px;
`
export const Text = styled.div`
  font-size: 14px;
`

export const OptionWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 20px 0;
  gap: 20px;
`
export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  padding: 10px 15px;
  border-radius: 12px;
  border: 2px solid ${({ theme, active }) => (active ? theme.colors.blue : theme.colors.white)};
  width: 100%;

  transition: 0.3s;
  cursor: pointer;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.blue};
  }
`
export const OptionIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    width: 30px;
    height: 30px;

    & path {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }
  }
`
export const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
export const OptionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`
export const OptionText = styled.div`
  font-size: 12px;
  font-weight: 300;
`
