import React from 'react'
import PropTypes from 'prop-types'
import ReactEcharts from 'echarts-for-react'

// Styled Elements
import { NodeChartWrapper } from './NodeChart.elements'

function NodeChart(props) {
  // Destructure
  const { options, style, onEvents, chartRef } = props

  return (
    <NodeChartWrapper>
      <ReactEcharts ref={chartRef} style={style} option={options} onEvents={onEvents} notMerge lazyUpdate />
    </NodeChartWrapper>
  )
}

// Default Props
NodeChart.defaultProps = {
  chartRef: {},
  options: {},
  style: {},
  onEvents: {},
}

// Proptypes Validation
NodeChart.propTypes = {
  chartRef: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  style: PropTypes.instanceOf(Object),
  onEvents: PropTypes.instanceOf(Object),
}

export default NodeChart
