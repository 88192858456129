import { useMutation } from 'react-query'

// Services
import { GetAddressChains } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddressChains,
    data: getAddressChainsData,
    error: getAddressChainsError,
    isLoading: isGetAddressChainsLoading,
    isSuccess: isGetAddressChainsSuccess,
    isError: isGetAddressChainsError,
  } = useMutation(GetAddressChains)

  return {
    getAddressChains,
    getAddressChainsData,
    getAddressChainsError,
    isGetAddressChainsLoading,
    isGetAddressChainsSuccess,
    isGetAddressChainsError,
  }
}
