import { useMutation } from 'react-query'

// Services
import { UpdateTransfer } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: updateTransfer,
    data: transferUpdateData,
    error: transferUpdateError,
    isLoading: isTransferUpdateLoading,
    isSuccess: isTransferUpdateSuccess,
    isError: isTransferUpdateError,
  } = useMutation(UpdateTransfer)

  return {
    updateTransfer,
    transferUpdateData,
    transferUpdateError,
    isTransferUpdateLoading,
    isTransferUpdateSuccess,
    isTransferUpdateError,
  }
}
