import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import buildCalendarDays from './helpers/buildCalendarDays'
import moment from 'moment'

// Assets
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'assets/images'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Utils
import { useOutsideAlerter } from 'common/utils/outsideAlerter'

// Components
import Button from '../Buttons/Button'

// Styled Components
import {
  DateRangePickerWrapper,
  DateRangePickerHeader,
  DateRangePickerTitle,
  DateRangePickerDate,

  // Picker
  DatePickerWrapper,
  DatePickerWrapperHeader,
  DateChanger,
  DateChangerWrapper,
  DateChangerContainer,
  DateContentWrapper,
  DateGroupWrapper,
  DateGroupTitle,
  DateWeekWrapper,
  DateDayWrapper,
  DateDayContainer,

  // Footer
  DatePickerWrapperFooter,
  FooterGroupWrapper,
} from './CustomDateRangePicker.elements'

function CustomDateRangePicker(props) {
  // props
  const { onApply, start, end, simplifiedDate, ...rest } = props

  const [open, setOpen] = useState(false)
  const [days, setDays] = useState([])
  const [days2, setDays2] = useState([])
  const [currDay, setCurrDay] = useState(moment())
  const [currDay2, setCurrDay2] = useState(moment().subtract(1, 'month'))
  const [selectedStart, setSelectedStart] = useState(start ? moment(start) : moment().subtract(1, 'month'))
  const [selectedEnd, setSelectedEnd] = useState(end ? moment(end) : moment())

  const pickerRef = useRef()

  // functions
  const handleOpen = () => {
    setOpen((prevValue) => !prevValue)
  }
  const handleCancel = () => {
    if (start && end) {
      setSelectedStart(moment(start))
      setSelectedEnd(moment(end))
    } else {
      setSelectedStart(moment().subtract(1, 'month'))
      setSelectedEnd(moment())
    }
    setOpen(false)
  }
  const handleApply = () => {
    if (selectedStart && selectedEnd) {
      onApply({
        start: selectedStart.format(DASHED_DATE_FORMAT),
        end: selectedEnd.format(DASHED_DATE_FORMAT),
      })
      setOpen(false)
    }
  }

  const moveBack = () => {
    setCurrDay(currDay.clone().subtract(1, 'month'))
    setCurrDay2(currDay2.clone().subtract(1, 'month'))
  }
  const moveNext = () => {
    if (!currDay.isAfter(moment().subtract(1, 'month'), 'month')) {
      setCurrDay(currDay.clone().add(1, 'month'))
      setCurrDay2(currDay2.clone().add(1, 'month'))
    }
  }
  const getYears = (back) => {
    const year = new Date().getFullYear()
    let reversedYears = []
    Array.from({ length: back }, (v, i) => {
      reversedYears = [{ value: year - back + i + 1, label: year - back + i + 1 }, ...reversedYears]
    })
    return reversedYears
  }

  const handleMonthChange = (value) => {
    setCurrDay((prevValue) => prevValue.clone().set('month', value))
    setCurrDay2((prevValue) => prevValue.clone().set('month', value).subtract(1, 'month'))
  }
  const handleYearChange = (value) => {
    setCurrDay((prevValue) => prevValue.clone().set('year', value))
    setCurrDay2((prevValue) => prevValue.clone().set('year', value))
  }
  const handleClick = (value) => {
    if (!selectedStart) {
      return setSelectedStart(value)
    }
    if (selectedStart) {
      if (value.isSame(selectedStart)) {
        return
      }
      if (!selectedEnd) {
        if (value.isAfter(selectedStart)) {
          return setSelectedEnd(value)
        }
        setSelectedStart(value)
      }
      if (selectedEnd) {
        setSelectedStart(value)
        setSelectedEnd()
      }
    }
  }
  // UseEffect
  useEffect(() => {
    setDays(buildCalendarDays(currDay))
    setDays2(buildCalendarDays(currDay2))
  }, [currDay, currDay2])
  useOutsideAlerter(pickerRef, handleCancel)

  return (
    <DateRangePickerWrapper {...rest} ref={pickerRef}>
      <DateRangePickerHeader onClick={handleOpen}>
        <DateRangePickerTitle>
          From:{' '}
          <DateRangePickerDate>
            {selectedStart &&
              (simplifiedDate ? selectedStart.format('MMM. DD, YYYY') : selectedStart.format('MMMM DD YYYY'))}
          </DateRangePickerDate>
        </DateRangePickerTitle>
        <DateRangePickerTitle>
          To:{' '}
          <DateRangePickerDate>
            {selectedEnd && (simplifiedDate ? selectedEnd.format('MMM. DD, YYYY') : selectedEnd.format('MMMM DD YYYY'))}
          </DateRangePickerDate>
        </DateRangePickerTitle>
      </DateRangePickerHeader>

      <DatePickerWrapper open={open}>
        <DatePickerWrapperHeader>
          <Button onClick={moveBack} variant="outlined" startIcon={<ArrowLeftCircleIcon />} />
          <DateChangerWrapper>
            <DateChangerContainer>
              <DateChanger
                options={moment.months().map((item) => ({ value: item, label: item }))}
                onChange={handleMonthChange}
                value={currDay.format('MMMM')}
                placeholder={currDay.format('MMMM')}
              />
            </DateChangerContainer>
            <DateChangerContainer>
              <DateChanger
                options={getYears(20)}
                onChange={handleYearChange}
                value={currDay.format('YYYY')}
                placeholder={currDay.format('YYYY')}
              />
            </DateChangerContainer>
          </DateChangerWrapper>
          <Button
            onClick={moveNext}
            disabled={currDay.isAfter(moment().subtract(1, 'month'), 'month')}
            variant="outlined"
            startIcon={<ArrowRightCircleIcon />}
          />
        </DatePickerWrapperHeader>

        <DateContentWrapper>
          <DateGroupWrapper>
            <DateGroupTitle>{currDay2.format('MMMM YYYY')}</DateGroupTitle>
            <DateWeekWrapper>
              {days2.map((week) => (
                <DateDayWrapper key={week} style={{ display: 'flex', gap: '20px' }}>
                  {week.map((day) => (
                    <DateDayContainer
                      key={day.format('MM/DD')}
                      onClick={() => !day.isAfter(moment(), 'day') && handleClick(day)}
                      forbidden={day.isAfter(moment(), 'day')}
                      disabled={day.isAfter(moment(), 'day') || !day.isSame(currDay2, 'month')}
                      active={
                        day.isSame(selectedStart) ||
                        (!!selectedEnd &&
                          !!selectedStart &&
                          day.isSameOrBefore(selectedEnd) &&
                          day.isSameOrAfter(selectedStart))
                      }
                    >
                      {day.format('D')}
                    </DateDayContainer>
                  ))}
                </DateDayWrapper>
              ))}
            </DateWeekWrapper>
          </DateGroupWrapper>

          <DateGroupWrapper>
            <DateGroupTitle>{currDay.format('MMMM YYYY')}</DateGroupTitle>
            <DateWeekWrapper>
              {days.map((week) => (
                <DateDayWrapper key={week} style={{ display: 'flex', gap: '20px' }}>
                  {week.map((day) => (
                    <DateDayContainer
                      key={day.format('MM/DD')}
                      onClick={() => !day.isAfter(moment(), 'day') && handleClick(day)}
                      forbidden={day.isAfter(moment(), 'day')}
                      disabled={day.isAfter(moment(), 'day') || !day.isSame(currDay, 'month')}
                      active={
                        day.isSame(selectedStart) ||
                        (!!selectedEnd &&
                          !!selectedStart &&
                          day.isSameOrBefore(selectedEnd) &&
                          day.isSameOrAfter(selectedStart))
                      }
                    >
                      {day.format('D')}
                    </DateDayContainer>
                  ))}
                </DateDayWrapper>
              ))}
            </DateWeekWrapper>
          </DateGroupWrapper>
        </DateContentWrapper>

        <DatePickerWrapperFooter>
          <FooterGroupWrapper>
            <DateRangePickerTitle>From: {selectedStart && selectedStart.format('MMMM DD YYYY')}</DateRangePickerTitle>
            <DateRangePickerTitle>To: {selectedEnd && selectedEnd.format('MMMM DD YYYY')}</DateRangePickerTitle>
          </FooterGroupWrapper>
          <FooterGroupWrapper>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button id="apply" onClick={handleApply} disabled={!selectedStart || !selectedEnd}>
              Apply
            </Button>
          </FooterGroupWrapper>
        </DatePickerWrapperFooter>
      </DatePickerWrapper>
    </DateRangePickerWrapper>
  )
}

// Default Props
CustomDateRangePicker.defaultProps = {
  onApply: () => {},
  start: '',
  end: '',
  simplifiedDate: false,
}

// Proptypes Validation
CustomDateRangePicker.propTypes = {
  onApply: PropTypes.func,
  start: PropTypes.string,
  end: PropTypes.string,
  simplifiedDate: PropTypes.bool,
}

export default CustomDateRangePicker
