import { useMutation } from 'react-query'

// Services
import { GetCaseAlerts } from 'services/api/Cases'

export default () => {
  const {
    mutate: getCaseAlerts,
    data: caseAlertsData,
    error: caseAlertsError,
    isLoading: isCaseAlertsLoading,
    isSuccess: isCaseAlertsSuccess,
    isError: isCaseAlertsError,
  } = useMutation(GetCaseAlerts)

  return {
    getCaseAlerts,
    caseAlertsData,
    caseAlertsError,
    isCaseAlertsLoading,
    isCaseAlertsSuccess,
    isCaseAlertsError,
  }
}
