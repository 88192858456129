import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

// Assets
import FolderIcon from 'assets/images/folder.svg'
import AlertIcon from 'assets/images/alert.svg'
import KeyWhiteIcon from 'assets/images/icon-2-white.svg'
import TransactionIcon from 'assets/images/transaction.svg'
import UserCircledWhiteIcon from 'assets/images/user-icon-white.svg'
import LogoutWhiteIcon from 'assets/images/log-out-white.svg'
import TravelRuleIcon from 'assets/images/chevrons-right.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useLogout } from 'core/hooks/api'

// Styled Elements
import {
  AppSidebarDrawerWrapper,
  AppSidebarList,
  AppSidebarListItem,
  AppSidebarListIcon,
  AppSidebarListLink,
} from './AppSidebarDrawer.elements'

// Views
import { Drawer, Image } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AppSidebarDrawer(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isAppSidebarDrawerOpen } = ui

  // Store Actions
  const { closeAppSidebarDrawer } = actions

  // Hooks
  const { logoutUser } = useLogout()

  // Functions
  const handleCloseDrawer = () => {
    closeAppSidebarDrawer()
  }

  const handleClickSidebarNav = () => {
    closeAppSidebarDrawer()
  }

  const handleClickLogout = () => {
    logoutUser()
  }

  return (
    <Drawer open={isAppSidebarDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <AppSidebarDrawerWrapper>
        <AppSidebarList>
          <AppSidebarListItem>
            <AppSidebarListLink to="/" onClick={handleClickSidebarNav}>
              <AppSidebarListIcon>
                <Image width={25} height={25} src={FolderIcon} />
              </AppSidebarListIcon>
              Cases
            </AppSidebarListLink>
          </AppSidebarListItem>
          <AppSidebarListItem>
            <AppSidebarListLink to="/whitelisting" onClick={handleClickSidebarNav}>
              <AppSidebarListIcon>
                <Image width={25} height={25} src={KeyWhiteIcon} />
              </AppSidebarListIcon>
              Addresses
            </AppSidebarListLink>
          </AppSidebarListItem>
          <AppSidebarListItem>
            <AppSidebarListLink to="/monitoring" onClick={handleClickSidebarNav}>
              <AppSidebarListIcon>
                <Image width={25} height={25} src={TransactionIcon} />
              </AppSidebarListIcon>
              Transactions
            </AppSidebarListLink>
          </AppSidebarListItem>
          <AppSidebarListItem>
            <AppSidebarListLink to="/alerts" onClick={handleClickSidebarNav}>
              <AppSidebarListIcon>
                <Image width={25} height={25} src={AlertIcon} />
              </AppSidebarListIcon>
              Alert Management
            </AppSidebarListLink>
          </AppSidebarListItem>
          <AppSidebarListItem>
            <AppSidebarListLink to="/travelrule" onClick={handleClickSidebarNav}>
              <AppSidebarListIcon>
                <Image width={25} height={25} src={TravelRuleIcon} />
              </AppSidebarListIcon>
              Travel Rule
            </AppSidebarListLink>
          </AppSidebarListItem>
        </AppSidebarList>
        <AppSidebarList>
          <AppSidebarListItem>
            <AppSidebarListLink to="/user-profile" onClick={handleClickSidebarNav}>
              <AppSidebarListIcon>
                <Image width={25} height={25} src={UserCircledWhiteIcon} />
              </AppSidebarListIcon>
              Profile
            </AppSidebarListLink>
          </AppSidebarListItem>
          <AppSidebarListItem>
            <AppSidebarListLink to="/" onClick={handleClickLogout}>
              <AppSidebarListIcon>
                <Image width={25} height={25} src={LogoutWhiteIcon} />
              </AppSidebarListIcon>
              Logout
            </AppSidebarListLink>
          </AppSidebarListItem>
        </AppSidebarList>
      </AppSidebarDrawerWrapper>
    </Drawer>
  )
}

// Default Props
AppSidebarDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
AppSidebarDrawer.propTypes = {
  ui: PropTypes.shape({ isAppSidebarDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ closeAppSidebarDrawer: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebarDrawer)
