/* eslint-disable max-len */
import React from 'react'

export default function ReceivedIcon() {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 26C20.4036 26 26 20.4036 26 13.5C26 6.59644 20.4036 1 13.5 1C6.59644 1 1 6.59644 1 13.5C1 20.4036 6.59644 26 13.5 26Z"
        stroke="#212123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.96566 17.0357H17.0367V9.96459" stroke="#212123" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.96604 9.96458L17.0371 17.0356" stroke="#212123" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
