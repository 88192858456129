/* eslint-disable max-len */
import React from 'react'

export default function VerticalElipsisIcon() {
  return (
    <svg width="3" height="13" viewBox="0 0 3 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="1.48911" cy="1.5" rx="1.48911" ry="1.5" fill="#727F97" />
      <ellipse cx="1.48911" cy="6.5" rx="1.48911" ry="1.5" fill="#727F97" />
      <ellipse cx="1.48911" cy="11.5" rx="1.48911" ry="1.5" fill="#727F97" />
    </svg>
  )
}
