import { useMutation } from 'react-query'

// Services
import { GetAddresses } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddresses,
    data: getAddressesData,
    error: getAddressesError,
    isLoading: isGetAddressesLoading,
    isSuccess: isGetAddressesSuccess,
    isError: isGetAddressesError,
  } = useMutation(GetAddresses)

  return {
    getAddresses,
    getAddressesData,
    getAddressesError,
    isGetAddressesLoading,
    isGetAddressesSuccess,
    isGetAddressesError,
  }
}
