import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import {
  CloseIcon,
  TravelRuleIcon,
  DirectoryIcon,
  BlockchainAnalyticsIcon,
  SettingsIcon,
  DocumentIcon,
} from 'assets/images'

// Store
import { actions } from 'core/store'

// Views
import { Modal, ModalHeader, YoutubeEmbed, LoadingTablePlaceholder } from 'views/components'

// Styled Elements
import {
  ModalBodyWrapper,
  Title,
  Text,
  OptionWrapper,
  OptionContainer,
  OptionIcon,
  OptionContent,
  OptionTitle,
  OptionText,
} from './ExploreAccountModal.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ExploreAccountModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isExploreAccountModalOpen } = ui

  // Store Actions
  const { toggleExploreAccountModalOpen } = actions

  // Local State
  const [currentOption, setCurrentOption] = useState('TravelRule')
  const [isLoading, setIsLoading] = useState(false)

  // Variables
  const IdMapping = {
    TravelRule: 'jAIoDa7RyIY',
    AccountsDirectory: 'A5XYfjs-vPI',
    BlockchainAnalytics: 'A5XYfjs-vPI',
    RestAPI: 'A5XYfjs-vPI',
    ReportingHub: 'ToPxo1H_IGg',
  }

  // Functions
  const toggleModal = () => {
    toggleExploreAccountModalOpen()
  }
  const handleClick = (value) => {
    if (isLoading) return
    if (value === 'TravelRule' || value === 'ReportingHub') setCurrentOption(value)
  }

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [currentOption])
  return (
    <Modal isOpen={isExploreAccountModalOpen} toggle={toggleModal} style={{ maxWidth: '1080px', width: '100%' }}>
      <ModalHeader toggle={toggleModal} close={<CloseIcon />} />
      <ModalBodyWrapper>
        <Title>Explore Ospree Account</Title>
        <Text>
          This comprehensive guide provides detailed information for businesses using Ospree, covering essential tasks
          across the platform modules to help you effectively manage your operations.
        </Text>
        <OptionWrapper>
          <OptionContainer active={currentOption === 'TravelRule'} onClick={() => handleClick('TravelRule')}>
            <OptionIcon>
              <TravelRuleIcon />
            </OptionIcon>
            <OptionContent>
              <OptionTitle>Travel Rule</OptionTitle>
              <OptionText>Transfer originator and beneficiary information during crypto transactions.</OptionText>
            </OptionContent>
          </OptionContainer>

          <OptionContainer
            active={currentOption === 'AccountsDirectory'}
            onClick={() => handleClick('AccountsDirectory')}
          >
            <OptionIcon>
              <DirectoryIcon />
            </OptionIcon>
            <OptionContent>
              <OptionTitle>Accounts Directory</OptionTitle>
              <OptionText>Ensure standardized data exchange between modules using unique account IDs.</OptionText>
            </OptionContent>
          </OptionContainer>

          <OptionContainer
            active={currentOption === 'BlockchainAnalytics'}
            onClick={() => handleClick('BlockchainAnalytics')}
          >
            <OptionIcon>
              <BlockchainAnalyticsIcon />
            </OptionIcon>
            <OptionContent>
              <OptionTitle>Blockchain Analytics</OptionTitle>
              <OptionText>Identify, assess, and mitigate associated financial crime risks.</OptionText>
            </OptionContent>
          </OptionContainer>

          <OptionContainer active={currentOption === 'RestAPI'} onClick={() => handleClick('RestAPI')}>
            <OptionIcon>
              <SettingsIcon />
            </OptionIcon>
            <OptionContent>
              <OptionTitle>Rest API</OptionTitle>
              <OptionText>Integrate a variety of crypto compliance modules with diverse capabilities.</OptionText>
            </OptionContent>
          </OptionContainer>

          <OptionContainer active={currentOption === 'ReportingHub'} onClick={() => handleClick('ReportingHub')}>
            <OptionIcon>
              <DocumentIcon />
            </OptionIcon>
            <OptionContent>
              <OptionTitle>Reporting Hub</OptionTitle>
              <OptionText>Generate audit-ready reports by reconciling necessary information across modules.</OptionText>
            </OptionContent>
          </OptionContainer>
        </OptionWrapper>
        {!isLoading && <YoutubeEmbed embedId={IdMapping[currentOption]} />}
      </ModalBodyWrapper>
      {isLoading && <LoadingTablePlaceholder />}
    </Modal>
  )
}

// Default Props
ExploreAccountModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
ExploreAccountModal.propTypes = {
  ui: PropTypes.shape({
    isExploreAccountModalOpen: PropTypes.bool,
    infoModalDetails: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      loading: PropTypes.bool,
    }),
  }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    toggleExploreAccountModalOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreAccountModal)
