import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

export const HeaderWrapper = styled.div.attrs(({ theme }) => ({
  display: theme.layout.header || theme.layout.header === undefined ? 'block' : 'none',
}))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;

  padding: 0 0 0 100px;
  height: fit-content;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  line-height: unset;
  font-weight: 500;

  // Themed Properties
  display: ${({ display }) => display};
`

export const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: unset;
  min-height: 60px;
  max-height: 60px;
`

export const HeaderTitle = styled.div`
  display: flex;
  gap: 10px;

  & button {
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkGray};

    padding: 0;
    background: transparent;
    border: 0px;
    & svg > path {
      stroke: ${({ theme }) => theme.colors.blue};
    }
  }
  & button:hover {
    background: transparent;
    border: 0px;
    & svg > path {
      stroke: ${({ theme }) => theme.colors.blue};
    }
  }

  font-size: 20px;
  font-weight: 600;
  padding-left: 8px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const HeaderRightNav = styled.div`
  padding: 0px;
  text-align: right;
  display: block;
`

export const HeaderRightNavList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  width: fit-content;
  list-style: none;
`

export const HeaderRightNavListItem = styled.li`
  display: inline-block;
  vertical-align: middle;
  width: fit-content;
  margin: 0px;
`

export const HeaderLogoutButton = styled.div`
  cursor: pointer;

  padding: 5px 0px;
  font-size: 15px;
  font-weight: 400;
`
export const DropdownItemText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;

  padding: 5px 0px;
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const DropdownItemDownload = styled.div`
  font-size: 15px;
  cursor: pointer;
`

export const DropdownItemLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;

  padding: 5px 0px;
  font-size: 15px;
  font-weight: 400;

  & > svg {
    height: 12px;
    width: 12px;
  }
  & > svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`

export const ProfileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px 15px 20px;
  gap: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};

  & > svg {
    height: 40px;
    width: 40px;
  }
  & > svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`
export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const ProfileFullName = styled.div`
  font-size: 20px;
  font-weight: 700;

  max-width: 170px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ProfileUserName = styled.div`
  font-size: 12px;
  font-weight: 400;
`

export const ProfileUserRole = styled.div`
  font-size: 12px;
  font-weight: 400;
`

export const HeaderSideMenuButton = styled.div`
  padding: 0px;
  text-align: right;
  display: none;

  // Media Query Properties
  /* ${({ theme }) =>
    theme.functions.mediaQuery('display', [
      'block',
      'block',
      'block',
      'block',
      'block',
      'block',
      'block',
      'block',
      'none',
    ])} */
`

export const HeaderSideMenuIcon = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 28px;
  height: 100%;
`

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 10px;

  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
  & > svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  & > svg > ellipse {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
`
export const SubHeaderWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
`

export const SubHeaderContent = styled.div`
  max-width: 1080px;
`

export const HasNotificationIcon = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  font-size: 1px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.red};
`
