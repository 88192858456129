// export const BASE_URL = 'https://api.ospree.io'
// export const BASE_URL = 'https://sandbox.ospree.io'
// export const DEMO_URL = 'https://flowapi-dev-new.ospree.io'
// TODO Switch back to original url
export const BASE_URL = 'https://flowapi-dev-new.ospree.io'
export const DEMO_URL = 'https://flowapi-dev-new.ospree.io'
export const OLD_URL = 'https://apitest2.nodestash.com'
export const LOCAL_URL = 'http://localhost:8000'
export const TRANSACTION_SERVICE_URL = 'https://service-transactions.onrender.com'
export const ADDRESS_SERVICE_URL = 'https://service-addresses.onrender.com'
//! REVERT BACK TO NEW URL BEFORE DEPLOYING TO PROD
export const ADMIN_ENDPOINTS = {
  NOTIFY_VASP: '/api/ui/users/confirm_vasp',
  REJECT_VASP: '/api/ui/users/reject_vasp',
}

export const AUTH_ENDPOINTS = {
  GET_USER: '/api/ui/users/',
  UPDATE_USER: '/api/ui/users/profile/',
  UPDATE_USER_PASSWORD: '/api/ui/users/password',
  LOGIN: '/api/ui/users/login',
  LOGIN_TWOFA: '/api/ui/users/login/',
  LOGOUT: '/api/ui/users/logout',
  RESET_PASSWORD: '/api/ui/users/password/reset',
  RESET_PASSWORD_CONFIRM: '/api/ui/users/password/reset/',
  REGISTER: '/api/ui/users/register',
  VASP_INFO: '/api/ui/directory/vasp_info',
  CONFIRM_VASP_EMAIL: '/api/ui/users/confirm_email',
  CONFIRM_VASP_USER_EMAIL: '/api/ui/users/confirm_vasp_user',
  GET_USER_AUTH: '/api/ui/users/user_auth/',
}

// Notes endpoints
export const NOTE_ENDPOINTS = {
  CREATE_NOTE: '/ui/notes',
  GET_NOTE: '/ui/notes',
}

// Address endpoints
export const ADDRESS_ENDPOINTS = {
  CREATE_OR_UPDATE_ADDRESS: '/api/ui/analytics/address',
  LINK_ADDRESS: '/api/ui/analytics/addresses',
  DELETE_ADDRESS: '/api/ui/analytics/addresses',
  GET_ADDRESSES: '/api/ui/analytics/addresses',
  GET_ADDRESS_DETAILS: '/api/ui/analytics/addresses',
  GET_ADDRESS_HISTORY: '/api/ui/analytics/addresses',
  POST_ADDRESS_REPORT: '/api/ui/analytics/addresses',
  GET_ADDRESS_VERIFICATION: '/api/ui/analytics/addresses',
  GET_ADDRESS_CHAIN_RECOMMENDATION: '/api/ui/analytics/addresses',
  GET_ADDRESS_ACCOUNT_SUMMARY: '/api/ui/analytics/addresses/account/summary',
  GET_ADDRESSES_LINKED: '/api/ui/analytics/addresses/account/linked',
  GET_ADDRESS_CHAINS: '/api/ui/analytics/addresses/chains',
}
// Transaction endpoints
export const TRANSACTION_ENDPOINTS = {
  CREATE_OR_UPDATE_TRANSACTION: '/api/ui/analytics/transaction',
  LINK_TRANSACTION: '/api/ui/analytics/transactions',
  DELETE_TRANSACTION: '/api/ui/analytics/transactions',
  GET_TRANSACTIONS: '/api/ui/analytics/transactions',
  GET_TRANSACTION_DETAILS: '/api/ui/analytics/transactions',
  GET_TRANSACTION_HISTORY: '/api/ui/analytics/transactions',
  POST_TRANSACTION_REPORT: '/api/ui/analytics/transactions',
  GET_TRANSACTION_EVENTS: '/api/ui/analytics/transactions',
  GET_TRANSACTION_CHAINS: '/api/ui/analytics/transactions/chains',
}

// Reports endpoints
export const REPORT_ENDPOINTS = {
  GET_REPORTS: '/api/ui/reports',
  GET_REPORT: '/api/ui/reports',
  GET_REPORTS_TRANSACTION: '/api/ui/reports/transactions',
  GET_REPORTS_TRAVELRULE: '/api/ui/travelrule/reports',
}

// Directory endpoints
export const ACCOUNT_ENDPOINTS = {
  GET_ALL_ACCOUNTS: '/api/ui/directory/accounts',
  GET_ACCOUNT: '/api/ui/directory/accounts/',
  SEARCH_ACCOUNT: '/api/ui/directory/accounts/search',
  CREATE_ACCOUNT_ENTITY: '/api/ui/directory/accounts/entity',
  CREATE_ACCOUNT_INDIVIDUAL: '/api/ui/directory/accounts/individual',
  UPDATE_ACCOUNT_ENTITY: '/api/ui/directory/accounts/entity/',
  UPDATE_ACCOUNT_INDIVIDUAL: '/api/ui/directory/accounts/individual/',
}
export const RECIPIENT_ENDPOINTS = {
  GET_ALL_RECIPIENTS: '/api/ui/directory/accounts',
  GET_RECIPIENT: '/api/ui/directory/accounts/',
  SEARCH_RECIPIENT: '/api/ui/directory/accounts/search',
  CREATE_RECIPIENT_ENTITY: '/api/ui/directory/accounts/entity',
  CREATE_RECIPIENT_INDIVIDUAL: '/api/ui/directory/accounts/individual',
  UPDATE_RECIPIENT_ENTITY: '/api/ui/directory/accounts/entity/',
  UPDATE_RECIPIENT_INDIVIDUAL: '/api/ui/directory/accounts/individual/',
}
export const VASP_ENDPOINTS = {
  GET_ALL_VASPS: '/api/ui/directory/vasps',
  GET_ALL_MID_PROSPECTS: '/api/ui/directory/vasps/mid_prospects',
  GET_VASP: '/api/ui/directory/vasps/',
  SEARCH_VASP: '/api/ui/directory/vasps/search',
  SEARCH_MID_PROSPECT: '/api/ui/directory/vasps/search/mid_prospects',
  CREATE_VASP: '/api/ui/directory/vasps',
  UPDATE_VASP: '/api/ui/directory/vasps',
}
// Travel Rule endpoints
export const TRAVEL_RULE_ENDPOINTS = {
  GET_ASSET_CONVERSION: '/api/ui/travelrule/conversion',
  GET_ALL_TRANSFERS: '/api/ui/travelrule/transfers',
  GET_TRANSFER: '/api/ui/travelrule/transfers/',
  CREATE_TRANSFER: '/api/ui/travelrule/transfers/start',
  UPDATE_TRANSFER: '/api/ui/travelrule/transfers/',
  SEARCH_TRANSFER: '/api/ui/travelrule/transfers/search',
  GET_RECIPIENT_VASP: '/api/ui/travelrule/transfers/analyzer',
  POST_TRANSFER_REPORT: '/api/ui/travelrule/reports',
}

export const WHITELISTING_ENDPOINTS = {
  PAGED_TABLE_DATA: '/api/whitelist/paged-table',
  RISK_BUCKET: '/api/whitelist/risk-bucket',
  ADDRESS_HISTORY: '/api/whitelist/address-hist',
  ADDRESS_COUNT: '/api/whitelist/count-address',
  ASSIGN_ADDRESS: '/api/whitelist/assign-address',
  SEARCH_ADDRESS: '/api/whitelist/search-address?',
  REMOVE_ADDRESS: '/api/whitelist/remove-address',
  GET_ADDRESS_REPORT: '/api/reporting/address',
  UPDATE_ADDRESS_NOTES: '/api/whitelist/notes',
}

export const MONITORING_ENDPOINTS = {
  PAGED_TABLE_DATA: '/api/monitoring/paged-table',
  RISK_BUCKET: '/api/monitoring/risk-bucket',
  TRANSACTION_HISTORY: '/api/monitoring/transaction-hist',
  TRANSACTION_COUNT: '/api/monitoring/count-transaction',
  ASSIGN_TRANSACTION: '/api/monitoring/assign-transaction',
  SEARCH_TRANSACTION: '/api/monitoring/search-transaction?',
  REMOVE_TRANSACTION: '/api/monitoring/remove-transaction',
  GET_TRANSACTION_REPORT: '/api/reporting/transaction',
  UPDATE_TRANSACTION_NOTES: '/api/monitoring/notes',
}

export const CASES_ENDPOINTS = {
  // Dashboard
  DASHBOARD_TOTAL_CASES: '/api/case/dashbaord-case-total',
  DASHBOARD_CASE_ADDRESSES: '/api/case/dashbaord-case-addresses',
  DASHBOARD_CASE_TRANSACTIONS: '/api/case/dashbaord-case-transactions',

  // Create Case
  CREATE_CASE: '/api/case/create-case',

  // Read
  READ_ALL_CASES: '/api/case/read-all-case',
  READ_CASE_ACTIVITIES: '/api/case/read-case-activities',
  READ_CASE_CUSTOMER: '/api/case/read-case-customer',
  READ_CASE_ADDRESSES: '/api/case/read-address-case',
  READ_CASE_DETAILS: '/api/case/read-detail-case',
  READ_CASE_TRANSACTIONS: '/api/case/read-transaction-case',
  READ_CASE_ALERTS: '/api/alerts/get_alerts/customer',

  // Search
  AUTOCOMPLETE_CUSTOMER_SEARCH: '/api/case/search-customer',
  CUSTOMER_SEARCH: '/api/case/read-case-customer',

  UPDATE_CASE: '/api/case/update-case',
  GET_ALL_COUNTRIES: '/api/case/get-all-countries',
  INVESTIGATION: '/api/investigation/investigation',
}

export const ALERTS_ENDPOINTS = {
  // Read
  READ_ALL_ALERTS: '/api/alerts/get_alerts',
  READ_ALERT_DETAILS: '/api/alerts/get_alert_details',
  READ_DAILY_ALERTS: '/api/alerts/get_daily_alerts',
  READ_ALERTS_BY_SEVERITY: '/api/alerts/get_alerts_by_severity',
  READ_ALERTS_BY_STATUS: '/api/alerts/get_alerts_by_status',
}

export const PROFILE_ENDPOINTS = {
  READ_PROFILE: '/api/ui/users',
  UPDATE_PROFILE: '/api/ui/users/profile',
  REQUEST_EMAIL_VERIFICATION: '/api/ui/users/email/verification',
  GET_ACTIVATION_LINK: '/api/ui/users/activation/',
  ACTIVATE_USER: '/api/ui/users/activation/',
}
export const MANAGER_PROFILE_ENDPOINTS = {
  GET_USERS: '/api/ui/users/manager/users',
  CREATE_USER: '/api/ui/users/manager/user',
  CREATE_USER_ACTIVATION_LINK: '/api/ui/users/manager/user/activation/',
  UPDATE_USER: '/api/ui/users/manager/user/',
}

export const EXTERNAL_URLS = {
  MAIN_SITE: 'https://www.ospree.io/',
  GET_STARTED: 'https://www.ospree.io/get-demo',
  FEEDBACK: 'https://ospree.io/help',
  PRIVACY_POLICY: 'https://ospree.io/terms-and-conditions',
  TERMS_OF_SERVICE: 'https://ospree.io/terms-and-conditions',
  OSPREE_VERSION_2: 'https://ospree.io/blog/ospree-version-2/',
}

export const BATCH_UPLOAD_UPLOADS = {
  CASES: '/api/case/batch-create-cases',
  ADDRESSES: '/api/case/batch-address',
  TRANSACTIONS: '/api/case/batch-transaction',
}
