import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'

// Store
import { actions } from 'core/store'

// Styled Elements
import { DrawerWrapper, DrawerCloseIcon } from '../DirectoryDrawers.elements'

// Views
import { Drawer } from 'views/components'

// Layouts
import { CreateEntityCaseForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function CreateEntityCaseDrawer(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isCreateEntityCaseDrawerOpen } = ui

  // Store Actions
  const { closeCreateEntityCaseDrawer } = actions

  const handleCloseDrawer = () => {
    closeCreateEntityCaseDrawer()
  }

  return (
    <Drawer open={isCreateEntityCaseDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <DrawerWrapper>
        <DrawerCloseIcon onClick={handleCloseDrawer}>
          <CloseIcon />
        </DrawerCloseIcon>
        <CreateEntityCaseForm />
      </DrawerWrapper>
    </Drawer>
  )
}

// Default Props
CreateEntityCaseDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
CreateEntityCaseDrawer.propTypes = {
  ui: PropTypes.shape({ isCreateEntityCaseDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ closeCreateEntityCaseDrawer: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEntityCaseDrawer)
