import { useMutation } from 'react-query'

// Services
import { UpdateCase } from 'services/api/Cases'

export default () => {
  const {
    mutate: updateCase,
    data: updateCaseData,
    error: updateCaseError,
    isLoading: isUpdateCaseLoading,
    isSuccess: isUpdateCaseSuccess,
    isError: isUpdateCaseError,
  } = useMutation(UpdateCase)

  return {
    updateCase,
    updateCaseData,
    updateCaseError,
    isUpdateCaseLoading,
    isUpdateCaseSuccess,
    isUpdateCaseError,
  }
}
