import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 10px 0 0 0;
`

export const TableOptionWrapper = styled.div`
  display: flex;
`

export const DropdownItemInnerText = styled.div`
  margin: 0px 0px 0px 16px;

  font-weight: inherit;
  font-size: inherit;
`
