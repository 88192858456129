import { useMutation } from 'react-query'

// Services
import { GetAddressAccountSummary } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddressAccountSummary,
    data: getAddressAccountSummaryData,
    error: getAddressAccountSummaryError,
    isLoading: isGetAddressAccountSummaryLoading,
    isSuccess: isGetAddressAccountSummarySuccess,
    isError: isGetAddressAccountSummaryError,
  } = useMutation(GetAddressAccountSummary)

  return {
    getAddressAccountSummary,
    getAddressAccountSummaryData,
    getAddressAccountSummaryError,
    isGetAddressAccountSummaryLoading,
    isGetAddressAccountSummarySuccess,
    isGetAddressAccountSummaryError,
  }
}
