import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { UncontrolledDropdownWrapper } from './UncontrolledDropdown.elements'

function UncontrolledDropdown(props) {
  // Destructure
  const { children, direction, ...rest } = props

  return (
    <UncontrolledDropdownWrapper direction={direction} {...rest}>
      {children}
    </UncontrolledDropdownWrapper>
  )
}

// Default Props
UncontrolledDropdown.defaultProps = {
  children: '',
  direction: '',
}

// Proptypes Validation
UncontrolledDropdown.propTypes = {
  direction: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default UncontrolledDropdown
