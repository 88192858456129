import { useMutation } from 'react-query'

// Services
import { SearchAccount } from 'services/api/Accounts'

export default () => {
  const {
    mutate: searchAccount,
    data: accountSearchData,
    error: accountSearchError,
    isLoading: isAccountSearchLoading,
    isSuccess: isAccountSearchSuccess,
    isError: isAccountSearchError,
  } = useMutation(SearchAccount)

  return {
    searchAccount,
    accountSearchData,
    accountSearchError,
    isAccountSearchLoading,
    isAccountSearchSuccess,
    isAccountSearchError,
  }
}
