/* eslint-disable max-len */
import React from 'react'

export default function HelpIcon() {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.50033 10.0846C8.03163 10.0846 10.0837 8.03261 10.0837 5.5013C10.0837 2.97 8.03163 0.917969 5.50033 0.917969C2.96902 0.917969 0.916992 2.97 0.916992 5.5013C0.916992 8.03261 2.96902 10.0846 5.50033 10.0846Z"
        fill="#F3F4F7"
      />
      <path
        d="M4.16602 4.12575C4.27377 3.81943 4.48646 3.56113 4.76641 3.3966C5.04636 3.23207 5.37551 3.17193 5.69555 3.22683C6.0156 3.28172 6.30589 3.44811 6.51501 3.69653C6.72413 3.94495 6.83858 4.25936 6.8381 4.58408C6.8381 5.50075 5.4631 5.95908 5.4631 5.95908"
        stroke="#393D46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.5 7.79297H5.50609" stroke="#393D46" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
