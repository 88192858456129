import { useMutation } from 'react-query'

// Services
import { GetCaseCustomer } from 'services/api/Cases'

export default () => {
  const {
    mutate: getCaseCustomer,
    data: caseCustomerData,
    error: caseCustomerError,
    isLoading: isCaseCustomerLoading,
    isSuccess: isCaseCustomerSuccess,
    isError: isCaseCustomerError,
  } = useMutation(GetCaseCustomer)

  return {
    getCaseCustomer,
    caseCustomerData,
    caseCustomerError,
    isCaseCustomerLoading,
    isCaseCustomerSuccess,
    isCaseCustomerError,
  }
}
