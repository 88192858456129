export const CASES_DUMMY_DATA = [
  {
    case_id: 'BGDUIV51',
    severity: 'Medium',
    case_status: 'Open',
    rule_description: 'High Address Risk',
    notes: '',
    timestamp: '2022-12-01',
    last_update: '2022-12-01',
    rule_code: 'high_address_risk',
    assignee: 'Analyst Level 1',
  },
  {
    case_id: 'BGDUIV52',
    severity: 'High',
    case_status: 'Open',
    rule_description: 'High Address Risk',
    notes: '',
    timestamp: '2022-12-02',
    last_update: '2022-12-02',
    rule_code: 'high_address_risk',
    assignee: 'Analyst Level 1',
  },
  {
    case_id: 'BGDUIV53',
    severity: 'Low',
    case_status: 'Open',
    rule_description: 'High Address Risk',
    notes: '',
    timestamp: '2022-12-03',
    last_update: '2022-12-03',
    rule_code: 'high_address_risk',
    assignee: 'Analyst Level 1',
  },
]
