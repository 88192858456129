import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Assets
import { CloseIcon, ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'assets/images'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useGetAddressHistory } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Constants
import { BLOCKCHAIN_MONO_SVGS } from 'common/constants/blockchainMonoSvgs'
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Styled Elements
import {
  ModalFooterWrapper,
  ModalFooterButton,
  ChartWrapper,
  ColWrapper,
  RowWrapper,
  RowContainer,
  GridRowContainer,
  Title,
  Text,
  TinyText,
  SmallText,
} from './AddressHistoryModal.elements'

// Views
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  TextSliced,
  LoadingTablePlaceholder,
  EmptyTablePlaceholder,
  CustomDateRangePicker,
} from 'views/components'
import { RiskLineChart, FinancialLineChart } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AddressHistoryModal(props) {
  // Destructure
  const { ui, actions, filters } = props

  // Store State
  const { isAddressHistoryModalOpen } = ui
  const { addressHistoryFilters } = filters
  const { start_range, end_range } = addressHistoryFilters

  // Store Actions
  const { toggleAddressHistoryModal, setAddressHistoryFilters } = actions

  // Constant Variables
  const dayCap = 13
  const weekCap = 103

  // Local State
  const [riskData, setRiskData] = useState()
  const [financialData, setFinancialData] = useState()
  const [historyData, setHistoryData] = useState()
  const [totalItems, setTotalItems] = useState()
  const [pageSize, setPageSize] = useState()
  const [activePage, setActivePage] = useState(1)
  const [activeChart, setActiveChart] = useState('risk')

  // Hooks
  const { getAddressHistory, getAddressHistoryData, isGetAddressHistoryLoading } = useGetAddressHistory()
  const [theme] = useLocalStorage('theme', 'light')

  const riskOptions = {
    layout: {
      padding: 10,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: theme === 'light' ? '#d2d2d2' : '#727F97',
          },
          ticks: {
            fontColor: theme === 'light' ? '#727F97' : '#e9e9e9',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            beginAtZero: true,
            suggestedMin: 0,
            suggestedMax: 100,
            max: 100,
            stepSize: 20,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  }
  const financialOptions = {
    layout: {
      padding: 10,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: theme === 'light' ? '#d2d2d2' : '#727F97',
          },
          ticks: {
            beginAtZero: true,
            suggestedMin: 0,
            fontColor: theme === 'light' ? '#727F97' : '#e9e9e9',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  }
  // Functions
  const toggleModal = () => {
    toggleAddressHistoryModal()
    setAddressHistoryFilters({})
  }
  function invertData(data) {
    let invertedData = []
    data.forEach((item) => {
      invertedData = [item, ...invertedData]
    })
    return invertedData
  }

  function formatHistoryData(data) {
    const newHistoryData = []
    data.forEach((item) => {
      const newData = {
        address: item.address,
        request_time: item.request_time,
        risk_score: item.risk_data.risk_ratio,
        balance_usd: item?.financial_data?.balance_usd ? Math.round(item.financial_data.balance_usd * 10) / 10 : null,
        balance: item?.financial_data?.balance,
        symbol: item.symbol,
        chain: item.chain,
      }
      newHistoryData.push(newData)
    })
    return newHistoryData
  }
  function formatToDays(data) {
    const datasets = []
    const labels = []
    const oldestTime = moment(data[data.length - 1].request_time)
    let latestTime = moment(data[0].request_time)
    while (moment(latestTime).diff(oldestTime, 'day') >= 0) {
      // pushing every single day to the dataset
      labels.push(`${latestTime.format('MMM DD').toString()}`)
      if (datasets.length < 1) {
        datasets.push(data[0].value * 1.0)
      }
      // Looping through the data
      for (let i = 0; i < data.length; i++) {
        // checking if there is data for the latest date
        if (moment(latestTime).diff(moment(data[i].request_time), 'day') === 0) {
          // checking if the latest date data exists in the dataset
          if (datasets.length < labels.length) {
            datasets.push(data[i].value * 1.0)
          } else if (datasets[datasets.length - 1] < data[i].value) {
            datasets[datasets.length - 1] = data[i].value * 1.0
          }
        }
      }
      // checking if the latest date data exists in the dataset
      if (datasets.length < labels.length) {
        // using the last risk ratio as the risk ratio
        // of the day if it doesnt have one
        datasets.push(datasets[datasets.length - 1] * 1.0)
      }
      latestTime = moment(latestTime).subtract(1, 'day')
    }
    return { datasets, labels }
  }
  function formatToWeeks(data) {
    const datasets = []
    const labels = []
    const oldestTime = moment(data[data.length - 1].request_time)
    let latestTime = moment(data[0].request_time)
    while (moment(latestTime).diff(oldestTime, 'week') >= 0) {
      if (datasets.length > 10) break
      // pushing every single week to the dataset
      labels.push(
        `${latestTime.clone().weekday(1).format('MMM DD').toString()} - ${latestTime
          .clone()
          .weekday(7)
          .format('MMM DD')
          .toString()}`
      )
      if (datasets.length < 1) {
        datasets.push(data[0].value * 1.0)
      }
      // Looping through the data
      for (let i = 0; i < data.length; i++) {
        // checking if there is data for the latest date
        if (moment(latestTime).diff(moment(data[i].request_time), 'week') === 0) {
          // checking if the latest date data exists in the dataset
          if (datasets.length < labels.length) {
            datasets.push(data[i].value * 1.0)
          } else if (datasets[datasets.length - 1] < data[i].value) {
            datasets[datasets.length - 1] = data[i].value * 1.0
          }
        }
      }
      // checking if the latest date data exists in the dataset
      if (datasets.length < labels.length) {
        // using the last risk ratio as the risk ratio
        // of the week if it doesnt have one
        datasets.push(datasets[datasets.length - 1] * 1.0)
      }
      latestTime = moment(latestTime).subtract(7, 'days')
    }
    return { datasets, labels }
  }
  function formatToMonths(data) {
    const datasets = []
    const labels = []
    const oldestTime = moment(data[data.length - 1].request_time)
    let latestTime = moment(data[0].request_time)
    while (moment(latestTime).diff(oldestTime, 'month') >= 0) {
      // pushing every single month to the dataset
      labels.push(`${latestTime.format('MMMM').toString()}`)
      if (datasets.length < 1) {
        datasets.push(data[0].value * 1.0)
      }
      // Looping through the data
      for (let i = 0; i < data.length; i++) {
        // checking if there is data for the latest date
        if (moment(latestTime).diff(moment(data[i].request_time), 'month') === 0) {
          // checking if the latest date data exists in the dataset
          if (datasets.length < labels.length) {
            datasets.push(data[i].value * 1.0)
          } else if (datasets[datasets.length - 1] < data[i].value) {
            datasets[datasets.length - 1] = data[i].value * 1.0
          }
        }
      }
      // checking if the latest date data exists in the dataset
      if (datasets.length < labels.length) {
        // using the last risk ratio as the risk ratio
        // of the month if it doesnt have one
        datasets.push(datasets[datasets.length - 1] * 1.0)
      }
      latestTime = moment(latestTime).subtract(1, 'month')
    }
    return { datasets, labels }
  }
  function formatRiskData(data) {
    if (data) {
      let datasets = []
      let labels = []
      const formattedData = []
      data.forEach((item) => {
        formattedData.push({ request_time: item.request_time, value: item.risk_score })
      })
      const oldestTime = moment(data[data.length - 1].request_time)
      const latestTime = moment(data[0].request_time)
      const diff = moment(latestTime).diff(oldestTime, 'day')
      if (diff <= dayCap) {
        const newData = formatToDays(formattedData)
        datasets = invertData(newData.datasets)
        labels = invertData(newData.labels)
      } else if (diff <= weekCap && diff > dayCap) {
        const newData = formatToWeeks(formattedData)
        datasets = invertData(newData.datasets)
        labels = invertData(newData.labels)
      } else if (diff > weekCap) {
        const newData = formatToMonths(formattedData)
        datasets = invertData(newData.datasets)
        labels = invertData(newData.labels)
      }
      setRiskData({
        labels,
        datasets: [
          {
            data: datasets,
            fill: false,
            borderColor: '#DCFE54',
            pointColor: '#DCFE54',
            fillColor: '#DCFE54',
            tension: 0.1,
            pointRadius: 5,
            pointHoverRadius: 7,
          },
        ],
      })
      return formattedData
    }
  }
  function formatFinancialData(data) {
    if (data) {
      let datasets = []
      let labels = []
      const formattedData = []
      data.forEach((item) => {
        formattedData.push({ request_time: item.request_time, value: item.balance })
      })

      const oldestTime = moment(data[data.length - 1].request_time)
      const latestTime = moment(data[0].request_time)
      const diff = moment(latestTime).diff(oldestTime, 'day')
      if (diff <= dayCap) {
        const newData = formatToDays(formattedData)
        datasets = invertData(newData.datasets)
        labels = invertData(newData.labels)
      } else if (diff <= weekCap && diff > dayCap) {
        const newData = formatToWeeks(formattedData)
        datasets = invertData(newData.datasets)
        labels = invertData(newData.labels)
      } else if (diff > weekCap) {
        const newData = formatToMonths(formattedData)
        datasets = invertData(newData.datasets)
        labels = invertData(newData.labels)
      }
      setFinancialData({
        labels,
        datasets: [
          {
            data: datasets,
            fill: false,
            borderColor: theme === 'light' ? '#26A3F5' : '#00CACD',
            pointColor: theme === 'light' ? '#26A3F5' : '#00CACD',
            fillColor: theme === 'light' ? '#26A3F5' : '#00CACD',
            tension: 0.1,
            pointRadius: 5,
            pointHoverRadius: 7,
          },
        ],
      })
      return formattedData
    }
  }
  const handleDateSelect = ({ start, end }) => {
    if (start !== start_range && end !== end_range) {
      setAddressHistoryFilters({
        ...addressHistoryFilters,
        start_range: start,
        end_range: end,
      })
    }
    setActivePage(1)
  }
  const handleFilterReset = () => {
    setAddressHistoryFilters({
      ...addressHistoryFilters,
      start_range: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    })
  }

  useEffect(() => {
    if (isAddressHistoryModalOpen && addressHistoryFilters?.address_id) getAddressHistory(addressHistoryFilters)
    if (isAddressHistoryModalOpen && !addressHistoryFilters?.address_id) setHistoryData()
  }, [isAddressHistoryModalOpen, addressHistoryFilters])
  useEffect(() => {
    if (getAddressHistoryData) {
      setTotalItems(getAddressHistoryData.pagination.total)
      setPageSize(getAddressHistoryData.pagination.size)
      const newHistoryData = formatHistoryData(getAddressHistoryData.items)
      setHistoryData(newHistoryData)
      formatRiskData(newHistoryData)
      formatFinancialData(newHistoryData)
    } else {
      setHistoryData()
      setTotalItems(0)
      setPageSize(0)
      setRiskData()
      setFinancialData()
    }
  }, [getAddressHistoryData])
  useEffect(() => {
    setAddressHistoryFilters({ ...addressHistoryFilters, page: activePage })
  }, [activePage])

  return (
    <Modal isOpen={isAddressHistoryModalOpen} toggle={toggleModal} style={{ maxWidth: '1000px' }}>
      <ModalHeader toggle={toggleModal} close={<CloseIcon />} style={{ border: 'none' }}>
        <RowWrapper>
          <ColWrapper>
            <Title>Wallet Address History</Title>
            <SmallText style={{ textTransform: 'uppercase' }}>
              {addressHistoryFilters.address && addressHistoryFilters.address}
            </SmallText>
          </ColWrapper>
          <RowContainer style={{ paddingRight: '30px', alignItems: 'flex-end' }}>
            <CustomDateRangePicker
              key={start_range}
              onApply={handleDateSelect}
              start={start_range}
              end={end_range}
              style={{ minWidth: '300px' }}
            />
          </RowContainer>
        </RowWrapper>
      </ModalHeader>

      <ModalBody>
        {/* <ChartWrapper>
          {isGetAddressHistoryLoading && <LoadingTablePlaceholder />}
          <ColWrapper>
            <RowContainer style={{ justifyContent: 'flex-start' }}>
              <Button variant={activeChart !== 'risk' && 'outlined'} onClick={() => setActiveChart('risk')}>
                Risk Data
              </Button>
              <Button variant={activeChart === 'risk' && 'outlined'} onClick={() => setActiveChart('financial')}>
                Financial Data
              </Button>
            </RowContainer>

            {!isGetAddressHistoryLoading && riskData && activeChart === 'risk' && (
              <RiskLineChart data={riskData} options={riskOptions} />
            )}
            {!isGetAddressHistoryLoading && financialData && activeChart !== 'risk' && (
              <FinancialLineChart data={financialData} options={financialOptions} />
            )}
          </ColWrapper>
        </ChartWrapper> */}
        <ColWrapper>
          <GridRowContainer>
            <Text style={{ textAlign: 'left' }}>Wallet Address</Text>
            <Text style={{ textAlign: 'left' }}>Timestamp</Text>
            <Text style={{ textAlign: 'right' }}>Risk Score</Text>
            <Text style={{ textAlign: 'right' }}>Balance</Text>
          </GridRowContainer>
          {isGetAddressHistoryLoading && (
            <ColWrapper style={{ alignItems: 'center' }}>
              <LoadingTablePlaceholder />
            </ColWrapper>
          )}
          {!isGetAddressHistoryLoading && !historyData && (
            <ColWrapper style={{ alignItems: 'center', paddingTop: '80px' }}>
              <EmptyTablePlaceholder setFilter={handleFilterReset} />
            </ColWrapper>
          )}
          {!isGetAddressHistoryLoading &&
            historyData &&
            historyData.map((item) => {
              const chainIcon = BLOCKCHAIN_MONO_SVGS[item.chain]
              return (
                <GridRowContainer key={item.request_time}>
                  <RowContainer style={{ justifyContent: 'flex-start' }}>
                    {chainIcon && chainIcon}
                    <TextSliced text={item.address} sliceLength={5} />
                  </RowContainer>
                  <Text>{moment(item.request_time).format('MMM-DD-YYYY hh:mm:ss')}</Text>
                  <Text style={{ textAlign: 'right' }}>
                    {item?.risk_score !== null && item?.risk_score !== undefined ? (
                      <>
                        {item.risk_score} <TinyText>/ 100</TinyText>
                      </>
                    ) : (
                      '-'
                    )}
                  </Text>
                  <Text style={{ fontWeight: '500', textAlign: 'right' }}>
                    {item?.balance !== null && item?.balance !== undefined
                      ? `${numberWithCommas(Math.round(item.balance * 100) / 100)} ${item?.symbol?.toUpperCase()}`
                      : `- ${item?.symbol.toUpperCase()}`}
                  </Text>
                  {/* <ColWrapper style={{ gap: '0px', textAlign: 'right' }}>
                    <Text style={{ fontWeight: '500' }}>
                      {`${numberWithCommas(Math.round(item.balance * 100) / 100)} ${item.symbol.toUpperCase()}`}
                    </Text>
                    <SmallText>{`$${numberWithCommas(Math.round(item.balance_usd * 100) / 100)}`}</SmallText>
                  </ColWrapper> */}
                </GridRowContainer>
              )
            })}
        </ColWrapper>
      </ModalBody>
      <ModalFooter style={{ marginTop: '20px', borderWidth: '2px' }}>
        <ModalFooterWrapper>
          {activePage && totalItems && pageSize && (
            <RowContainer>
              <ModalFooterButton>
                <Button
                  variant="outlined"
                  startIcon={<ArrowLeftCircleIcon />}
                  disabled={activePage <= 1}
                  onClick={() => setActivePage(activePage - 1)}
                >
                  Newer
                </Button>
              </ModalFooterButton>
              <RowContainer style={{ alignItems: 'center' }}>
                <Text>{`${activePage} / ${Math.ceil(totalItems / pageSize)}`}</Text>
              </RowContainer>
              <ModalFooterButton>
                <Button
                  variant="outlined"
                  endIcon={<ArrowRightCircleIcon />}
                  disabled={activePage > totalItems / pageSize}
                  onClick={() => setActivePage(activePage + 1)}
                >
                  Older
                </Button>
              </ModalFooterButton>
            </RowContainer>
          )}
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
AddressHistoryModal.defaultProps = {
  ui: {},
  filters: {},
  address: {},
  actions: {},
}

// Proptypes Validation
AddressHistoryModal.propTypes = {
  ui: PropTypes.shape({ isAddressHistoryModalOpen: PropTypes.bool }),
  filters: PropTypes.shape({
    addressHistoryFilters: PropTypes.shape({
      address: PropTypes.string,
      address_id: PropTypes.string,
      sort: PropTypes.string,
      page: PropTypes.number,
      size: PropTypes.number,
      start_range: PropTypes.string,
      end_range: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({ addressHistoryDetails: PropTypes.shape({}) }),
  actions: PropTypes.shape({
    toggleAddressHistoryModal: PropTypes.func,
    setAddressHistoryFilters: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressHistoryModal)
