import { useMutation } from 'react-query'

// Services
import { RemoveWhitelistingAddress } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: removeWhitelistingAddress,
    data: removeWhitelistingAddressData,
    error: removeWhitelistingAddressError,
    isLoading: isRemoveWhitelistingAddressLoading,
    isSuccess: isRemoveWhitelistingAddressSuccess,
    isError: isRemoveWhitelistingAddressError,
  } = useMutation(RemoveWhitelistingAddress)

  return {
    removeWhitelistingAddress,
    removeWhitelistingAddressData,
    removeWhitelistingAddressError,
    isRemoveWhitelistingAddressLoading,
    isRemoveWhitelistingAddressSuccess,
    isRemoveWhitelistingAddressError,
  }
}
