import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper, Title, RowWrapper, CheckboxInput, CheckboxLabel, Check, Dot } from './Checkbox.elements'

function Checkbox(props) {
  // Destructure
  const { id, name, type, label, options, onChange, value, disabled, ...rest } = props

  function handleChange(selectedValue, checked) {
    let newItems = [...value]
    if (checked) {
      newItems.push(selectedValue)
    }
    if (!checked) {
      newItems = []
      value.forEach((item) => {
        if (item !== selectedValue) {
          newItems.push(item)
        }
      })
    }
    onChange(newItems)
  }

  return (
    <Wrapper {...rest}>
      {label && <Title>{label}</Title>}
      {options &&
        options.map((item) => (
          <RowWrapper key={item.value}>
            <CheckboxInput
              name={name}
              id={item.value}
              type="checkbox"
              onChange={(e) => handleChange(item.value, e.target.checked)}
            />
            <CheckboxLabel for={item.value}>
              <Check>
                <Dot />
              </Check>
              {item.label}
            </CheckboxLabel>
          </RowWrapper>
        ))}
    </Wrapper>
  )
}

// Default Props
Checkbox.defaultProps = {
  id: '',
  name: '',
  type: '',
  label: '',
  disabled: false,
  options: [],
  onChange: () => {},
  value: [],
}

// Proptypes Validation
Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf([PropTypes.string]),
  options: PropTypes.instanceOf(Array),
}

export default Checkbox
