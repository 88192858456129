import styled from 'styled-components'

export const CardWrapper = styled.div`
  border-radius: 5px;

  // Themed Properties
  border: 1px solid ${({ theme }) => theme.colors.ash};
  background-color: ${({ theme }) => theme.colors.white};
`
export const CardHeader = styled.div`
  position: relative;
  padding: 15px 15px 15px 20px;

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.ash};
`

export const CardHeaderIcon = styled.div`
  display: table-cell;
  width: 30px;
  min-width: 30px;
  vertical-align: middle;
`

export const CardHeaderTitle = styled.div`
  display: table-cell;
  font-weight: 500;
  white-space: nowrap;
  padding-left: 10px;
  vertical-align: middle;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('font-size', ['14px', '14px', '14px', '14px', '14px', '14px', '17px', '17px'])}
`

export const CardBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px;
`

export const CardFooter = styled.div``
