import styled from 'styled-components'

export const Text = styled.p`
  font-size: 13px;
  margin: 0;
  text-align: right;
`
export const Label = styled.p`
  font-size: 13px;
  margin: 0;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Title = styled.h3`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.darkGray};

  max-width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const SubTitle = styled.h4`
  font-size: 10px;
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const TableRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 1fr;
`
export const TableColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 15px;

  &:first-child {
    background-color: ${({ theme }) => `${theme.colors.gray}10`};
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};
    border-radius: 10px 10px 0 0;
    gap: 5px;
    & button {
      visibility: ${({ pending }) => !pending && 'hidden'};
      color: ${({ theme }) => theme.colors.blue} !important;
      border-color: ${({ theme }) => theme.colors.blue} !important;
    }
  }
  &:last-child {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0 0 10px 10px;
  }

  &:last-child {
    border-top: 2px solid ${({ theme }) => theme.colors.lightGray};
    & div > p {
      font-size: 13px;
      font-weight: 100;
      color: ${({ theme }) => theme.colors.darkGray};
    }
  }
`
