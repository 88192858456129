import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
// import { TableWrapper } from './ReportsTransaction.elements'

import {
  App,
  Header,
  Sidebar,
  Content,
  PageWrapper,
  ReportsTransactionTable,
  ReportsSubHeader,
  NoAccess,
} from 'views/layouts'
import { Container } from '../../../components'

function ReportsTransaction() {
  // Destructure
  // const { } = props

  return (
    <App>
      <Sidebar />
      <Header subHeader={<ReportsSubHeader />} pageTitle="Reports" />
      <NoAccess />
      {/* <Content>
        <PageWrapper>
          <Container>
            <ReportsTransactionTable />
          </Container>
        </PageWrapper>
      </Content> */}
    </App>
  )
}

// Default Props
ReportsTransaction.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
ReportsTransaction.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default ReportsTransaction
