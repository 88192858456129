import styled from 'styled-components'

export const TableItemWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 450px;
  transition: 0.3s;
  cursor: pointer;

  /* display: flex;
  justify-content: center;
  align-items: center; */

  // Themed Properties
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.darkGray};
  border: 1px solid ${({ theme }) => `${theme.colors.lightGray}`};
  &:hover {
    border: 1px solid ${({ theme }) => `${theme.colors.blue}40`};
  }
`
