/* eslint-disable max-len */
import React from 'react'

export default function XdcMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M196.767 148.364L249.267 233.08H250.858L303.358 148.364H332.392L268.358 250.182L332.392 352H303.358L250.858 268.875H249.267L196.767 352H167.733L233.358 250.182L167.733 148.364H196.767Z"
        fill="#00CACD"
      />
      <path d="M433 250L493 215.359V284.641L433 250Z" fill="#00CACD" />
    </svg>
  )
}
