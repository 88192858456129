import { useMutation } from 'react-query'

// Services
import { GetAddressDetails } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddressDetails,
    data: getAddressDetailsData,
    error: getAddressDetailsError,
    isLoading: isGetAddressDetailsLoading,
    isSuccess: isGetAddressDetailsSuccess,
    isError: isGetAddressDetailsError,
  } = useMutation(GetAddressDetails)

  return {
    getAddressDetails,
    getAddressDetailsData,
    getAddressDetailsError,
    isGetAddressDetailsLoading,
    isGetAddressDetailsSuccess,
    isGetAddressDetailsError,
  }
}
