const mobileFirst = true

// List of Breakpoints
const breakpoints = {
  verySmall: '320px',
  mobileSmall: '430px',
  mobileMedium: '500px',
  mobile: '600px',
  tabletPortraitUp: '768px',
  tabletLandscapeUp: '920px',
  laptop: '1024px',
  laptopMedium: '1200px',
  laptopLarge: '1320px',
  bigDesktop: '1600px',
  bigDesktopUp: '1800px',
  wideScreen: '4000px',
}

/**
 * Returns media query rule per property breakpoint
 *
 * @param  {} width Max width of media query rule
 * @param  {} content Property : values; for an element
 */
const mediaQuery = (width, content) => {
  const breakpointWidth = width

  return `@media screen and (max-width: ${breakpointWidth}) {
    ${content}
  }`
}

/**
 * Fills property values for unassigned breakpoint
 *
 * @param  {} values Assigned values per property breakpoint
 */
const fillValues = (values) => {
  const argsMissingValueLength = Object.keys(breakpoints).length - values.length
  const lastArgsValue = values[values.length - 1]

  const updatedValues = values

  for (let index = 0; index < argsMissingValueLength; index++) {
    updatedValues.push(lastArgsValue)
  }

  return updatedValues
}

/**
 * Generates media query breakpoint rules for a property
 *
 * @param  {} property Css property
 * @param  {} values Set of values for a property per breakpoint
 */
const generateMediaQuery = (property, values) => {
  const breakpointWidths = Object.values(breakpoints)

  const breakpointLength = breakpointWidths.length

  const propertyValues = fillValues(values)

  let generatedMediaQueryRule = ''

  if (mobileFirst) {
    // Generates media query breakpoint rules for mobile first approach
    let count = breakpointLength - 1

    while (count >= 0) {
      generatedMediaQueryRule += mediaQuery(breakpointWidths[count], `${property}: ${propertyValues[count]};`)
      count--
    }
  } else {
    // Generates media query breakpoint rules for desktop first approach
    let count = 0

    while (count < breakpointLength) {
      generatedMediaQueryRule += mediaQuery(
        breakpointWidths[breakpointLength - count - 1],
        `${property}: ${propertyValues[count]};`
      )
      count++
    }
  }

  return generatedMediaQueryRule
}

export default generateMediaQuery
