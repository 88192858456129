import styled from 'styled-components'

export const NoAccessWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`
export const NoAccessContainer = styled.div`
  max-width: 500px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `solid 1px ${theme.colors.gray}`};
`
export const NoAccessHeaderContainer = styled.div`
  padding: 10px 30px;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.gray}`};
`
export const NoAccessContentContainer = styled.div`
  padding: 50px 30px;
`

export const NoAccessContent = styled.div`
  text-align: center;
  font-size: 15px;

  color: ${({ theme }) => theme.colors.darkGray};
`
export const NoAccessHeader = styled.div`
  text-align: center;
  font-size: 22px;

  color: ${({ theme }) => theme.colors.darkGray};
`
