import { useMutation } from 'react-query'

// Services
import { BatchUploadCases } from 'services/api/Upload'

export default () => {
  const {
    mutate: batchUploadCases,
    data: batchUploadCasesData,
    error: batchUploadCasesError,
    isLoading: isBatchUploadCasesLoading,
    isSuccess: isBatchUploadCasesSuccess,
    isError: isBatchUploadCasesError,
  } = useMutation(BatchUploadCases)

  return {
    batchUploadCases,
    batchUploadCasesData,
    batchUploadCasesError,
    isBatchUploadCasesLoading,
    isBatchUploadCasesSuccess,
    isBatchUploadCasesError,
  }
}
