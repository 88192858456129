import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Title } from './FormTitle.elements'

function FormTitle(props) {
  // Destructure
  const { children } = props

  return <Title>{children}</Title>
}

// Default Props
FormTitle.defaultProps = {
  children: ''
}

// Proptypes Validation
FormTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node])
}

export default FormTitle
