import { useMutation } from 'react-query'

// Services
import { ConfirmVaspEmail } from 'services/api/Auth'

export default () => {
  const {
    mutate: confirmVaspEmail,
    data: confirmVaspEmailData,
    error: confirmVaspEmailError,
    isLoading: isConfirmVaspEmailLoading,
    isSuccess: isConfirmVaspEmailSuccess,
    isError: isConfirmVaspEmailError,
  } = useMutation(ConfirmVaspEmail)

  return {
    confirmVaspEmail,
    confirmVaspEmailData,
    confirmVaspEmailError,
    isConfirmVaspEmailLoading,
    isConfirmVaspEmailSuccess,
    isConfirmVaspEmailError,
  }
}
