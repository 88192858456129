import React from 'react'

// Styled Elements
import {
  SubHeaderWrapper,
  SubHeaderNavList,
  SubHeaderNavListItem,
  SubHeaderNavListLink,
} from './BlockchainAnalyticsSubHeader.elements'

function BlockchainAnalyticsSubHeader() {
  return (
    <SubHeaderWrapper>
      <SubHeaderNavList>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/blockchain-analytics">
            Address
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
        <SubHeaderNavListItem>
          <SubHeaderNavListLink exact activeClassName="active" to="/blockchain-analytics/transactions">
            Transactions
          </SubHeaderNavListLink>
        </SubHeaderNavListItem>
      </SubHeaderNavList>
    </SubHeaderWrapper>
  )
}

export default BlockchainAnalyticsSubHeader
