import styled from 'styled-components'

export const LoaderCircleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Loader = styled.div.attrs((props) => ({
  // Props
  width: props.width || '80px',
  height: props.height || '80px',
  thickness: props.thickness || '8px'
  }))`

  display: inline-block;
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: 15px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: ${(props) => props.thickness} solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.colors.blue} transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
