/* eslint-disable max-len */
import React from 'react'

export default function TronMonoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 500 500">
      <path
        fill="#00CACD"
        stroke="#00CACD"
        strokeWidth="3"
        d="M370.582 198.346l.016.015.017.014a8.407 8.407 0 012.793 7.578 37.391 37.391 0 01-3.829 5.627l-.012.014-.011.014c-6.007 7.668-38.756 47.575-70.028 85.594a80942.578 80942.578 0 01-59.677 72.467l-.998 1.21-.255.31-.065.078-.016.02-.004.005c-.001.001-.001.001 1.156.956l-1.157-.955-.02.025-.019.024v.001h-.001l-.003.005-.014.017-.054.072-.21.274a111.597 111.597 0 01-3.192 3.966c-.92 1.087-1.862 2.144-2.655 2.919-.4.391-.728.677-.974.855-.056.04-.1.07-.135.092h-.367c-.625-.081-1.387-.704-2.199-2.181-.778-1.416-1.334-3.128-1.752-4.424l-.008-.026-.01-.026c-6.264-17.245-24.208-60.199-43.481-106.336l-5.325-12.75c-21.16-50.675-42.778-102.689-51.593-127.18v-2.831l.106-.274.009-.025.009-.026a7.671 7.671 0 011.725-2.823l.652-.515a4.27 4.27 0 011.627-.624l.029.002c.089.006.221.019.403.04.362.042.87.111 1.522.207 1.303.193 3.139.488 5.445.874 4.612.772 11.075 1.904 18.857 3.292 15.561 2.777 36.377 6.579 58.146 10.589 38.958 7.175 80.964 15.013 101.37 18.821l6.143 1.146a14.205 14.205 0 014.017 2.265l.039.031.04.029.751.525c6.902 6.13 14.593 13.514 22.112 20.803 1.267 1.227 2.529 2.453 3.781 3.669 6.195 6.014 12.156 11.803 17.299 16.551zm-101.333 14.553l-4.409 3.657 5.635-1.026 80.597-14.673 2.961-.539-2.213-2.04-29.798-27.455-.964-.888-1.01.837-50.799 42.127zm-19.722-.438l.965.79.953-.804 54.047-45.58 2.426-2.046-3.121-.576-141.507-26.098-1.223 2.635 87.46 71.679zm8.604 16.141l-1.119.18-.132 1.126-12.824 108.873-.592 5.033 3.236-3.899 102.339-123.3 2.552-3.075-3.945.636-89.515 14.426zM152.11 145.63l-2.359 1.712 78.707 192.265 2.878-.394 13.316-114.176.093-.798-.612-.519-92.023-78.09z"
      />
      <path
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
        d="M490 250c0 132.548-107.452 240-240 240S10 382.548 10 250 117.452 10 250 10s240 107.452 240 240z"
      />
    </svg>
  )
}
