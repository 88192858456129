import { useMutation } from 'react-query'

// Services
import { GetCaseDetails } from 'services/api/Cases'

export default () => {
  const {
    mutate: getCaseDetails,
    data: caseDetailsData,
    error: caseDetailsError,
    isLoading: isCaseDetailsLoading,
    isSuccess: isCaseDetailsSuccess,
    isError: isCaseDetailsError,
  } = useMutation(GetCaseDetails)

  return {
    getCaseDetails,
    caseDetailsData,
    caseDetailsError,
    isCaseDetailsLoading,
    isCaseDetailsSuccess,
    isCaseDetailsError,
  }
}
