import { useMutation } from 'react-query'

// Services
import { UpdatePassword } from 'services/api/Auth'

export default () => {
  const {
    mutate: updatePassword,
    data: updatePasswordData,
    error: updatePasswordError,
    isLoading: isUpdatePasswordLoading,
    isSuccess: isUpdatePasswordSuccess,
    isError: isUpdatePasswordError,
  } = useMutation(UpdatePassword)

  return {
    updatePassword,
    updatePasswordData,
    updatePasswordError,
    isUpdatePasswordLoading,
    isUpdatePasswordSuccess,
    isUpdatePasswordError,
  }
}
