import { useMutation } from 'react-query'

// Services
import { GetTransactionDetails } from 'services/api/Transactions'

export default () => {
  const {
    mutate: getTransactionDetails,
    data: getTransactionDetailsData,
    error: getTransactionDetailsError,
    isLoading: isGetTransactionDetailsLoading,
    isSuccess: isGetTransactionDetailsSuccess,
    isError: isGetTransactionDetailsError,
  } = useMutation(GetTransactionDetails)

  return {
    getTransactionDetails,
    getTransactionDetailsData,
    getTransactionDetailsError,
    isGetTransactionDetailsLoading,
    isGetTransactionDetailsSuccess,
    isGetTransactionDetailsError,
  }
}
