import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Views
import { Alert } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function Alerts(props) {
  // Destructure
  const { alert, actions } = props

  // Store States
  const {
    alertList: { open, message, type, duration },
  } = alert

  // Store Actions
  const { closeAlert } = actions

  // Functions
  const handleOnCloseAlert = () => closeAlert()

  return <Alert open={open} autoHideDuration={duration} type={type} message={message} onClose={handleOnCloseAlert} />
}

// Default Props
Alerts.defaultProps = {
  alert: '',
}

// Proptypes Validation
Alerts.propTypes = {
  alert: PropTypes.shape({ alertList: PropTypes.instanceOf(Object) }),
  actions: PropTypes.shape({ closeAlert: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)
