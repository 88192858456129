import { useMutation } from 'react-query'

// Services
import { GetTransactionChains } from 'services/api/Transactions'

export default () => {
  const {
    mutate: getTransactionChains,
    data: getTransactionChainsData,
    error: getTransactionChainsError,
    isLoading: isGetTransactionChainsLoading,
    isSuccess: isGetTransactionChainsSuccess,
    isError: isGetTransactionChainsError,
  } = useMutation(GetTransactionChains)

  return {
    getTransactionChains,
    getTransactionChainsData,
    getTransactionChainsError,
    isGetTransactionChainsLoading,
    isGetTransactionChainsSuccess,
    isGetTransactionChainsError,
  }
}
