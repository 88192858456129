import React from 'react'
import PropTypes from 'prop-types'

// Assets
import { EmptyIcon } from 'assets/images'

// Components
import { Button } from 'views/components'

// Styled Elements
import {
  ButtonWrapper,
  EmptyTablePlaceholderWrapper,
  EmptyTablePlaceholderImage,
  EmptyTablePlaceholderTextWrapper,
  EmptyTablePlaceholderText,
  EmptyTablePlaceholderSubText,
} from './EmptyTablePlaceholder.elements'

function EmptyTablePlaceholder(props) {
  const { setFilter, title, content } = props

  return (
    <EmptyTablePlaceholderWrapper>
      <EmptyTablePlaceholderImage>
        <EmptyIcon />
      </EmptyTablePlaceholderImage>
      <EmptyTablePlaceholderTextWrapper>
        <EmptyTablePlaceholderText>{title || 'No search result'}</EmptyTablePlaceholderText>
        <EmptyTablePlaceholderSubText>
          {content || 'Please, try a different combination of filters'}
        </EmptyTablePlaceholderSubText>
        {setFilter && (
          <ButtonWrapper>
            <Button onClick={() => setFilter()}>Reset Filters</Button>
          </ButtonWrapper>
        )}
      </EmptyTablePlaceholderTextWrapper>
    </EmptyTablePlaceholderWrapper>
  )
}

// Default Props
EmptyTablePlaceholder.defaultProps = {
  setFilter: null,
  title: '',
  content: '',
}

// Proptypes Validation
EmptyTablePlaceholder.propTypes = {
  setFilter: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
}

export default EmptyTablePlaceholder
