export const COUNTRIES_LIST = [
  {
    id: 1,
    label: 'Afghanistan',
    value: 'AF',
    default_timezone: 86,
    sort_order: 1,
  },
  {
    id: 2,
    label: 'Aland Islands',
    value: 'AX',
    default_timezone: 64,
    sort_order: 2,
  },
  {
    id: 3,
    label: 'Albania',
    value: 'AL',
    default_timezone: 52,
    sort_order: 3,
  },
  {
    id: 4,
    label: 'Algeria',
    value: 'DZ',
    default_timezone: 55,
    sort_order: 4,
  },
  {
    id: 5,
    label: 'American Samoa',
    value: 'AS',
    default_timezone: 2,
    sort_order: 5,
  },
  {
    id: 6,
    label: 'Andorra',
    value: 'AD',
    default_timezone: 51,
    sort_order: 6,
  },
  {
    id: 7,
    label: 'Angola',
    value: 'AO',
    default_timezone: 55,
    sort_order: 7,
  },
  {
    id: 8,
    label: 'Anguilla',
    value: 'AI',
    default_timezone: 30,
    sort_order: 8,
  },
  {
    id: 9,
    label: 'Antarctica',
    value: 'AQ',
    default_timezone: 31,
    sort_order: 9,
  },
  {
    id: 10,
    label: 'Antigua and Barbuda',
    value: 'AG',
    default_timezone: 30,
    sort_order: 10,
  },
  {
    id: 11,
    label: 'Argentina',
    value: 'AR',
    default_timezone: 36,
    sort_order: 11,
  },
  {
    id: 12,
    label: 'Armenia',
    value: 'AM',
    default_timezone: 85,
    sort_order: 12,
  },
  {
    id: 13,
    label: 'Aruba',
    value: 'AW',
    default_timezone: 30,
    sort_order: 13,
  },
  {
    id: 14,
    label: 'Australia',
    value: 'AU',
    default_timezone: 119,
    sort_order: 14,
  },
  {
    id: 15,
    label: 'Austria',
    value: 'AT',
    default_timezone: 51,
    sort_order: 15,
  },
  {
    id: 16,
    label: 'Azerbaijan',
    value: 'AZ',
    default_timezone: 79,
    sort_order: 16,
  },
  {
    id: 17,
    label: 'Bahamas',
    value: 'BS',
    default_timezone: 21,
    sort_order: 17,
  },
  {
    id: 18,
    label: 'Bahrain',
    value: 'BH',
    default_timezone: 72,
    sort_order: 18,
  },
  {
    id: 19,
    label: 'Bangladesh',
    value: 'BD',
    default_timezone: 95,
    sort_order: 19,
  },
  {
    id: 20,
    label: 'Barbados',
    value: 'BB',
    default_timezone: 30,
    sort_order: 20,
  },
  {
    id: 21,
    label: 'Belarus',
    value: 'BY',
    default_timezone: 73,
    sort_order: 21,
  },
  {
    id: 22,
    label: 'Belgium',
    value: 'BE',
    default_timezone: 53,
    sort_order: 22,
  },
  {
    id: 23,
    label: 'Belize',
    value: 'BZ',
    default_timezone: 14,
    sort_order: 23,
  },
  {
    id: 24,
    label: 'Benin',
    value: 'BJ',
    default_timezone: 55,
    sort_order: 24,
  },
  {
    id: 25,
    label: 'Bermuda',
    value: 'BM',
    default_timezone: 27,
    sort_order: 25,
  },
  {
    id: 26,
    label: 'Bhutan',
    value: 'BT',
    default_timezone: 95,
    sort_order: 26,
  },
  {
    id: 27,
    label: 'Bolivia',
    value: 'BO',
    default_timezone: 30,
    sort_order: 27,
  },
  {
    id: 28,
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    default_timezone: 30,
    sort_order: 28,
  },
  {
    id: 29,
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    default_timezone: 54,
    sort_order: 29,
  },
  {
    id: 30,
    label: 'Botswana',
    value: 'BW',
    default_timezone: 63,
    sort_order: 30,
  },
  {
    id: 31,
    label: 'Bouvet Island',
    value: 'BV',
    default_timezone: 46,
    sort_order: 31,
  },
  {
    id: 32,
    label: 'Brazil',
    value: 'BR',
    default_timezone: 34,
    sort_order: 32,
  },
  {
    id: 33,
    label: 'British Indian Ocean Territory',
    value: 'IO',
    default_timezone: 94,
    sort_order: 33,
  },
  {
    id: 34,
    label: 'Brunei Darussalam',
    value: 'BN',
    default_timezone: 106,
    sort_order: 34,
  },
  {
    id: 35,
    label: 'Bulgaria',
    value: 'BG',
    default_timezone: 64,
    sort_order: 35,
  },
  {
    id: 36,
    label: 'Burkina Faso',
    value: 'BF',
    default_timezone: 48,
    sort_order: 36,
  },
  {
    id: 37,
    label: 'Burundi',
    value: 'BI',
    default_timezone: 63,
    sort_order: 37,
  },
  {
    id: 38,
    label: 'Cambodia',
    value: 'KH',
    default_timezone: 98,
    sort_order: 38,
  },
  {
    id: 39,
    label: 'Cameroon',
    value: 'CM',
    default_timezone: 55,
    sort_order: 39,
  },
  {
    id: 40,
    label: 'Canada',
    value: 'CA',
    default_timezone: 21,
    sort_order: 40,
  },
  {
    id: 41,
    label: 'Cape Verde',
    value: 'CV',
    default_timezone: 45,
    sort_order: 41,
  },
  {
    id: 42,
    label: 'Cayman Islands',
    value: 'KY',
    default_timezone: 19,
    sort_order: 42,
  },
  {
    id: 43,
    label: 'Central African Republic',
    value: 'CF',
    default_timezone: 55,
    sort_order: 43,
  },
  {
    id: 44,
    label: 'Chad',
    value: 'TD',
    default_timezone: 55,
    sort_order: 44,
  },
  {
    id: 45,
    label: 'Chile',
    value: 'CL',
    default_timezone: 31,
    sort_order: 45,
  },
  {
    id: 46,
    label: 'China',
    value: 'CN',
    default_timezone: 104,
    sort_order: 46,
  },
  {
    id: 47,
    label: 'Christmas Island',
    value: 'CX',
    default_timezone: 98,
    sort_order: 47,
  },
  {
    id: 48,
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
    default_timezone: 97,
    sort_order: 48,
  },
  {
    id: 49,
    label: 'Colombia',
    value: 'CO',
    default_timezone: 19,
    sort_order: 49,
  },
  {
    id: 50,
    label: 'Comoros',
    value: 'KM',
    default_timezone: 75,
    sort_order: 50,
  },
  {
    id: 51,
    label: 'Congo',
    value: 'CG',
    default_timezone: 55,
    sort_order: 51,
  },
  {
    id: 52,
    label: 'Congo, Democratic Republic',
    value: 'CD',
    default_timezone: 55,
    sort_order: 52,
  },
  {
    id: 53,
    label: 'Cook Islands',
    value: 'CK',
    default_timezone: 4,
    sort_order: 53,
  },
  {
    id: 54,
    label: 'Costa Rica',
    value: 'CR',
    default_timezone: 14,
    sort_order: 54,
  },
  {
    id: 55,
    label: "Côte d'Ivoire",
    value: 'CI',
    default_timezone: 48,
    sort_order: 55,
  },
  {
    id: 56,
    label: 'Croatia',
    value: 'HR',
    default_timezone: 54,
    sort_order: 56,
  },
  {
    id: 57,
    label: 'Cuba',
    value: 'CU',
    default_timezone: 21,
    sort_order: 57,
  },
  {
    id: 58,
    label: 'Curaçao',
    value: 'CW',
    default_timezone: 30,
    sort_order: 58,
  },
  {
    id: 59,
    label: 'Cyprus',
    value: 'CY',
    default_timezone: 60,
    sort_order: 59,
  },
  {
    id: 60,
    label: 'Czech Republic',
    value: 'CZ',
    default_timezone: 52,
    sort_order: 60,
  },
  {
    id: 61,
    label: 'Denmark',
    value: 'DK',
    default_timezone: 53,
    sort_order: 61,
  },
  {
    id: 62,
    label: 'Djibouti',
    value: 'DJ',
    default_timezone: 75,
    sort_order: 62,
  },
  {
    id: 63,
    label: 'Dominica',
    value: 'DM',
    default_timezone: 30,
    sort_order: 63,
  },
  {
    id: 64,
    label: 'Dominican Republic',
    value: 'DO',
    default_timezone: 30,
    sort_order: 64,
  },
  {
    id: 65,
    label: 'Ecuador',
    value: 'EC',
    default_timezone: 19,
    sort_order: 65,
  },
  {
    id: 66,
    label: 'Egypt',
    value: 'EG',
    default_timezone: 59,
    sort_order: 66,
  },
  {
    id: 67,
    label: 'El Salvador',
    value: 'SV',
    default_timezone: 14,
    sort_order: 67,
  },
  {
    id: 68,
    label: 'Equatorial Guinea',
    value: 'GQ',
    default_timezone: 55,
    sort_order: 68,
  },
  {
    id: 69,
    label: 'Eritrea',
    value: 'ER',
    default_timezone: 75,
    sort_order: 69,
  },
  {
    id: 70,
    label: 'Estonia',
    value: 'EE',
    default_timezone: 64,
    sort_order: 70,
  },
  {
    id: 71,
    label: 'Ethiopia',
    value: 'ET',
    default_timezone: 75,
    sort_order: 71,
  },
  {
    id: 72,
    label: 'Falkland Islands (Malvinas)',
    value: 'FK',
    default_timezone: 35,
    sort_order: 72,
  },
  {
    id: 73,
    label: 'Faroe Islands',
    value: 'FO',
    default_timezone: 47,
    sort_order: 73,
  },
  {
    id: 74,
    label: 'Fiji',
    value: 'FJ',
    default_timezone: 133,
    sort_order: 74,
  },
  {
    id: 75,
    label: 'Finland',
    value: 'FI',
    default_timezone: 64,
    sort_order: 75,
  },
  {
    id: 76,
    label: 'France',
    value: 'FR',
    default_timezone: 53,
    sort_order: 76,
  },
  {
    id: 77,
    label: 'French Guiana',
    value: 'GF',
    default_timezone: 35,
    sort_order: 77,
  },
  {
    id: 78,
    label: 'French Polynesia',
    value: 'PF',
    default_timezone: 4,
    sort_order: 78,
  },
  {
    id: 79,
    label: 'French Southern Territories',
    value: 'TF',
    default_timezone: 87,
    sort_order: 79,
  },
  {
    id: 80,
    label: 'Gabon',
    value: 'GA',
    default_timezone: 55,
    sort_order: 80,
  },
  {
    id: 81,
    label: 'Gambia',
    value: 'GM',
    default_timezone: 48,
    sort_order: 81,
  },
  {
    id: 82,
    label: 'Georgia',
    value: 'GE',
    default_timezone: 83,
    sort_order: 82,
  },
  {
    id: 83,
    label: 'Germany',
    value: 'DE',
    default_timezone: 51,
    sort_order: 83,
  },
  {
    id: 84,
    label: 'Ghana',
    value: 'GH',
    default_timezone: 48,
    sort_order: 84,
  },
  {
    id: 85,
    label: 'Gibraltar',
    value: 'GI',
    default_timezone: 51,
    sort_order: 85,
  },
  {
    id: 86,
    label: 'Greece',
    value: 'GR',
    default_timezone: 57,
    sort_order: 86,
  },
  {
    id: 87,
    label: 'Greenland',
    value: 'GL',
    default_timezone: 37,
    sort_order: 87,
  },
  {
    id: 88,
    label: 'Grenada',
    value: 'GD',
    default_timezone: 30,
    sort_order: 88,
  },
  {
    id: 89,
    label: 'Guadeloupe',
    value: 'GP',
    default_timezone: 30,
    sort_order: 89,
  },
  {
    id: 90,
    label: 'Guam',
    value: 'GU',
    default_timezone: 120,
    sort_order: 90,
  },
  {
    id: 91,
    label: 'Guatemala',
    value: 'GT',
    default_timezone: 14,
    sort_order: 91,
  },
  {
    id: 92,
    label: 'Guernsey',
    value: 'GG',
    default_timezone: 47,
    sort_order: 92,
  },
  {
    id: 93,
    label: 'Guinea',
    value: 'GN',
    default_timezone: 48,
    sort_order: 93,
  },
  {
    id: 94,
    label: 'Guinea-Bissau',
    value: 'GW',
    default_timezone: 48,
    sort_order: 94,
  },
  {
    id: 95,
    label: 'Guyana',
    value: 'GY',
    default_timezone: 30,
    sort_order: 95,
  },
  {
    id: 96,
    label: 'Haiti',
    value: 'HT',
    default_timezone: 21,
    sort_order: 96,
  },
  {
    id: 97,
    label: 'Heard Island and McDonald Islands',
    value: 'HM',
    default_timezone: 81,
    sort_order: 97,
  },
  {
    id: 98,
    label: 'Holy See (Vatican City State)',
    value: 'VA',
    default_timezone: 51,
    sort_order: 98,
  },
  {
    id: 99,
    label: 'Honduras',
    value: 'HN',
    default_timezone: 14,
    sort_order: 99,
  },
  {
    id: 100,
    label: 'Hong Kong',
    value: 'HK',
    default_timezone: 104,
    sort_order: 100,
  },
  {
    id: 101,
    label: 'Hungary',
    value: 'HU',
    default_timezone: 52,
    sort_order: 101,
  },
  {
    id: 102,
    label: 'Iceland',
    value: 'IS',
    default_timezone: 48,
    sort_order: 102,
  },
  {
    id: 103,
    label: 'India',
    value: 'IN',
    default_timezone: 91,
    sort_order: 103,
  },
  {
    id: 104,
    label: 'Indonesia',
    value: 'id',
    default_timezone: 98,
    sort_order: 104,
  },
  {
    id: 105,
    label: 'Iran, Islamic Republic of',
    value: 'IR',
    default_timezone: 76,
    sort_order: 105,
  },
  {
    id: 106,
    label: 'Iraq',
    value: 'IQ',
    default_timezone: 70,
    sort_order: 106,
  },
  {
    id: 107,
    label: 'Ireland',
    value: 'IE',
    default_timezone: 47,
    sort_order: 107,
  },
  {
    id: 108,
    label: 'Isle of Man',
    value: 'IM',
    default_timezone: 47,
    sort_order: 108,
  },
  {
    id: 109,
    label: 'Israel',
    value: 'IL',
    default_timezone: 65,
    sort_order: 109,
  },
  {
    id: 110,
    label: 'Italy',
    value: 'IT',
    default_timezone: 51,
    sort_order: 110,
  },
  {
    id: 111,
    label: 'Jamaica',
    value: 'JM',
    default_timezone: 19,
    sort_order: 111,
  },
  {
    id: 112,
    label: 'Japan',
    value: 'JP',
    default_timezone: 112,
    sort_order: 112,
  },
  {
    id: 113,
    label: 'Jersey',
    value: 'JE',
    default_timezone: 47,
    sort_order: 113,
  },
  {
    id: 114,
    label: 'Jordan',
    value: 'JO',
    default_timezone: 56,
    sort_order: 114,
  },
  {
    id: 115,
    label: 'Kazakhstan',
    value: 'KZ',
    default_timezone: 94,
    sort_order: 115,
  },
  {
    id: 116,
    label: 'Kenya',
    value: 'KE',
    default_timezone: 75,
    sort_order: 116,
  },
  {
    id: 117,
    label: 'Kiribati',
    value: 'KI',
    default_timezone: 132,
    sort_order: 117,
  },
  {
    id: 118,
    label: "Korea, Democratic People's Republic of",
    value: 'KP',
    default_timezone: 114,
    sort_order: 118,
  },
  {
    id: 119,
    label: 'Korea, Republic of',
    value: 'KR',
    default_timezone: 114,
    sort_order: 119,
  },
  {
    id: 120,
    label: 'Kuwait',
    value: 'KW',
    default_timezone: 72,
    sort_order: 120,
  },
  {
    id: 121,
    label: 'Kyrgyzstan',
    value: 'KG',
    default_timezone: 94,
    sort_order: 121,
  },
  {
    id: 122,
    label: "Lao People's Democratic Republic",
    value: 'LA',
    default_timezone: 98,
    sort_order: 122,
  },
  {
    id: 123,
    label: 'Latvia',
    value: 'LV',
    default_timezone: 64,
    sort_order: 123,
  },
  {
    id: 124,
    label: 'Lebanon',
    value: 'LB',
    default_timezone: 58,
    sort_order: 124,
  },
  {
    id: 125,
    label: 'Lesotho',
    value: 'LS',
    default_timezone: 63,
    sort_order: 125,
  },
  {
    id: 126,
    label: 'Liberia',
    value: 'LR',
    default_timezone: 48,
    sort_order: 126,
  },
  {
    id: 127,
    label: 'Libya',
    value: 'LY',
    default_timezone: 60,
    sort_order: 127,
  },
  {
    id: 128,
    label: 'Liechtenstein',
    value: 'LI',
    default_timezone: 51,
    sort_order: 128,
  },
  {
    id: 129,
    label: 'Lithuania',
    value: 'LT',
    default_timezone: 64,
    sort_order: 129,
  },
  {
    id: 130,
    label: 'Luxembourg',
    value: 'LU',
    default_timezone: 51,
    sort_order: 130,
  },
  {
    id: 131,
    label: 'Macao',
    value: 'MO',
    default_timezone: 104,
    sort_order: 131,
  },
  {
    id: 132,
    label: 'Macedonia',
    value: 'MK',
    default_timezone: 54,
    sort_order: 132,
  },
  {
    id: 133,
    label: 'Madagascar',
    value: 'MG',
    default_timezone: 75,
    sort_order: 133,
  },
  {
    id: 134,
    label: 'Malawi',
    value: 'MW',
    default_timezone: 63,
    sort_order: 134,
  },
  {
    id: 135,
    label: 'Malaysia',
    value: 'MY',
    default_timezone: 106,
    sort_order: 135,
  },
  {
    id: 136,
    label: 'Maldives',
    value: 'MV',
    default_timezone: 87,
    sort_order: 136,
  },
  {
    id: 137,
    label: 'Mali',
    value: 'ML',
    default_timezone: 48,
    sort_order: 137,
  },
  {
    id: 138,
    label: 'Malta',
    value: 'MT',
    default_timezone: 51,
    sort_order: 138,
  },
  {
    id: 139,
    label: 'Marshall Islands',
    value: 'MH',
    default_timezone: 132,
    sort_order: 139,
  },
  {
    id: 140,
    label: 'Martinique',
    value: 'MQ',
    default_timezone: 30,
    sort_order: 140,
  },
  {
    id: 141,
    label: 'Mauritania',
    value: 'MR',
    default_timezone: 48,
    sort_order: 141,
  },
  {
    id: 142,
    label: 'Mauritius',
    value: 'MU',
    default_timezone: 81,
    sort_order: 142,
  },
  {
    id: 143,
    label: 'Mayotte',
    value: 'YT',
    default_timezone: 75,
    sort_order: 143,
  },
  {
    id: 144,
    label: 'Mexico',
    value: 'MX',
    default_timezone: 17,
    sort_order: 144,
  },
  {
    id: 145,
    label: 'Micronesia, Federated States of',
    value: 'FM',
    default_timezone: 120,
    sort_order: 145,
  },
  {
    id: 146,
    label: 'Moldova, Republic of',
    value: 'MD',
    default_timezone: 57,
    sort_order: 146,
  },
  {
    id: 147,
    label: 'Monaco',
    value: 'MC',
    default_timezone: 51,
    sort_order: 147,
  },
  {
    id: 148,
    label: 'Mongolia',
    value: 'MN',
    default_timezone: 109,
    sort_order: 148,
  },
  {
    id: 149,
    label: 'Montenegro',
    value: 'ME',
    default_timezone: 54,
    sort_order: 149,
  },
  {
    id: 150,
    label: 'Montserrat',
    value: 'MS',
    default_timezone: 30,
    sort_order: 150,
  },
  {
    id: 151,
    label: 'Morocco',
    value: 'MA',
    default_timezone: 50,
    sort_order: 151,
  },
  {
    id: 152,
    label: 'Mozambique',
    value: 'MZ',
    default_timezone: 63,
    sort_order: 152,
  },
  {
    id: 153,
    label: 'Myanmar',
    value: 'MM',
    default_timezone: 97,
    sort_order: 153,
  },
  {
    id: 154,
    label: 'Namibia',
    value: 'NA',
    default_timezone: 69,
    sort_order: 154,
  },
  {
    id: 155,
    label: 'Nauru',
    value: 'NR',
    default_timezone: 132,
    sort_order: 155,
  },
  {
    id: 156,
    label: 'Nepal',
    value: 'NP',
    default_timezone: 93,
    sort_order: 156,
  },
  {
    id: 157,
    label: 'Netherlands',
    value: 'NL',
    default_timezone: 51,
    sort_order: 157,
  },
  {
    id: 158,
    label: 'New Caledonia',
    value: 'NC',
    default_timezone: 129,
    sort_order: 158,
  },
  {
    id: 159,
    label: 'New Zealand',
    value: 'NZ',
    default_timezone: 131,
    sort_order: 159,
  },
  {
    id: 160,
    label: 'Nicaragua',
    value: 'NI',
    default_timezone: 14,
    sort_order: 160,
  },
  {
    id: 161,
    label: 'Niger',
    value: 'NE',
    default_timezone: 55,
    sort_order: 161,
  },
  {
    id: 162,
    label: 'Nigeria',
    value: 'NG',
    default_timezone: 55,
    sort_order: 162,
  },
  {
    id: 163,
    label: 'Niue',
    value: 'NU',
    default_timezone: 2,
    sort_order: 163,
  },
  {
    id: 164,
    label: 'Norfolk Island',
    value: 'NF',
    default_timezone: 129,
    sort_order: 164,
  },
  {
    id: 165,
    label: 'Northern Mariana Islands',
    value: 'MP',
    default_timezone: 120,
    sort_order: 165,
  },
  {
    id: 166,
    label: 'Norway',
    value: 'NO',
    default_timezone: 51,
    sort_order: 166,
  },
  {
    id: 167,
    label: 'Oman',
    value: 'OM',
    default_timezone: 77,
    sort_order: 167,
  },
  {
    id: 168,
    label: 'Pakistan',
    value: 'PK',
    default_timezone: 89,
    sort_order: 168,
  },
  {
    id: 169,
    label: 'Palau',
    value: 'PW',
    default_timezone: 112,
    sort_order: 169,
  },
  {
    id: 170,
    label: 'Palestine, State of',
    value: 'PS',
    default_timezone: 59,
    sort_order: 170,
  },
  {
    id: 171,
    label: 'Panama',
    value: 'PA',
    default_timezone: 19,
    sort_order: 171,
  },
  {
    id: 172,
    label: 'Papua New Guinea',
    value: 'PG',
    default_timezone: 120,
    sort_order: 172,
  },
  {
    id: 173,
    label: 'Paraguay',
    value: 'PY',
    default_timezone: 26,
    sort_order: 173,
  },
  {
    id: 174,
    label: 'Peru',
    value: 'PE',
    default_timezone: 19,
    sort_order: 174,
  },
  {
    id: 175,
    label: 'Philippines',
    value: 'PH',
    default_timezone: 106,
    sort_order: 175,
  },
  {
    id: 176,
    label: 'Pitcairn',
    value: 'PN',
    default_timezone: 10,
    sort_order: 176,
  },
  {
    id: 177,
    label: 'Poland',
    value: 'PL',
    default_timezone: 54,
    sort_order: 177,
  },
  {
    id: 178,
    label: 'Portugal',
    value: 'PT',
    default_timezone: 47,
    sort_order: 178,
  },
  {
    id: 179,
    label: 'Puerto Rico',
    value: 'PR',
    default_timezone: 30,
    sort_order: 179,
  },
  {
    id: 180,
    label: 'Qatar',
    value: 'QA',
    default_timezone: 72,
    sort_order: 180,
  },
  {
    id: 181,
    label: 'Réunion',
    value: 'RE',
    default_timezone: 81,
    sort_order: 181,
  },
  {
    id: 182,
    label: 'Romania',
    value: 'RO',
    default_timezone: 57,
    sort_order: 182,
  },
  {
    id: 183,
    label: 'Russian Federation',
    value: 'RU',
    default_timezone: 74,
    sort_order: 183,
  },
  {
    id: 184,
    label: 'Rwanda',
    value: 'RW',
    default_timezone: 63,
    sort_order: 184,
  },
  {
    id: 185,
    label: 'Saint Barthélemy',
    value: 'BL',
    default_timezone: 30,
    sort_order: 185,
  },
  {
    id: 186,
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
    default_timezone: 48,
    sort_order: 186,
  },
  {
    id: 187,
    label: 'Saint Kitts and Nevis',
    value: 'KN',
    default_timezone: 30,
    sort_order: 187,
  },
  {
    id: 188,
    label: 'Saint Lucia',
    value: 'LC',
    default_timezone: 30,
    sort_order: 188,
  },
  {
    id: 189,
    label: 'Saint Martin (French part)',
    value: 'MF',
    default_timezone: 30,
    sort_order: 189,
  },
  {
    id: 190,
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
    default_timezone: 37,
    sort_order: 190,
  },
  {
    id: 191,
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
    default_timezone: 30,
    sort_order: 191,
  },
  {
    id: 192,
    label: 'Samoa',
    value: 'WS',
    default_timezone: 138,
    sort_order: 192,
  },
  {
    id: 193,
    label: 'San Marino',
    value: 'SM',
    default_timezone: 51,
    sort_order: 193,
  },
  {
    id: 194,
    label: 'Sao Tome and Principe',
    value: 'ST',
    default_timezone: 48,
    sort_order: 194,
  },
  {
    id: 195,
    label: 'Saudi Arabia',
    value: 'SA',
    default_timezone: 72,
    sort_order: 195,
  },
  {
    id: 196,
    label: 'Senegal',
    value: 'SN',
    default_timezone: 48,
    sort_order: 196,
  },
  {
    id: 197,
    label: 'Serbia',
    value: 'RS',
    default_timezone: 52,
    sort_order: 197,
  },
  {
    id: 198,
    label: 'Seychelles',
    value: 'SC',
    default_timezone: 81,
    sort_order: 198,
  },
  {
    id: 199,
    label: 'Sierra Leone',
    value: 'SL',
    default_timezone: 48,
    sort_order: 199,
  },
  {
    id: 200,
    label: 'Singapore',
    value: 'SG',
    default_timezone: 106,
    sort_order: 200,
  },
  {
    id: 201,
    label: 'Sint Maarten (Dutch part)',
    value: 'SX',
    default_timezone: 30,
    sort_order: 201,
  },
  {
    id: 202,
    label: 'Slovakia',
    value: 'SK',
    default_timezone: 52,
    sort_order: 202,
  },
  {
    id: 203,
    label: 'Slovenia',
    value: 'SI',
    default_timezone: 52,
    sort_order: 203,
  },
  {
    id: 204,
    label: 'Solomon Islands',
    value: 'SB',
    default_timezone: 129,
    sort_order: 204,
  },
  {
    id: 205,
    label: 'Somalia',
    value: 'SO',
    default_timezone: 75,
    sort_order: 205,
  },
  {
    id: 206,
    label: 'South Africa',
    value: 'ZA',
    default_timezone: 63,
    sort_order: 206,
  },
  {
    id: 207,
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    default_timezone: 42,
    sort_order: 207,
  },
  {
    id: 208,
    label: 'South Sudan',
    value: 'SS',
    default_timezone: 75,
    sort_order: 208,
  },
  {
    id: 209,
    label: 'Spain',
    value: 'ES',
    default_timezone: 53,
    sort_order: 209,
  },
  {
    id: 210,
    label: 'Sri Lanka',
    value: 'LK',
    default_timezone: 92,
    sort_order: 210,
  },
  {
    id: 211,
    label: 'Sudan',
    value: 'SD',
    default_timezone: 75,
    sort_order: 211,
  },
  {
    id: 212,
    label: 'Suriname',
    value: 'SR',
    default_timezone: 35,
    sort_order: 212,
  },
  {
    id: 213,
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
    default_timezone: 51,
    sort_order: 213,
  },
  {
    id: 214,
    label: 'Swaziland',
    value: 'SZ',
    default_timezone: 63,
    sort_order: 214,
  },
  {
    id: 215,
    label: 'Sweden',
    value: 'SE',
    default_timezone: 51,
    sort_order: 215,
  },
  {
    id: 216,
    label: 'Switzerland',
    value: 'CH',
    default_timezone: 51,
    sort_order: 216,
  },
  {
    id: 217,
    label: 'Syrian Arab Republic',
    value: 'SY',
    default_timezone: 61,
    sort_order: 217,
  },
  {
    id: 218,
    label: 'Taiwan, Province of China',
    value: 'TW',
    default_timezone: 108,
    sort_order: 218,
  },
  {
    id: 219,
    label: 'Tajikistan',
    value: 'TJ',
    default_timezone: 87,
    sort_order: 219,
  },
  {
    id: 220,
    label: 'Tanzania, United Republic of',
    value: 'TZ',
    default_timezone: 75,
    sort_order: 220,
  },
  {
    id: 221,
    label: 'Thailand',
    value: 'TH',
    default_timezone: 98,
    sort_order: 221,
  },
  {
    id: 222,
    label: 'Timor-Leste',
    value: 'TL',
    default_timezone: 112,
    sort_order: 222,
  },
  {
    id: 223,
    label: 'Togo',
    value: 'TG',
    default_timezone: 48,
    sort_order: 223,
  },
  {
    id: 224,
    label: 'Tokelau',
    value: 'TK',
    default_timezone: 137,
    sort_order: 224,
  },
  {
    id: 225,
    label: 'Tonga',
    value: 'TO',
    default_timezone: 137,
    sort_order: 225,
  },
  {
    id: 226,
    label: 'Trinidad and Tobago',
    value: 'TT',
    default_timezone: 30,
    sort_order: 226,
  },
  {
    id: 227,
    label: 'Tunisia',
    value: 'TN',
    default_timezone: 55,
    sort_order: 227,
  },
  {
    id: 228,
    label: 'Turkey',
    value: 'TR',
    default_timezone: 71,
    sort_order: 228,
  },
  {
    id: 229,
    label: 'Turkmenistan',
    value: 'TM',
    default_timezone: 87,
    sort_order: 229,
  },
  {
    id: 230,
    label: 'Turks and Caicos Islands',
    value: 'TC',
    default_timezone: 21,
    sort_order: 230,
  },
  {
    id: 231,
    label: 'Tuvalu',
    value: 'TV',
    default_timezone: 132,
    sort_order: 231,
  },
  {
    id: 232,
    label: 'Uganda',
    value: 'UG',
    default_timezone: 75,
    sort_order: 232,
  },
  {
    id: 233,
    label: 'Ukraine',
    value: 'UA',
    default_timezone: 64,
    sort_order: 233,
  },
  {
    id: 234,
    label: 'United Arab Emirates',
    value: 'AE',
    default_timezone: 77,
    sort_order: 234,
  },
  {
    id: 235,
    label: 'United Kingdom',
    value: 'UK',
    default_timezone: 47,
    sort_order: 235,
  },
  {
    id: 236,
    label: 'United States',
    value: 'US',
    default_timezone: 10,
    sort_order: 236,
  },
  {
    id: 237,
    label: 'United States Minor Outlying Islands',
    value: 'UM',
    default_timezone: 2,
    sort_order: 237,
  },
  {
    id: 238,
    label: 'Uruguay',
    value: 'UY',
    default_timezone: 38,
    sort_order: 238,
  },
  {
    id: 239,
    label: 'Uzbekistan',
    value: 'UZ',
    default_timezone: 87,
    sort_order: 239,
  },
  {
    id: 240,
    label: 'Vanuatu',
    value: 'VU',
    default_timezone: 129,
    sort_order: 240,
  },
  {
    id: 241,
    label: 'Venezuela, Bolivarian Republic of',
    value: 'VE',
    default_timezone: 28,
    sort_order: 241,
  },
  {
    id: 242,
    label: 'Viet Nam',
    value: 'VN',
    default_timezone: 98,
    sort_order: 242,
  },
  {
    id: 243,
    label: 'Virgin Islands, British',
    value: 'VG',
    default_timezone: 30,
    sort_order: 243,
  },
  {
    id: 244,
    label: 'Virgin Islands, U.S.',
    value: 'VI',
    default_timezone: 30,
    sort_order: 244,
  },
  {
    id: 245,
    label: 'Wallis and Futuna',
    value: 'WF',
    default_timezone: 132,
    sort_order: 245,
  },
  {
    id: 246,
    label: 'Western Sahara',
    value: 'EH',
    default_timezone: 55,
    sort_order: 246,
  },
  {
    id: 247,
    label: 'Yemen',
    value: 'YE',
    default_timezone: 72,
    sort_order: 247,
  },
  {
    id: 248,
    label: 'Zambia',
    value: 'ZM',
    default_timezone: 63,
    sort_order: 248,
  },
  {
    id: 249,
    label: 'Zimbabwe',
    value: 'ZW',
    default_timezone: 63,
    sort_order: 249,
  },
]
