import styled from 'styled-components'

export const DataBreakdownSummaryWrapper = styled.div`
  width: 100%;
`

export const DataBreakdownSummaryInner = styled.div`
  width: 100%;
  color: #393e46;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
`

export const DataBreakdownTitle = styled.div`
  flex: 0 0 100%;
  font-size: 15px;

  // TODO: Use mixins
  &:first-child {
    font-weight: 300;
  }
`

export const TotalFigureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;

  // Themed Properties
  border-top: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};
`

export const TotalFigureValue = styled.div`
  font-size: 45px;
  font-weight: 300;
`

export const TotalFigureHeader = styled.div`
  text-align: right;
`

export const TotalFigureTitle = styled.div`
  font-weight: 100;
  font-size: 15px;
  line-height: 16px;
`

export const TotalFigureSubtitle = styled.div`
  font-weight: 100;
  font-size: 15px;
  line-height: 16px;
`
