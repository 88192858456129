import styled from 'styled-components'

export const SeverityChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;
  max-height: 210px;
  padding: 10px;
  border-radius: 5px;

  font-size: 15px;
  font-weight: 100;

  background-color: white;
  border: ${({ theme }) => `solid 1px ${theme.colors.lightGray}`}
`

export const SeverityChartRowWrapper = styled.div`
  height: 130px;
  display: flex;
  gap: 10px;
  padding: 0 20px;
  width: 90%;
  flex: 1 1 auto;
`

export const SeverityChartRowContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const SeverityChartColWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  flex: 1 1 auto;

  gap: 10px;
  width: 100%;
  max-width: 50px;
  padding: 10px 0;

  text-align: right;
`

export const SeverityChartTitle = styled.div`
  font-size: 12px;
  font-weight: 500;

  margin-bottom: 5px;

  color: ${({ theme }) => theme.colors.darkGray}
`

export const SeverityChartText = styled.div`
  font-size: 12px;
`
