import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ChartWrapper, ColWrapper } from './FinancialLineChart.elements'

// Views
import { LineChart } from 'views/components'

function FinancialLineChart(props) {
  // Destructure
  const { data, options } = props

  return (
    <ChartWrapper>
      <ColWrapper>
        <LineChart data={data} width={200} height={200} options={options} />
      </ColWrapper>
    </ChartWrapper>
  )
}

// Default Props
FinancialLineChart.defaultProps = {
  data: { label: [], datasets: [] },
  options: {},
}

// Proptypes Validation
FinancialLineChart.propTypes = {
  data: PropTypes.shape({}),
  options: PropTypes.shape({}),
}

export default FinancialLineChart
