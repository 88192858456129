import React, { useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'

// Store
import { actions } from 'core/store'

// Styled Elements
import { AddPolicyFormWrapper, ButtonWrapper } from './AddPolicyForm.elements'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Views
import { FormTextField, Button, Form } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function AddPolicyForm(props) {
  // Destructure
  const { isCreatePolicyActive, setIsCreatePolicyActive, onSubmit } = props

  // hooks
  const [policiesList, setPoliciesList] = useLocalStorage('policies', [])

  // Variables
  const initialValues = {
    policyTitle: '',
    policyDescription: '',
  }
  const validationSchema = Yup.object().shape({})

  // Internal State
  const [formValues] = useState(initialValues)

  // functions
  const handleOnSubmit = (values) => {
    if (!values.policyTitle || !values.policyDescription) return setIsCreatePolicyActive(false)
    const policyItem = {
      policy_id: uuid(),
      policy_info: {
        ...values,
        status: 'inactive',
      },
      scenarios: [],
    }
    setPoliciesList([policyItem, ...policiesList])
    setIsCreatePolicyActive(false)
    onSubmit({ values: [policyItem, ...policiesList] })
  }

  return (
    <AddPolicyFormWrapper isCreatePolicyActive={isCreatePolicyActive}>
      <Form
        enableReinitialize
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        autoComplete="off"
      >
        <FormTextField label="Policy Title" type="text" name="policyTitle" disabled={!isCreatePolicyActive} required />
        <FormTextField
          label="Policy Description"
          type="text"
          name="policyDescription"
          disabled={!isCreatePolicyActive}
          required
        />
        <ButtonWrapper>
          <Button variant="outlined" onClick={() => setIsCreatePolicyActive(false)}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </ButtonWrapper>
      </Form>
    </AddPolicyFormWrapper>
  )
}

// Default Props
AddPolicyForm.defaultProps = {
  ui: {},
  formData: {},
  actions: {},
  form: {},
  setIsCreatePolicyActive: () => {},
  isCreatePolicyActive: false,
}

// Proptypes Validation
AddPolicyForm.propTypes = {
  ui: PropTypes.shape({ isLinkAddressDrawerOpen: PropTypes.bool }),
  formData: PropTypes.shape({
    transfer: PropTypes.shape({
      in_hash_address: PropTypes.string,
    }),
    account: PropTypes.shape({
      external_id: PropTypes.string,
      account_type_desc: PropTypes.string,
      display_name: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setToBeUpdatedRecipient: PropTypes.func,
  }),
  form: PropTypes.shape({
    createdAccount: PropTypes.shape({}),
  }),
  setIsCreatePolicyActive: PropTypes.func,
  onSubmit: PropTypes.func,
  isCreatePolicyActive: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPolicyForm)
