import styled from 'styled-components'

export const TabsOuterWrapper = styled.div`
  display: flex;
  width: fit-content;
  color: ${({ theme }) => theme.colors.darkGray} !important;
  font-size: 14px;
  border-radius: 10px;
  background-color: ${({ theme }) => `${theme.colors.white}`};
`

export const TabsWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding: 4px;
  border-radius: 10px;
  background-color: ${({ theme }) => `${theme.colors.gray}20`};
`
export const TabsItemContainer = styled.div``
export const TabsItem = styled.div`
  padding: 8px 24px;
  border-radius: 7px;
  font-weight: 600;
  cursor: pointer;
  &.active {
    background-color: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.blue};
  }
`
