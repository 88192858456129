import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
`
// TODO add variants
export const ButtonContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
  color: ${({ theme }) => theme.colors.darkGray};
  padding: 5px;
  border-radius: 10px;

  & button:first-child {
    padding: 3px 10px;
    border-radius: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.white};
  }
  & button:last-child {
    padding: 3px 5px;
  }

  & button {
    background-color: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => `2px solid ${theme.colors.white}`};
    color: ${({ theme }) => theme.colors.darkGray};
    & svg > * {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }
  }
  & button:hover {
    opacity: 0.8;
    background-color: ${({ theme }) => theme.colors.lightGray};
    border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
    color: ${({ theme }) => theme.colors.darkGray};

    & svg > * {
      stroke: ${({ theme }) => theme.colors.darkGray};
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
    &:first-child {
      border-radius: 5px 0 0 5px;
    }
  }
`
export const ButtonColContainer = styled.div`
  position: absolute;
  margin-top: 5px;
  top: 100%;
  right: 0;
  width: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  padding: 10px;

  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
  border-radius: 10px;

  & button {
    width: 100%;
    background-color: transparent;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  & button:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.blue};
    border: none;
  }
`

export const ButtonSeperator = styled.div`
  width: 100%;
  border-top: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
  height: 2px;
`
