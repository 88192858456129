import React from 'react'

// Constants
// import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Styled Elements
import {
  Wrapper,
  InnerWrapper,
  LogoLink,
  Title,
  FormWrapper,
  FormInnerWrapper,
  FormContent,
  // FormFooterLink,
} from './UpdatePassword.elements'

// Components
import { UpdatePasswordForm } from 'views/layouts'

function UpdatePassword() {
  return (
    <Wrapper>
      <InnerWrapper>
        <FormWrapper>
          <FormInnerWrapper>
            <FormContent>
              <LogoLink>
                <OspreeLogoIcon />
              </LogoLink>
              <Title>Update Password</Title>
              <UpdatePasswordForm />
            </FormContent>
          </FormInnerWrapper>
        </FormWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

// Default Props
UpdatePassword.defaultProps = {}

// Proptypes Validation
UpdatePassword.propTypes = {}

export default UpdatePassword
