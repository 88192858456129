import { useMutation } from 'react-query'

// Services
import { ConfirmVaspUserEmail } from 'services/api/Auth'

export default () => {
  const {
    mutate: confirmVaspUserEmail,
    data: confirmVaspUserEmailData,
    error: confirmVaspUserEmailError,
    isLoading: isConfirmVaspUserEmailLoading,
    isSuccess: isConfirmVaspUserEmailSuccess,
    isError: isConfirmVaspUserEmailError,
  } = useMutation(ConfirmVaspUserEmail)

  return {
    confirmVaspUserEmail,
    confirmVaspUserEmailData,
    confirmVaspUserEmailError,
    isConfirmVaspUserEmailLoading,
    isConfirmVaspUserEmailSuccess,
    isConfirmVaspUserEmailError,
  }
}
