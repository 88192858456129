import styled from 'styled-components'

import { UncontrolledDropdown } from 'reactstrap'

export const UncontrolledDropdownWrapper = styled(UncontrolledDropdown)`
  display: flex;
  align-items: center;

  & > div {
    padding: 0;
  }
`
