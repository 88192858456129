import { useMutation } from 'react-query'

// Services
import { GetAllAccounts } from 'services/api/Accounts'

export default () => {
  const {
    mutate: getAllAccounts,
    data: allAccountsData,
    error: allAccountsError,
    isLoading: isAllAccountsLoading,
    isSuccess: isAllAccountsSuccess,
    isError: isAllAccountsError,
  } = useMutation(GetAllAccounts)

  return {
    getAllAccounts,
    allAccountsData,
    allAccountsError,
    isAllAccountsLoading,
    isAllAccountsSuccess,
    isAllAccountsError,
  }
}
