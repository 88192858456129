import { useMutation } from 'react-query'

// Services
import { NotifyVasp } from 'services/api/Admin'

export default () => {
  const {
    mutate: notifyVasp,
    data: notifyVaspData,
    error: notifyVaspError,
    isLoading: isNotifyVaspLoading,
    isSuccess: isNotifyVaspSuccess,
    isError: isNotifyVaspError,
  } = useMutation(NotifyVasp)

  return {
    notifyVasp,
    notifyVaspData,
    notifyVaspError,
    isNotifyVaspLoading,
    isNotifyVaspSuccess,
    isNotifyVaspError,
  }
}
