import { useMutation } from 'react-query'

// Services
import { LinkAddress } from 'services/api/Addresses'

export default () => {
  const {
    mutate: linkAddress,
    data: linkAddressData,
    error: linkAddressError,
    isLoading: isLinkAddressLoading,
    isSuccess: isLinkAddressSuccess,
    isError: isLinkAddressError,
  } = useMutation(LinkAddress)

  return {
    linkAddress,
    linkAddressData,
    linkAddressError,
    isLinkAddressLoading,
    isLinkAddressSuccess,
    isLinkAddressError,
  }
}
