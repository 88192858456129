import React from 'react'

// Constants
// import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Styled Elements
import {
  LoginWrapper,
  LoginInnerWrapper,
  LoginLogoLink,
  LoginTitle,
  LoginFormWrapper,
  LoginFormInnerWrapper,
  LoginFormContent,
  LoginFormFooterText,
  LoginFormFooterLink,
} from './Login.elements'

// Components
import { LoginForm } from 'views/layouts'

function Login() {
  return (
    <LoginWrapper>
      <LoginInnerWrapper>
        <LoginFormWrapper>
          <LoginFormInnerWrapper>
            <LoginFormContent>
              <LoginLogoLink>
                <OspreeLogoIcon />
              </LoginLogoLink>
              <LoginTitle>Sign in to Ospree</LoginTitle>
              <LoginForm />
              <LoginFormFooterText>
                Don&apos;t have an account?&nbsp;&nbsp;
                <LoginFormFooterLink href="https://ospree.io/">Contact us here. </LoginFormFooterLink>
              </LoginFormFooterText>
            </LoginFormContent>
          </LoginFormInnerWrapper>
        </LoginFormWrapper>
      </LoginInnerWrapper>
    </LoginWrapper>
  )
}

// Default Props
Login.defaultProps = {}

// Proptypes Validation
Login.propTypes = {}

export default Login
