import styled from 'styled-components'

export const TableFilterFormWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  // If there is space then it's for the child
  // If there is no space then it's for the current component
  &#accountAddressTableFilter {
    & > div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  & #travelruleDateFilter {
    justify-content: center;
    & > div {
      max-width: none !important;
    }
  }
  & #travelRuleSort {
    min-width: 245px;
    & .simpleSelectInputLabel {
      min-width: 165px;
    }
  }
  & #travelRuleAssetSort {
    & .simpleSelectInputLabel {
      min-width: 130px;
    }
  }
  & #addressDateFilter {
    & > div {
      max-width: none !important;
      padding: 0;
      height: 100%;
      gap: 10px;

      & p {
        font-size: 12px !important;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  & #VASPSort {
    min-width: 200px;
    & .simpleSelectInputLabel {
      min-width: 120px;
    }
  }
`
export const FilterWrapper = styled.div``
