import { useMutation } from 'react-query'

// Services
import { PatchWhitelistingAddressNotes } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: patchWhitelistingAddressNotes,
    data: whitelistingAddressNotesData,
    error: whitelistingAddressNotesError,
    isLoading: isWhitelistingAddressNotesLoading,
    isSuccess: isWhitelistingAddressNotesSuccess,
    isError: isWhitelistingAddressNotesError,
  } = useMutation(PatchWhitelistingAddressNotes)

  return {
    patchWhitelistingAddressNotes,
    whitelistingAddressNotesData,
    whitelistingAddressNotesError,
    isWhitelistingAddressNotesLoading,
    isWhitelistingAddressNotesSuccess,
    isWhitelistingAddressNotesError,
  }
}
