/* eslint-disable react/forbid-prop-types */
import React, {
  useEffect,
  // useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Constants
// import { MODULE_ID_MAPPING } from 'common/constants/formOptions'

// Styled Elements
import { Wrapper, Container } from './TransactionsTableItemDetails.elements'

// Hooks
import { useGetTransactionDetails } from 'core/hooks/api'

// Layouts
// import { TransactionDetailsSubheader } from 'views/layouts'

// Internal Components
import { DetailsSummary } from './ItemDetailsComponents'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TransactionsTableItemDetails(props) {
  // Destructure
  const {
    actions,
    isExpanded,
    eventDetails,
    setEventDetails,
    riskData,
    setRiskData,
    transactionData,
    setTransactionData,
    senderAccountData,
    // setSenderAccountData,
    recipientAccountData,
    // setRecipientAccountData,
    // businessNotes,
    // setBusinessNotes,
  } = props

  const { showAlert } = actions

  // Hooks
  const {
    getTransactionDetails,
    getTransactionDetailsData,
    getTransactionDetailsError,
    isGetTransactionDetailsLoading,
  } = useGetTransactionDetails()

  // Local state
  // const [page, setPage] = useState(0)

  // Functions

  // UseEffect
  useEffect(() => {
    if (isExpanded && !riskData) {
      getTransactionDetails({ transaction_id: transactionData.id })
    }
  }, [isExpanded])
  useEffect(() => {
    if (getTransactionDetailsData) {
      setRiskData(getTransactionDetailsData.risk_data)
      setTransactionData({ ...transactionData, ...getTransactionDetailsData, risk_data: null })
    }
  }, [getTransactionDetailsData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getTransactionDetailsError) showAlert({ type: 'error', message: 'An error occured in fetching transactions' })
  }, [getTransactionDetailsError])
  return (
    <Wrapper>
      <Container>
        {/* <TransactionDetailsSubheader page={page} setPage={setPage} /> */}
        {/* {page === 0 && ( */}
        <DetailsSummary
          riskData={riskData}
          setRiskData={setRiskData}
          transactionData={transactionData}
          setTransactionData={setTransactionData}
          senderAccountData={senderAccountData}
          recipientAccountData={recipientAccountData}
          isGetTransactionDetailsLoading={isGetTransactionDetailsLoading}
          isExpanded={isExpanded}
          eventDetails={eventDetails}
          setEventDetails={setEventDetails}
        />
        {/* )} */}
        {/* {page === 1 && (
          <LinkedAccount
            senderAccountData={senderAccountData}
            recipientAccountData={recipientAccountData}
            setSenderAccountData={setSenderAccountData}
            setRecipientAccountData={setRecipientAccountData}
            address_data={transactionData}
            isExpanded={isExpanded}
            // isLoading={isAccountLoading || isRecipientLoading}
            // isFetchError={accountError || recipientError}
          />
        )}
        {page === 2 && (
          <Events isGetTransactionEventsLoading={isGetTransactionEventsLoading} financialData={financialData} />
        )} */}
        {/* {page === 3 && (
          <BusinessNotes
            address_data={transactionData}
            isExpanded={isExpanded}
            businessNotes={businessNotes}
            setBusinessNotes={setBusinessNotes}
            isLoading={isGetNoteLoading}
            isFetchError={getNoteError}
          />
        )} */}
      </Container>
    </Wrapper>
  )
}

// Default Props
TransactionsTableItemDetails.defaultProps = {
  riskData: null,
  setRiskData: () => {},
  transactionData: null,
  setTransactionData: () => {},
  actions: {},
  filters: {},
  ui: {},
  isExpanded: false,
}

// Proptypes Validation
TransactionsTableItemDetails.propTypes = {
  eventDetails: PropTypes.shape({
    inputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
    outputs: PropTypes.shape({
      events: PropTypes.arrayOf({}),
      page: PropTypes.number,
    }),
  }),
  setEventDetails: PropTypes.func,
  riskData: PropTypes.instanceOf(Object),
  setRiskData: PropTypes.func,
  transactionData: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    chain: PropTypes.string,
    symbol: PropTypes.string,
    risk_score: PropTypes.number,
    risk_ratio: PropTypes.number,
    input_usd: PropTypes.number,
    output_usd: PropTypes.number,
    fee_amount_usd: PropTypes.number,
    balance: PropTypes.number,
    modify_tz: PropTypes.string,
    create_tz: PropTypes.string,
    sender_link_type: PropTypes.number,
    sender_link_id: PropTypes.string,
    recipient_link_type: PropTypes.number,
    recipient_link_id: PropTypes.string,
  }),
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
    setTransactionEventsFilters: PropTypes.func,
  }),
  senderAccountData: PropTypes.shape({}),
  recipientAccountData: PropTypes.shape({}),
  // businessNotes: PropTypes.arrayOf({}),
  // setBusinessNotes: PropTypes.func,
  setTransactionData: PropTypes.func,
  filters: PropTypes.shape({
    transactionEventsFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  isExpanded: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTableItemDetails)
