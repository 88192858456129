import { useMutation } from 'react-query'

// Services
import { CreateOrUpdateTransaction } from 'services/api/Transactions'

export default () => {
  const {
    mutate: createOrUpdateTransaction,
    data: createOrUpdateTransactionData,
    error: createOrUpdateTransactionError,
    isLoading: isCreateOrUpdateTransactionLoading,
    isSuccess: isCreateOrUpdateTransactionSuccess,
    isError: isCreateOrUpdateTransactionError,
  } = useMutation(CreateOrUpdateTransaction)

  return {
    createOrUpdateTransaction,
    createOrUpdateTransactionData,
    createOrUpdateTransactionError,
    isCreateOrUpdateTransactionLoading,
    isCreateOrUpdateTransactionSuccess,
    isCreateOrUpdateTransactionError,
  }
}
