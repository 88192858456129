import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { TabPaneWrapper } from './TabPane.elements'

function TabPane(props) {
  // Destructure
  const { id, children } = props

  return <TabPaneWrapper tabId={id}>{children}</TabPaneWrapper>
}

// Default Props
TabPane.defaultProps = {
  id: '1',
  children: '',
}

// Proptypes Validation
TabPane.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default TabPane
