import { authAxios } from 'common/utils/axios'
import { NOTE_ENDPOINTS } from '../../common/constants/apiUrls'

const { CREATE_NOTE, GET_NOTE } = NOTE_ENDPOINTS

export const CreateNote = async ({ note, module_id, record_id, business_id, created_by }) => {
  const response = await authAxios.post(CREATE_NOTE, {
    note,
    module_id,
    record_id,
    business_id,
    created_by,
  })

  const { data } = response.data

  return data
}
export const GetNote = async ({ module_id, start_range, end_range, page, size, sort }) => {
  const response = await authAxios.get(GET_NOTE, {
    params: {
      module_id: module_id && module_id,
      start_range: start_range && start_range,
      end_range: end_range && end_range,
      page: page && page,
      size: size && size,
      sort: sort && sort,
    },
  })

  const { data } = response.data

  return data
}
