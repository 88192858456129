/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

// Styled Elements
import { TableButtonWrapper, TableRowWrapper, TableRowContainer, TableText } from './CasesTableHeader.elements'

function CasesTableHeader() {
  return (
    <TableButtonWrapper>
      <TableRowWrapper>
        <TableRowContainer>
          <TableText>Case ID</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Severity</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Status</TableText>
        </TableRowContainer>
        <TableRowContainer>
          <TableText>Summary</TableText>
        </TableRowContainer>
        <TableRowContainer style={{ marginLeft: '-36px' }}>
          <TableText>Timestamp</TableText>
        </TableRowContainer>
      </TableRowWrapper>
    </TableButtonWrapper>
  )
}

// Default Props
CasesTableHeader.defaultProps = {}

// Proptypes Validation
CasesTableHeader.propTypes = {}
export default CasesTableHeader
