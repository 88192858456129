import { useMutation } from 'react-query'

// Services
import { GetWhitelistingAddressReport } from 'services/api/Whitelisting'

export default () => {
  const {
    mutate: getWhitelistingAddressReport,
    data: whitelistingAddressReportData,
    error: whitelistingAddressReportError,
    isLoading: isWhitelistingAddressReportLoading,
    isSuccess: isWhitelistingAddressReportSuccess,
    isError: isWhitelistingAddressReportError,
  } = useMutation(GetWhitelistingAddressReport)

  return {
    getWhitelistingAddressReport,
    whitelistingAddressReportData,
    whitelistingAddressReportError,
    isWhitelistingAddressReportLoading,
    isWhitelistingAddressReportSuccess,
    isWhitelistingAddressReportError,
  }
}
