import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  DataBreakdownSummaryWrapper,
  DataBreakdownSummaryInner,
  DataBreakdownTitle,
  TotalFigureWrapper,
  TotalFigureValue,
  TotalFigureHeader,
  TotalFigureTitle,
  TotalFigureSubtitle,
} from './DataBreakdownSummary.elements'

// Views
import { DataBreakdownTable } from 'views/components'

function DataBreakdownSummary(props) {
  // Destructure
  const { title, figureTotalValue, figureTitle, figureSubtitle, breakdownData } = props

  return (
    <DataBreakdownSummaryWrapper>
      <DataBreakdownSummaryInner>
        <DataBreakdownTitle>{title}</DataBreakdownTitle>
        <TotalFigureWrapper>
          <TotalFigureValue>{figureTotalValue}</TotalFigureValue>
          <TotalFigureHeader>
            <TotalFigureTitle>{figureTitle}</TotalFigureTitle>
            <TotalFigureSubtitle>{figureSubtitle}</TotalFigureSubtitle>
          </TotalFigureHeader>
        </TotalFigureWrapper>
        <DataBreakdownTable data={breakdownData} />
      </DataBreakdownSummaryInner>
    </DataBreakdownSummaryWrapper>
  )
}

// Default Props
DataBreakdownSummary.defaultProps = {
  title: 'Title',
  figureTotalValue: 0,
  figureTitle: 'Figure Title',
  figureSubtitle: 'Figure Subtitle',
  breakdownData: [],
}

// Proptypes Validation
DataBreakdownSummary.propTypes = {
  title: PropTypes.string,
  figureTotalValue: PropTypes.number,
  figureTitle: PropTypes.string,
  figureSubtitle: PropTypes.string,
  breakdownData: PropTypes.instanceOf(Array),
}

export default DataBreakdownSummary
