import React from 'react'
import {
  NoAccessWrapper,
  NoAccessContainer,
  NoAccessContent,
  NoAccessContentContainer,
  NoAccessHeader,
  NoAccessHeaderContainer,
} from './NoAccess.elements'

function NoAccess() {
  return (
    <NoAccessWrapper>
      <NoAccessContainer>
        <NoAccessHeaderContainer>
          <NoAccessHeader>Authorization Needed</NoAccessHeader>
        </NoAccessHeaderContainer>

        <NoAccessContentContainer>
          <NoAccessContent>
            You currently do not have access to this feature. Please contact{' '}
            <a href="sales@ospree.io">(sales@ospree.io)</a> for more information.
          </NoAccessContent>
        </NoAccessContentContainer>
      </NoAccessContainer>
    </NoAccessWrapper>
  )
}

export default NoAccess
