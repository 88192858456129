/* eslint-disable max-len */
import React from 'react'

export default function NearMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M325.333 137.764L273.169 215.178C269.562 220.45 276.498 226.831 281.493 222.392L332.825 177.719C334.212 176.609 336.154 177.442 336.154 179.384V318.951C336.154 320.893 333.657 321.726 332.547 320.339L177.164 134.434C172.17 128.33 164.956 125 156.909 125H151.36C136.931 125 125 136.931 125 151.637V348.085C125 362.791 136.931 374.723 151.637 374.723C160.794 374.723 169.395 370.006 174.39 361.959L226.554 284.545C230.161 279.273 223.224 272.891 218.23 277.331L166.898 321.726C165.511 322.836 163.568 322.003 163.568 320.061V180.771C163.568 178.829 166.065 177.997 167.175 179.384L322.558 365.289C327.553 371.393 335.044 374.723 342.814 374.723H348.363C363.069 374.723 375 362.791 375 348.085V151.637C375 136.931 363.069 125 348.363 125C338.929 125 330.327 129.717 325.333 137.764Z"
        fill="#00CACD"
      />
    </svg>
  )
}
