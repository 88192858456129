import styled from 'styled-components'

export const LoadingPageWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadingPageTitle = styled.div`
  font-size: 18px;
  padding: 15px 35px;

  border-radius: 5px;
  background-color: white;
  border: ${(({ theme }) => `solid 1px ${theme.colors.lightGray}`)};
`
