import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { NumberedChartWrapper, NumberBlock } from './NumberedChart.elements'

function NumberedChart(props) {
  // Destructure
  const { numOfBlocks, activeNumber } = props

  return (
    <NumberedChartWrapper>
      <NumberBlock isActive={activeNumber === 0}>0</NumberBlock>
      <NumberBlock isActive={activeNumber === 1}>1</NumberBlock>
      <NumberBlock isActive={activeNumber === 2}>2</NumberBlock>
      <NumberBlock isActive={activeNumber === 3}>3</NumberBlock>
      <NumberBlock isActive={activeNumber === 4}>4</NumberBlock>
      <NumberBlock isActive={activeNumber > 4}>+</NumberBlock>
    </NumberedChartWrapper>
  )
}

// Default Props
NumberedChart.defaultProps = {
  activeNumber: 0,
  numOfBlocks: 3,
}

// Proptypes Validation
NumberedChart.propTypes = {
  activeNumber: PropTypes.number,
  numOfBlocks: PropTypes.number,
}

export default NumberedChart
