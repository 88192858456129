import React from 'react'
import { NavLink } from 'react-router-dom'

// Styled Elements
import {
  SidebarBrand,
  SidebarWrapper,
  SidebarMenuNav,
  SidebarBrandLogo,
  SidebarMenuNavList,
  SidebarMenuNavItem,
  SidebarMenuNavLink,
  SidebarMenuNavText,
} from './AdminSidebar.elements'

// assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'
import SettingsIcon from 'assets/images/settings'

function AdminSidebar() {
  return (
    <SidebarWrapper>
      <SidebarMenuNav>
        <SidebarMenuNavList>
          <SidebarBrand>
            <NavLink to="/">
              <SidebarBrandLogo>
                <OspreeLogoIcon />
              </SidebarBrandLogo>
            </NavLink>
          </SidebarBrand>
        </SidebarMenuNavList>
        <SidebarMenuNavList>
          <SidebarMenuNavItem>
            <SidebarMenuNavLink exact to="/admin/vaspnotification">
              <SettingsIcon />
              <SidebarMenuNavText>Vasp Notification</SidebarMenuNavText>
            </SidebarMenuNavLink>
          </SidebarMenuNavItem>
        </SidebarMenuNavList>
      </SidebarMenuNav>
    </SidebarWrapper>
  )
}

export default AdminSidebar
