import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  Wrapper,
  LineWrapper,
  CompletedLineWrapper,
  HeaderWrapper,
  HeaderContainer,
  NumberContainer,
  LabelContainer,
} from './FormNavigation.elements'

function FormNavigation(props) {
  // Destructure
  const { headers, pageNumber, setPageNumber } = props

  const handlePageChange = (value) => {
    if (setPageNumber) {
      setPageNumber(value)
    }
  }

  return (
    <Wrapper>
      <LineWrapper />
      <CompletedLineWrapper width={((pageNumber - 1) / headers.length) * 100 + 5} />
      <HeaderWrapper>
        {headers.map((header, i) => (
          <HeaderContainer
            key={header.label}
            onClick={() => {
              handlePageChange(i + 1)
            }}
          >
            <NumberContainer active={pageNumber === i + 1} completed={pageNumber > i + 1}>
              {i + 1}
            </NumberContainer>
            <LabelContainer active={pageNumber === i + 1} completed={pageNumber > i + 1}>
              {header.label}
            </LabelContainer>
          </HeaderContainer>
        ))}
      </HeaderWrapper>
    </Wrapper>
  )
}

// Default Props
FormNavigation.defaultProps = {
  headers: {},
  pageNumber: 0,
  setPageNumber: () => {},
}

// Proptypes Validation
FormNavigation.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
}

export default FormNavigation
