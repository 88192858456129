import styled from 'styled-components'

export const TransferFormWrapper = styled.div`
  height: 100%;
`

export const FormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const FormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 15px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.lightGray};
`
export const FormRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  & button {
    font-size: 10px !important;
    width: auto;
  }
  & > div:last-child {
    text-align: right;
  }
`

export const FormGroupTitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const FormGroupText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};

  min-width: 40px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const FormButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`
