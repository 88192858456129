/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/close'

// Hooks
import { useLinkAddress } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  CreateEntityDrawerWrapper,
  DrawerNavWrapper,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerCloseIcon,
  DrawerTitle,
} from './LinkAccountDrawer.elements'

// Views
import { Drawer, Button, LoadingTablePlaceholder } from 'views/components'

import { LinkAddressForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function LinkAccountDrawer(props) {
  // Destructure
  const { ui, actions, address } = props

  // Store State
  const { isLinkAddressDrawerOpen } = ui
  const { addressLinkDetails } = address

  // Store Actions
  const { setIsLinkAddressDrawerOpen, showAlert, setAddressLinkData } = actions

  // Hooks
  const { linkAddress, linkAddressData, linkAddressError, isLinkAddressLoading } = useLinkAddress()

  // State
  const [formData, setFormData] = useState({
    account: null,
  })

  // Functions
  const handleSubmit = () => {
    const { address_id } = addressLinkDetails
    linkAddress({
      address_id,
      link_id: formData.account.id,
      link_type: 1,
    })
  }
  const handleCloseDrawer = () => {
    setFormData({
      account: null,
    })
    setIsLinkAddressDrawerOpen(false)
  }

  // UseEffects
  useEffect(() => {
    if (linkAddressData) {
      setAddressLinkData(formData.account)
      handleCloseDrawer()
    }
  }, [linkAddressData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (linkAddressError) showAlert({ type: 'error', message: 'An error occured in linking address' })
  }, [linkAddressError])

  return (
    <Drawer open={isLinkAddressDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <CreateEntityDrawerWrapper>
        <DrawerNavWrapper>
          <DrawerCloseIcon onClick={handleCloseDrawer}>
            <CloseIcon />
          </DrawerCloseIcon>
        </DrawerNavWrapper>

        <DrawerHeader>
          <DrawerTitle>Link Address To An Account</DrawerTitle>
        </DrawerHeader>

        <DrawerContent>
          {isLinkAddressLoading && <LoadingTablePlaceholder />}
          {!isLinkAddressLoading && <LinkAddressForm formData={formData} setFormData={setFormData} />}
        </DrawerContent>
        <DrawerFooter>
          <Button disabled={formData.account === null || isLinkAddressLoading} onClick={handleSubmit}>
            Confirm
          </Button>
        </DrawerFooter>
      </CreateEntityDrawerWrapper>
    </Drawer>
  )
}

// Default Props
LinkAccountDrawer.defaultProps = {
  ui: {},
  actions: {},
  address: {},
}

// Proptypes Validation
LinkAccountDrawer.propTypes = {
  ui: PropTypes.shape({ isLinkAddressDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    setIsLinkAddressDrawerOpen: PropTypes.func,
    setCreatedAccount: PropTypes.func,
    setCreatedRecipient: PropTypes.func,
    setCreatedVasp: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressLinkDetails: PropTypes.func,
    setAddressLinkData: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  address: PropTypes.shape({
    addressLinkDetails: PropTypes.shape({
      address_id: PropTypes.string,
    }),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkAccountDrawer)
