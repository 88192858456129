import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { IconButtonWrapper } from './IconButton.elements'

function IconButton(props) {
  // Destructure
  const { children, onClick } = props

  return <IconButtonWrapper onClick={onClick}>{children}</IconButtonWrapper>
}

// Default Props
IconButton.defaultProps = {
  onClick: () => {},
  children: '',
}

// Proptypes Validation
IconButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default IconButton
