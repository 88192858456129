import xlsx from 'xlsx'
import moment from 'moment'
import { saveAs } from 'file-saver'

// Contants
import { SAMPLE_UPLOAD_FILE } from 'common/constants/sampleData'
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

//* ----- Handling file drop ----- */
export const handleOnFileDrop = ({
	files,
	activeModule,
	setRejectedFile,
	setCaseDataLength,
	setUploadData,
	setInvalidHeaders,
	activeCase,
	userCredentials,
	setAcceptedFilesName
	}) => {
	let sheetEndRange = null

	if (activeModule === 'cases') {
		sheetEndRange = 8
	} else if (activeModule === 'transactions') {
		sheetEndRange = 1
	} else if (activeModule === 'addresses') {
		sheetEndRange = 1
	}

	files.forEach((file) => {
		setAcceptedFilesName(file.path)
		setRejectedFile(false)
		const reader = new FileReader()

		reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => console.log('file reading has failed')

		reader.onload = () => {
			const binaryStr = reader.result
			const data = new Uint8Array(binaryStr)

			const arr = []
			for (let i = 0; i !== data.length; ++i) {
				arr[i] = String.fromCharCode(data[i])
			}
			const bstr = arr.join('')

			// Call xlsx
			const workbook = xlsx.read(bstr, { type: 'binary', cellDates: true })

			/* DO SOMETHING WITH workbook HERE */
			const first_sheet_name = workbook.SheetNames[0]
			/* Get worksheet */
			const worksheet = workbook.Sheets[first_sheet_name]

			let new_range = null
			try {
				const range = xlsx.utils.decode_range(workbook.Sheets[first_sheet_name]['!ref'])
				range.s.c = 0 // 0 == xlsx.utils.decode_col("A")
				range.e.c = sheetEndRange // 6 == xlsx.utils.decode_col("G")
				// range.s.c =
				new_range = xlsx.utils.encode_range(range)
			} catch (err) {
				console.log(err)
			}

			const excelData = xlsx.utils.sheet_to_json(worksheet, {
				raw: true,
				defval: null,
				dateNF: 'yyyy-mm-dd',
				range: new_range,
			})

			if (excelData.length > 0) {
				const headers = Object.keys(excelData[0])
				const headerResult = checkHeaders(headers, activeModule)
				let cleanObject = {}
				setCaseDataLength(excelData.length)
				if (typeof headerResult === 'boolean') {
					if (activeModule === 'cases') {
						cleanObject = processExcelCaseKeys(excelData, userCredentials)
					} else if (activeModule === 'transactions') {
						cleanObject = processExcelTransactionKeys(excelData, activeCase, userCredentials)
					} else if (activeModule === 'addresses') {
						cleanObject = processExcelAddressKeys(excelData, activeCase, userCredentials)
					}

					setInvalidHeaders([])

					// CHECK DATE FORMAT
					cleanObject.forEach((item) => {
						if (!Number.isNaN(new Date(item.Date_Birth).getTime())) {
							if (moment(item.Date_Birth).format(DASHED_DATE_FORMAT) !== 'Invalid date') {
								item.Date_Birth = moment(item.Date_Birth).format(DASHED_DATE_FORMAT)
							}
						}
						return false
					})
					// removing date of birth if empty
					cleanObject.map((item) => (
						Object.keys(item).forEach((k) => (item[k] === null && k === 'Date_Birth') && delete item[k])
					))
					setUploadData(cleanObject)
				} else {
					setRejectedFile(true)
					setInvalidHeaders(headerResult)
				}
			}
		}
		reader.readAsArrayBuffer(file)
	})
}

//* ----- Processing Keys ----- */
const clone = (obj) => ({ ...obj })
const renameKey = (object, key, newKey) => {
	const clonedObj = clone(object)
	const targetKey = clonedObj[key]
	delete clonedObj[key]
	clonedObj[newKey] = targetKey
	return clonedObj
}

export const processExcelCaseKeys = (excelData, userCredentials) => {
	excelData.forEach((data, index) => {
		excelData[index].Business_ID = userCredentials.Business_ID

		excelData[index] = renameKey(data, 'Unique Id', 'Customer_ID')
		excelData[index] = renameKey(excelData[index], 'Entity Type', 'Case_Type')
		excelData[index] = renameKey(excelData[index], 'First Name', 'First_Name')
		excelData[index] = renameKey(excelData[index], 'Last Name', 'Last_Name')
		excelData[index] = renameKey(excelData[index], 'Business Name', 'Company_Name')
		excelData[index] = renameKey(excelData[index], 'Date of Birth', 'Date_Birth')
		excelData[index] = renameKey(excelData[index], 'Country Location', 'Country_Address')
		excelData[index] = renameKey(excelData[index], 'Nationality', 'Nationality')
		excelData[index] = renameKey(excelData[index], 'Registered Country', 'Country_Incorporation')
	})

	return excelData
}

export const processExcelAddressKeys = (excelData, activeCase, userCredentials) => {
	excelData.forEach((data, index) => {
		if (typeof window !== 'undefined') {
			excelData[index].case_id = activeCase
			excelData[index].user_id = userCredentials.User_ID
			excelData[index] = renameKey(excelData[index], 'Address', 'address')
			excelData[index] = renameKey(excelData[index], 'Blockchain', 'chain')
		}
	})

	return excelData
}

export const processExcelTransactionKeys = (excelData, activeCase, userCredentials) => {
	excelData.forEach((data, index) => {
		if (typeof window !== 'undefined') {
			excelData[index].case_id = activeCase
			excelData[index].user_id = userCredentials.User_ID
			excelData[index] = renameKey(excelData[index], 'Transaction', 'transaction')
			excelData[index] = renameKey(excelData[index], 'Blockchain', 'chain')
		}
	})

	return excelData
}

//* ----- checking Headers ----- */
export const checkHeaders = (header, activeModule) => {
	let correctHeaders = []
	if (activeModule === 'cases') {
		correctHeaders = [
			'Unique Id',
			'Entity Type',
			'First Name',
			'Last Name',
			'Business Name',
			'Date of Birth',
			'Country Location',
			'Nationality',
			'Registered Country',
		]
	} else if (activeModule === 'addresses') {
		correctHeaders = ['Address', 'Blockchain']
	} else if (activeModule === 'transactions') {
		correctHeaders = ['Transaction', 'Blockchain']
	}

	let counter = 0
	const invalid = []

	header.forEach((item) => {
		if (!correctHeaders.includes(item)) {
			invalid.push(item)
		} else {
			counter++
		}
	})

	if (counter === correctHeaders.length) {
		return true
	}

	return invalid
}

//* ----- Saving sample file ----- */

export const saveExportableFile = (activeModule) => {
	const s2ab = (s) => {
		const buf = new ArrayBuffer(s.length)
		const view = new Uint8Array(buf)
		// eslint-disable-next-line no-bitwise
		for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
		return buf
	}

	const exportSampleFile = (config) => {
		const wb = xlsx.utils.book_new()
		wb.Props = { Title: 'Ospree',	Subject: 'Ospree', Author: 'Ospree', CreatedDate: moment() }
		wb.SheetNames.push('Sheet1')

		const ws = xlsx.utils.json_to_sheet(config.obj, {
			header: config.header,
		})

		const title = config.filename
		wb.Sheets.Sheet1 = ws
		const wbout = xlsx.write(wb, { bookType: 'xlsx', type: 'binary' })
		saveAs(
			new Blob([s2ab(wbout)], {	type: 'application/octet-stream' }),
			title
		)
	}

	if (activeModule === 'addresses') {
		return (exportSampleFile(SAMPLE_UPLOAD_FILE[0]))
	} if (activeModule === 'cases') {
		return (exportSampleFile(SAMPLE_UPLOAD_FILE[1]))
	} if (activeModule === 'transactions') {
		return (exportSampleFile(SAMPLE_UPLOAD_FILE[2]))
	}
}

export const handleFileError = (err, filename) => {
	const wb = xlsx.utils.book_new();
	wb.Props = {
		Title: 'Ospree',
		Subject: 'Ospree',
		Author: 'Ospree',
		CreatedDate: moment(),
	};

	wb.SheetNames.push('Errors');
	const ws_data = err.response.data.content;
	const ws = xlsx.utils.json_to_sheet(ws_data);
	const title = `${filename.split('.')[0]}_${moment().valueOf()}_report.csv`

	wb.Sheets.Errors = ws;
	const wbout = xlsx.write(wb, { bookType: 'csv', type: 'binary' });
	if (typeof window !== 'undefined') {
		const newReport = wbout;
		const saved = JSON.parse(localStorage.getItem('reportStrings'));

		let newTitle = title;
		const titleSaved = JSON.parse(localStorage.getItem('reportTitles'));
		if (saved && titleSaved) {
			if (titleSaved.length >= 6) {
				saved.splice(1, 1);
				titleSaved.splice(1, 1);
			}

			saved[saved.length] = newReport;
			newTitle = newTitle.split(' ').join('_');
			titleSaved[titleSaved.length] = newTitle;

			localStorage.setItem('reportStrings', JSON.stringify(saved));
			localStorage.setItem('reportTitles', JSON.stringify(titleSaved));
		} if (!saved && !titleSaved) {
			newTitle = [newTitle.split(' ').join('_')];

			localStorage.setItem('reportStrings', JSON.stringify([newReport]));
			localStorage.setItem('reportTitles', JSON.stringify(newTitle));
		}
	}
}
