import styled from 'styled-components'

export const AutoCompleteWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${({ label }) => label && '-5px;'};
`

export const Label = styled.label`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  padding: 0 7px;
  transform: translate(15px, 15px);

  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.white : 'transparent')};
`

export const AutoCompleteInput = styled.input`
  transition: 0.4s;

  position: relative;

  min-height: 54px;
  height: fit-content;
  width: 100%;

  padding: 15px 20px;

  font-size: 16px;
  border-radius: 10px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  color: ${({ theme }) => theme.colors.lightGray};

  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};

  background-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.colors.lightGray)};

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`

export const SelectInputOptionsContainer = styled.div`
  position: absolute;
  top: 110%;
  display: flex;
  flex-direction: column;

  padding: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  font-size: 16px;
  border-radius: 10px;
  font-weight: 400;

  border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.lightGray : 'transparent')};
  user-select: none;
  z-index: 2;
`

export const SelectInputOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkGray};

  cursor: pointer;
  transition: 0.4s;

  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0);

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  }
`

export const DummyInput = styled.input`
  display: none;
`
