import React from 'react'

export default function DownloadIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11H11" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 1L6 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M6 9L9.5 5.5H2.5L6 9Z" stroke="white" strokeLinejoin="round" />
    </svg>
  )
}
