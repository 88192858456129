import { useMutation } from 'react-query'

// Services
import { BatchUploadCaseTransactions } from 'services/api/Upload'

export default () => {
  const {
    mutate: batchUploadTransactions,
    data: batchUploadTransactionsData,
    error: batchUploadTransactionsError,
    isLoading: isBatchUploadTransactionsLoading,
    isSuccess: isBatchUploadTransactionsSuccess,
    isError: isBatchUploadTransactionsError,
  } = useMutation(BatchUploadCaseTransactions)

  return {
    batchUploadTransactions,
    batchUploadTransactionsData,
    batchUploadTransactionsError,
    isBatchUploadTransactionsLoading,
    isBatchUploadTransactionsSuccess,
    isBatchUploadTransactionsError,
  }
}
