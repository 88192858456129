import styled from 'styled-components'

export const DrawerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 400px;
  padding: 20px;

  // Themed Properties
  background-color: ${({ theme }) => theme.colors.white};
`

export const DrawerCloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;

  // TODO: Use Theme Functions
  &:hover {
    opacity: 0.7;
  }
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`
