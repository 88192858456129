import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Hooks
// import { useLocalStorage } from 'core/hooks/storage'
import { useGetAddressesLinked } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './RecipientAddressesTable.elements'

// Views
import { AccordionTable, EmptyTablePlaceholder, LoadingTablePlaceholder } from 'views/components'

import { AddressesTableItem } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RecipientAddressesTable(props) {
  // Destructure
  const { filters, ui, actions, address } = props
  const { account_id } = useParams()

  // Store States
  const { addressFilters } = filters
  const { scannedAddress } = address
  const { isPageTableUpdated } = ui
  const { setIsPageTableUpdated, setAddressFilters, showAlert, setScannedAddress, setScannedAddressDetails } = actions

  // Hooks
  const { getAddressesLinked, getAddressesLinkedData, getAddressesLinkedError, isGetAddressesLinkedLoading } =
    useGetAddressesLinked()

  // Local states
  const [addresses, setAddresses] = useState([])
  const [pagination, setPagination] = useState()
  const [openedTab, setOpenedTab] = useState()

  // Functions
  function handleScannedAddress() {
    const newAddress = {
      id: scannedAddress.address_id,
      address: scannedAddress.address,
      chain: scannedAddress.chain,
      symbol: scannedAddress.symbol,
      risk_score: scannedAddress.risk_data.risk_score,
      risk_ratio: scannedAddress.risk_data.risk_ratio,
      balance_usd: scannedAddress.financial_data.balance_usd,
      balance: scannedAddress.financial_data.balance,
      modify_tz: scannedAddress.request_tz,
      link_id: scannedAddress.link_id,
      link_type: scannedAddress.link_type,
      modified_by_name: scannedAddress.modified_by_name,
    }
    const newAddressList = [newAddress]
    addresses.forEach((item) => {
      if (item.address !== scannedAddress.address) {
        newAddressList.push(item)
      }
    })
    // Changing it back to null
    setScannedAddress(null)
    setAddresses(newAddressList)
    setScannedAddressDetails(scannedAddress)
  }

  // UseEffects
  useEffect(() => {
    if (isPageTableUpdated) {
      getAddressesLinked(addressFilters)
      setIsPageTableUpdated(false)
    } else if (addressFilters) {
      getAddressesLinked(addressFilters)
    }
  }, [isPageTableUpdated, addressFilters])
  useEffect(() => {
    if (getAddressesLinkedData) {
      setAddresses(getAddressesLinkedData.items)
      setPagination(getAddressesLinkedData.pagination)
    }
  }, [getAddressesLinkedData])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (getAddressesLinkedError) showAlert({ type: 'error', message: 'An error occured in fetching addresses' })
  }, [getAddressesLinkedError])
  useEffect(() => {
    // TODO ADD PROPER ERROR MSG SENT FROM SERVER
    if (scannedAddress) handleScannedAddress()
  }, [scannedAddress])

  return (
    <TableWrapper>
      <AccordionTable
        totalItems={pagination?.total && pagination.total}
        minWidth={900}
        tableFilters={addressFilters}
        setTableFilters={setAddressFilters}
      >
        {addresses.length > 0 &&
          !isGetAddressesLinkedLoading &&
          addresses.map((data) => (
            <AddressesTableItem
              openedTab={openedTab}
              setOpenedTab={setOpenedTab}
              key={`${data.id}${data.address}`}
              data={data}
              getAddressesError={getAddressesLinkedError}
            />
          ))}
        {isGetAddressesLinkedLoading && <LoadingTablePlaceholder />}
        {(addresses?.length < 1 || !addresses) && !isGetAddressesLinkedLoading && (
          <EmptyTablePlaceholder setFilter={() => setAddressFilters({ link_id: account_id })} />
        )}
      </AccordionTable>
    </TableWrapper>
  )
}

// Default Props
RecipientAddressesTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
RecipientAddressesTable.propTypes = {
  filters: PropTypes.shape({
    addressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setToBeUpdatedRecipient: PropTypes.func,
    setRecipientDirectoryFilters: PropTypes.func,
    setIsIndividualRecipientDrawerOpen: PropTypes.func,
    setIsEntityRecipientDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressFilters: PropTypes.func,
    showAlert: PropTypes.func,
    setScannedAddress: PropTypes.func,
    setScannedAddressDetails: PropTypes.func,
  }),
  address: PropTypes.shape({
    scannedAddress: PropTypes.shape({
      address_id: PropTypes.string,
      address: PropTypes.string,
      chain: PropTypes.string,
      symbol: PropTypes.string,
      symbol_icon: PropTypes.string,
      financial_data: {
        provider_name: PropTypes.string,
        balance: PropTypes.number,
        balance_usd: PropTypes.number,
        spent: PropTypes.number,
        spent_usd: PropTypes.number,
        received: PropTypes.number,
        received_usd: PropTypes.number,
        transaction_count: PropTypes.number,
        latest_activity: PropTypes.string,
      },
      risk_data: {
        provider_name: PropTypes.string,
        risk_score: PropTypes.number,
        vasp_name_legal: PropTypes.string,
        vasp_name_business: PropTypes.string,
        vasp_website: PropTypes.string,
        vasp_category: PropTypes.string,
        api_result_id: PropTypes.string,
      },
      request_tz: PropTypes.string,
      modified_by_name: PropTypes.string,
      link_id: PropTypes.string,
      link_type: PropTypes.number,
    }),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipientAddressesTable)
