import { useMutation } from 'react-query'

// Services
import { GetUserAuth } from 'services/api/Auth'

export default () => {
  const {
    mutate: getUserAuth,
    data: getUserAuthData,
    error: getUserAuthError,
    isLoading: isGetUserAuthLoading,
    isSuccess: isGetUserAuthSuccess,
    isError: isGetUserAuthError,
  } = useMutation(GetUserAuth)

  return {
    getUserAuth,
    getUserAuthData,
    getUserAuthError,
    isGetUserAuthLoading,
    isGetUserAuthSuccess,
    isGetUserAuthError,
  }
}
