import React from 'react'

export default function FilterIcon() {
  return (
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.9231H13.9874" stroke="#00CACD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 6H9.51935" stroke="#00CACD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <line
        x1="1"
        y1="10"
        x2="3.46808"
        y2="10"
        stroke="#00CACD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
