import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 320px;
  min-width: 260px;
  position: relative;
  width: 100%;
`
export const BarBackdrop = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 10px;
  width: 100%;
  border-radius: 15px;
`
export const BarLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  background-color: ${({ theme, failed }) => (failed ? theme.colors.red : theme.colors.blue)};
  border-radius: 15px;
`
