import { useState, useEffect } from 'react'

const getValue = (key, initialValue) => {
  const value = JSON.parse(localStorage.getItem(key))

  if (value) return value

  if (initialValue instanceof Function) return initialValue()

  if (initialValue !== undefined) return initialValue
}

const useLocalStorage = (key, initialValue) => {
  const [localStorageValue, setLocalStorageValue] = useState(() => getValue(key, initialValue))

  useEffect(() => {
    if (localStorageValue !== undefined) {
      localStorage.setItem(key, JSON.stringify(localStorageValue))
    }
  }, [localStorageValue])

  return [localStorageValue, setLocalStorageValue]
}

export default useLocalStorage
