import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  & g {
    & #foreground {
      stroke: ${({ theme }) => theme.colors.lightGray};
    }
  }
`
export const ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 100%;
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 100%;
  height: 100%;
`
export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`
export const Text = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray};
`
