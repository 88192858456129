import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { TextSlicedWrapper, TextContainer, SliceBlock } from './TextSliced.elements'

function TextSliced(props) {
  // Destructure
  const { text, sliceLength } = props

  const startText = text.slice(0, sliceLength)
  const endText = text.slice(sliceLength * -1)

  return (
    <TextSlicedWrapper>
      <TextContainer>{startText}</TextContainer>
      <SliceBlock />
      <SliceBlock />
      <SliceBlock />
      <TextContainer>{endText}</TextContainer>
    </TextSlicedWrapper>
  )
}

// Default Props
TextSliced.defaultProps = {
  text: '',
  sliceLength: '',
}

// Proptypes Validation
TextSliced.propTypes = {
  text: PropTypes.string,
  sliceLength: PropTypes.number,
}

export default TextSliced
