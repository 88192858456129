import styled from 'styled-components'

export const TableItemWrapper = styled.div`
  position: relative;
  padding: 10px 20px;

  // TODO: Use theme functions
  &:focus {
    outline: none;
  }

  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};

  transition: 0.3s;
  border: 1px solid ${({ theme }) => `${theme.colors.lightGray}`};
  &:hover {
    border: 1px solid ${({ theme }) => `${theme.colors.blue}40`};
  }
`

export const TableItemInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const TableItemInner = styled.div`
  flex: 1;
  font-weight: 100;
  font-size: 15px;
`

export const TableItemStartIcon = styled.div`
  display: flex;
  max-height: 30px;
  max-width: 30px;

  transition: all 0.15s ease-in;
`

export const TableItemEndIcon = styled.div`
  display: flex;
  max-height: 30px;
  max-width: 30px;

  transition: all 0.15s ease-in;
`
