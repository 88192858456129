import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalBodyWrapper = styled.div`
  font-size: 14.5px;
  font-weight: 400;
  padding: 10px 0px;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
`
export const Link = styled.a`
  color: ${({ theme }) => theme.colors.blue};
`
