import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 500px;
  height: 100%;

  font-size: 18px;
`

export const HeaderFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
`

export const HeaderOptionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: -20px;
`

export const TableBodyWrapper = styled.div`
  padding-top: 40px;
  width: 100%;
`

export const TableBodyChildrenWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ length }) => (length > 1 ? 'repeat(auto-fill, minmax(300px, 1fr))' : '1fr')};
  gap: 20px;
`
