import { useMutation } from 'react-query'

// Services
import { GetVasp } from 'services/api/Vasps'

export default () => {
  const {
    mutate: getVasp,
    data: vaspData,
    error: vaspError,
    isLoading: isVaspLoading,
    isSuccess: isVaspSuccess,
    isError: isVaspError,
  } = useMutation(GetVasp)

  return {
    getVasp,
    vaspData,
    vaspError,
    isVaspLoading,
    isVaspSuccess,
    isVaspError,
  }
}
