import { useMutation } from 'react-query'

// Services
import { SignupVasp } from 'services/api/Auth'

export default () => {
  const {
    mutate: signupVasp,
    data: signupVaspData,
    error: signupVaspError,
    isLoading: isSignupVaspLoading,
    isSuccess: isSignupVaspSuccess,
    isError: isSignupVaspError,
  } = useMutation(SignupVasp)

  return {
    signupVasp,
    signupVaspData,
    signupVaspError,
    isSignupVaspLoading,
    isSignupVaspSuccess,
    isSignupVaspError,
  }
}
