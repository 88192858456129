import { useMutation } from 'react-query'

// Services
import { GetMonitoringPagedTable } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: getMonitoringPagedTable,
    data: monitoringPagedTableData,
    error: monitoringPagedTableError,
    isLoading: isMonitoringPagedTableLoading,
    isSuccess: isMonitoringPagedTableSuccess,
    isError: isMonitoringPagedTableError,
  } = useMutation(GetMonitoringPagedTable)

  return {
    getMonitoringPagedTable,
    monitoringPagedTableData,
    monitoringPagedTableError,
    isMonitoringPagedTableLoading,
    isMonitoringPagedTableSuccess,
    isMonitoringPagedTableError,
  }
}
