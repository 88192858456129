import React from 'react'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Styled Elements
import {
  VaspSignupWrapper,
  VaspSignupInnerWrapper,
  VaspSignupLogoLink,
  VaspSignupFormWrapper,
  VaspSignupFormInnerWrapper,
  VaspSignupFormContent,
} from './VaspSignup.elements'

// Components
import { VaspSignupForm } from 'views/layouts'

function VaspSignup() {
  return (
    <VaspSignupWrapper>
      <VaspSignupInnerWrapper>
        <VaspSignupFormWrapper>
          <VaspSignupFormInnerWrapper>
            <VaspSignupFormContent>
              <VaspSignupLogoLink>
                <OspreeLogoIcon />
              </VaspSignupLogoLink>
              <VaspSignupForm />
            </VaspSignupFormContent>
          </VaspSignupFormInnerWrapper>
        </VaspSignupFormWrapper>
      </VaspSignupInnerWrapper>
    </VaspSignupWrapper>
  )
}

// Default Props
VaspSignup.defaultProps = {}

// Proptypes Validation
VaspSignup.propTypes = {}

export default VaspSignup
