import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Hooks
import { useVerifyEmail } from 'core/hooks/api'
import { useParams } from 'react-router'

// Store
import { actions } from 'core/store'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Views
import { LoaderCircle, Button } from 'views/components'

// Styled Elements
import {
  LoginWrapper,
  LoginInnerWrapper,
  LoginLogoLink,
  LoginTitle,
  LoginError,
  LoginSucess,
  LoginFormWrapper,
  LoginFormInnerWrapper,
  LoginFormContent,
  LoadingWrapper,
  ButtonWrapper,
  // LoginFormFooterText,
  // LoginFormFooterLink,
} from './EmailVerification.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function EmailVerification() {
  // Hooks
  const { verifyEmail, isVerifyEmailLoading, isVerifyEmailSuccess, isVerifyEmailError } = useVerifyEmail()
  const { auth_hash } = useParams()

  // Local States

  const handleOnSubmit = (auth_hash) => {
    verifyEmail({ auth_hash })
  }

  useEffect(() => {
    handleOnSubmit(auth_hash)
  }, [auth_hash])
  return (
    <LoginWrapper>
      <LoginInnerWrapper>
        <LoginFormWrapper>
          <LoginFormInnerWrapper>
            <LoginFormContent>
              <LoginLogoLink>
                <OspreeLogoIcon />
              </LoginLogoLink>
              {isVerifyEmailLoading && (
                <LoadingWrapper>
                  <LoginTitle>Verifying Email</LoginTitle>
                  <LoaderCircle />
                </LoadingWrapper>
              )}
              {isVerifyEmailSuccess && !isVerifyEmailLoading && (
                <>
                  <LoginSucess>Email has been verified.</LoginSucess>
                  <ButtonWrapper href="/">
                    <Button fullWidth>Go back</Button>
                  </ButtonWrapper>
                </>
              )}
              {isVerifyEmailError && !isVerifyEmailLoading && (
                <>
                  <LoginError>The link is invalid.</LoginError>
                  <ButtonWrapper href="/">
                    <Button fullWidth>Go back</Button>
                  </ButtonWrapper>
                </>
              )}
            </LoginFormContent>
          </LoginFormInnerWrapper>
        </LoginFormWrapper>
      </LoginInnerWrapper>
    </LoginWrapper>
  )
}

// Default Props
EmailVerification.defaultProps = {
  actions: {},
}

// Proptypes Validation
EmailVerification.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification)
