import styled from 'styled-components'

export const Wrapper = styled.div``
export const SearchBarWrapper = styled.div`
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.darkGray};
  // Themed Properties
`

export const SearchBarContainer = styled.div`
  padding-top: 20px;
  z-index: 7;
  position: relative;
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  // Themed Properties
`

export const SearchInputWrapper = styled.div`
  z-index: ${({ isActive }) => (isActive ? 7 : 0)};
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 250px;
  justify-content: center;
  align-items: center;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.lightGray : theme.colors.white)};
  border-radius: 10px;
  padding: 5px 20px 5px 20px;

  transition: 0.3s;
  border: 1px solid ${({ theme }) => `${theme.colors.lightGray}`};
  &:hover {
    border: 1px solid ${({ theme }) => `${theme.colors.blue}40`};
  }
  & input {
    background: ${({ theme, isActive }) => (isActive ? theme.colors.lightGray : theme.colors.white)};
    border: none;
    padding: 15px 20px 15px 15px;
  }
  & svg {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  & svg > path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
  & #addressSearchOptionsProvider {
    & > div > div:nth-child(3) {
      min-width: 160px;
      border-radius: 10px;
      & > div > div {
        padding: 8px 12px;
      }
    }
  }
  & #addressSearchOptionsChain {
    & > div > div:nth-child(3) {
      min-width: 160px;
      border-radius: 10px;
      & > div > div {
        padding: 8px 12px;
        & svg {
          min-width: 25px;
          stroke: transparent;
          & path {
            stroke: ${({ theme }) => theme.colors.blue};
          }
        }
      }
    }
  }
`
export const SearchInputOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: ${({ theme }) => `${theme.colors.white}`};
  transition: 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 39px;
  right: -60px;
  cursor: pointer;
  & svg {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
  & svg > path {
    fill: ${({ theme }) => theme.colors.darkGray};
  }
`
export const ChainChoiceWrapper = styled.div`
  z-index: ${({ isActive }) => (isActive ? 6 : 0)};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 10px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.07);
  max-height: 400px;
  overflow-y: auto;
`
export const ChainChoice = styled.div`
  cursor: pointer;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 7px;

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.lightGray};
  }
  border: ${({ theme, active }) => active && `1px solid ${theme.colors.blue}`};
  border-radius: 8px;
`
export const Title = styled.h2`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  margin: 15px 0 0 15px;
  color: ${({ theme }) => theme.colors.darkGray};
`
