/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

export default function MarkerIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
      <circle cx="11.142" cy="11.145" r="5" stroke="#D5D6D8" strokeWidth="2" transform="rotate(-45 11.142 11.145)" />
      <path
        stroke="#D5D6D8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18.213 18.216c3.905-3.906 3.905-10.237 0-14.143C14.308.168 7.976.168 4.071 4.073.166 7.98.166 14.31 4.071 18.216c3.905 3.905 10.237 3.905 14.142 0zM18.215 4.074l-2.828 2.828M6.899 15.386L4.07 18.215M6.899 6.903L4.07 4.074M18.215 18.215l-2.828-2.828"
      />
    </svg>
  )
}
