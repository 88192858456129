import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllMidProspects } from 'core/hooks/api'

// Styled Elements
import { TableWrapper } from './RequestedVaspNotificationTable.elements'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white'

// Views
import { CardTable, EmptyTablePlaceholder, LoadingTablePlaceholder, Button } from 'views/components'

import { VaspNotificationTableItem, RequestedVaspTableFilter } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RequestedVaspNotificationTable(props) {
  // Destructure
  const { filters, ui, actions } = props

  // Store States
  const { vaspDirectoryFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const { setVaspDirectoryFilters, setIsPageTableUpdated, setIsVaspDrawerOpen } = actions

  // Variables
  const { sort, page, size } = vaspDirectoryFilters

  // States
  const [vaspsDataState, setVaspsDataState] = useState({ items: [], total: 0 })

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allMidProspectsData, getAllMidProspects, isAllMidProspectsLoading } = useFetchAllMidProspects()

  // Functions
  const fetchMidProspects = () => {
    getAllMidProspects({
      biz_id: userCredentials.Business_ID,
      sort,
      page,
      size,
    })
  }

  // UseEffects
  useEffect(() => {
    if (allMidProspectsData) setVaspsDataState(allMidProspectsData)
  }, [allMidProspectsData])

  useEffect(() => {
    if (vaspDirectoryFilters || isPageTableUpdated) {
      fetchMidProspects()
      setIsPageTableUpdated(false)
    }
  }, [vaspDirectoryFilters, isPageTableUpdated])

  return (
    <TableWrapper>
      <CardTable
        totalItems={vaspsDataState.total}
        minWidth={900}
        filterComponents={<RequestedVaspTableFilter />}
        tableFilters={vaspDirectoryFilters}
        setTableFilters={setVaspDirectoryFilters}
        headerOptions={
          <Button size="small" startIcon={<PlusWhiteIcon />} onClick={() => setIsVaspDrawerOpen(true)}>
            Vasp
          </Button>
        }
      >
        {vaspsDataState.items.length > 0 && !isAllMidProspectsLoading ? (
          vaspsDataState.items.map((vaspDetails) => (
            <VaspNotificationTableItem key={vaspDetails?.id} vaspDetails={vaspDetails} isRequestedVasp />
          ))
        ) : (
          <div>
            {isAllMidProspectsLoading ? (
              <LoadingTablePlaceholder />
            ) : (
              <EmptyTablePlaceholder setFilter={setVaspDirectoryFilters} />
            )}
          </div>
        )}
      </CardTable>
    </TableWrapper>
  )
}

// Default Props
RequestedVaspNotificationTable.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
RequestedVaspNotificationTable.propTypes = {
  filters: PropTypes.shape({
    vaspDirectoryFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setVaspDirectoryFilters: PropTypes.func,
    setToBeUpdatedVasp: PropTypes.func,
    setIsVaspDrawerOpen: PropTypes.func,
    setIsEntityVaspDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestedVaspNotificationTable)
