import styled from 'styled-components'

export const TableDataWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 200px 120px 1fr 120px;
  gap: 25px;
  padding: 10px;
`

export const TableDataItem = styled.div`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 15px;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};

  color: ${({ theme, status }) => status && status === 'active' && theme.colors.green};
  color: ${({ theme, status }) => status && status === 'inactive' && theme.colors.red};
  ${({ width }) => width && `width: ${width}px;`}
  ${({ width }) => width && 'flex: none;'}
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 25px;
  height: 25px;
  padding: 3px;

  & svg {
    height: 100%;
  }
  & svg > * {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`
