import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`
export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 -40px 0;
`
export const FooterBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 40px;
`

export const Title = styled.h1`
  margin: 0;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Subtitle = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const Container = styled.div`
  margin-top: 20px;
  padding: 30px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.white};
`
export const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  max-width: 500px;
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGray};

  &:last-child {
    border: none;
    padding: 10px 0 0 0;
  }
`
export const RowHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const Text = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGray};
`
