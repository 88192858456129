/* eslint-disable max-len */
import React from 'react'

export default function CloseIcon() {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84391 2.3144C3.42564 1.89566 2.74695 1.89513 2.32802 2.31322C1.9091 2.73132 1.90857 3.40971 2.32684 3.82845L7.98529 9.49325L2.31336 15.1715C1.89509 15.5903 1.89561 16.2687 2.31454 16.6868C2.73347 17.1049 3.41215 17.1043 3.83043 16.6856L9.49325 11.0164L15.1561 16.6856C15.5744 17.1043 16.2531 17.1049 16.672 16.6868C17.0909 16.2687 17.0914 15.5903 16.6731 15.1715L11.0147 9.50675L16.6866 3.82845C17.1049 3.40971 17.1044 2.73132 16.6855 2.31322C16.2665 1.89513 15.5879 1.89566 15.1696 2.3144L9.50674 7.98359L3.84391 2.3144Z"
        fill="white"
      />
    </svg>
  )
}
