/* eslint-disable max-len */
import React from 'react'

export default function ElipisisIcon() {
  return (
    <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="1.95982" cy="2" rx="1.48911" ry="1.5" fill="#727F97" />
      <ellipse cx="1.95982" cy="7" rx="1.48911" ry="1.5" fill="#727F97" />
      <ellipse cx="1.95982" cy="12" rx="1.48911" ry="1.5" fill="#727F97" />
    </svg>
  )
}
