import { useMutation } from 'react-query'

// Services
import { GetAllVasps } from 'services/api/Vasps'

export default () => {
  const {
    mutate: getAllVasps,
    data: allVaspsData,
    error: allVaspsError,
    isLoading: isAllVaspsLoading,
    isSuccess: isAllVaspsSuccess,
    isError: isAllVaspsError,
  } = useMutation(GetAllVasps)

  return {
    getAllVasps,
    allVaspsData,
    allVaspsError,
    isAllVaspsLoading,
    isAllVaspsSuccess,
    isAllVaspsError,
  }
}
