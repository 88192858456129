import React from 'react'
// import { useParams } from 'react-router-dom'

// Styled Elements
import {
  CasesSubHeaderWrapper,
  CasesSubHeaderNavList,
  CasesSubHeaderNavListItem,
  CasesSubHeaderNavListLink,
} from './CasesSubHeader.elements'

function CasesSubHeader() {
  // Hooks
  // const { customerId } = useParams()

  return (
    <CasesSubHeaderWrapper>
      <CasesSubHeaderNavList>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to="/cases">
            Cases
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
        <CasesSubHeaderNavListItem>
          <CasesSubHeaderNavListLink exact activeClassName="active" to="/cases/policies">
            Policies
          </CasesSubHeaderNavListLink>
        </CasesSubHeaderNavListItem>
      </CasesSubHeaderNavList>
    </CasesSubHeaderWrapper>
  )
}

export default CasesSubHeader
