import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`
export const BarBackdrop = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  height: 13px;
  width: 100%;
  border-radius: 3px;
`
export const BarLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 13px;
  background-color: ${({ theme }) => `${theme.colors.darkGray}20`};
  border-right: 2px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 3px 0 0 3px;
`
