import { useMutation } from 'react-query'

// Services
import { RemoveMonitoringTransaction } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: removeMonitoringTransaction,
    data: removeMonitoringTransactionData,
    error: removeMonitoringTransactionError,
    isLoading: isRemoveMonitoringTransactionLoading,
    isSuccess: isRemoveMonitoringTransactionSuccess,
    isError: isRemoveMonitoringTransactionError,
  } = useMutation(RemoveMonitoringTransaction)

  return {
    removeMonitoringTransaction,
    removeMonitoringTransactionData,
    removeMonitoringTransactionError,
    isRemoveMonitoringTransactionLoading,
    isRemoveMonitoringTransactionSuccess,
    isRemoveMonitoringTransactionError,
  }
}
