/* eslint-disable max-len */
import React from 'react'

export default function BitcoinMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.859428 9.59853L0.859453 9.59843C2.46161 3.16918 8.97341 -0.743507 15.4015 0.859207L15.4015 0.859213C21.8312 2.46194 25.744 8.97413 24.1411 15.403C22.5382 21.8314 16.0261 25.7435 9.5959 24.1407C3.16893 22.538 -0.743847 16.0266 0.859428 9.59853Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
      />
      <path
        d="M18.0122 10.7194C18.2607 9.05608 16.9941 8.16194 15.2623 7.56546L15.8241 5.31235L14.4521 4.97055L13.9051 7.16429C13.5449 7.07445 13.1746 6.98968 12.8066 6.9057L13.3574 4.69751L11.9866 4.35571L11.4244 6.60805C11.1259 6.54008 10.8329 6.47289 10.5485 6.40219L10.5501 6.39516L8.65844 5.92289L8.29356 7.38773C8.29356 7.38773 9.31125 7.62093 9.28976 7.63538C9.8453 7.77406 9.94609 8.14163 9.9289 8.43304L9.28898 10.9998C9.32727 11.0096 9.37688 11.0236 9.43158 11.0455L9.28703 11.0096L8.38966 14.6053C8.32169 14.774 8.1494 15.0271 7.76108 14.9311C7.77475 14.951 6.76409 14.6822 6.76409 14.6822L6.08315 16.2525L7.86851 16.6974C8.20058 16.7807 8.52601 16.8678 8.84596 16.9498L8.27832 19.2291L9.6484 19.5709L10.211 17.3162C10.5848 17.4178 10.9482 17.5115 11.3037 17.5998L10.7434 19.8439L12.1151 20.1857L12.6827 17.9111C15.0217 18.3537 16.7808 18.1752 17.5204 16.0603C18.1169 14.3572 17.4911 13.3748 16.2605 12.7338C17.1567 12.5264 17.8317 11.9369 18.0118 10.7194H18.0122ZM14.8779 15.1135C14.4536 16.8166 11.5861 15.8963 10.6559 15.665L11.4091 12.6459C12.3389 12.8779 15.3201 13.3373 14.8783 15.1135H14.8779ZM15.3018 10.6947C14.915 12.244 12.528 11.4568 11.7533 11.2639L12.4362 8.52561C13.2109 8.71858 15.7049 9.07874 15.3018 10.6947V10.6947Z"
        fill="#00CACD"
      />
    </svg>
  )
}
