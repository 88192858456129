import styled from 'styled-components'

export const CaseDetailsFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FormGroupWrapperCol = styled.div`
  display: flex;
  flex-direction: column;
`
export const FormGroupWrapperRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  &:first-child {
    align-items: center;
  }
`
export const FormGroupContainerRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 20px;
`

export const FormGroupWrapperHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 400px;
  gap: 20px;

  & > div {
    width: 100%;
  }
`

export const FormGroupWrapperSmall = styled.div`
  display: flex;
  min-width: 300px;
  flex-direction: column;
`

export const FormGroupWrapperLarge = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div > textarea {
    height: 150px;
  }
`

export const FormInputGroupItem = styled.div`
  & > div > textarea {
    height: 374px;
  }
`

export const FormErrorText = styled.div`
  margin-bottom: 11px;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.red};
`
