import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
// import { TableWrapper } from './ReportsAddress.elements'

import { App, Header, Sidebar, Content, PageWrapper, ReportsAddressTable, ReportsSubHeader } from 'views/layouts'
import { Container } from '../../../components'

function ReportsAddress() {
  // Destructure
  // const { } = props

  return (
    <App>
      <Sidebar />
      <Header subHeader={<ReportsSubHeader />} pageTitle="Reports" />
      <Content>
        <PageWrapper>
          <Container>
            <ReportsAddressTable />
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
ReportsAddress.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
ReportsAddress.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default ReportsAddress
