import axios from 'axios'

// Endpoint
import { ALERTS_ENDPOINTS } from 'common/constants/apiUrls'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const authAxios = axios.create({
  baseURL: 'https://dev-detect.ospree.io/',
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const GetAllAlerts = async ({ business_id }) => {
  const response = await authAxios.get(`${ALERTS_ENDPOINTS.READ_ALL_ALERTS}/${business_id}`)

  const data = response.data.content

  return data
}

export const GetAlertDetails = async ({ alert_id }) => {
  const response = await authAxios.get(`${ALERTS_ENDPOINTS.READ_ALERT_DETAILS}/${alert_id}`)

  const data = response.data.content

  return data
}

export const GetDailyAlerts = async () => {
	const response = await authAxios.get(ALERTS_ENDPOINTS.READ_DAILY_ALERTS, { })

  const data = response.data.content

  return data
}

export const GetAlertsBySeverity = async () => {
	const response = await authAxios.get(ALERTS_ENDPOINTS.READ_ALERTS_BY_SEVERITY, { })

	const data = response.data.content

	return data
}

export const GetAlertsByStatus = async () => {
	const response = await authAxios.get(ALERTS_ENDPOINTS.READ_ALERTS_BY_STATUS, { })

	const data = response.data.content

	return data
}
