import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper, BarBackdrop, BarLine } from './RiskBar.elements'

function RiskBar(props) {
  // Destructure
  const { width, height } = props

  return (
    <Wrapper>
      <BarBackdrop height={height} style={{ width: `${(parseInt(width, 10) - 100) * -1}%` }} />
      <BarLine height={height} />
    </Wrapper>
  )
}

// Default Props
RiskBar.defaultProps = {
  width: '',
  height: 0,
}

// Proptypes Validation
RiskBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
}

export default RiskBar
