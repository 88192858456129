import React from 'react'

// Constants
// import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Assets
import OspreeLogoIcon from 'assets/images/ospreeLogo'

// Styled Elements
import {
  ResetPasswordConfirmWrapper,
  ResetPasswordConfirmInnerWrapper,
  ResetPasswordConfirmLogoLink,
  ResetPasswordConfirmTitle,
  ResetPasswordConfirmFormWrapper,
  ResetPasswordConfirmFormInnerWrapper,
  ResetPasswordConfirmFormContent,
  ResetPasswordConfirmFormFooterText,
  ResetPasswordConfirmFormFooterLink,
  FormText,
} from './ResetPasswordConfirm.elements'

// Components
import { ResetPasswordConfirmForm } from 'views/layouts'

function ResetPasswordConfirm() {
  return (
    <ResetPasswordConfirmWrapper>
      <ResetPasswordConfirmInnerWrapper>
        <ResetPasswordConfirmFormWrapper>
          <ResetPasswordConfirmFormInnerWrapper>
            <ResetPasswordConfirmFormContent>
              <ResetPasswordConfirmLogoLink>
                <OspreeLogoIcon />
              </ResetPasswordConfirmLogoLink>
              <ResetPasswordConfirmTitle>Reset Password</ResetPasswordConfirmTitle>
              <ResetPasswordConfirmForm />
            </ResetPasswordConfirmFormContent>
          </ResetPasswordConfirmFormInnerWrapper>
        </ResetPasswordConfirmFormWrapper>
      </ResetPasswordConfirmInnerWrapper>
    </ResetPasswordConfirmWrapper>
  )
}

// Default Props
ResetPasswordConfirm.defaultProps = {}

// Proptypes Validation
ResetPasswordConfirm.propTypes = {}

export default ResetPasswordConfirm
