import { useMutation } from 'react-query'

// Services
import { GetAddressHistory } from 'services/api/Addresses'

export default () => {
  const {
    mutate: getAddressHistory,
    data: getAddressHistoryData,
    error: getAddressHistoryError,
    isLoading: isGetAddressHistoryLoading,
    isSuccess: isGetAddressHistorySuccess,
    isError: isGetAddressHistoryError,
  } = useMutation(GetAddressHistory)

  return {
    getAddressHistory,
    getAddressHistoryData,
    getAddressHistoryError,
    isGetAddressHistoryLoading,
    isGetAddressHistorySuccess,
    isGetAddressHistoryError,
  }
}
