import { useMutation } from 'react-query'

// Services
import { GetAccount } from 'services/api/Accounts'

export default () => {
  const {
    mutate: getAccount,
    data: accountData,
    error: accountError,
    isLoading: isAccountLoading,
    isSuccess: isAccountSuccess,
    isError: isAccountError,
  } = useMutation(GetAccount)

  return {
    getAccount,
    accountData,
    accountError,
    isAccountLoading,
    isAccountSuccess,
    isAccountError,
  }
}
