/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ButtonFilterWrapper, FilterWrapper, FilterContainer } from './ButtonFilter.elements'

// Assets
import FilterIcon from 'assets/images/filter'
import CloseIcon from 'assets/images/close'

// Components
import { Button } from 'views/components'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, handleClose) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function ButtonFilter(props) {
  // Destructure
  const {
    // label,
    // options,
    // disabledOptions,
    // setOptions,
    // placeholder,
    filter,
    // bottomOptions,
    // onInputChange,
    // disabled,
    // onSelect,
    // isLoading,
    // value,
    id,
    ...rest
  } = props

  // Variables
  const wrapperRef = useRef()

  // States
  const [collapseFilter, setCollapseFilter] = useState(true)

  // Functions
  const handleCollapseFilter = () => {
    setCollapseFilter((prevState) => !prevState)
  }
  // Closing options on click away
  useOutsideAlerter(wrapperRef, handleCollapseFilter)

  return (
    <ButtonFilterWrapper>
      {filter && (
        <FilterWrapper id={id}>
          <FilterContainer collapseFilter={collapseFilter}>{filter}</FilterContainer>
          <Button
            size="small"
            onClick={handleCollapseFilter}
            startIcon={collapseFilter ? <FilterIcon /> : <CloseIcon />}
            variant="outlined"
            {...rest}
          >
            {collapseFilter ? 'Filters' : 'Close'}
          </Button>
        </FilterWrapper>
      )}
    </ButtonFilterWrapper>
  )
}

// Default Props
ButtonFilter.defaultProps = {
  id: '',
  label: '',
  placeholder: '',
  options: [],
  setOptions: () => {},
  onSelect: () => {},
  onInputChange: () => {},
  isLoading: false,
  disabled: false,
  value: '',
}

// Proptypes Validation
ButtonFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  setOptions: PropTypes.func,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledOptions: PropTypes.bool,
  value: PropTypes.string,
  filter: PropTypes.any,
  bottomOptions: PropTypes.any,
}

export default ButtonFilter
