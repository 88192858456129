import React, { createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import moment from 'moment'
import { connect } from 'react-redux'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'
import { CASE_STATUS_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useUpdateCase } from 'core/hooks/api'

// Styled Elements
import {
  CaseDetailsProfileFormWrapper,
  FormHeader,
  FormInputGroupWrapper,
  FormInputGroupItem,
} from './CaseDetailsProfileForm.elements'

// Views
import { Form, FormTextField, FormSelect, FormDatePicker } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function CaseDetailsProfileForm(props) {
  // Destructure
  const { setIsCaseUpdated, formRef, customerData, countryListOptions, actions } = props
  const { setShowHeaderLoader, showAlert } = actions
  const {
    Adverse_Media,
    Adverse_Media_ID,
    Business_ID,
    Business_Name,
    Case_ID,
    Case_Risk,
    Case_Since,
    Case_Type,
    Case_Type_ID,
    Comments,
    Company_Name,
    Country_Address,
    Customer_ID,
    Date_Birth,
    Date_Incorporation,
    Country_Incorporation,
    First_Name,
    Last_Modified,
    Last_Name,
    Middle_Name,
    Nationality,
    PEP,
    PEP_ID,
    Risk_ID,
    Status_ID,
  } = customerData

  // Hooks
  const { updateCase, isUpdateCaseLoading, isUpdateCaseSuccess, isUpdateCaseError } = useUpdateCase()

  // Variables
  const statusOptions = CASE_STATUS_OPTIONS.filter((object) => object.label !== 'All')
  const isLegalPersonCaseType = Case_Type === 'Legal Person'
  const dateIncTernary = (Date_Incorporation !== '0000-00-00' ? Date_Incorporation : '')
  const dateBirthTernary = (Date_Birth !== '0000-00-00' ? Date_Birth : '')

  const initialValues = {
    customerId: Customer_ID,
    caseType: Case_Type,
    countryOfDomicile: Country_Address,
    lastModified: Last_Modified,
    status: Status_ID,
    dateOfIncorporation: isLegalPersonCaseType ? dateIncTernary : '',

    companyName: Company_Name,
    countryOfIncorporation: Country_Incorporation,
    caseSince: moment(Case_Since).format(DASHED_DATE_FORMAT),

    firstName: First_Name,
    middleName: Middle_Name,
    lastName: Last_Name,
    dateOfBirth: isLegalPersonCaseType ? '' : dateBirthTernary,
    nationality: Nationality,
  }

  const validationSchema = Yup.object().shape({})

  // Functions
  const handleOnSubmit = (values) => {
    if (isUpdateCaseLoading) {
      return
    }
    const {
      customerId,
      caseType,
      countryOfDomicile,
      status,
      dateOfIncorporation,
      companyName,
      countryOfIncorporation,
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      nationality,
    } = values

    const isLegalPersonCaseType = caseType === 'Legal Person'

    const conditionalPayload = !isLegalPersonCaseType ?
      {
        date_birth: dateOfBirth,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        nationality,
        nationalityText: countryListOptions.filter(({ value }) => value === nationality)[0]?.label || ''
      } :
      {
        company_name: companyName,
        date_incorporation: dateOfIncorporation,
        country_incorporation: countryOfIncorporation,
      }
    // removing empty fields on object
    Object.keys(conditionalPayload).forEach((k) => conditionalPayload[k] === '' && delete conditionalPayload[k]);
    const payload = {
      // Default
      adverseMediaText: Adverse_Media,
      adverse_media: Adverse_Media_ID,
      caseRiskText: Case_Risk,
      case_risk: Risk_ID,
      comments: Comments,
      pep: PEP_ID,
      pepText: PEP,
      customer_id: customerId,
      case_type: caseType,
      case_type_id: Case_Type_ID,
      case_since: Case_Since,
      last_modified: Last_Modified,

      ...conditionalPayload,

      business_id: Business_ID,
      business_name: Business_Name,
      case_id: Case_ID,
      statusText: CASE_STATUS_OPTIONS.filter(({ value }) => value === +status)[0]?.label || '',
      status,
      country_address: countryOfDomicile,
    }

    updateCase(payload)
  }

  useEffect(() => {
    if (isUpdateCaseLoading) { return setShowHeaderLoader(true) }
    if (!isUpdateCaseLoading && isUpdateCaseSuccess) {
      setIsCaseUpdated(true)
      setShowHeaderLoader(false)
    }
  }, [isUpdateCaseLoading, isUpdateCaseSuccess])

  useEffect(() => {
    if (isUpdateCaseError) showAlert({ type: 'error', message: 'An error occured in saving the case, please try again.' })
  }, [isUpdateCaseError])

  return (
    <CaseDetailsProfileFormWrapper>
      <Form
        enableReinitialize
        formRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        onChange={() => {}}
      >
        <FormHeader>Personal Information</FormHeader>
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormTextField label="Customer ID" type="text" name="customerId" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Case Type" type="text" name="caseType" disabled />
          </FormInputGroupItem>
        </FormInputGroupWrapper>
        { Object.keys(customerData).length !== 0 ?
          <>
            {Case_Type === 'Legal Person' ? (
              <FormInputGroupWrapper>
                <FormInputGroupItem>
                  <FormTextField label="Company Name" type="text" name="companyName" />
                </FormInputGroupItem>
              </FormInputGroupWrapper>
            ) : (
              <FormInputGroupWrapper>
                <FormInputGroupItem>
                  <FormTextField label="First Name" type="text" name="firstName" />
                </FormInputGroupItem>
                <FormInputGroupItem>
                  <FormTextField label="Middle Name" type="text" name="middleName" />
                </FormInputGroupItem>
                <FormInputGroupItem>
                  <FormTextField label="Last Name" type="text" name="lastName" />
                </FormInputGroupItem>
              </FormInputGroupWrapper>
            )}
            <FormInputGroupWrapper>
              {Case_Type === 'Legal Person' ? (
                <FormInputGroupItem>
                  <FormDatePicker
                    label="Date of Incorporation"
                    type="date"
                    name="dateOfIncorporation"
                    id="dateOfIncorporation"
                  />
                </FormInputGroupItem>
              ) : (
                <FormInputGroupItem>
                  <FormDatePicker label="Date of Birth" type="date" name="dateOfBirth" />
                </FormInputGroupItem>
              )}
              {Case_Type === 'Legal Person' ? (
                <FormInputGroupItem>
                  <FormSelect
                    label="Country of Incorporation"
                    name="countryOfIncorporation"
                    id="countryOfIncorporation"
                    placeholder="Choose Country"
                    options={countryListOptions}
                  />
                </FormInputGroupItem>
              ) : (
                <FormInputGroupItem>
                  <FormSelect
                    label="Nationality"
                    name="nationality"
                    placeholder="Choose Country"
                    options={countryListOptions}
                  />
                </FormInputGroupItem>
              )}
              <FormInputGroupItem>
                <FormSelect
                  label="Country of Domicile"
                  name="countryOfDomicile"
                  placeholder="Choose Country"
                  options={countryListOptions}
                />
              </FormInputGroupItem>
            </FormInputGroupWrapper>
          </>
          :
          <></>
        }
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormTextField label="Case Since" type="text" name="caseSince" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Last Modified" type="text" name="lastModified" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormSelect label="Status" name="status" options={statusOptions} />
          </FormInputGroupItem>
        </FormInputGroupWrapper>
      </Form>
    </CaseDetailsProfileFormWrapper>
  )
}

// Default Props
CaseDetailsProfileForm.defaultProps = {
  setIsCaseUpdated: () => {},
  actions: {},
  formRef: createRef(),
  customerData: {},
  countryListOptions: [],
}

// Proptypes Validation
CaseDetailsProfileForm.propTypes = {
  setIsCaseUpdated: PropTypes.func,
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  formRef: PropTypes.shape({ root: PropTypes.string }),
  countryListOptions: PropTypes.instanceOf(Array),
  customerData: PropTypes.shape({
    Customer_ID: PropTypes.string,
    Adverse_Media: PropTypes.string,
    Adverse_Media_ID: PropTypes.number,
    Business_ID: PropTypes.string,
    Business_Name: PropTypes.string,
    Case_ID: PropTypes.string,
    Case_Risk: PropTypes.string,
    Case_Since: PropTypes.string,
    Case_Status: PropTypes.string,
    Case_Type: PropTypes.string,
    Case_Type_ID: PropTypes.number,
    Comments: PropTypes.string,
    Company_Name: PropTypes.string,
    Country_Address: PropTypes.string,
    Country_Address_Description: PropTypes.string,
    Country_Incorporation: PropTypes.string,
    Country_Incorporation_Description: PropTypes.string,
    Date_Birth: PropTypes.string,
    Date_Incorporation: PropTypes.string,
    First_Name: PropTypes.string,
    Last_Modified: PropTypes.string,
    Last_Name: PropTypes.string,
    Middle_Name: PropTypes.string,
    Nationality: PropTypes.string,
    Nationality_Description: PropTypes.string,
    PEP: PropTypes.string,
    PEP_ID: PropTypes.number,
    Risk_ID: PropTypes.number,
    Status_ID: PropTypes.number,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetailsProfileForm)
