import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 400px;
  min-width: 260px;
  position: relative;
  width: 100%;
`
export const BarLine = styled.div`
  background: linear-gradient(45deg, #caff0a, #caff0a 30%, #ec8800 50%, #cb5300 100%);
  height: ${({ height }) => (height ? `${height}px` : '8px')};
  width: 100%;
  border-radius: 5px;
  width: 100%;
`
export const BarBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: ${({ height }) => (height ? `${height}px` : '8px')};
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 0 5px 5px 0;
`
