import React from 'react'

export default function ArrowUpIcon() {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.75 13.75L14.25 1.25" stroke="#727F97" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.75 1.25H14.25V13.75" stroke="#727F97" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
