import { useMutation } from 'react-query'

// Services
import { CreateOrUpdateAddress } from 'services/api/Addresses'

export default () => {
  const {
    mutate: createOrUpdateAddress,
    data: createOrUpdateAddressData,
    error: createOrUpdateAddressError,
    isLoading: isCreateOrUpdateAddressLoading,
    isSuccess: isCreateOrUpdateAddressSuccess,
    isError: isCreateOrUpdateAddressError,
  } = useMutation(CreateOrUpdateAddress)

  return {
    createOrUpdateAddress,
    createOrUpdateAddressData,
    createOrUpdateAddressError,
    isCreateOrUpdateAddressLoading,
    isCreateOrUpdateAddressSuccess,
    isCreateOrUpdateAddressError,
  }
}
