import { useState } from 'react'
import moment from 'moment'
import { useMutation } from 'react-query'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Services
import { GetWhitelistingAddressHistory } from 'services/api/Whitelisting'

export default () => {
  // States
  const [whitelistingAddressHistoryData, setWhitelistingAddressHistoryData] = useState({
    labels: [],
    datasets: [
      {
        backgroundColor: '#cbcbcb',
        data: [],
        label: 'Daily Activity',
      },
    ],
  })

  // Hooks
  const {
    mutate: getWhitelistingAddressHistory,
    error: whitelistingAddressHistoryError,
    isLoading: isWhitelistingAddressHistoryLoading,
    isSuccess: isWhitelistingAddressHistorySuccess,
    isError: isWhitelistingAddressHistoryError,
  } = useMutation(GetWhitelistingAddressHistory, {
    onSuccess: (data, params) => {
      const chartData = getDates(data, params)

      const labels = chartData.map((o) => o.label)
      const values = chartData.map((o) => o.count)

      setWhitelistingAddressHistoryData({
        labels,
        datasets: [
          {
            label: 'Daily Activity',
            data: values,
            backgroundColor: '#cbcbcb',
          },
        ],
      })
    },
  })

  // Functions
  const getDates = (data, params) => {
    const { start_range, end_range } = params

    const days = []

    let currentDate = moment(start_range)
    const stopDate = moment(end_range)

    while (currentDate <= stopDate) {
      days.push({
        label: moment(currentDate).format(DASHED_DATE_FORMAT),
      })
      currentDate = moment(currentDate).add(1, 'days')
    }

    const copyArr = days

    data.forEach((result) => {
      copyArr.forEach((item, i) => {
        if (result.CreateTimestamp === item.label) {
          days[i].count = result.count
        }
      })
    })

    days.forEach((item, i) => {
      if (item.count === undefined) {
        days[i].count = 0
      }
    })

    return days
  }

  return {
    getWhitelistingAddressHistory,
    whitelistingAddressHistoryData,
    whitelistingAddressHistoryError,
    isWhitelistingAddressHistoryLoading,
    isWhitelistingAddressHistorySuccess,
    isWhitelistingAddressHistoryError,
  }
}
