import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Styled Elements
import {
  DateRangePickerWrapper,
  DateRangeHolder,
  DateRangeTextWrapper,
  DateRangeText,
} from './DateRangePicker.elements'

import { CalendarIcon } from 'assets/images'

function DateRangePicker(props) {
  // Destructure
  const { opens, onApply, start, end, id, ...rest } = props

  // States
  const [dateRange, setDateRange] = useState({
    startDate: start || moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    endDate: end || moment().add(1, 'days').format(DASHED_DATE_FORMAT),
  })

  // Functions
  const handleApplyDatePicker = (e, picker) => {
    // Ignoring changes to table filters that are exactly the same as previous state
    // const { startDate, endDate } = dateRange
    // if (
    //   startDate === picker.startDate.format(DASHED_DATE_FORMAT) &&
    //   endDate === picker.endDate.format(DASHED_DATE_FORMAT)
    // ) {
    //   return
    // }
    setDateRange({
      startDate: picker.startDate.format(DASHED_DATE_FORMAT),
      endDate: picker.endDate.format(DASHED_DATE_FORMAT),
    })

    onApply({
      start: picker.startDate.format(DASHED_DATE_FORMAT),
      end: picker.endDate.format(DASHED_DATE_FORMAT),
    })
  }

  return (
    <DateRangePickerWrapper id={id}>
      <DatePicker
        onApply={handleApplyDatePicker}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        locale={{ format: DASHED_DATE_FORMAT }}
        initialSettings={{
          maxDate: moment(moment(), DASHED_DATE_FORMAT),
          startDate: moment(dateRange.startDate, DASHED_DATE_FORMAT),
          endDate: moment(dateRange.endDate, DASHED_DATE_FORMAT),
          showDropdowns: true,
        }}
        opens={opens}
        {...rest}
      >
        <DateRangeHolder>
          <DateRangeTextWrapper>
            <DateRangeText>From:</DateRangeText>
            <DateRangeText>{dateRange.startDate}</DateRangeText>
          </DateRangeTextWrapper>
          <DateRangeTextWrapper>
            <DateRangeText>To:</DateRangeText>
            <DateRangeText>{dateRange.endDate}</DateRangeText>
          </DateRangeTextWrapper>
          <DateRangeTextWrapper>
            <CalendarIcon />
          </DateRangeTextWrapper>
        </DateRangeHolder>
      </DatePicker>
    </DateRangePickerWrapper>
  )
}

// Default Props
DateRangePicker.defaultProps = {
  opens: 'center',
  onApply: () => {},
  start: '',
  end: '',
  id: '',
}

// Proptypes Validation
DateRangePicker.propTypes = {
  opens: PropTypes.string,
  onApply: PropTypes.func,
  start: PropTypes.string,
  end: PropTypes.string,
  id: PropTypes.string,
}

export default DateRangePicker
