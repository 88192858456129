/* eslint-disable max-len */
import React from 'react'

export default function NotificationIcon() {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3714 20.3857C12.1902 20.6995 11.9302 20.96 11.6174 21.1411C11.3046 21.3222 10.95 21.4175 10.589 21.4175C10.228 21.4175 9.87337 21.3222 9.56056 21.1411C9.24776 20.96 8.98777 20.6995 8.80664 20.3857"
        stroke="#727F97"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3387 7.71235C17.3387 6.06473 16.6875 4.4846 15.5282 3.31955C14.3689 2.15451 12.7966 1.5 11.1571 1.5C9.51769 1.5 7.94538 2.15451 6.78611 3.31955C5.62683 4.4846 4.97556 6.06473 4.97556 7.71235C4.97556 14.9601 1.88477 17.0309 1.88477 17.0309H20.4295C20.4295 17.0309 17.3387 14.9601 17.3387 7.71235Z"
        stroke="#727F97"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
