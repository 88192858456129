import { authAxios } from 'common/utils/axios'

import { RECIPIENT_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'

import axios from 'axios'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const authAxiosSecondServer = axios.create({
  baseURL: LOCAL_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const GetAllRecipients = async (values) => {
  const response = await authAxios.get(RECIPIENT_ENDPOINTS.GET_ALL_RECIPIENTS, {
    params: { ...values, is_recipient: true },
  })

  const { data } = response.data

  return data
}

export const GetRecipient = async ({ account_id }) => {
  const response = await authAxios.get(`${RECIPIENT_ENDPOINTS.GET_RECIPIENT}${account_id}`)

  const { data } = response.data

  return data
}
export const SearchRecipient = async ({ search_term }) => {
  const response = await authAxios.get(RECIPIENT_ENDPOINTS.SEARCH_RECIPIENT, {
    params: {
      search_term,
      is_recipient: true,
    },
  })

  const { data } = response.data

  return data
}

export const CreateRecipientEntity = async (values) => {
  const response = await authAxios.post(RECIPIENT_ENDPOINTS.CREATE_RECIPIENT_ENTITY, {
    ...values,
    is_recipient: true,
  })

  const { data } = response.data

  return data
}
export const CreateRecipientIndividual = async (values) => {
  const response = await authAxios.post(RECIPIENT_ENDPOINTS.CREATE_RECIPIENT_INDIVIDUAL, {
    ...values,
    is_recipient: true,
  })

  const { data } = response.data

  return data
}

export const UpdateRecipientEntity = async ({ account_id, values }) => {
  const response = await authAxios.patch(`${RECIPIENT_ENDPOINTS.UPDATE_RECIPIENT_ENTITY}${account_id}`, {
    ...values,
    is_recipient: true,
  })

  const { data } = response.data

  return data
}
export const UpdateRecipientIndividual = async ({ account_id, values }) => {
  const response = await authAxios.patch(`${RECIPIENT_ENDPOINTS.UPDATE_RECIPIENT_INDIVIDUAL}${account_id}`, {
    ...values,
    is_recipient: true,
  })

  const { data } = response.data

  return data
}
