import { useMutation } from 'react-query'

// Services
import { GetTransactionEvents } from 'services/api/Transactions'

export default () => {
  const {
    mutate: getTransactionEvents,
    data: getTransactionEventsData,
    error: getTransactionEventsError,
    isLoading: isGetTransactionEventsLoading,
    isSuccess: isGetTransactionEventsSuccess,
    isError: isGetTransactionEventsError,
  } = useMutation(GetTransactionEvents)

  return {
    getTransactionEvents,
    getTransactionEventsData,
    getTransactionEventsError,
    isGetTransactionEventsLoading,
    isGetTransactionEventsSuccess,
    isGetTransactionEventsError,
  }
}
