import { useMutation } from 'react-query'

// Services
import { RejectVasp } from 'services/api/Admin'

export default () => {
  const {
    mutate: rejectVasp,
    data: rejectVaspData,
    error: rejectVaspError,
    isLoading: isRejectVaspLoading,
    isSuccess: isRejectVaspSuccess,
    isError: isRejectVaspError,
  } = useMutation(RejectVasp)

  return {
    rejectVasp,
    rejectVaspData,
    rejectVaspError,
    isRejectVaspLoading,
    isRejectVaspSuccess,
    isRejectVaspError,
  }
}
