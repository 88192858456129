import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ModalFooterWrapper } from './ModalFooter.elements'

function ModalFooter(props) {
  // Destructure
  const { children, ...rest } = props

  return <ModalFooterWrapper {...rest}>{children}</ModalFooterWrapper>
}

// Default Props
ModalFooter.defaultProps = {
  children: '',
}

// Proptypes Validation
ModalFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default ModalFooter
