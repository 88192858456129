import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Constants
import { ROLE_OPTIONS } from 'common/constants/formOptions'

// Hooks
import { useCreateUser } from 'core/hooks/api'

// Store
import { actions } from 'core/store'

// Styled Elements
import { FormWrapper, FormInputGroupItem, FormGroupWrapper, FormTitle, ErrMsg } from './UserForm.elements'

// Views
import { Form, FormTextField, FormSelect, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserForm(props) {
  // Destructure
  const { actions } = props

  // Actions
  const { setIsPageTableUpdated, showAlert, setIsUserDrawerOpen } = actions

  // Internal State
  const [errorMsg, setErrorMsg] = useState()

  // ref
  const formRef = useRef()

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required.'),
    name_first: Yup.string().max(100).required('First name is required.'),
    name_last: Yup.string().max(100).required('Last name is required.'),
    role_id: Yup.number().required('Role is required.'),
  })

  // Hooks
  const { createUser, createUserData, createUserError, isCreateUserLoading } = useCreateUser()

  // Functions
  const triggerSubmit = () => {
    formRef.current.submitForm()
  }
  const handleSubmit = (values) => {
    createUser(values)
  }
  const handleSuccess = () => {
    setIsPageTableUpdated(true)
    showAlert({ type: 'success', message: 'Successfully created user' })
    setIsUserDrawerOpen()
  }
  const handleErr = () => {
    if (createUserError?.response?.data?.context) {
      setErrorMsg(createUserError?.response?.data?.context)
      return
    }
    showAlert({ type: 'error', message: 'Error: Could not create user' })
  }

  // UseEffects
  useEffect(() => {
    if (isCreateUserLoading) return
    if (createUserError) {
      handleErr()
      return
    }
    if (createUserData) handleSuccess()
  }, [createUserData, createUserError])
  return (
    <FormWrapper>
      <Form formRef={formRef} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <FormTitle>Create User</FormTitle>
        <FormGroupWrapper>
          {errorMsg && <ErrMsg>{errorMsg}</ErrMsg>}
          <FormInputGroupItem>
            <FormTextField label="Email (Required)" type="email" name="email" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="First Name (Required)" name="name_first" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="Last Name (Required)" name="name_last" />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormSelect label="Role Type (Required)" name="role_id" options={ROLE_OPTIONS} />
          </FormInputGroupItem>
        </FormGroupWrapper>
        {isCreateUserLoading ? (
          <Button disabled>Creating user...</Button>
        ) : (
          <Button type="button" onClick={triggerSubmit}>
            Submit
          </Button>
        )}
      </Form>
    </FormWrapper>
  )
}

// Default Props
UserForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
  values: {
    external_id: '',
    account_type_id: 0,
    status_id: 0,
    email: '',
    primary_activity_code: 0,
    nationality_code: '',
    name_first: '',
    name_middle: '',
    name_last: '',
    dob: '',

    created: '',
    last_modified: '',

    address_street: '',
    address_city: '',
    address_region: '',
    address_postcode: '',
    address_country_code: '',
  },
}

// Proptypes Validation
UserForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    COUNTRIES_LIST: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.shape({}),
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setIsUserDrawerOpen: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  values: PropTypes.shape({
    external_id: PropTypes.string,
    account_type_id: PropTypes.number,
    status_id: PropTypes.number,
    email: PropTypes.string,
    primary_activity_code: PropTypes.number,
    nationality_code: PropTypes.number,
    name_first: PropTypes.string,
    name_middle: PropTypes.string,
    name_last: PropTypes.string,
    dob: PropTypes.string,

    created: PropTypes.string,
    last_modified: PropTypes.string,

    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_region: PropTypes.string,
    address_postcode: PropTypes.string,
    address_country_code: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
