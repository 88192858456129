import styled from 'styled-components'

export const BreakdownWrapper = styled.div`
  padding: 0 0 30px 0;
`
export const BreakdownHeaders = styled.div`
  padding: 5px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2.3fr;
  
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const BreakdownHeaderTitle = styled.div`
  text-align: right;
  &:first-child {
    text-align: left;
  }
`
export const BreakdownContainer = styled.div`
  padding: 5px 0px 10px 0px;

  border-top: ${({ theme }) => `2px solid ${theme.colors.darkGray}`};
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.darkGray}`};

  max-height: 120px;
  min-height: 120px;
  overflow-y: auto;
`
export const BreakdownSpan = styled.span`
  padding-right: 5px;
  
  font-weight: 300;
`
export const BreakdownRow = styled.div`
  display: grid;
  padding: 5px 0;
  grid-template-columns: 1fr 1fr 1fr 2.3fr;

  border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
  
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const BreakdownDetails = styled.div`
  text-align: right;
  &:first-child {
    text-align: left;
  }
`
