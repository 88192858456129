import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import { queryBuilderConfig } from './queryBuilderConfig';

const InitialConfig = AntdConfig;

export const configData = {
  ...InitialConfig,
  ...queryBuilderConfig,
  fields: {
    payment_direction: {
      label: 'Payment direction',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        showSearch: true,
        listValues: [
          { value: 'incoming', title: 'Incoming' },
          { value: 'outgoing', title: 'Outgoing' },
          { value: 'incomingoutgoing', title: 'Incoming and Outgoing' }
        ],
      },
    },
    threshold_value: {
      label: 'Threshold Value',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 0,
        max: 5000000
      },
    },
    agg_threshold_value: {
      label: 'Aggregate Threshold Value',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 0,
        max: 5000000
      },
    },
    evaluation_period_days: {
      label: 'Evaluation Period (in days)',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 1,
      },
    },
    address_risk_score: {
      label: 'Address Risk Score (1-10)',
      type: 'number',
      preferWidgets: ['number'],
      valueSources: ['value'],
      fieldSettings: {
        min: 0,
        max: 10,
      },
    },
    transaction_risk_score: {
      label: 'Transaction Risk Score (1-10)',
      type: 'number',
      preferWidgets: ['number'],
      valueSources: ['value'],
      fieldSettings: {
        min: 0,
        max: 10,
      },
    },
    counterparty_country_risk: {
      label: 'Counterparty Country Risk (1-10)',
      type: 'number',
      preferWidgets: ['number'],
      valueSources: ['value'],
      fieldSettings: {
        min: 0,
        max: 10,
      },
    },
    counterparty_vasp_name: {
      label: 'Counterparty VASP Name',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'known', title: 'Known' },
          { value: 'unknown', title: 'Unknown' },
        ],
      },
    },
    counterparty_user_name: {
      label: 'Counterparty User Name',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'sanctioned', title: 'Sanctioned' },
          { value: 'unsanctioned', title: 'Unsanctioned' },
        ],
      },
    },
    profile_risk_score: {
      label: 'Profile Risk Score',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'low', title: 'Low' },
          { value: 'medium', title: 'Medium' },
          { value: 'high', title: 'High' },
        ],
      },
    },
    deviation_from_average_transaction_volume: {
      label: 'Deviation from Average Transaction Volume (in percentage)',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 0,
        max: 100
      },
    },
    associated_flags: {
      label: 'Associated Flag(s)',
      type: 'multiselect',
      fieldSettings: {
        listValues: {
          ransomware: 'Ransomware',
          sanctioned: 'Sanctioned',
          child_exploitation: 'Child Exploitation',
          terrorism: 'Terrorism',
          weapons_trafficking: 'Weapons Trafficking',
          dark_market: 'Dark Market',
          drug_trafficking: 'Drug Trafficking',
          extortion: 'Extortion',
          fraud: 'Fraud',
          hack: 'Hack',
          mixer: 'Mixer',
          gambling: 'Gambling',
          pornography: 'Pornography',
        },
      },
    },
    distance: {
      label: 'Distance',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        showSearch: true,
        listValues: [
          { value: '1_hop', title: '1 Hop' },
          { value: '2_hops', title: '2 Hops' },
          { value: '3_hops', title: '3 Hops' },
          { value: '4_hops', title: '4 Hops' },
          { value: '5_hops', title: '5 Hops' },
        ],
      },
    },
  }
}

export const configDataReadOnly = {
  ...InitialConfig,
  ...queryBuilderConfig,
  settings: {
    immutableGroupsMode: true,
    immutableFieldsMode: true,
    immutableOpsMode: true,
    immutableValuesMode: true,
    canReorder: false,
    canRegroup: false,
  },
  fields: {
    payment_direction: {
      label: 'Payment direction',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        showSearch: true,
        listValues: [
          { value: 'incoming', title: 'Incoming' },
          { value: 'outgoing', title: 'Outgoing' },
          { value: 'incomingoutgoing', title: 'Incoming and Outgoing' }
        ],
      },
    },
    threshold_value: {
      label: 'Threshold Value',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 0,
        max: 5000000
      },
    },
    agg_threshold_value: {
      label: 'Aggregate Threshold Value',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 0,
        max: 5000000
      },
    },
    evaluation_period_days: {
      label: 'Evaluation Period (in days)',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 1,
      },
    },
    address_risk_score: {
      label: 'Address Risk Score (1-10)',
      type: 'number',
      preferWidgets: ['number'],
      valueSources: ['value'],
      fieldSettings: {
        min: 0,
        max: 10,
      },
    },
    transaction_risk_score: {
      label: 'Transaction Risk Score (1-10)',
      type: 'number',
      preferWidgets: ['number'],
      valueSources: ['value'],
      fieldSettings: {
        min: 0,
        max: 10,
      },
    },
    counterparty_country_risk: {
      label: 'Counterparty Country Risk (1-10)',
      type: 'number',
      preferWidgets: ['number'],
      valueSources: ['value'],
      fieldSettings: {
        min: 0,
        max: 10,
      },
    },
    counterparty_vasp_name: {
      label: 'Counterparty VASP Name',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'known', title: 'Known' },
          { value: 'unknown', title: 'Unknown' },
        ],
      },
    },
    counterparty_user_name: {
      label: 'Counterparty User Name',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'sanctioned', title: 'Sanctioned' },
          { value: 'unsanctioned', title: 'Unsanctioned' },
        ],
      },
    },
    profile_risk_score: {
      label: 'Profile Risk Score',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        listValues: [
          { value: 'low', title: 'Low' },
          { value: 'medium', title: 'Medium' },
          { value: 'high', title: 'High' },
        ],
      },
    },
    deviation_from_average_transaction_volume: {
      label: 'Deviation from Average Transaction Volume (in percentage)',
      type: 'number',
      preferWidgets: ['number'],
      fieldSettings: {
        min: 0,
        max: 100
      },
    },
    associated_flags: {
      label: 'Associated Flag(s)',
      type: 'multiselect',
      fieldSettings: {
        listValues: {
          ransomware: 'Ransomware',
          sanctioned: 'Sanctioned',
          child_exploitation: 'Child Exploitation',
          terrorism: 'Terrorism',
          weapons_trafficking: 'Weapons Trafficking',
          dark_market: 'Dark Market',
          drug_trafficking: 'Drug Trafficking',
          extortion: 'Extortion',
          fraud: 'Fraud',
          hack: 'Hack',
          mixer: 'Mixer',
          gambling: 'Gambling',
          pornography: 'Pornography',
        },
      },
    },
    distance: {
      label: 'Distance',
      type: 'select',
      valueSources: ['value'],
      fieldSettings: {
        showSearch: true,
        listValues: [
          { value: '1_hop', title: '1 Hop' },
          { value: '2_hops', title: '2 Hops' },
          { value: '3_hops', title: '3 Hops' },
          { value: '4_hops', title: '4 Hops' },
          { value: '5_hops', title: '5 Hops' },
        ],
      },
    },
  }
}
