import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Assets
import ArrowLeftIcon from 'assets/images/chevron-left.svg'
import ArrowRightIcon from 'assets/images/chevron-right.svg'

// Styled Elements
import {
  PaginationWrapper,
  PaginationNavigation,
  PaginationRangeSelectorWrapper,
  PaginationRangeSelector,
  PaginationRangeSelectorOption,
} from './Pagination.elements'

// Views
import { Image } from 'views/components'

function Pagination(props) {
  // Destructure
  const { prevPageText, nextPageText, onChange, itemsCountPerPage, totalItemsCount, pageRangeDisplayed, page } = props

  // States
  const [size, setSize] = useState(10)

  // Functions
  const handlePageChange = (page) => {
    onChange({ page: +page, size })
  }

  const handleOnSelectChange = ({ target }) => {
    setSize(+target.value)
    onChange({ page: 1, size: +target.value })
  }

  return (
    <PaginationWrapper>
      <PaginationNavigation
        prevPageText={prevPageText}
        nextPageText={nextPageText}
        activePage={page}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
      />
      <PaginationRangeSelectorWrapper>
        <PaginationRangeSelector onChange={handleOnSelectChange}>
          <PaginationRangeSelectorOption value="10">10 per page</PaginationRangeSelectorOption>
          <PaginationRangeSelectorOption value="20">20 per page</PaginationRangeSelectorOption>
          <PaginationRangeSelectorOption value="30">30 per page</PaginationRangeSelectorOption>
          <PaginationRangeSelectorOption value="40">40 per page</PaginationRangeSelectorOption>
          <PaginationRangeSelectorOption value="50">50 per page</PaginationRangeSelectorOption>
          <PaginationRangeSelectorOption value="100">100 per page</PaginationRangeSelectorOption>
        </PaginationRangeSelector>
      </PaginationRangeSelectorWrapper>
    </PaginationWrapper>
  )
}

// Default Props
Pagination.defaultProps = {
  prevPageText: <Image src={ArrowLeftIcon} />,
  nextPageText: <Image src={ArrowRightIcon} />,
  onChange: () => {},
  itemsCountPerPage: 10,
  totalItemsCount: 10,
  pageRangeDisplayed: 5,
  page: 0,
}

// Proptypes Validation
Pagination.propTypes = {
  prevPageText: PropTypes.node,
  nextPageText: PropTypes.node,
  onChange: PropTypes.func,
  itemsCountPerPage: PropTypes.number,
  totalItemsCount: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  page: PropTypes.number,
}

export default Pagination
