import styled from 'styled-components'

export const PoliciesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0;
`

export const AddPolicyButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1024px;
  width: 100%;
  margin: 50px 0 20px 0;

  height: ${({ isCreatePolicyActive }) => (isCreatePolicyActive ? 0 : 'fit-content')};
  overflow: hidden;
  transition: 0.3s;
`

export const PolicyItem = styled.div`
  height: ${({ isCreatePolicyActive }) => (isCreatePolicyActive ? 'fit-content' : 0)};
  padding: ${({ isCreatePolicyActive }) => (isCreatePolicyActive ? '25px 30px' : '0px 30px')};
  overflow: hidden;
  transition: 0.3s;

  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};

  /* FOR ADDING POLICIES */
  & > div {
    width: 100%;
  }
  form {
    display: grid;
    align-items: center;
    grid-template-columns: 200px 1fr 180px;
    gap: 25px;
    margin-top: -20px;
  }
  form > div {
    width: 100%;
  }
  form > div:last-child {
    width: auto;
  }
  textarea {
    height: auto;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
`
