import { useMutation } from 'react-query'

// Services
import { PostAddressReport } from 'services/api/Addresses'

export default () => {
  const {
    mutate: postAddressReport,
    data: postAddressReportData,
    error: postAddressReportError,
    isLoading: isPostAddressReportLoading,
    isSuccess: isPostAddressReportSuccess,
    isError: isPostAddressReportError,
  } = useMutation(PostAddressReport)

  return {
    postAddressReport,
    postAddressReportData,
    postAddressReportError,
    isPostAddressReportLoading,
    isPostAddressReportSuccess,
    isPostAddressReportError,
  }
}
