import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Styled Elements
import { DatePickerWrapper, DatePickerInput, DateHolder, DateTextWrapper, DateText } from './DatePicker.elements'

function DateRangePicker(props) {
  // Destructure
  const { opens, onApply, defaultDate, disabled } = props

  // States
  const [date, setDate] = useState('')

  // Functions
  const handleApplyDatePicker = (e, picker) => {
    // Ignoring changes to table filters that are exactly the same as previous state
    if (date !== picker.startDate.format(DASHED_DATE_FORMAT)) {
      setDate(picker.startDate.format(DASHED_DATE_FORMAT))
      onApply(picker.startDate.format(DASHED_DATE_FORMAT))
    }
  }

  useEffect(() => {
    if (defaultDate) {
      setDate(defaultDate)
    }
    if (!defaultDate || defaultDate === 'Invalid date') {
      setDate('Select Date')
    }
  }, [defaultDate])

  return (
    <DatePickerWrapper>
      {disabled ? (
        <DateHolder disabled={disabled}>
          <DateTextWrapper>
            <DateText>{date || 'Select Date'}</DateText>
          </DateTextWrapper>
        </DateHolder>
      ) : (
        <DatePickerInput
          onApply={handleApplyDatePicker}
          locale={{ format: DASHED_DATE_FORMAT }}
          initialSettings={{
            maxDate: moment(moment(), DASHED_DATE_FORMAT),
            singleDatePicker: true,
            showDropdowns: true,
          }}
          opens={opens}
          disabled={disabled}
        >
          <DateHolder>
            <DateTextWrapper>
              <DateText>{date || 'Select Date'}</DateText>
            </DateTextWrapper>
          </DateHolder>
        </DatePickerInput>
      )}
    </DatePickerWrapper>
  )
}

// Default Props
DateRangePicker.defaultProps = {
  opens: 'center',
  onApply: () => {},
  defaultDate: '',
  disabled: false,
}

// Proptypes Validation
DateRangePicker.propTypes = {
  opens: PropTypes.string,
  onApply: PropTypes.func,
  defaultDate: PropTypes.string,
  disabled: PropTypes.bool,
}

export default DateRangePicker
