import { useMutation } from 'react-query'

// Services
import { UpdateRecipientEntity } from 'services/api/Recipients'

export default () => {
  const {
    mutate: updateRecipientEntity,
    data: recipientEntityUpdateData,
    error: recipientEntityUpdateError,
    isLoading: isRecipientEntityUpdateLoading,
    isSuccess: isRecipientEntityUpdateSuccess,
    isError: isRecipientEntityUpdateError,
  } = useMutation(UpdateRecipientEntity)

  return {
    updateRecipientEntity,
    recipientEntityUpdateData,
    recipientEntityUpdateError,
    isRecipientEntityUpdateLoading,
    isRecipientEntityUpdateSuccess,
    isRecipientEntityUpdateError,
  }
}
