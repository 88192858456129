import { useMutation } from 'react-query'

// Services
import { CreateNote } from 'services/api/Notes'

export default () => {
  const {
    mutate: createNote,
    data: createNoteData,
    error: createNoteError,
    isLoading: isCreateNoteLoading,
    isSuccess: isCreateNoteSuccess,
    isError: isCreateNoteError,
  } = useMutation(CreateNote)

  return {
    createNote,
    createNoteData,
    createNoteError,
    isCreateNoteLoading,
    isCreateNoteSuccess,
    isCreateNoteError,
  }
}
