import styled from 'styled-components'

export const ChartWrapper = styled.div`
  width: 100%;
`

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`
