import { authAxios } from 'common/utils/axios'

import { BATCH_UPLOAD_UPLOADS } from 'common/constants/apiUrls'

export const BatchUploadCases = async (payload) => {
  const response = await authAxios.post(BATCH_UPLOAD_UPLOADS.CASES, payload)

  const data = response.data.content[0]

  return data
}

export const BatchUploadCaseAddresses = async (payload) => {
  const response = await authAxios.post(BATCH_UPLOAD_UPLOADS.ADDRESSES, payload)

  const data = response.data.content[0]

  return data
}

export const BatchUploadCaseTransactions = async (payload) => {
  const response = await authAxios.post(BATCH_UPLOAD_UPLOADS.TRANSACTIONS, payload)

  const data = response.data.content[0]

  return data
}
