import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Assets
// import PlusWhiteIcon from 'assets/images/plus-white'
// import UsersIcon from 'assets/images/users'
// import BriefCaseIcon from 'assets/images/briefcase'

// Store
import { actions } from 'core/store'

// Utils
// import { numberWithCommas } from 'common/utils/valueFormat'

// Assets
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from 'assets/images'

// Constants
import { IP_RISK_PARTNER_OPTIONS_MAPPING } from 'common/constants/formOptions'

// Components
import { CircularProgressBar, LoadingTablePlaceholder } from 'views/components'

// Styled Elements
import {
  Text,
  Title,
  ColContainer,
  RowWrapper,
  RowContainer,
  GridRowContainer,
  TextRowContainer,
  BlockContainer,
  InfoTitle,
  RiskContainer,
} from './RiskDetails.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function RiskDetails(props) {
  const { riskData, isGetTransactionDetailsLoading, isCreateOrUpdateTransactionLoading, currentIpUsed, onClick } = props
  // Actions

  // Functions
  const handleClick = (index) => {
    if (onClick && !(riskData[index]?.in_risk_ratio === null || riskData[index]?.out_risk_ratio === null)) {
      onClick(index)
    }
  }
  return (
    <>
      {riskData &&
        !isGetTransactionDetailsLoading &&
        riskData.map((data, i) => (
          <BlockContainer
            id="riskBlock"
            onClick={() => handleClick(i)}
            style={{ marginTop: '15px', padding: '10px', borderWidth: 2 }}
            key={data?.id || i}
          >
            <RowWrapper style={{ padding: 0 }}>
              {isCreateOrUpdateTransactionLoading && data.risk_provider_id === currentIpUsed && (
                <RowContainer>
                  <BlockContainer style={{ display: 'flex', justifyContent: 'center', padding: 0, borderWidth: 2 }}>
                    <LoadingTablePlaceholder style={{ padding: '45px 0' }} />
                  </BlockContainer>
                </RowContainer>
              )}
              {!isCreateOrUpdateTransactionLoading && (
                <GridRowContainer style={{ gap: '15px' }}>
                  <RowContainer style={{ gap: '10px' }}>
                    <RowContainer style={{ maxWidth: '250px', alignItems: 'center' }}>
                      <BlockContainer style={{ display: 'flex', padding: '30px 0' }}>
                        <RiskContainer>
                          <CircularProgressBar
                            key={data?.id}
                            id={`${data?.id}-${i}`}
                            size={130}
                            progress={data?.in_risk_ratio && data.in_risk_ratio}
                          >
                            {data?.in_risk_ratio && (
                              <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                                <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${data.in_risk_ratio}%`}</Text>
                                <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                                  Risk
                                </Text>
                              </ColContainer>
                            )}
                          </CircularProgressBar>
                        </RiskContainer>
                      </BlockContainer>
                    </RowContainer>
                    <RowContainer style={{ flex: 1 }}>
                      {data?.in_risk_ratio !== null || data?.out_risk_ratio !== null ? (
                        <BlockContainer>
                          <Title style={{ textAlign: 'center' }}>
                            Input Risk <ArrowLeftCircleIcon />
                          </Title>
                          <TextRowContainer>
                            <InfoTitle>Risk Score</InfoTitle>
                            <Text style={{ border: 'none', fontSize: 15 }}>
                              {`${
                                data?.in_risk_score !== null && data?.in_risk_score !== undefined
                                  ? data?.in_risk_score
                                  : ''
                              } / ${data?.max_score !== null && data?.max_score !== undefined ? data?.max_score : ''}`}
                            </Text>
                          </TextRowContainer>
                          <TextRowContainer>
                            <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                            <Text style={{ border: 'none', fontSize: 15 }}>
                              {data?.created && moment(data.created).format('YYYY-MM-DD')}
                            </Text>
                          </TextRowContainer>
                          <TextRowContainer>
                            <InfoTitle>Source</InfoTitle>
                            <Text style={{ border: 'none', fontSize: 15 }}>
                              {data?.risk_provider_code || 'unknown'}
                            </Text>
                          </TextRowContainer>
                        </BlockContainer>
                      ) : (
                        <BlockContainer>
                          <Title style={{ textAlign: 'center' }}>
                            Input Risk (Unavailable) <ArrowLeftCircleIcon />
                          </Title>
                        </BlockContainer>
                      )}
                    </RowContainer>
                  </RowContainer>

                  <RowContainer style={{ gap: '10px' }}>
                    <RowContainer style={{ maxWidth: '250px', alignItems: 'center' }}>
                      <BlockContainer style={{ display: 'flex', padding: '30px 0' }}>
                        <RiskContainer>
                          <CircularProgressBar
                            key={data?.id}
                            id={`${data?.id}-${i}`}
                            size={130}
                            progress={data?.out_risk_ratio && data.out_risk_ratio}
                          >
                            {data?.out_risk_ratio && (
                              <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                                <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${data.out_risk_ratio}%`}</Text>
                                <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                                  Risk
                                </Text>
                              </ColContainer>
                            )}
                          </CircularProgressBar>
                        </RiskContainer>
                      </BlockContainer>
                    </RowContainer>
                    <RowContainer style={{ flex: 1 }}>
                      {data?.in_risk_ratio !== null || data?.out_risk_ratio !== null ? (
                        <BlockContainer>
                          <Title style={{ textAlign: 'center' }}>
                            Output Risk <ArrowRightCircleIcon />
                          </Title>
                          <TextRowContainer>
                            <InfoTitle>Risk Score</InfoTitle>
                            <Text style={{ border: 'none', fontSize: 15 }}>
                              {`${
                                data?.out_risk_score !== null && data?.out_risk_score !== undefined
                                  ? data?.out_risk_score
                                  : ''
                              } / ${data?.max_score !== null && data?.max_score !== undefined ? data?.max_score : ''}`}
                            </Text>
                          </TextRowContainer>
                          <TextRowContainer>
                            <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                            <Text style={{ border: 'none', fontSize: 15 }}>
                              {data?.created && moment(data.created).format('YYYY-MM-DD')}
                            </Text>
                          </TextRowContainer>
                          <TextRowContainer>
                            <InfoTitle>Source</InfoTitle>
                            <Text style={{ border: 'none', fontSize: 15 }}>
                              {data?.risk_provider_code || 'unknown'}
                            </Text>
                          </TextRowContainer>
                        </BlockContainer>
                      ) : (
                        <BlockContainer>
                          <Title style={{ textAlign: 'center' }}>
                            Output Risk (Unavailable) <ArrowRightCircleIcon />
                          </Title>
                        </BlockContainer>
                      )}
                    </RowContainer>
                  </RowContainer>
                </GridRowContainer>
              )}
            </RowWrapper>
          </BlockContainer>
        ))}
      {(!riskData || riskData?.length < 1) && isCreateOrUpdateTransactionLoading && (
        <BlockContainer style={{ marginTop: '15px' }}>
          <RowWrapper style={{ padding: 0 }}>
            <RowContainer>
              <BlockContainer style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
                <LoadingTablePlaceholder style={{ padding: '45px 0' }} />
              </BlockContainer>
            </RowContainer>
          </RowWrapper>
        </BlockContainer>
      )}
    </>
  )
}

// Default Props
RiskDetails.defaultProps = {
  riskData: null,
  isGetTransactionDetailsLoading: false,
  isCreateOrUpdateTransactionLoading: false,
  currentIpUsed: null,
  actions: {},
}

// Proptypes Validation
RiskDetails.propTypes = {
  riskData: PropTypes.instanceOf(Object),
  isGetTransactionDetailsLoading: PropTypes.bool,
  onClick: PropTypes.func,
  isCreateOrUpdateTransactionLoading: PropTypes.bool,
  currentIpUsed: PropTypes.string,
  actions: PropTypes.shape({
    toggleInfoModal: PropTypes.func,
    setInfoModalDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskDetails)
