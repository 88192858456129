import { useMutation } from 'react-query'

// Services
import { UpdateUser } from 'services/api/ManagerProfile'

export default () => {
  const {
    mutate: updateUser,
    data: updateUserData,
    error: updateUserError,
    isLoading: isUpdateUserLoading,
    isSuccess: isUpdateUserSuccess,
    isError: isUpdateUserError,
  } = useMutation(UpdateUser)

  return {
    updateUser,
    updateUserData,
    updateUserError,
    isUpdateUserLoading,
    isUpdateUserSuccess,
    isUpdateUserError,
  }
}
