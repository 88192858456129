import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Views
import { CardInfo } from 'views/components'
import { AccountAddressTableFilter } from 'views/layouts'

// Services
import { GetAddressAccountSummary } from 'services/api/Addresses'

// Assets
import { BlockchainAnalyticsIcon, TravelRuleIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Styled Elements
import { Wrapper, Container } from './AccountAddressFilters.elements'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function AccountAddressFilters(props) {
  // Destructure
  const { filters, actions, account_id } = props

  // store states
  const { addressFilters } = filters

  // Local state
  const [allSummary, setAllSummary] = useState()
  const [unidentifiedSummary, setUnidentifiedSummary] = useState()

  // store actions
  const { setAddressFilters } = actions

  // functions
  const fetchData = async () => {
    const all = await GetAddressAccountSummary({ account_id })
    const unidentified = await GetAddressAccountSummary({ account_id, identified: false })
    if (all) setAllSummary(all)
    if (unidentified) setUnidentifiedSummary(unidentified)
  }
  const handleAllFilter = () => {
    setAddressFilters({
      sort: '-last_modified',
      page: 1,
      size: 10,
      start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      link_id: account_id,
      identified: undefined,
    })
  }
  const handleUnidentifiedFilter = () => {
    setAddressFilters({
      sort: '-last_modified',
      page: 1,
      size: 10,
      start_range: moment('Jan 1 2008').format(DASHED_DATE_FORMAT),
      end_range: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
      link_id: account_id,
      identified: false,
    })
  }

  // useEffects
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Wrapper>
      <Container>
        <CardInfo
          data={[
            { label: 'Today', value: allSummary?.today ? allSummary?.today : '-' },
            { label: 'Last 30 Days', value: allSummary?.days_30 ? allSummary?.days_30 : '-' },
            { label: 'All Time', value: allSummary?.all_time ? allSummary?.all_time : '-' },
          ]}
          title="All Linked Addresses"
          icon={<BlockchainAnalyticsIcon />}
          active={addressFilters?.identified !== false}
          onClick={handleAllFilter}
        />
        <CardInfo
          data={[
            { label: 'Today', value: unidentifiedSummary?.today ? unidentifiedSummary?.today : '-' },
            { label: 'Last 30 Days', value: unidentifiedSummary?.days_30 ? unidentifiedSummary?.days_30 : '-' },
            { label: 'All Time', value: unidentifiedSummary?.all_time ? unidentifiedSummary?.all_time : '-' },
          ]}
          title="Unidentified Controlling Entities"
          icon={<TravelRuleIcon />}
          active={addressFilters?.identified === false}
          onClick={handleUnidentifiedFilter}
        />
      </Container>

      <AccountAddressTableFilter />
    </Wrapper>
  )
}

// Default Props
AccountAddressFilters.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
  account_id: '',
}

// Proptypes Validation
AccountAddressFilters.propTypes = {
  account_id: PropTypes.string,
  filters: PropTypes.shape({
    addressFilters: PropTypes.instanceOf(Object),
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    setAccountDirectoryFilters: PropTypes.func,
    setIsIndividualAccountDrawerOpen: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
    setAddressFilters: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountAddressFilters)
