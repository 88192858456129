import { v4 as uuid } from 'uuid'

export const defaultInitValue = {
	id: uuid(),
	type: 'group',
	children1: {
			[uuid()]: {
					type: 'rule',
					properties: {
							field: 'payment_direction',
							operator: 'select_equals',
							value: [
									'incomingoutgoing'
							],
							valueSrc: [
									'value'
							],
							valueType: [
									'select'
							]
					}
			}
	}
}
