import { useMutation } from 'react-query'

// Services
import { AssignMonitoringTransaction } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: assignMonitoringTransaction,
    data: assignMonitoringTransactionData,
    error: assignMonitoringTransactionError,
    isLoading: isAssignMonitoringTransactionLoading,
    isSuccess: isAssignMonitoringTransactionSuccess,
    isError: isAssignMonitoringTransactionError,
  } = useMutation(AssignMonitoringTransaction)

  return {
    assignMonitoringTransaction,
    assignMonitoringTransactionData,
    assignMonitoringTransactionError,
    isAssignMonitoringTransactionLoading,
    isAssignMonitoringTransactionSuccess,
    isAssignMonitoringTransactionError,
  }
}
