import styled from 'styled-components'

// Library Components
import Pagination from 'react-js-pagination'

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
  margin-top: 10px;
  padding: 22px 0;

  // Overrides
  & ul {
    & li {
      margin: 0 11px;
      color: ${({ theme }) => theme.colors.darkGray};

      &:first-child,
      &:last-child {
        display: none;
      }
    }

    & .active {
      margin: 0 2px;
      padding: 0 7px;

      font-size: 15px;
      font-weight: 400;

      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background: ${({ theme }) => theme.colors.white};

      a {
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
        color: ${({ theme }) => theme.colors.darkGray};
      }
    }
  }
`

export const PaginationNavigation = styled(Pagination)``

export const PaginationRangeSelectorWrapper = styled.div`
  width: 100%;
  max-width: 140px;

  // TODO: Use theme function
  &:after {
    color: ${({ theme }) => theme.colors.darkGray};
  }
`

export const PaginationRangeSelector = styled.select`
  padding: 0 10px;
  width: 140px;

  font-size: 16px;
  font-weight: 400;

  border: none;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: center;
  background: transparent;

  // TODO: Use theme function
  &:focus {
    outline: none;
  }
`

export const PaginationRangeSelectorOption = styled.option`
  font-size: 15px;
  font-weight: 200;
  padding: 2px 5px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 5px 10px #888888;
  color: ${({ theme }) => theme.colors.darkGray};
`
