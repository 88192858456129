import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './DeleteAddressModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Hooks
import { useDeleteAddress } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function DeleteAddressModal(props) {
  // Destructure
  const { ui, actions, address } = props

  // Store State
  const { isDeleteAddressModalOpen } = ui
  const { addressRemove } = address

  // Store Actions
  const { toggleDeleteAddressModal, setIsPageTableUpdated, setAddressRemove } = actions

  // Hooks
  const { deleteAddress, deleteAddressData, deleteAddressError, isDeleteAddressLoading } = useDeleteAddress()

  // Functions
  const toggleModal = () => {
    toggleDeleteAddressModal()
  }
  const handleDelete = () => {
    deleteAddress({ address_id: addressRemove.address_id })
  }

  // UseEffect
  useEffect(() => {
    if (isDeleteAddressModalOpen) {
      if (!isDeleteAddressLoading && !!deleteAddressData && !deleteAddressError) {
        setIsPageTableUpdated(true)
        setAddressRemove({ addressRemove: '' })
        toggleDeleteAddressModal()
      }
    }
  }, [isDeleteAddressLoading])
  return (
    <Modal isOpen={isDeleteAddressModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Delete address
      </ModalHeader>
      <ModalBody>
        {isDeleteAddressLoading && <ModalBodyWrapper>Deleting...</ModalBodyWrapper>}
        {!deleteAddressData && !isDeleteAddressLoading && !deleteAddressError && (
          <ModalBodyWrapper>
            Are you sure you want to delete this address? This action is irreversible.
          </ModalBodyWrapper>
        )}
        {!isDeleteAddressLoading && deleteAddressError && (
          <ModalBodyWrapper>An error occured in removing address, please try again later.</ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={toggleModal}>
            <Button color="default">Cancel</Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={handleDelete}>
            <Button color="secondary">Delete</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
DeleteAddressModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
DeleteAddressModal.propTypes = {
  ui: PropTypes.shape({ isDeleteAddressModalOpen: PropTypes.bool }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  address: PropTypes.shape({
    addressRemove: PropTypes.shape({ address_id: PropTypes.string }),
  }),
  actions: PropTypes.shape({
    toggleDeleteAddressModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setAddressRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAddressModal)
