/* eslint-disable react/jsx-no-useless-fragment */
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Constants
import { CASE_ASSIGNEE_OPTIONS, CASE_STATUS_OPTIONS } from 'common/constants/formOptions'
// Store
import { actions } from 'core/store'

// Styled Elements
import {
  CaseDetailsFormWrapper,
  FormGroupWrapperRow,
  FormGroupWrapperHeader,
  FormGroupWrapperSmall,
  FormGroupWrapperLarge,
  FormErrorText,
  FormInputGroupItem,
  FormGroupWrapperCol,
  FormGroupContainerRow,
} from './CaseDetailsForm.elements'

// Views
import { Form, FormTextField, FormTextArea, FormSelect, Button, LoadingTablePlaceholder } from 'views/components'
import moment from 'moment'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function CaseDetailsForm(props) {
  // Destructure
  const { data } = props
  const { severity, case_id, last_update, timestamp, rule_code, rule_description, assignee, case_status, notes } = data

  // Hooks
  const [casesData, setCasesData] = useLocalStorage('casesData')

  // Refs
  const formRef = useRef()

  // Variables
  const initialValues = {
    severity: severity || '',
    case_id: case_id || '',
    last_update: last_update || '',
    timestamp: timestamp || '',
    rule_code: rule_code || '',
    rule_description: rule_description || '',
    assignee: assignee || '',
    case_status: case_status || '',
    notes: notes || '',
  }
  const validationSchema = Yup.object().shape({})

  // States
  const [defaultValues, setDefaultValues] = useState(initialValues)
  const [formValues, setFormValues] = useState(initialValues)
  const [errmsg] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Functions
  const handleSave = (values) => {
    setDefaultValues(values)
    setFormValues(values)

    let newCasesList = []
    casesData.forEach((item) => {
      if (item.case_id !== case_id) newCasesList.push(item)
      if (item.case_id === case_id) {
        newCasesList = [{ ...values, last_update: moment().format('YYYY-MM-DD').toString() }, ...newCasesList]
      }
    })
    setCasesData(newCasesList)
    setIsEditing(false)
  }
  const handleCancel = () => {
    setIsLoading(true)
    setTimeout(() => setIsLoading(false), 200)
    setFormValues(defaultValues)
    setIsEditing(false)
  }

  // UseEffects

  return (
    <>
      {isLoading && <LoadingTablePlaceholder />}
      {formValues && !isLoading && (
        <Form
          key={formValues}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          autoComplete="off"
          onSubmit={handleSave}
          formRef={formRef}
        >
          {errmsg && <FormErrorText>{errmsg}</FormErrorText>}
          <CaseDetailsFormWrapper>
            <FormGroupWrapperCol>
              <FormGroupContainerRow>
                {!isEditing && (
                  <FormGroupWrapperHeader>
                    <Button type="button" disabled>
                      Delete
                    </Button>
                    <Button type="button" onClick={() => setIsEditing(true)}>
                      Edit
                    </Button>
                  </FormGroupWrapperHeader>
                )}
              </FormGroupContainerRow>

              <FormGroupWrapperRow>
                <FormGroupWrapperSmall>
                  <FormInputGroupItem>
                    <FormTextField label="Severity" type="text" name="severity" disabled />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Alert ID" type="text" name="case_id" disabled />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Last Update" type="text" name="last_update" disabled />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextField label="Timestamp" type="text" name="timestamp" disabled />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSelect
                      label="Assignee"
                      type="text"
                      name="assignee"
                      placeholder={initialValues.assignee}
                      options={CASE_ASSIGNEE_OPTIONS}
                      disabled={!isEditing}
                    />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormSelect
                      label="Status"
                      type="text"
                      name="case_status"
                      placeholder={initialValues.case_status}
                      options={CASE_STATUS_OPTIONS}
                      disabled={!isEditing}
                    />
                  </FormInputGroupItem>
                </FormGroupWrapperSmall>

                <FormGroupWrapperLarge>
                  <FormInputGroupItem>
                    <FormTextField label="Scenarios Name" type="text" name="rule_code" disabled />
                  </FormInputGroupItem>
                  <FormInputGroupItem>
                    <FormTextArea label="Summary" type="text" name="rule_description" disabled />
                  </FormInputGroupItem>
                </FormGroupWrapperLarge>
              </FormGroupWrapperRow>

              <FormGroupWrapperLarge>
                <FormTextArea label="Notes" type="text" name="notes" disabled={!isEditing} />
              </FormGroupWrapperLarge>
            </FormGroupWrapperCol>
            {isEditing && (
              <FormGroupContainerRow>
                <FormGroupWrapperHeader>
                  <Button variant="outlined" type="button" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </FormGroupWrapperHeader>
              </FormGroupContainerRow>
            )}
          </CaseDetailsFormWrapper>
        </Form>
      )}
    </>
  )
}

// Default Props
CaseDetailsForm.defaultProps = {
  formRef: {},
  form: {},
}

// Proptypes Validation
CaseDetailsForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({ addressOrTransactionIdToAssign: PropTypes.instanceOf(Object) }),
  data: PropTypes.shape({
    severity: PropTypes.string,
    case_id: PropTypes.string,
    last_update: PropTypes.string,
    timestamp: PropTypes.string,
    rule_description: PropTypes.string,
    rule_code: PropTypes.string,
    assignee: PropTypes.string,
    case_status: PropTypes.string,
    notes: PropTypes.string,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDetailsForm)
