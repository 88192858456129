import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Constants
// import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

// Assets
import { CloseIcon } from 'assets/images'

// Store
import { actions } from 'core/store'

// Hooks
import { useCreateTransferReport } from 'core/hooks/api'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton, RowContainer } from './ReportTransferModal.elements'

// Views
import {
  LoadingTablePlaceholder,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CustomDateRangePicker,
} from 'views/components'

// constants
// import { REPORT_DATA_TYPE_OPTIONS } from 'common/constants/formOptions'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function ReportTransferModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store Actions
  const { toggleTransferReportModal } = actions

  // Store State
  const { isTransferReportModalOpen } = ui

  // Hooks
  const { createTransferReport, transferReportCreateData, isTransferReportCreateError, isTransferReportCreateLoading } =
    useCreateTransferReport()

  // Local State
  const [formValue, setFormValue] = useState({
    start_range: moment().subtract(30, 'days'),
    end_range: moment().add(1, 'days'),
  })
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  // Functions
  const handleDateSelect = async ({ start, end }) => {
    setFormValue({ start_range: start, end_range: end })
  }
  const toggleModal = () => {
    toggleTransferReportModal()
  }
  const handleSubmit = () => {
    createTransferReport(formValue)
  }

  // UseEffects
  useEffect(() => transferReportCreateData && setIsSuccess(true), [transferReportCreateData])
  useEffect(() => isTransferReportCreateError && setIsError(true), [isTransferReportCreateError])
  useEffect(() => {
    if (isTransferReportModalOpen) {
      setFormValue({
        start_range: moment().subtract(30, 'days'),
        end_range: moment().add(1, 'days'),
      })
      setIsError(false)
      setIsSuccess(false)
    }
  }, [isTransferReportModalOpen])
  return (
    <Modal isOpen={isTransferReportModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<CloseIcon onClick={toggleModal} />}>
        REQUEST REPORT
      </ModalHeader>
      <ModalBody style={{ maxHeight: 'none', overflow: 'visible' }}>
        {isError && !isTransferReportCreateLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            An error occured in generating a report. Please try again later.
          </ModalBodyWrapper>
        )}
        {isSuccess && !isTransferReportCreateLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <span>
              Successfully requested for report, generating the report may take a few minutes. Please view the progress
              in the <a href="/reports">reports tab</a>.
            </span>
          </ModalBodyWrapper>
        )}
        {isTransferReportCreateLoading && (
          <ModalBodyWrapper style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <LoadingTablePlaceholder style={{ padding: '45px 0' }} />
          </ModalBodyWrapper>
        )}
        {!isTransferReportCreateLoading && !isError && !isSuccess && (
          <ModalBodyWrapper>
            <RowContainer style={{ justifyContent: 'center' }}>
              <CustomDateRangePicker
                key={formValue?.start_range}
                onApply={handleDateSelect}
                start={formValue?.start_range}
                end={formValue?.end_range}
                style={{ minWidth: '270px' }}
              />
            </RowContainer>
          </ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        {!isTransferReportCreateLoading && !isError && !isSuccess && (
          <ModalFooterWrapper>
            <ModalFooterButton onClick={toggleModal}>
              <Button variant="outlined">Cancel</Button>
            </ModalFooterButton>
            <ModalFooterButton>
              <Button
                onClick={handleSubmit}
                variant="outlined"
                disabled={!formValue?.start_range || !formValue?.end_range}
              >
                Request
              </Button>
            </ModalFooterButton>
          </ModalFooterWrapper>
        )}
      </ModalFooter>
    </Modal>
  )
}

// Default Props
ReportTransferModal.defaultProps = {
  ui: {},
  address: {},
  actions: {},
}

// Proptypes Validation
ReportTransferModal.propTypes = {
  ui: PropTypes.shape({ isTransferReportModalOpen: PropTypes.bool }),
  address: PropTypes.shape({
    addressReportDetails: PropTypes.shape({
      address_id: PropTypes.string,
      address: PropTypes.string,
      chain: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    toggleTransferReportModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setTransferReportDetails: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportTransferModal)
