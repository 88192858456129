import { useMutation } from 'react-query'

// Services
import { CreateTransferReport } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: createTransferReport,
    data: transferReportCreateData,
    error: transferReportCreateError,
    isLoading: isTransferReportCreateLoading,
    isSuccess: isTransferReportCreateSuccess,
    isError: isTransferReportCreateError,
  } = useMutation(CreateTransferReport)

  return {
    createTransferReport,
    transferReportCreateData,
    transferReportCreateError,
    isTransferReportCreateLoading,
    isTransferReportCreateSuccess,
    isTransferReportCreateError,
  }
}
