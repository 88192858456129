import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

// Store
import { actions } from 'core/store'

// Assets
import { ArrowLeftCircleIcon, ArrowRightCircleIcon, HelpIcon } from 'assets/images'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

// Styled Elements
import {
  Text,
  Title,
  ColContainer,
  RowContainer,
  GridRowContainer,
  TextRowContainer,
  InfoTitle,
  RiskContainer,
} from './TransactionRisk.elements'

// Views
import { CircularProgressBar } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function TransactionRisk(props) {
  // Destructure
  const { actions, riskData: risk_data, transactionData: transaction_data } = props

  // Store Actions
  const { setInfoModalDetails, toggleInfoModal } = actions

  // Local State
  const [riskData, setRiskData] = useState()
  const [transactionData, setTransactionData] = useState()

  // Functions
  const handleToggleInfo = (title, content) => {
    setInfoModalDetails({ title, content })
    toggleInfoModal(true)
  }
  const formatValue = (number) => {
    if (number < 0.01) return number
    return numberWithCommas(Math.round(number * 100) / 100)
  }
  // UseEffects
  useEffect(() => {
    setRiskData(risk_data)
  }, [risk_data])
  useEffect(() => {
    setTransactionData(transaction_data)
  }, [transaction_data])
  return (
    <GridRowContainer>
      <RowContainer style={{ gap: '20px' }}>
        <RowContainer style={{ maxWidth: '160px', alignItems: 'center' }}>
          <RiskContainer>
            <CircularProgressBar
              key={`${riskData?.id}${riskData?.id}1`}
              id={`${riskData?.id}${riskData?.id}1`}
              size={110}
              progress={riskData?.in_risk_ratio && riskData.in_risk_ratio}
            >
              {riskData?.in_risk_ratio && (
                <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                  <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${riskData.in_risk_ratio}%`}</Text>
                  <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                    Risk
                  </Text>
                </ColContainer>
              )}
            </CircularProgressBar>
          </RiskContainer>
        </RowContainer>
        <RowContainer style={{ flex: 1 }}>
          {riskData?.in_risk_ratio !== null || riskData?.out_risk_ratio !== null ? (
            <RowContainer>
              <TextRowContainer>
                <InfoTitle onClick={() => handleToggleInfo('Risk Score Information', riskData?.risk_score_desc)}>
                  Risk Score
                  <HelpIcon />
                </InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {`${riskData?.in_risk_score ? riskData?.in_risk_score : ''} / ${
                    riskData?.max_score !== null && riskData?.max_score !== undefined ? riskData?.max_score : ''
                  }`}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {riskData?.created && moment(riskData.created).format('YYYY-MM-DD')}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle
                  onClick={() => handleToggleInfo('Risk Score Source Information', riskData?.risk_provider_desc)}
                >
                  Source
                  <HelpIcon />
                </InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>{riskData?.risk_provider_code || 'unknown'}</Text>
              </TextRowContainer>
            </RowContainer>
          ) : (
            <Title style={{ textAlign: 'center' }}>
              Input Risk (Unavailable) <ArrowLeftCircleIcon />
            </Title>
          )}
        </RowContainer>
        <RowContainer style={{ flex: 1, minWidth: '250px' }}>
          {riskData?.in_risk_ratio !== null || riskData?.out_risk_ratio !== null ? (
            <RowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Input USD:</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {transactionData?.in_usd ? `$ ${formatValue(transactionData.in_usd)}` : '-'}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Input Events</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  <Text>{transactionData?.in_count}</Text>
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Fee</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {transactionData?.fee_amount_usd ? `$ ${formatValue(transactionData.fee_amount_usd)}` : '-'}
                </Text>
              </TextRowContainer>
            </RowContainer>
          ) : (
            <Title style={{ textAlign: 'center' }}>
              Input Risk (Unavailable) <ArrowLeftCircleIcon />
            </Title>
          )}
        </RowContainer>
      </RowContainer>

      <RowContainer style={{ gap: '20px' }}>
        <RowContainer style={{ maxWidth: '160px', alignItems: 'center' }}>
          <RiskContainer>
            <CircularProgressBar
              key={`${riskData?.id}${riskData?.id}2`}
              id={`${riskData?.id}${riskData?.id}2`}
              size={110}
              progress={riskData?.out_risk_ratio && riskData.out_risk_ratio}
            >
              {riskData?.out_risk_ratio && (
                <ColContainer style={{ justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                  <Text style={{ fontSize: '18px', fontWeight: 600 }}>{`${riskData.out_risk_ratio}%`}</Text>
                  <Text style={{ fontSize: '12px', fontWeight: 400 }} id="rsSubTitle">
                    Risk
                  </Text>
                </ColContainer>
              )}
            </CircularProgressBar>
          </RiskContainer>
        </RowContainer>
        <RowContainer style={{ flex: 1 }}>
          {riskData?.in_risk_ratio !== null || riskData?.out_risk_ratio !== null ? (
            <RowContainer>
              <TextRowContainer>
                <InfoTitle onClick={() => handleToggleInfo('Risk Score Information', riskData?.risk_score_desc)}>
                  Risk Score
                  <HelpIcon />
                </InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {`${riskData?.out_risk_score ? riskData?.out_risk_score : ''} / ${
                    riskData?.max_score !== null && riskData?.max_score !== undefined ? riskData?.max_score : ''
                  }`}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Timestamp</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {riskData?.created && moment(riskData.created).format('YYYY-MM-DD')}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle
                  onClick={() => handleToggleInfo('Risk Score Source Information', riskData?.risk_provider_desc)}
                >
                  Source
                  <HelpIcon />
                </InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>{riskData?.risk_provider_code || 'unknown'}</Text>
              </TextRowContainer>
            </RowContainer>
          ) : (
            <Title style={{ textAlign: 'center' }}>
              Output Risk (Unavailable) <ArrowRightCircleIcon />
            </Title>
          )}
        </RowContainer>
        <RowContainer style={{ flex: 1, minWidth: '250px' }}>
          {riskData?.in_risk_ratio !== null || riskData?.out_risk_ratio !== null ? (
            <RowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Output USD:</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {transactionData?.out_usd ? `$ ${formatValue(transactionData.out_usd)}` : '-'}
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Output Events</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  <Text>{transactionData?.out_count}</Text>
                </Text>
              </TextRowContainer>
              <TextRowContainer>
                <InfoTitle style={{ cursor: 'default' }}>Fee</InfoTitle>
                <Text style={{ border: 'none', fontSize: 15 }}>
                  {transactionData?.fee_amount_usd ? `$ ${formatValue(transactionData.fee_amount_usd)}` : '-'}
                </Text>
              </TextRowContainer>
            </RowContainer>
          ) : (
            <Title style={{ textAlign: 'center' }}>
              Output Risk (Unavailable) <ArrowLeftCircleIcon />
            </Title>
          )}
        </RowContainer>
      </RowContainer>
    </GridRowContainer>
  )
}

// Default Props
TransactionRisk.defaultProps = {
  riskData: {},
  transactionData: {},
  actions: {},
}

// Proptypes Validation
TransactionRisk.propTypes = {
  riskData: PropTypes.shape({}),
  transactionData: PropTypes.shape({}),
  actions: PropTypes.shape({
    setInfoModalDetails: PropTypes.func,
    toggleInfoModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionRisk)
