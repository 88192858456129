import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { LegendTextWrapper, LegendColor } from './LegendText.elements'

function LegendText(props) {
  // Destructure
  const { color, children } = props

  return (
    <LegendTextWrapper>
      <LegendColor color={color} />
      {children}
    </LegendTextWrapper>
  )
}

// Default Props
LegendText.defaultProps = {
  children: '',
  color: '',
}

// Proptypes Validation
LegendText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
}

export default LegendText
