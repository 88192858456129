import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  Wrapper,
  TitleContainer,
  Title,
  IconContainer,
  Spacer,
  DataWrapper,
  DataContainer,
  DataLabel,
  DataValue,
} from './CardInfo.elements'

function CardInfo(props) {
  // Destructure
  const { title, icon, data, active, ...rest } = props

  return (
    <Wrapper active={active} {...rest}>
      <TitleContainer>
        {icon && <IconContainer>{icon}</IconContainer>}
        {title && <Title>{title}</Title>}
      </TitleContainer>
      <Spacer />
      <DataWrapper>
        {data &&
          data?.map((item) => (
            <DataContainer key={item?.label}>
              <DataLabel>{item?.label}: </DataLabel>
              <DataValue>{item?.value}</DataValue>
            </DataContainer>
          ))}
      </DataWrapper>
    </Wrapper>
  )
}

// Default Props
CardInfo.defaultProps = {
  title: '',
  data: [],
  active: false,
}

// Proptypes Validation
CardInfo.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  data: PropTypes.arrayOf({}),
  active: PropTypes.bool,
}

export default CardInfo
