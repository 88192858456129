import { useMutation } from 'react-query'

// Services
import { GetRecipientVasp } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: getRecipientVasp,
    data: recipientVaspData,
    error: recipientVaspError,
    isLoading: isRecipientVaspLoading,
    isSuccess: isRecipientVaspSuccess,
    isError: isRecipientVaspError,
  } = useMutation(GetRecipientVasp)

  return {
    getRecipientVasp,
    recipientVaspData,
    recipientVaspError,
    isRecipientVaspLoading,
    isRecipientVaspSuccess,
    isRecipientVaspError,
  }
}
