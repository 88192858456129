import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

// Styled Elements
import {
  AutoCompleteWrapper,
  Label,
  AutoCompleteInput,
  SelectInputOptionsContainer,
  SelectInputOptions,
  DummyInput,
} from './FormAutoComplete.elements'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function FormAutoComplete(props) {
  // Destructure
  const { label, options, placeholder, onInputChange, onSelect, isLoading, disabled, ...rest } = props

  // Variables
  const withOptions = options && options?.length > 0

  // Refs
  const inputRef = useRef()
  const wrapperRef = useRef()

  // Hooks
  const [{ name }, , { setValue }] = useField(props)

  // States
  const [inputValue, setInputValue] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  // Functions
  const handleOnSelect = (e) => {
    onSelect(e)
  }
  const handleOnClick = async (value) => {
    await setValue(value)
    await setInputValue(value)
    await setShowOptions(false)
    inputRef.current.click()
  }
  const handleOnChange = (e) => {
    setInputValue(e.target.value)
    onInputChange(e)
  }

  // Closing options on click away
  useOutsideAlerter(wrapperRef, setShowOptions)

  return (
    <AutoCompleteWrapper label={label} ref={wrapperRef}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <DummyInput ref={inputRef} value={inputValue} onChange={() => {}} onClick={handleOnSelect} />
      <AutoCompleteInput
        name={name}
        value={inputValue}
        onChange={handleOnChange}
        placeholder={placeholder}
        onFocus={() => {
          setShowOptions(true)
        }}
        type="search"
        autocomplete="off"
        disabled={disabled}
        {...rest}
      />
      {showOptions && (
        <SelectInputOptionsContainer>
          {withOptions ? (
            options.map(({ value, label }) => (
              <SelectInputOptions
                onClick={() => {
                  handleOnClick(value)
                }}
                key={label}
                value={value}
              >
                {label}
              </SelectInputOptions>
            ))
          ) : (
            <>
              {isLoading && 'loading...'}
              {!isLoading && 'No results found'}
            </>
          )}
        </SelectInputOptionsContainer>
      )}
    </AutoCompleteWrapper>
  )
}

// Default Props
FormAutoComplete.defaultProps = {
  type: '',
  label: '',
  placeholder: '',
  variant: 'outlined',
  options: [],
  onSelect: () => {},
  onInputChange: () => {},
  getOptionSelected: () => {},
  getOptionLabel: () => {},
  isLoading: false,
  disabled: false,
}

// Proptypes Validation
FormAutoComplete.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  getOptionSelected: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default FormAutoComplete
