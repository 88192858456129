import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './DeleteAddressNoteModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function DeleteAddressNoteModal(props) {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isDeleteAddressNoteModalOpen } = ui

  // Store Actions
  const { toggleDeleteAddressNoteModal } = actions

  // Functions
  const toggleModal = () => {
    toggleDeleteAddressNoteModal()
  }
  const handleDelete = () => {}

  // UseEffect
  return (
    <Modal isOpen={isDeleteAddressNoteModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Delete note
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>Are you sure you want to delete this note? This action is irreversible.</ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={handleDelete}>
            <Button color="secondary">Delete</Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={toggleModal}>
            <Button color="default">Cancel</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
DeleteAddressNoteModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
DeleteAddressNoteModal.propTypes = {
  ui: PropTypes.shape({ isDeleteAddressNoteModalOpen: PropTypes.bool }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  actions: PropTypes.shape({
    toggleDeleteAddressNoteModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setActivityToRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAddressNoteModal)
