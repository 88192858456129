import { useMutation } from 'react-query'

// Services
import { ResetPasswordConfirm } from 'services/api/Auth'

export default () => {
  const {
    mutate: resetPasswordConfirm,
    data: resetPasswordConfirmData,
    error: resetPasswordConfirmError,
    isLoading: isResetPasswordConfirmLoading,
    isSuccess: isResetPasswordConfirmSuccess,
    isError: isResetPasswordConfirmError,
  } = useMutation(ResetPasswordConfirm)

  return {
    resetPasswordConfirm,
    resetPasswordConfirmData,
    resetPasswordConfirmError,
    isResetPasswordConfirmLoading,
    isResetPasswordConfirmSuccess,
    isResetPasswordConfirmError,
  }
}
