import { useMutation } from 'react-query'

// Services
import { GetNote } from 'services/api/Notes'

export default () => {
  const {
    mutate: getNote,
    data: getNoteData,
    error: getNoteError,
    isLoading: isGetNoteLoading,
    isSuccess: isGetNoteSuccess,
    isError: isGetNoteError,
  } = useMutation(GetNote)

  return {
    getNote,
    getNoteData,
    getNoteError,
    isGetNoteLoading,
    isGetNoteSuccess,
    isGetNoteError,
  }
}
