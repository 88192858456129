import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper, Title, RowWrapper, RadioInput, RadioLabel, RadioCheck, RadioDot } from './Radio.elements'

function Radio(props) {
  // Destructure
  const { id, name, type, label, options, onChange, value, disabled, ...rest } = props

  const [inputValue, setInputValue] = useState(value)

  function handleChange(selectedValue) {
    setInputValue(selectedValue)
    onChange(selectedValue)
  }

  return (
    <Wrapper {...rest}>
      {label && <Title>{label}</Title>}
      {options &&
        options.map((item) => (
          <RowWrapper key={item.value} onClick={() => handleChange(item.value)}>
            <RadioInput
              name={name}
              id={item.value}
              type="radio"
              value={item.value}
              checked={item.value === inputValue}
            />
            <RadioLabel for={item.value}>
              <RadioCheck>
                <RadioDot />
              </RadioCheck>
              {item.label}
            </RadioLabel>
          </RowWrapper>
        ))}
    </Wrapper>
  )
}

// Default Props
Radio.defaultProps = {
  id: '',
  name: '',
  type: '',
  label: '',
  disabled: false,
  options: [],
  onChange: () => {},
  value: '',
}

// Proptypes Validation
Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.instanceOf(Array),
}

export default Radio
