import { useMutation } from 'react-query'

// Services
import { PatchMonitoringTransactionNotes } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: patchMonitoringTransactionNotes,
    data: monitoringTransactionNotesData,
    error: monitoringTransactionNotesError,
    isLoading: isMonitoringTransactionNotesLoading,
    isSuccess: isMonitoringTransactionNotesSuccess,
    isError: isMonitoringTransactionNotesError,
  } = useMutation(PatchMonitoringTransactionNotes)

  return {
    patchMonitoringTransactionNotes,
    monitoringTransactionNotesData,
    monitoringTransactionNotesError,
    isMonitoringTransactionNotesLoading,
    isMonitoringTransactionNotesSuccess,
    isMonitoringTransactionNotesError,
  }
}
