import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ContentWrapper } from './Content.elements'

function Content(props) {
  // Destructure
  const { children } = props

  return <ContentWrapper>{children}</ContentWrapper>
}

// Default Props
Content.defaultProps = {
  children: '',
}

// Proptypes Validation
Content.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Content
