import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper, InnerCircle, Backdrop, BarLine } from './CircleProgressBar.elements'

function CircleProgressBar(props) {
  // Destructure
  const { width, children, ...rest } = props

  return (
    <Wrapper {...rest}>
      <BarLine deg={(width / 100) * 360}>
        <InnerCircle>
          <Backdrop>{children && children}</Backdrop>
        </InnerCircle>
      </BarLine>
    </Wrapper>
  )
}

// Default Props
CircleProgressBar.defaultProps = {
  width: '',
  children: '',
}

// Proptypes Validation
CircleProgressBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default CircleProgressBar
