import { useMutation } from 'react-query'

// Services
import { GetTransactionHistory } from 'services/api/Transactions'

export default () => {
  const {
    mutate: getTransactionHistory,
    data: getTransactionHistoryData,
    error: getTransactionHistoryError,
    isLoading: isGetTransactionHistoryLoading,
    isSuccess: isGetTransactionHistorySuccess,
    isError: isGetTransactionHistoryError,
  } = useMutation(GetTransactionHistory)

  return {
    getTransactionHistory,
    getTransactionHistoryData,
    getTransactionHistoryError,
    isGetTransactionHistoryLoading,
    isGetTransactionHistorySuccess,
    isGetTransactionHistoryError,
  }
}
