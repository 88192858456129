import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { Wrapper } from './YoutubeEmbed.elements'

function YoutubeEmbed(props) {
  // Destructure
  const { embedId, ...rest } = props
  return (
    <Wrapper {...rest}>
      <iframe
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video"
      />
    </Wrapper>
  )
}

// Default Props
YoutubeEmbed.defaultProps = {
  embedId: '',
}

// Proptypes Validation
YoutubeEmbed.propTypes = {
  embedId: PropTypes.string,
}

export default YoutubeEmbed
