/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react'

export default function LinkedIcon(props) {
  return (
    <svg
      width={props?.width ? props.width : '30'}
      height={props?.height ? props.height : '30'}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M24.5 12.5001C24.5 19.127 19.1275 24.5 12.5001 24.5C5.8729 24.5 0.5 19.127 0.5 12.5001C0.5 5.8726 5.87289 0.5 12.5001 0.5C19.1276 0.5 24.5 5.87249 24.5 12.5001Z"
        fill="white"
        fillOpacity="0.1"
        stroke="#393D46"
      />
      <path
        d="M10 10.6654V8.91536C10 8.53234 10.0754 8.15307 10.222 7.7992C10.3686 7.44534 10.5834 7.12381 10.8543 6.85297C11.1251 6.58213 11.4466 6.36729 11.8005 6.22071C12.1544 6.07414 12.5336 5.9987 12.9167 5.9987C13.2997 5.9987 13.679 6.07414 14.0328 6.22071C14.3867 6.36729 14.7082 6.58213 14.9791 6.85297C15.2499 7.12381 15.4647 7.44534 15.6113 7.7992C15.7579 8.15307 15.8333 8.53234 15.8333 8.91536V10.6654M15.8333 14.1654V15.9154C15.8333 16.2984 15.7579 16.6777 15.6113 17.0315C15.4647 17.3854 15.2499 17.7069 14.9791 17.9778C14.4321 18.5247 13.6902 18.832 12.9167 18.832C12.1431 18.832 11.4013 18.5247 10.8543 17.9778C10.3073 17.4308 10 16.6889 10 15.9154V14.1654"
        stroke="#393D46"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 14.832V10.1654" stroke="#393D46" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
