import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './DeleteTransactionModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Hooks
import { useDeleteTransaction } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function DeleteTransactionModal(props) {
  // Destructure
  const { ui, actions, transaction } = props

  // Store State
  const { isDeleteTransactionModalOpen } = ui
  const { transactionRemove } = transaction

  // Store Actions
  const { toggleDeleteTransactionModal, setIsPageTableUpdated, setTransactionRemove } = actions

  // Hooks
  const { deleteTransaction, deleteTransactionData, deleteTransactionError, isDeleteTransactionLoading } =
    useDeleteTransaction()

  // Functions
  const toggleModal = () => {
    toggleDeleteTransactionModal()
  }
  const handleDelete = () => {
    deleteTransaction({ transaction_id: transactionRemove.transaction_id })
  }

  // UseEffect
  useEffect(() => {
    if (isDeleteTransactionModalOpen) {
      if (!isDeleteTransactionLoading && !!deleteTransactionData && !deleteTransactionError) {
        setIsPageTableUpdated(true)
        setTransactionRemove({ transaction_id: '' })
        toggleDeleteTransactionModal()
      }
    }
  }, [isDeleteTransactionLoading])
  return (
    <Modal isOpen={isDeleteTransactionModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Delete transaction
      </ModalHeader>
      <ModalBody>
        {isDeleteTransactionLoading && <ModalBodyWrapper>Deleting...</ModalBodyWrapper>}
        {!deleteTransactionData && !isDeleteTransactionLoading && !deleteTransactionError && (
          <ModalBodyWrapper>
            Are you sure you want to delete this transaction? This action is irreversible.
          </ModalBodyWrapper>
        )}
        {!isDeleteTransactionLoading && deleteTransactionError && (
          <ModalBodyWrapper>An error occured in removing transaction, please try again later.</ModalBodyWrapper>
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={toggleModal}>
            <Button color="default">Cancel</Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={handleDelete}>
            <Button color="secondary">Delete</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
DeleteTransactionModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
DeleteTransactionModal.propTypes = {
  ui: PropTypes.shape({ isDeleteTransactionModalOpen: PropTypes.bool }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string,
    }),
  }),
  transaction: PropTypes.shape({
    transactionRemove: PropTypes.shape({ transaction_id: PropTypes.string }),
  }),
  actions: PropTypes.shape({
    toggleDeleteTransactionModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setTransactionRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTransactionModal)
