import { useMutation } from 'react-query'

// Services
import { GetReport } from 'services/api/Reports'

export default () => {
  const {
    mutate: getReport,
    data: getReportData,
    error: getReportError,
    isLoading: isGetReportLoading,
    isSuccess: isGetReportSuccess,
    isError: isGetReportError,
  } = useMutation(GetReport)

  return {
    getReport,
    getReportData,
    getReportError,
    isGetReportLoading,
    isGetReportSuccess,
    isGetReportError,
  }
}
