import styled from 'styled-components'

import { DropdownMenu } from 'reactstrap'

export const DropdownMenuWrapper = styled(DropdownMenu)`
  padding: 5px;
  margin: 5px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  min-width: 230px;
`
