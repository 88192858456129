/* eslint-disable max-len */
import React from 'react'

export default function EthereumMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 12.5001C24.5 19.127 19.1275 24.5 12.5001 24.5C5.8729 24.5 0.5 19.127 0.5 12.5001C0.5 5.8726 5.87289 0.5 12.5001 0.5C19.1276 0.5 24.5 5.87249 24.5 12.5001Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
      />
      <g opacity="0.6">
        <path d="M12.5116 10.4548L7.95 12.6468L12.5116 15.4943L17.0714 12.6468L12.5116 10.4548Z" fill="#00CACD" />
      </g>
      <g opacity="0.45">
        <path d="M7.95 12.6467L12.5116 15.4942V4.6499L7.95 12.6467Z" fill="#00CACD" />
      </g>
      <g opacity="0.8">
        <path d="M12.5116 4.6499V15.4942L17.0714 12.6467L12.5116 4.6499Z" fill="#00CACD" />
      </g>
      <g opacity="0.45">
        <path d="M7.95 13.5601L12.5116 20.3501V16.4075L7.95 13.5601Z" fill="#00CACD" />
      </g>
      <g opacity="0.8">
        <path d="M12.5116 16.4075V20.3501L17.075 13.5601L12.5116 16.4075Z" fill="#00CACD" />
      </g>
    </svg>
  )
}
