import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  width: 100%;
  transition: 0.3s;
  cursor: pointer;

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ active, theme }) => (active ? theme.colors.blue : theme.colors.gray)};
  color: ${({ theme }) => theme.colors.darkGray};
  padding: 20px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.blue};
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & svg path {
    stroke: ${({ theme }) => theme.colors.darkGray};
  }
`

export const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  height: 35px;
  width: 35px;
`

export const Spacer = styled.div`
  padding-top: 30px;
  width: 100%;
`

export const DataWrapper = styled.div`
  display: flex;
  gap: 60px;
`
export const DataContainer = styled.div`
  display: flex;
  gap: 8px;
`
export const DataLabel = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 12px;
`
export const DataValue = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 12px;
`
