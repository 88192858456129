import React from 'react'
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2'

// Styled Elements
import { DoughnutChartWrapper } from './DoughnutChart.elements'

function DoughnutChart(props) {
  // Destructure
  const { data, width, height, options } = props

  return (
    <DoughnutChartWrapper>
      <Doughnut data={data} width={width} height={height} options={options} />
    </DoughnutChartWrapper>
  )
}

// Default Props
DoughnutChart.defaultProps = {
  data: {
    label: [],
    datasets: [],
  },
  width: '',
  height: '',
  options: {},
}

// Proptypes Validation
DoughnutChart.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.instanceOf(Array),
    datasets: PropTypes.instanceOf(Array),
  }),
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.shape({ root: PropTypes.string }),
}

export default DoughnutChart
