import { useMutation } from 'react-query'

// Services
import { LoginTwoFa } from 'services/api/Auth'

export default () => {
  const {
    mutate: loginUserTwoFa,
    data: loginUserTwoFaData,
    error: loginUserTwoFaError,
    isLoading: isLoginUserTwoFaLoading,
    isSuccess: isLoginUserTwoFaSuccess,
    isError: isLoginUserTwoFaError,
  } = useMutation(LoginTwoFa)

  // credentials and isloggedin are set in the loginform component

  return {
    loginUserTwoFa,
    loginUserTwoFaData,
    loginUserTwoFaError,
    isLoginUserTwoFaLoading,
    isLoginUserTwoFaSuccess,
    isLoginUserTwoFaError,
  }
}
