import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Components
import { TableWrapper, TabsWrapper } from './Vasps.elements'

// Views
import { Container, Tabs } from 'views/components'
import {
  App,
  Header,
  Sidebar,
  Content,
  VaspsTable,
  RequestedVaspsTable,
  PageWrapper,
  DirectorySubHeader,
  // NoAccess
} from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const pages = [
  { label: 'VASPs', value: 'vasps' },
  { label: 'Requested VASPs', value: 'requested-vasps' },
]

function Vasps() {
  const [page, setPage] = useState(pages[0]?.value)

  return (
    <App>
      <Sidebar />
      <Header pageTitle="Directory" subHeader={<DirectorySubHeader />} />
      {/* <NoAccess /> */}
      <Content>
        <PageWrapper>
          <Container>
            <TableWrapper>
              <TabsWrapper>
                <Tabs pages={pages} page={page} setPage={setPage} />
              </TabsWrapper>
              {page === 'vasps' && <VaspsTable />}
              {page === 'requested-vasps' && <RequestedVaspsTable />}
            </TableWrapper>
          </Container>
        </PageWrapper>
      </Content>
    </App>
  )
}

// Default Props
Vasps.defaultProps = {
  actions: {},
  ui: {},
  filters: {},
}

// Proptypes Validation
Vasps.propTypes = {
  actions: PropTypes.shape({
    setActiveModule: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setNewCaseCreated: PropTypes.func,
    setCaseManagementFilters: PropTypes.func,
  }),
  ui: PropTypes.shape({
    isNewCaseCreated: PropTypes.bool,
  }),
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Vasps)
