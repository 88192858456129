/* eslint-disable max-len */
import React from 'react'

export default function PolkadotMonoIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M490 250C490 382.548 382.548 490 250 490C117.452 490 10 382.548 10 250C10 117.452 117.452 10 250 10C382.548 10 490 117.452 490 250Z"
        fill="#00CACD"
        fillOpacity="0.1"
        stroke="#00CACD"
        strokeWidth="20"
      />
      <path
        d="M250 148C283.137 148 310 131.658 310 111.5C310 91.3416 283.137 75 250 75C216.863 75 190 91.3416 190 111.5C190 131.658 216.863 148 250 148Z"
        fill="#00CACD"
      />
      <path
        d="M250 425C283.137 425 310 408.435 310 388C310 367.565 283.137 351 250 351C216.863 351 190 367.565 190 388C190 408.435 216.863 425 250 425Z"
        fill="#00CACD"
      />
      <path
        d="M165.954 199.227C182.609 169.04 182.501 136.346 165.713 126.203C148.924 116.06 121.812 132.308 105.157 162.495C88.5018 192.681 88.6099 225.375 105.399 235.519C122.187 245.662 149.299 229.414 165.954 199.227Z"
        fill="#00CACD"
      />
      <path
        d="M394.827 337.503C411.482 307.316 411.383 274.628 394.606 264.491C377.829 254.355 350.726 270.609 334.071 300.795C317.416 330.982 317.515 363.67 334.292 373.807C351.069 383.943 378.172 367.69 394.827 337.503Z"
        fill="#00CACD"
      />
      <path
        d="M165.72 373.799C182.509 363.656 182.617 330.962 165.962 300.775C149.307 270.588 122.195 254.34 105.406 264.483C88.6177 274.627 88.5096 307.321 105.165 337.507C121.82 367.694 148.932 383.942 165.72 373.799Z"
        fill="#00CACD"
      />
      <path
        d="M394.622 235.527C411.399 225.39 411.498 192.702 394.843 162.515C378.187 132.328 351.085 116.075 334.308 126.211C317.53 136.348 317.431 169.036 334.087 199.223C350.742 229.409 377.845 245.663 394.622 235.527Z"
        fill="#00CACD"
      />
    </svg>
  )
}
