import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { TabsOuterWrapper, TabsWrapper, TabsItemContainer, TabsItem } from './Tabs.elements'

function Tabs(props) {
  // Destructure
  const { page, setPage, pages } = props

  return (
    <TabsOuterWrapper>
      <TabsWrapper>
        {pages &&
          pages?.map((item) => (
            <TabsItemContainer>
              <TabsItem
                className={page === item.value && 'active'}
                onClick={() => {
                  setPage(item.value)
                }}
              >
                {item.label}
              </TabsItem>
            </TabsItemContainer>
          ))}
      </TabsWrapper>
    </TabsOuterWrapper>
  )
}

// Default Props
Tabs.defaultProps = {
  page: 0,
  setPage: () => {},
  pages: [],
}

// Proptypes Validation
Tabs.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  pages: PropTypes.arrayOf({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
}

export default Tabs
