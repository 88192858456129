import { useMutation } from 'react-query'

// Services
import { GetAlertDetails } from 'services/api/Alerts'

export default () => {
  const {
    mutate: getAlertDetails,
    data: alertDetailsData,
    error: alertDetailsError,
    isLoading: isAlertDetailsLoading,
    isSuccess: isAlertDetailsSuccess,
    isError: isAlertDetailsError,
  } = useMutation(GetAlertDetails)

  return {
    getAlertDetails,
    alertDetailsData,
    alertDetailsError,
    isAlertDetailsLoading,
    isAlertDetailsSuccess,
    isAlertDetailsError,
  }
}
