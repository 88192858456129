import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'

// Views
import { Image } from 'views/components'

// Styled Elements
import {
  SelectWrapper,
  Label,
  FormGroupWrapper,
  SelectInput,
  SelectInputLabel,
  SelectInputOptionsContainer,
  SelectInputOptions,
  FormErrorMessage,
  Abbreviation,
} from './FormSelect.elements'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

function FormSelect(props) {
  // Destructure
  const { label, placeholder, options, disabled, type, ...rest } = props

  const [fields] = useField(props)

  // Variables
  const withOptions = options && options?.length > 0
  const wrapperRef = useRef()
  const inputRef = useRef(null)
  const { name, value, onChange } = fields

  // Hooks
  const [formValue, setValue] = useState('')
  const [valueLabel, setValueLabel] = useState(placeholder || '')
  const [showOptions, setShowOptions] = useState(false)

  // Functions
  const handleOnChange = async (elValue, elLabel) => {
    await setValue(elValue)
    if (elValue === undefined) {
      await setValueLabel(placeholder || '')
      return setShowOptions(false)
    }
    await setValueLabel(elLabel)
    setShowOptions(false)
    // manually calling onchange event of formik input through onClick
    inputRef.current.click()
  }

  // UseEffects
  useEffect(() => {
    // setting the value and label on initial load
    if (value !== undefined && value !== null) {
      setValue(value)
      const currentLabel = options.find((data) => {
        if (typeof data.value === 'number') {
          return data.value === Number(value)
        }
        return data.value === value
      })
      if (currentLabel?.label) {
        setValueLabel(currentLabel.label)
      } else if (!currentLabel?.label) {
        setValueLabel(placeholder || '')
      }
    }
  }, [value])

  // Closing options on click away
  useOutsideAlerter(wrapperRef, setShowOptions)

  return (
    <SelectWrapper label={label} ref={wrapperRef}>
      {label && <Label disabled={disabled}>{label}</Label>}
      <FormGroupWrapper>
        <SelectInput
          ref={inputRef}
          type={type}
          onClick={(e) => {
            onChange(e)
          }}
          name={name}
          onChange={() => {}}
          value={formValue}
          {...rest}
        />
        <SelectInputLabel
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              setShowOptions((prevState) => !prevState)
            }
          }}
        >
          {valueLabel !== undefined ? valueLabel : placeholder}
        </SelectInputLabel>
        {showOptions && (
          <SelectInputOptionsContainer>
            {withOptions &&
              options.map(({ value, label, abrv, icon }) => (
                <div key={label}>
                  {label !== valueLabel && (
                    <SelectInputOptions
                      onClick={() => {
                        handleOnChange(value, label)
                      }}
                      value={value}
                    >
                      {icon && <Image src={icon} width={30} height={30} />}
                      {label}
                      {abrv && <Abbreviation> {abrv} </Abbreviation>}
                    </SelectInputOptions>
                  )}
                </div>
              ))}
          </SelectInputOptionsContainer>
        )}
        <FormErrorMessage>
          <ErrorMessage {...fields} />
        </FormErrorMessage>
      </FormGroupWrapper>
    </SelectWrapper>
  )
}

// Default Props
FormSelect.defaultProps = {
  label: '',
  placeholder: '',
  type: '',
  options: [],
}

// Proptypes Validation
FormSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
}

export default FormSelect
