export const onEvent = (content) => `
    &:active,
    &:focus,
    &:hover {
     ${content}
    }`

export const transition = (value) => `
    -webkit-transition: ${value};
    -moz-transition: ${value};
    -ms-transition: ${value};
    -o-transform: ${value};
    transition: ${value};`

export const transform = (value) => `
    -webkit-transform: ${value};
    -moz-transform: ${value};
    -ms-transform: ${value};
    -o-transform: ${value};
    transform: ${value};`

export const animation = (value) => `
    -webkit-animation: ${value};
    -moz-animation: ${value};
    -ms-animation: ${value};
    -o-transform: ${value};
    animation: ${value};`

export const boxShadow = (value) => `
    -webkit-box-shadow: ${value};
    -moz-box-shadow: ${value};
    -ms-box-shadow: ${value};
    -o-box-shadow: ${value};
    box-shadow: ${value};`

export const textShadow = (value) => `
    -webkit-text-shadow: ${value};
    -moz-text-shadow: ${value};
    -ms-text-shadow: ${value};
    -o-text-shadow: ${value};
    text-shadow: ${value};`
