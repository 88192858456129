import { useMutation } from 'react-query'

// Services
import { UpdateRecipientIndividual } from 'services/api/Recipients'

export default () => {
  const {
    mutate: updateRecipientIndividual,
    data: recipientIndividualUpdateData,
    error: recipientIndividualUpdateError,
    isLoading: isRecipientIndividualUpdateLoading,
    isSuccess: isRecipientIndividualUpdateSuccess,
    isError: isRecipientIndividualUpdateError,
  } = useMutation(UpdateRecipientIndividual)

  return {
    updateRecipientIndividual,
    recipientIndividualUpdateData,
    recipientIndividualUpdateError,
    isRecipientIndividualUpdateLoading,
    isRecipientIndividualUpdateSuccess,
    isRecipientIndividualUpdateError,
  }
}
