import { useMutation } from 'react-query'

// Services
import { GetMonitoringTransactionReport } from 'services/api/Monitoring'

export default () => {
  const {
    mutate: getMonitoringTransactionReport,
    data: monitoringTransactionReportData,
    error: monitoringTransactionReportError,
    isLoading: isMonitoringTransactionReportLoading,
    isSuccess: isMonitoringTransactionReportSuccess,
    isError: isMonitoringTransactionReportError,
  } = useMutation(GetMonitoringTransactionReport)

  return {
    getMonitoringTransactionReport,
    monitoringTransactionReportData,
    monitoringTransactionReportError,
    isMonitoringTransactionReportLoading,
    isMonitoringTransactionReportSuccess,
    isMonitoringTransactionReportError,
  }
}
