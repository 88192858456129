import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
// import jwt from 'jwt-decode'

// Constants
// import { TIMEZONES } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useActivateUser, useGetActivationLink } from 'core/hooks/api'

// Styled Elements
import {
  FormWrapper,
  FormInputGroupItem,
  FormGroupWrapper,
  // FormInputTimezoneGroupItem,
  FormTitle,
  ErrorTitle,
  ButtonWrapper,
} from './UserSignupForm.elements'

// Views
import { Form, FormTextField, LoaderCircle, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

function UserSignupForm(props) {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Hooks
  const { activateUser, activateUserData, activateUserError, isActivateUserLoading } = useActivateUser()
  const { getActivationLink, getActivationLinkData, getActivationLinkError, isGetActivationLinkLoading } =
    useGetActivationLink()
  const { auth_hash } = useParams()
  // const token = jwt(jwttoken)

  // Ref
  const formRef = useRef()

  // Local State
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirm_password: '',
  })

  // Variables
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character'),
    confirm_password: Yup.string().when('password', {
      is: (val) => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    }),
  })

  // Functions
  const handleOnSubmit = (values) => {
    if (values?.password) {
      activateUser({ auth_hash, password: values?.password })
    }
  }

  // UseEffects
  useEffect(() => {
    if (activateUserError) showAlert({ type: 'error', message: 'An error occured in registering' })
  }, [activateUserError])
  useEffect(() => {
    if (auth_hash) getActivationLink({ auth_hash })
  }, [auth_hash])
  useEffect(() => {
    if (getActivationLinkData && getActivationLinkData?.email) {
      setFormValues((prevValues) => ({
        ...prevValues,
        email: getActivationLinkData.email,
      }))
    }
  }, [getActivationLinkData])
  return (
    <FormWrapper>
      {activateUserData && !activateUserError && (
        <FormTitle>Successfully activated account, you may now login.</FormTitle>
      )}
      {!isGetActivationLinkLoading && getActivationLinkError && (
        <ErrorTitle>Activation link is invalid or expired</ErrorTitle>
      )}
      {activateUserData && (
        <ButtonWrapper href="/login">
          <Button fullWidth>Go to Login</Button>
        </ButtonWrapper>
      )}
      {isGetActivationLinkLoading && <LoaderCircle />}
      {!activateUserData && formValues?.email && !isGetActivationLinkLoading && (
        <Form
          initialValues={formValues}
          formRef={formRef}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          <FormGroupWrapper>
            <FormInputGroupItem>
              <FormTextField label="Email" type="email" name="email" disabled />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Password" type="password" name="password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <FormTextField label="Confirm Password" type="password" name="confirm_password" />
            </FormInputGroupItem>
          </FormGroupWrapper>

          <Button fullWidth type="submit" disabled={isActivateUserLoading}>
            {isActivateUserLoading ? 'Activating...' : 'Signup'}
          </Button>
        </Form>
      )}
    </FormWrapper>
  )
}

// Default Props
UserSignupForm.defaultProps = {
  formRef: {},
  form: {},
  actions: {},
}

// Proptypes Validation
UserSignupForm.propTypes = {
  formRef: PropTypes.shape({ root: PropTypes.string }),
  form: PropTypes.shape({
    TIMEZONES: PropTypes.instanceOf(Array),
    toBeUpdatedAccount: PropTypes.func,
  }),
  actions: PropTypes.shape({
    setCountryListOptions: PropTypes.func,
    setIsEntityAccountDrawerOpen: PropTypes.func,
    setToBeUpdatedAccount: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSignupForm)
