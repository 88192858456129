import { useMutation } from 'react-query'

// Services
import { GetReportsTravelRule } from 'services/api/Reports'

export default () => {
  const {
    mutate: getReportsTravelRule,
    data: getReportsTravelRuleData,
    error: getReportsTravelRuleError,
    isLoading: isGetReportsTravelRuleLoading,
    isSuccess: isGetReportsTravelRuleSuccess,
    isError: isGetReportsTravelRuleError,
  } = useMutation(GetReportsTravelRule)

  return {
    getReportsTravelRule,
    getReportsTravelRuleData,
    getReportsTravelRuleError,
    isGetReportsTravelRuleLoading,
    isGetReportsTravelRuleSuccess,
    isGetReportsTravelRuleError,
  }
}
