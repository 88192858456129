/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React, { Children } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  TableWrapper,
  HeaderFilterButton,
  TableBodyWrapper,
  TableBodyChildrenWrapper,
  HeaderOptionsWrapper,
} from './CardTable.elements'

// Views
import { Pagination } from 'views/components'

// Map Redux Props
function Table(props) {
  // Destructure
  const { headerOptions, children, totalItems, minWidth, filterComponents, tableFilters, setTableFilters, key } = props
  const { size, page } = tableFilters

  const handlePageChange = async ({ page, size }) => {
    await setTableFilters({ ...tableFilters, page, size })
  }

  return (
    <>
      <TableWrapper allowZeroExpanded>
        <HeaderFilterButton>
          <HeaderOptionsWrapper>{headerOptions}</HeaderOptionsWrapper>
        </HeaderFilterButton>

        {filterComponents && filterComponents}

        <TableBodyWrapper minWidth={minWidth}>
          <TableBodyChildrenWrapper key={key} length={Children.count(children)}>
            {children}
          </TableBodyChildrenWrapper>
        </TableBodyWrapper>
      </TableWrapper>

      <Pagination
        itemsCountPerPage={size}
        totalItemsCount={totalItems}
        page={page}
        pageRangeDisplayed={5}
        onChange={handlePageChange}
      />
    </>
  )
}

// Default Props
Table.defaultProps = {
  headerOptions: '',
  children: '',
  minWidth: 0,
  totalItems: 0,
  filterComponents: '',
  tableFilters: {},
  actions: {},
}

// Proptypes Validation
Table.propTypes = {
  key: PropTypes.any,
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  totalItems: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  setTableFilters: PropTypes.func,
  tableFilters: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
  }),
  actions: PropTypes.shape({
    setCaseManagementFilters: PropTypes.func,
  }),
}

export default Table
