import React, { createRef } from 'react'
import PropTypes from 'prop-types'

import { Formik, Form as FormikForm } from 'formik'

function Form(props) {
  // Destructure
  const { children, initialValues, onSubmit, validationSchema, formRef, autoComplete, ...rest } = props

  return (
    <Formik
      autocomplete="off"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
      {...rest}
    >
      <FormikForm autocomplete="off">{children}</FormikForm>
    </Formik>
  )
}

// Default Props
Form.defaultProps = {
  children: '',
  initialValues: {},
  onSubmit: () => {},
  validationSchema: {},
  formRef: createRef(),
  autoComplete: 'on',
}

// Proptypes Validation
Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  initialValues: PropTypes.shape({ root: PropTypes.string }),
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({ root: PropTypes.string }),
  formRef: PropTypes.shape({ root: PropTypes.string }),
  autoComplete: PropTypes.string,
}

export default Form
