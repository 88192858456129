import { useMutation } from 'react-query'

// Services
import { GetAllTransfers } from 'services/api/TravelRule'

export default () => {
  const {
    mutate: getAllTransfers,
    data: allTransferData,
    error: allTransferError,
    isLoading: isAllTransferLoading,
    isSuccess: isAllTransferSuccess,
    isError: isAllTransferError,
  } = useMutation(GetAllTransfers)

  return {
    getAllTransfers,
    allTransferData,
    allTransferError,
    isAllTransferLoading,
    isAllTransferSuccess,
    isAllTransferError,
  }
}
